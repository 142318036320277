import { AxiosRequestConfig } from 'axios';
import HttpService from './HttpService';

export interface SaveSearchData {
    category: number;
    search_type: number;
    pk: string;
    search_payload: {
        isPinned: boolean;
        name: string;
        url: string;
    };
}

export interface PostSaveSearch {
    category: number;
    search_type: number;
    search_payload: {
        isPinned: boolean;
        name: string;
        url: string;
    };
}

export interface SaveSearch {
    count: number;
    data: SaveSearchData[];
    next: null;
    previous: null;
}

class SaveSearchService extends HttpService {
    constructor(options?: AxiosRequestConfig) {
        super(options);
    }

    saveSearch = async (payload: PostSaveSearch): Promise<any> => {
        const response = await this.httpClient.post('/dashboard/searches/config', {
            category: payload.category,
            search_type: payload.search_type,
            search_payload: {
                name: payload.search_payload.name,
                url: payload.search_payload.url,
                isPinned: false,
            },
        });
        return response.data;
    };

    getSavedSearches = async (): Promise<SaveSearch> => {
        const response = await this.httpClient.get('/dashboard/searches/list?category=2');
        return response.data;
    };

    getPinnedSaveSearches = async (): Promise<SaveSearch> => {
        const response = await this.httpClient.get('/dashboard/searches/list/?category=2&isPinned=true&order_by=-search_payload__pinnedAt');
        return response.data;
    };

    pinnedSavedSearch = async (payload: SaveSearchData): Promise<SaveSearch> => {
        const updatedPayload = {
            category: payload.category,
            search_type: payload.search_type,
            search_payload: {
                name: payload.search_payload.name,
                url: payload.search_payload.url,
                isPinned: true,
                pinnedAt: new Date().getTime(),
            },
        };
        const response = await this.httpClient.patch(`/dashboard/searches/config/${payload.pk}/`, updatedPayload);
        return response.data;
    };

    unPinnedSavedSearch = async (payload: SaveSearchData): Promise<SaveSearch> => {
        const updatedPayload = {
            category: payload.category,
            search_type: payload.search_type,
            search_payload: {
                name: payload.search_payload.name,
                url: payload.search_payload.url,
                isPinned: false,
            },
        };
        const response = await this.httpClient.patch(`/dashboard/searches/config/${payload.pk}/`, updatedPayload);
        return response.data;
    };

    deleteSaveSearch = async (pk: string): Promise<SaveSearch> => {
        const response = await this.httpClient.delete(`/dashboard/searches/config/${pk}`);
        return response.data;
    };
}

export const saveSearchClient = Object.freeze(new SaveSearchService());
export default SaveSearchService;
