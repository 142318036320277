import React, { ComponentType } from 'react';
import { useLocation } from 'react-router-dom';

const withClaimContext = <P extends object>(WrappedComponent: ComponentType<P>) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function claimContext(componentProps: P | any) {
        const location = useLocation();

        return location.pathname.includes('/claim-creation') ||
            location.pathname.includes('/claim-review') ||
            location.pathname.includes('/claim-payment') ? (
            <WrappedComponent {...componentProps}>{componentProps.children}</WrappedComponent>
        ) : null;
    };

export default withClaimContext;
