export const environment = {
    production: true,
    APP_NAME: 'tigercare',
    PUBLIC_PATH: '/',
    STATS_FILENAME: 'webpack-stats-react_search.json',
    REACT_APP_API_BASE_URL: 'https://tigercare-bo-prod.i2go.io/api/v3',

    REACT_APP_GOOGLE_CAPTCHA: '6LdfcE8kAAAAANWtTs7RiulVdQP1NeGByA6kM6cw',
    REACT_APP_SESSION_KEY: 'product_quote',
    REACT_APP_PRODUCT_NAME: 'cochlear_device_insurance',
    REACT_APP_ITALIAN_PRODUCT_NAME: 'Protezione del dispositivo Cochlear',
    REACT_APP_STRIPE_PUBLISHABLE_KEY: 'pk_live_51LI6I8ElfST75SiPWAlFNyAlRTBxJCRn4kP9nMKTZZ7S4GQ19LPoLfHVnfDkbtEN5yEhpSW55NobByGVy6m3X6Z3003hKgH65t',

    REACT_APP_DEFAULT_USER_TOKEN: 'ffcd7301cb7abdbc0ccb2e108acc7899bcebaae6',
    REACT_APP_PRODUCT_UUID: 'a5f7281e-94b5-4d60-8476-57af82aeb17f',
    REACT_APP_PRODUCT_PLAN_ID: 'f694dfe6-b8fb-4351-a759-614ccdbc1c74',
    REACT_APP_CUSTOMER_ROLE: '02a06edb-d7ae-43f1-a3a7-d996eb46271a',
    REACT_APP_GUARDIAN_RELATIONSHIP_ID: 'bc8efb3d-3021-479b-93a4-d3ea29954c41',
    REACT_APP_PATIENT_RELATIONSHIP_ID: 'c3d9f1bd-4075-454e-8f59-a5f325806bcc',
    REACT_APP_AGENT_ID: 'bd0ad0ef-11dd-4281-88a4-19dea930261d',

    // Sentry DSN
    sentryDSN: 'https://dc4c2d0fc316eba427ce90189d49150c@o175944.ingest.us.sentry.io/4508364725616640',
};
