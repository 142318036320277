import { Box } from '@mui/material';
import FormHeader from 'components/FormHeader';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import React, { FC } from 'react';
import InsuranceAcknowledgeLocation from '../insuranceAcknowledgeLocation/InsuranceAcknowledgeLocation';
import PatientDetails from './PatientDetails';

const OtherAndSameOwnerDetails: FC = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];

    return (
        <Box sx={{ mb: 1 }}>
            <FormHeader
                title={formattedMessages.device_owner_details_label}
                titleVariant="subtitle1"
                titleStyle={{ fontWeight: 500, fontSize: '1.1rem' }}
                subtitle=""
                subtitleStyle={{ fontWeight: 400, color: 'rgba(0, 0, 0, 0.6)' }}
                rootStyle={{ mx: { xs: 1, md: 0 }, mb: { xs: 0, md: 1 } }}
            />
            <PatientDetails formikFieldName="patient" deviceModel="" deviceSerialNumber="" hasHeader={false} />
            <InsuranceAcknowledgeLocation />
        </Box>
    );
};

export default OtherAndSameOwnerDetails;
