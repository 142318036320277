import { CardContent, Dialog, LinearProgress, PaperProps, Typography } from '@mui/material';
import React, { FC } from 'react';

interface AttachmentUploadProps {
    isOpen: boolean;
    uploadProgress: number;
    paperProps?: Partial<PaperProps<'div'>>;
    cardTitle: string;
    cardSubheading: string;
}
const AttachmentUploadDialog: FC<AttachmentUploadProps> = ({ isOpen, cardTitle, cardSubheading, paperProps, uploadProgress }) => (
    <Dialog open={isOpen || false} PaperProps={{ ...paperProps }}>
        <CardContent sx={{ marginTop: '2rem' }}>
            <Typography gutterBottom variant="h5" component="div" style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>
                {cardTitle}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" style={{ textAlign: 'center' }}>
                {cardSubheading}
            </Typography>
            <LinearProgress variant="determinate" value={uploadProgress} />
        </CardContent>
    </Dialog>
);
AttachmentUploadDialog.defaultProps = {
    paperProps: { sx: { width: 452, height: 190 } },
};
export default AttachmentUploadDialog;
