import React, { lazy, Suspense } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import NoData from 'components/NoData';
import { dataProps, statusColor } from 'views/ClaimHistory';

const TableHeader = lazy(() => import('./TableHeader'));
const TableContent = lazy(() => import('./TableContent'));

interface CustomTableProps {
    data: dataProps[];
    statusLabel?: string;
    tableCell?: string[];
}

const CustomTable = ({ data, statusLabel, tableCell }: CustomTableProps) => (
    <TableContainer elevation={0} component={Paper} style={{ marginTop: 40 }}>
        <Suspense fallback={<span />}>
            {data && data?.length > 0 ? (
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHeader data={tableCell} />
                    <TableContent data={data} statusLabel={statusLabel} statusColor={statusColor} />
                </Table>
            ) : (
                <NoData endTitle="" />
            )}
        </Suspense>
    </TableContainer>
);

CustomTable.defaultProps = {
    statusLabel: undefined,
    tableCell: [
        'status_column_header',
        'claim_date_column_header',
        'incident_date_column_header',
        'insurance_product_name_column_header',
        'policy_number_label',
        'claim_number_column_header',
        'item_column_header',
        'type_of_notification_column_header',
        // 'Approved amount (USD)',
    ],
};

export default CustomTable;
