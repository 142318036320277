/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import { AuthenticationProvider } from 'context/Authentication';
import React from 'react';
import { useClaimContext } from './ClaimContext';
import CustomisedIntlProvider from './CustomisedIntlProvider';
// import { ClaimContext } from './ClaimContext';
import { DeviceProvider } from './Devices';
import useLanguageContext from './LanguageContext';
import { PolicyProvider } from './Policy';

export const composeProviders =
    (...providers: any) =>
    ({ children }: { children: React.ReactNode }) => providers.reduceRight(
            (child: React.ReactNode, ProviderWrapper: React.ComponentClass) => <ProviderWrapper>{child}</ProviderWrapper>,
            children
        );

const Provider = composeProviders(
    AuthenticationProvider,
    DeviceProvider,
    PolicyProvider,
    useClaimContext,
    useLanguageContext,
    CustomisedIntlProvider
);

export default Provider;
