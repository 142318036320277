import React, { MouseEventHandler } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Restore from '@mui/icons-material/Restore';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import translate from 'i18n/messages/translate';

interface DashboardHeaderProps {
    buttonTitle?: string;
    endActionTitle?: string;
    handleEndAction?: MouseEventHandler<HTMLButtonElement> | undefined;
    handleSecondaryEndAction?: MouseEventHandler<HTMLButtonElement> | undefined;
    handleStartAction?: MouseEventHandler<HTMLButtonElement> | undefined;
    headerSubtitle?: string;
    headerTitle?: string;
    secondaryEndActionTitle?: string;
}

const DashboardHeader = ({
    buttonTitle,
    endActionTitle,
    handleEndAction,
    handleSecondaryEndAction,
    handleStartAction,
    headerSubtitle,
    headerTitle,
    secondaryEndActionTitle,
}: DashboardHeaderProps) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', mx: { xs: 1, md: 0 } }}>
        {buttonTitle && buttonTitle?.length > 0 ? (
            <Box sx={{ paddingBottom: 10 }}>
                <Button variant="text" startIcon={<ChevronLeft />} onClick={handleStartAction}>
                    {translate(buttonTitle)}
                </Button>
            </Box>
        ) : null}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ml: { xs: 1, md: 0 } }}>
                <Typography variant="h4">{translate(headerTitle ?? 'policies')}</Typography>
                {headerSubtitle && headerSubtitle?.length > 0 ? (
                    <Typography variant="subtitle1" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {headerSubtitle}
                    </Typography>
                ) : null}
            </Box>
            <Stack
                direction="row"
                sx={{
                    width: '100%',
                    justifyContent: { xs: 'flex-start', md: 'end' },
                    mt: { xs: 1, md: 0 },
                }}
            >
                {endActionTitle && endActionTitle?.length > 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box sx={{ display: 'inline-block', alignSelf: 'flex-end' }}>
                            <Button variant="text" startIcon={<Restore />} onClick={handleEndAction}>
                                {translate(endActionTitle)}
                            </Button>
                        </Box>
                    </Box>
                ) : null}
                {secondaryEndActionTitle && secondaryEndActionTitle?.length > 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '12px' }}>
                        <Box sx={{ display: 'inline-block', alignSelf: 'flex-end' }}>
                            <Button variant="contained" startIcon={<AddIcon />} onClick={handleSecondaryEndAction}>
                                {translate(secondaryEndActionTitle)}
                            </Button>
                        </Box>
                    </Box>
                ) : null}
            </Stack>
        </Box>
    </Box>
);

DashboardHeader.defaultProps = {
    buttonTitle: 'back to page',
    endActionTitle: 'claim_history_button_label',
    handleEndAction: undefined,
    handleSecondaryEndAction: undefined,
    handleStartAction: undefined,
    headerSubtitle: 'Gravida diam vitae sed consectetur nulla euismod lacus ornare dictumst eu consectetur',
    headerTitle: 'policies',
    secondaryEndActionTitle: 'add_new_device_button_label',
};

export default DashboardHeader;
