import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

const DeviceListLoader = () => (
    <Grid container spacing={1} sx={{ m: 2, p: 2, bgcolor: '#f5f5f5' }}>
        <Grid item xs={4} md={2}>
            <Skeleton variant="rectangular" height={60} width={60} sx={{ borderRadius: '4px', mt: 0.75, bgcolor: '#e0e0e0' }} />
        </Grid>
        <Grid item xs={8} md={10}>
            <Box display="flex" sx={{ flexDirection: 'column', ml: 1 }}>
                <Skeleton variant="text" sx={{ my: 0, py: 0, bgcolor: '#e0e0e0', width: { xs: 120, md: 160 } }} />
                <Skeleton variant="text" width={120} sx={{ my: 0, py: 0, bgcolor: '#e0e0e0', width: { xs: 100, md: 120 } }} />
                <Skeleton variant="rectangular" width={80} height={25} sx={{ my: 1, bgcolor: '#e0e0e0' }} />
            </Box>
        </Grid>
    </Grid>
);

export default DeviceListLoader;
