import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import FormHeader from 'components/FormHeader';
import { getDevicePurchaseLocation, getDevicePurchasePractitioner } from 'services/httpService';
import { LocationSelect } from 'interfaces/enrollment';
import { extractChoicesInfo } from 'utils/enrollment';
import { providerProps } from 'interfaces/profile';
import { AuthenticationStore } from 'context/Authentication';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import DeviceLocationSelect from './DeviceLocationSelect';

interface InsuranceAcknowledgeLocationProps {
    primaryHeaderStyle?: SxProps;
    secondaryHeaderStyle?: SxProps;
}
const InsuranceAcknowledgeLocation: FC<InsuranceAcknowledgeLocationProps> = ({ primaryHeaderStyle, secondaryHeaderStyle }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { practitioner, location } = authenticationState;
    const [practitionerStoresArr, setPractitionerStoresArr] = useState<LocationSelect[]>([]);
    const [practitionerStoreSelected, setPractitionerStoreSelected] = useState<string>(practitioner ?? '');
    const [locationsArr, setLocationsArr] = useState<LocationSelect[]>([]);
    const [locationsSelected, setLocationsSelected] = useState<string>(location ?? '');

    const updateLocationsArr = (practitionerSelected: string) => {
        getDevicePurchaseLocation(practitionerSelected).then((res) => {
            const modifiedLocationsArr = extractChoicesInfo(res);
            setLocationsArr(modifiedLocationsArr);
        });
    };
    useEffect(() => {
        // get the complete list of practitioner stores when page rendered

        getDevicePurchasePractitioner().then((res) => {
            const modifiedPractitionersArr: LocationSelect[] = extractChoicesInfo(res);
            setPractitionerStoresArr(modifiedPractitionersArr);
            if (practitionerStoreSelected) {
                const practitionerSlug = practitionerStoreSelected.split(':')[0];
                updateLocationsArr(practitionerSlug);
                if (location) setLocationsSelected(location);
            }
        });
    }, []);

    const handlePractitionerStoreChange = (practitionerSelected: string) => {
        const [slug] = practitionerSelected.split(':');
        setPractitionerStoreSelected(practitionerSelected);
        updateLocationsArr(slug);
    };

    const handleLocationChange = (locationInput: string) => {
        setLocationsSelected(locationInput);
    };
    return (
        <>
            <FormHeader
                title={formattedMessages['where_hear_insurance_label' as keyof typeof formattedMessages]}
                titleVariant="subtitle1"
                titleStyle={{ fontWeight: 500, fontSize: '1.1rem', ...primaryHeaderStyle }}
                subtitleStyle={{ ...secondaryHeaderStyle }}
                subtitle=""
                rootStyle={{ mx: { xs: 1, md: 0 }, mb: { xs: 0, md: 1 } }}
            />
            <Box display="flex" sx={{ mx: { xs: 1 }, flexDirection: { xs: 'column', md: 'row' } }}>
                <DeviceLocationSelect
                    itemsArr={practitionerStoresArr}
                    name="practitioner"
                    valueSelected={practitionerStoreSelected}
                    selectLabel={formattedMessages.enrollment_practitioner_center_label}
                    handleChange={handlePractitionerStoreChange}
                />
                <DeviceLocationSelect
                    itemsArr={locationsArr}
                    name="location"
                    selectLabel={formattedMessages.location_label}
                    valueSelected={locationsSelected}
                    handleChange={handleLocationChange}
                    // disable when practitioner store not selected
                    isDisabled={!practitionerStoreSelected}
                />
            </Box>
        </>
    );
};

export default InsuranceAcknowledgeLocation;
InsuranceAcknowledgeLocation.defaultProps = {
    primaryHeaderStyle: {},
    secondaryHeaderStyle: {},
};
