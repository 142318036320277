import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

interface HttpService {
    httpClient: AxiosInstance;
    safePromise(promise: () => Promise<AxiosResponse>): Promise<AxiosResponse>;
}

class HttpService {
    public httpClient: AxiosInstance;

    constructor(options: AxiosRequestConfig = {}) {
        const token = Cookies.get('csrftoken');
        if (token) {
            if (options.headers) {
                // eslint-disable-next-line no-param-reassign
                options.headers['X-CSRFToken'] = token;
            } else {
                // eslint-disable-next-line no-param-reassign
                options.headers = { 'X-CSRFToken': token };
            }
        }
        this.httpClient = axios.create(options);
    }

    safePromise = async (promise: () => Promise<AxiosResponse>) => {
        try {
            const response = await promise();
            return response;
        } catch (err: any) {
            console.error(`Failed to fetch data. ${err.message}`);
            throw err;
        }
    };
}

export default HttpService;
