import { Box, Button, Grid } from '@mui/material';
import Header from 'components/Header';
import PageLayout from 'components/PageLayout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import FormHeader from 'components/FormHeader';
import DeviceCard from 'views/enrollment/cardComponent/DeviceCard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import paymentValidation from 'context/paymentValidation';
import { useMediaQuery } from 'react-responsive';
import translate from 'i18n/messages/translate';
import StripePaymentForm from './paymentForm/StripePaymentForm';
import { environment } from '../../../environments/environment';

const Payment = () => {
    const navigate = useNavigate();
    const stripePromise = loadStripe(environment.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '');
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    return (
        <PageLayout>
            <Header
                title=""
                subTitle="checkout_label"
                hasBackButton
                rootStyle={{ mx: { xs: 1, md: 0 } }}
                backButton={
                    <Box display="flex">
                        <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => navigate('/enrollment')}>
                            {translate('back')}
                        </Button>
                    </Box>
                }
            />
            {!isBigScreen && <DeviceCard isDeviceRemovable={false} />}
            <Grid container spacing={2}>
                <Grid sx={{ mt: { xs: '25px', md: '40px' }, mx: { xs: 1, md: 0 } }} item lg={7} xs={12}>
                    <FormHeader titleVariant="h6" title="payment_details_label" />
                    <Elements stripe={stripePromise}>
                        <StripePaymentForm />
                    </Elements>
                </Grid>
                {isBigScreen && <DeviceCard isDeviceRemovable={false} />}
            </Grid>
        </PageLayout>
    );
};

const PaymentValidate = paymentValidation(Payment);
export default PaymentValidate;
