/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode, useMemo } from 'react';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import translate from 'i18n/messages/translate';

interface VerificationWrapperProp {
    children: ReactNode;
    textH5: string | ReactNode;
    textBody1: string | ReactNode;
    continueHandler: () => void;
    goBackHandler: () => void;
    goBackButtonLabel: string | ReactNode;
    disabled?: boolean;
    processing?: boolean;
    disabledPrevious?: boolean;
    disabledContinue?: boolean;
}

const VerificationWrapper = ({
    disabled,
    disabledContinue,
    processing,
    children,
    textBody1,
    goBackHandler,
    continueHandler,
    disabledPrevious,
    goBackButtonLabel,
    textH5,
}: VerificationWrapperProp) => {
    const disabledLinkStyle = useMemo(() => (disabled ? { color: 'gray', textDecoration: 'none', cursor: 'default' } : {}), [disabled]);
    return (
        <Container gap={2}>
            <TopContainer direction="row">
                <TopLeft disabledPrevious={Boolean(disabledPrevious)}>
                    <TextH5>{textH5}</TextH5>
                    <TextBody1>{textBody1}</TextBody1>
                </TopLeft>
                {!disabledPrevious && (
                    <Link disabled={disabled} sx={{ marginTop: '5px', ...disabledLinkStyle }} component="button" onClick={goBackHandler}>
                        {goBackButtonLabel}
                    </Link>
                )}
            </TopContainer>
            {children}
            <Disclaimer>
                {translate('login_disclaimer', {
                    terms_and_condition: <InlineTextLink href="https://tigercare.io/terms-of-use/">{translate('term_and_condition')}</InlineTextLink>,
                    privacy_policy: <InlineTextLink href="https://tigercare.io/privacy-policy/">{translate('privacy_policy')}.</InlineTextLink>,
                })}
            </Disclaimer>
            <ContinueButton
                key="button-continue-enroll"
                loading={processing}
                disabled={disabledContinue}
                variant="contained"
                onClick={continueHandler}
            >
                <span>{translate('continue')}</span>
            </ContinueButton>
        </Container>
    );
};

VerificationWrapper.defaultProps = {
    disabled: false,
    processing: false,
    disabledPrevious: false,
    disabledContinue: false,
};

const InlineTextLink = styled(Link)(() => ({
    color: '#00000099',
    textDecorationColor: '#00000099',
}));

const Disclaimer = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    color: '#00000099',
}));

const TextH5 = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: 400,
    color: '#000000DE',
}));

const TextBody1 = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000DE',
}));

const TopLeft = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'disabledPrevious',
})<{ disabledPrevious: boolean }>(({ theme, disabledPrevious }) => ({
    flexGrow: 1,
    gap: '8px',
    maxWidth: !disabledPrevious ? '371px' : 'none',

    [theme.breakpoints.down('sm')]: {
        maxWidth: !disabledPrevious ? '247px' : 'none',
    },
}));

const TopContainer = styled(Stack)(() => ({
    gap: '20px',
    alignItems: 'flex-start',
}));

const Container = styled(Stack)(({ theme }) => ({
    width: '100%',
    maxWidth: '488px',

    [theme.breakpoints.up('sm')]: {
        minWidth: '488px',
    },
}));

const ContinueButton = styled(LoadingButton)(() => ({
    width: '121px',
}));

export default VerificationWrapper;
