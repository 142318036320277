import React, { CSSProperties, MouseEventHandler } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SxProps } from '@mui/system';
import { MuiColor } from 'interfaces/general';
import { Backdrop } from '@mui/material';
import PageLoader from 'components/loader/PageLoader';

interface ConfirmationAlertProps {
    ariaDescriptionBy?: string;
    ariaLabelBy?: string;
    dialogContentTextId?: string;
    dialogTitleId?: string;
    handleClose?: MouseEventHandler<HTMLButtonElement>;
    handlePrimaryAction?: MouseEventHandler<HTMLButtonElement>;
    handleSecondaryAction?: MouseEventHandler<HTMLButtonElement>;
    open: boolean;
    primaryActionTitle?: string;
    secondaryActionTitle?: string;
    subtitle?: string;
    subtitleStyle?: CSSProperties;
    title?: string;
    titleStyle?: CSSProperties;
    fullWidth?: boolean;
    loading?: boolean;
    secondaryButtonStyle?: SxProps;
    primaryButtonStyle?: SxProps;
    primaryButtonColor?: MuiColor;
    secondaryButtonColor?: MuiColor;
}

const ConfirmationAlert = ({
    ariaDescriptionBy,
    ariaLabelBy,
    dialogContentTextId,
    dialogTitleId,
    fullWidth,
    handleClose,
    handlePrimaryAction,
    handleSecondaryAction,
    open,
    primaryActionTitle,
    secondaryActionTitle,
    subtitle,
    subtitleStyle,
    title,
    titleStyle,
    loading,
    secondaryButtonStyle,
    primaryButtonStyle,
    primaryButtonColor,
    secondaryButtonColor,
}: ConfirmationAlertProps) => (
    <Dialog open={open} fullWidth={fullWidth} onClose={handleClose} aria-labelledby={ariaLabelBy} aria-describedby={ariaDescriptionBy}>
        {title && title?.length > 0 ? (
            <DialogTitle id={dialogTitleId} sx={{ ...titleStyle }}>
                {title}
            </DialogTitle>
        ) : null}
        {subtitle && subtitle?.length > 0 ? (
            <DialogContent>
                <DialogContentText id={dialogContentTextId} sx={{ ...subtitleStyle }}>
                    {subtitle}
                </DialogContentText>
            </DialogContent>
        ) : null}
        <DialogActions>
            {primaryActionTitle ? (
                <Button variant="text" sx={{ ...primaryButtonStyle }} color={primaryButtonColor} onClick={handlePrimaryAction} disabled={loading}>
                    {primaryActionTitle}
                </Button>
            ) : null}
            {secondaryActionTitle ? (
                <Button
                    variant="contained"
                    sx={{ ...secondaryButtonStyle }}
                    color={secondaryButtonColor}
                    onClick={handleSecondaryAction}
                    autoFocus
                    disabled={loading}
                >
                    {secondaryActionTitle}
                </Button>
            ) : null}
        </DialogActions>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 99 }} open={loading ?? false}>
            <PageLoader rootStyle={{ zIndex: '99' }} />{' '}
        </Backdrop>
    </Dialog>
);

ConfirmationAlert.defaultProps = {
    ariaDescriptionBy: 'alert-dialog-description',
    ariaLabelBy: 'alert-dialog-title',
    dialogContentTextId: 'dialogContentTextId',
    dialogTitleId: 'dialogTitleId',
    handleClose: undefined,
    handlePrimaryAction: undefined,
    handleSecondaryAction: undefined,
    primaryActionTitle: 'Cancel',
    secondaryActionTitle: 'Proceed',
    subtitle: 'An account with this email address already exists. Click proceed to verify your account.',
    title: 'Account Exists',
    fullWidth: false,
    titleStyle: {},
    subtitleStyle: {},
    loading: false,
    secondaryButtonStyle: {},
    primaryButtonStyle: {},
    primaryButtonColor: 'primary',
    secondaryButtonColor: 'primary',
};

export default ConfirmationAlert;
