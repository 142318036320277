import { Typography } from '@mui/material';
import React, { FC, useContext, useMemo } from 'react';
import { AuthenticationStore } from 'context/Authentication';
import { providerProps } from 'interfaces/profile';
import { useFormikContext } from 'formik';
import { EnrollmentFormValues } from 'interfaces/enrollment';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface DeviceOwnerNamingProps {
    serialNumber: string;
}
const DynamicOwnerNaming: FC<DeviceOwnerNamingProps> = ({ serialNumber }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { firstName, lastName } = authenticationState;
    const { values } = useFormikContext<EnrollmentFormValues>();
    const { personalOrOthers, sameOwner, patients, patient } = values;
    const listedDeviceOwner = useMemo(() => {
        if (personalOrOthers === 'personal') {
            return `${firstName} ${lastName}`;
        }
        if (sameOwner) {
            return `${patient.firstName} ${patient.lastName}`;
        }
        const correspondingPatient = patients.find((patientLoop) => {
            const deviceSerialNumber = Object.keys(patientLoop.device)[0];
            return serialNumber === deviceSerialNumber;
        });
        return `${correspondingPatient?.firstName ?? ''} ${correspondingPatient?.lastName ?? ''}`;
    }, [personalOrOthers, sameOwner, patients, patient, firstName, lastName]);
    return (
        <Typography variant="body2" sx={{ pl: 1, display: 'flex', color: 'rgba(0, 0, 0, 0.6)', flexDirection: { xs: 'column', md: 'row' } }}>
            <span style={{ minWidth: 110 }}>{`${formattedMessages.owner_label}: `}</span> {listedDeviceOwner}
        </Typography>
    );
};

export default DynamicOwnerNaming;
