/* eslint-disable no-use-before-define */
import { useEffect, useRef } from 'react';
import { useLocation, Location } from 'react-router-dom';
import { usePrevious } from './usePrevious';

const defaultIgnoreFirstRender = true;
type IgnoreFirstRender = typeof defaultIgnoreFirstRender;

type PrevArg<T = IgnoreFirstRender> = T extends true ? Location : Location | undefined;
type LocationArgs<T = IgnoreFirstRender> = [current: Location, previous: PrevArg<T>];
type LocationEqualityFn<T = IgnoreFirstRender> = (...args: LocationArgs<T>) => boolean;
type LocationChangeHandler<T = IgnoreFirstRender> = (...args: LocationArgs<T>) => void;

type UseLocationChangeOptions<T extends boolean = IgnoreFirstRender> = {
    /**
     * A custom equality function that determines whether the two locations are equal (and thus should not trigger the handler).
     *
     * By default, it compares the `pathname` of the two locations. ({@linkcode defaultEqualityFn})
     */
    equalityFn?: LocationEqualityFn<T>;
    /**
     * The handler to call when the location changes. Accepts the new location and the previous location.
     */
    handler: LocationChangeHandler<T>;
    /**
     * Whether to ignore the first render (i.e. do not call the handler if the previous location is undefined).
     *
     * Default: `true` ({@linkcode defaultIgnoreFirstRender})
     */
    ignoreFirstRender?: T;
};

/**
 * A hook that calls a handler when the location changes.
 * The handler is called with the new location and the previous location.
 *
 * Can be called in two ways:
 * - `useLocationChange((location, prevLocation) => { ... })`: Uses the default equality function which compares based on the pathname.
 * - `useLocationChange({ equalityFn, handler })`: Allows you to specify a custom equality function.
 *
 * **NOTE: The default equality function will not call the handler on the first render**
 * (i.e. will not invoke if the previous pathname is undefined)
 *
 * @example
 * ```tsx
 * useLocationChange((location) => {
 *     console.log('Pathname changed to', location.pathname);
 * });
 * ```
 *
 * @example
 * ```tsx
 * useLocationChange({
 *     equalityFn: (curr, prev) => curr.search === prev?.search,
 *     handler: (location, prevLocation) => {
 *         console.log(`Search query changed from "${prevLocation?.search}" to "${location.search}"`);
 *     }
 * });
 */
function useLocationChange<T extends boolean = IgnoreFirstRender>(cb: LocationChangeHandler<T>): void;
function useLocationChange<T extends boolean = IgnoreFirstRender>(options: UseLocationChangeOptions<T>): void;
function useLocationChange<T extends boolean = IgnoreFirstRender>(arg: UseLocationChangeOptions<T> | LocationChangeHandler<T>) {
    const location = useLocation();
    const previousLocation = usePrevious(location);
    const callbackRef = useRef<LocationChangeHandler<T>>();
    const equalityRef = useRef<LocationEqualityFn<T>>();
    const ignoreInitialRef = useRef(defaultIgnoreFirstRender);

    useEffect(() => {
        const defaultEquality = defaultEqualityFn as LocationEqualityFn<T>;
        callbackRef.current = typeof arg === 'function' ? arg : arg.handler;
        equalityRef.current = typeof arg === 'function' ? defaultEquality : arg.equalityFn || defaultEquality;
        ignoreInitialRef.current = typeof arg === 'function' ? defaultIgnoreFirstRender : arg.ignoreFirstRender ?? defaultIgnoreFirstRender;
    }, [arg]);

    useEffect(() => {
        const handler = callbackRef.current;
        const equalityFn = equalityRef.current;
        const ignoreInitial = ignoreInitialRef.current;

        if (handler && equalityFn) {
            if (ignoreInitial && !previousLocation) {
                return;
            }
            if (!equalityFn(location, previousLocation as PrevArg<T>)) {
                handler(location, previousLocation as PrevArg<T>);
            }
        }
    }, [location, previousLocation]);
}

const defaultEqualityFn: LocationEqualityFn = (curr, prev) => curr.pathname === prev.pathname;

export default useLocationChange;
