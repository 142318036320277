import { useState, useMemo } from 'react';
import messages from 'i18n/messages';
import { useMutation } from '@tanstack/react-query';
import { useLanguage } from 'context/LanguageContext';
import { getServiceRatingPricing } from 'services/httpService';
import { Pricing, listOfQuoteProps } from 'interfaces/quote';
import { productOverdue } from 'constant/quote';
import useQuotationStorage from './useQuotationStorage';

export interface QuotedItems {
    serialNumber: string;
    model: string;
    name: string;
    pricing: Pricing;
}

function useQuoteMutation() {
    const locale = useLanguage();
    const quoteStore = useQuotationStorage();
    const listSerialNumber = useMemo(() => quoteStore.items.listSerialNumber, [quoteStore.items]);
    const quotedItems = useMemo(() => quoteStore.items.quotedItems, [quoteStore.items]);
    const quoteResponse = useMemo(() => quoteStore.items.listQuote, [quoteStore.items]);
    const [fieldError, setFieldError] = useState<string | undefined>();
    const [removedItem, setRemovedItem] = useState<QuotedItems | undefined>();
    const formattedMessage = messages[locale];

    function mapResponseToQuotedItems(response: listOfQuoteProps, input: string) {
        return Object.entries(response).reduce((acc, [key, value]: any) => {
            if (listSerialNumber.length && listSerialNumber.some((sn) => sn === key)) {
                acc.push({ serialNumber: key, ...value });
            } else if (input === key) {
                acc.push({ serialNumber: input, ...value });
            }
            return acc;
        }, [] as QuotedItems[]);
    }

    const quoteMutation = useMutation({
        mutationFn: async (input: string) => {
            try {
                const response = await getServiceRatingPricing([...listSerialNumber, input]);
                const mappedQuotedItems = mapResponseToQuotedItems(response, input);

                quoteStore.addItem('listSerialNumber', [...listSerialNumber, input]);
                quoteStore.addItem('quotedItems', mappedQuotedItems);
                quoteStore.addItem('listQuote', response);
            } catch (error: any) {
                if (formattedMessage[error.message as keyof typeof formattedMessage]) {
                    setFieldError(formattedMessage[error.message as keyof typeof formattedMessage]);
                } else {
                    setFieldError(productOverdue);
                }
            }
        },
    });

    const removeQuoteMutation = useMutation({
        mutationFn: async (input: string) => {
            try {
                const filteredSerialNumber = listSerialNumber.filter((sn) => sn !== input);
                const response = await getServiceRatingPricing(filteredSerialNumber);
                const mappedQuotedItems = mapResponseToQuotedItems(response, input);

                quoteStore.addItem('listSerialNumber', filteredSerialNumber);
                quoteStore.addItem('quotedItems', mappedQuotedItems);
                quoteStore.addItem('listQuote', response);
                setRemovedItem(undefined);
            } catch (error: any) {
                setRemovedItem(undefined);
                throw Error(error.message);
            }
        },
    });

    const fetchPricing = (input: string, onSuccess?: () => void) => {
        const existingSN = listSerialNumber.find((sn) => sn === input);
        if (existingSN) {
            setFieldError(formattedMessage.error_similar_device);
            return;
        }
        quoteMutation.mutate(input, { onSuccess });
    };

    const removeQuoteItem = (serialNumber: string) => {
        if (listSerialNumber.length === 1) {
            quoteStore.removeItem('quotedItems');
            quoteStore.removeItem('listSerialNumber');
            quoteStore.removeItem('listQuote');
        } else {
            const itemToRemoved = quotedItems.find((value) => value.serialNumber === serialNumber);
            setRemovedItem(itemToRemoved);
            removeQuoteMutation.mutate(serialNumber);
        }
    };

    const resetFieldError = () => {
        quoteMutation.reset();
        setFieldError(undefined);
    };

    return {
        fetchPricing,
        resetFieldError,
        removeQuoteItem,
        removedItem,
        quotedItems,
        fieldError,
        totalPrice: quoteResponse?.totalAmounts.premiumExcludingIpt,
        status: {
            isError: quoteMutation.isError || Boolean(fieldError),
            isSucess: quoteMutation.isSuccess,
            isLoading: quoteMutation.isLoading,
            isRemoveError: removeQuoteMutation.isError,
            isRemoving: removeQuoteMutation.isLoading,
            isRemoveSuccess: removeQuoteMutation.isSuccess,
        },
    };
}

export default useQuoteMutation;
