import React, { Suspense, lazy } from 'react';
import Stack from '@mui/material/Stack';
import FormHeader from 'components/FormHeader';
import usePolicyOverview from 'hook/usePolicy';
import PageLoader from 'components/loader/PageLoader';
import { useMediaQuery } from 'react-responsive';
import MobileDeviceCard from 'components/deviceCard/mobileCard/MobileDeviceCard';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

const DevicesCard = lazy(() => import('components/deviceCard'));
const NoData = lazy(() => import('components/NoData'));

const Devices = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { loading, activePolicy } = usePolicyOverview();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const renderLoad = loading ? <PageLoader /> : <NoData />;

    const desktopDevicesRender = (
        <Suspense fallback={<span />}>{activePolicy && activePolicy?.length > 0 ? <DevicesCard deviceData={activePolicy} /> : renderLoad}</Suspense>
    );

    const mobileDevicesRender = <MobileDeviceCard data={activePolicy ?? []} />;
    return (
        <Stack sx={{ height: '100%' }}>
            <FormHeader title={formattedMessages.devices} rootStyle={{ p: { xs: 2, sm: 1, md: 0 } }} />
            {isBigScreen && <div style={{ margin: 10 }} />}
            {isBigScreen ? desktopDevicesRender : mobileDevicesRender}
        </Stack>
    );
};

export default Devices;
