import React from 'react';
import { Outlet } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import DesktopView from './DesktopView';

const AccountSetting = () => (
    <>
        <MediaQuery minWidth={1200}>
            <DesktopView />
        </MediaQuery>
        <MediaQuery maxWidth={1200}>
            <Outlet />
        </MediaQuery>
    </>
);

export default AccountSetting;
