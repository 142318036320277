import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';

interface AccountSettingProps {
    childPath?: number;
    defaultSelectedPage?: string;
    parentPath?: number;
    splitBy?: string;
}

const AccountSetting = ({ childPath, defaultSelectedPage, parentPath, splitBy }: AccountSettingProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedPage, setSelectedPage] = useState(defaultSelectedPage);
    const parentPathname = location.pathname.split(splitBy || '')[parentPath || 0];
    const childPathname = location.pathname.split(splitBy || '')[childPath || 0];

    useEffect(() => {
        if (childPathname) setSelectedPage(childPathname);
    }, [childPathname]);

    useEffect(() => {
        if (location.pathname === '/account-setting') navigate(`${location.pathname}/profile`, { replace: true });
    }, []);

    const handleSelect = (itemPath: string) => {
        const nextLocation = `/${parentPathname}/${itemPath}`;
        if (childPathname !== itemPath) navigate(nextLocation);
    };

    return (
        <Stack style={{ padding: '41px 120px 0px 120px', marginBottom: 10, height: '100%', overflow: 'auto' }}>
            <Stack>
                <Header />
            </Stack>
            <Stack direction="row" style={{ paddingTop: 40, height: '100%' }}>
                <Stack style={{ minWidth: 220 }}>
                    <Sidebar defaultSelected={selectedPage} handleSelect={handleSelect} />
                </Stack>
                <Divider orientation="vertical" />
                <Stack style={{ width: '100%', marginLeft: 33 }}>
                    <Outlet />
                </Stack>
            </Stack>
        </Stack>
    );
};

AccountSetting.defaultProps = {
    childPath: 2,
    defaultSelectedPage: 'general',
    parentPath: 1,
    splitBy: '/',
};

export default AccountSetting;
