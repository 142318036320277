import { Outlet } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import Container from '@mui/material/Container';
import Navbar from 'v2/components/Navbar';
import Footer from 'v2/components/Footer';
import theme from 'v2/components/theme';
import Provider from 'context/Provider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'v2/components/ErrorFallback';

const Layout = () => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Stack>
                    <Navbar />
                    <Container disableGutters>
                        <ErrorBoundary fallback={<ErrorFallback callToAction="reload" />}>
                            <Outlet />
                        </ErrorBoundary>
                    </Container>
                    <Footer />
                </Stack>
            </ThemeProvider>
        </Provider>
    </LocalizationProvider>
);

export default Layout;
