import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import { MuiTypographyVariant } from 'interfaces/enrollment';
import { Box, SxProps } from '@mui/material';
import translate from 'i18n/messages/translate';

interface FormHeaderProps {
    buttonTitle?: string;
    rootStyle?: SxProps;
    subtitle?: string;
    subtitleStyle?: SxProps;
    subtitleVariant?: MuiTypographyVariant;
    title?: string;
    titleStyle?: SxProps;
    titleVariant?: MuiTypographyVariant;
    withAction?: boolean;
    withDivider?: boolean;
}

const FormHeader = ({
    buttonTitle,
    rootStyle,
    subtitle,
    subtitleStyle,
    subtitleVariant,
    title,
    titleStyle,
    titleVariant,
    withAction,
    withDivider,
}: FormHeaderProps) => (
    <Stack sx={{ ...rootStyle }}>
        <Stack direction="row" style={{ width: '100%', marginBottom: 2 }}>
            <Box>
                <Typography variant={titleVariant} sx={{ width: '100%', ...titleStyle }}>
                    {title?.includes('_') ? translate(title ?? 'personal_details_label') : title}
                </Typography>
                <Typography variant={subtitleVariant} sx={{ width: '100%', ...subtitleStyle }}>
                    {subtitle}
                </Typography>
            </Box>
            {withAction ? (
                <Button variant="text" startIcon={<EditIcon />} sx={{ minWidth: 90 }}>
                    {buttonTitle}
                </Button>
            ) : null}
        </Stack>
        {withDivider ? <Divider /> : null}
    </Stack>
);

FormHeader.defaultProps = {
    buttonTitle: 'Save',
    rootStyle: { width: '98%' },
    subtitle: '',
    subtitleStyle: {},
    subtitleVariant: 'subtitle2',
    title: 'personal_details_label',
    titleStyle: {},
    titleVariant: 'h5',
    withAction: false,
    withDivider: true,
};

export default FormHeader;
