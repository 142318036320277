import React, { useState, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import moment from 'moment';
import { cancelTargetedPolicy } from 'services/httpService';
import Popper, { popupProps } from 'components/Popper';
import { activePolicyProps } from 'views/dashboard';
import CardMenu from 'components/CardMenu';
import DocumentAlert from 'views/dashboard/customCard/DocumentAlert';
import ConfirmationAlert from 'components/customAlert/ConfirmationAlert';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

export interface listMenuProps {
    color: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any | null;
    id: number;
    label: string;
}

interface ItemMenuProps {
    item?: activePolicyProps;
}

const ItemMenu = ({ item }: ItemMenuProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const [popup, setPopup] = useState<popupProps>({
        message: '',
        severity: 'info',
        status: false,
    });
    const [loading, setLoading] = useState(false);
    const [actionAlert, setActionAlert] = useState({ document: false, policy: false });
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = (label: string) => {
        if (label === 'view_docs_button_label') {
            setActionAlert({ ...actionAlert, document: true });
        } else if (label === 'cancel_policy_button_label') {
            setActionAlert({ ...actionAlert, policy: true });
        }
    };

    const handleCloseDocumentAlert = () => {
        setActionAlert({ ...actionAlert, document: false });
    };

    const handleClosePolicyAlert = () => {
        setActionAlert({ ...actionAlert, policy: false });
    };

    const handleCancelPolicy = async () => {
        setLoading(true);

        if (item?.policySelfId) {
            cancelTargetedPolicy(item?.policySelfId as string)
                .then(() => {
                    window.location.reload();
                })
                .catch((error) => {
                    setLoading(false);
                    setPopup({
                        message: formattedMessages[error?.message as keyof typeof formattedMessages],
                        severity: 'error',
                        status: true,
                    });
                });
        } else {
            setLoading(false);
            setPopup({
                message: formattedMessages.no_uuid,
                severity: 'error',
                status: true,
            });
        }
    };

    const handlePopup = () => {
        setPopup({ ...popup, status: false });
    };

    const listMenu = useMemo(() => {
        const list: listMenuProps[] = [{ id: 0, icon: null, label: 'view_docs_button_label', color: '' }];

        if (!item) return list;

        const coveragePeriod = item.coveragePeriod.split(' - ');
        const priorThirtyDaysDate = moment(coveragePeriod[1]).subtract(30, 'days');
        const allowCancelledPeriod = moment().isBefore(moment(priorThirtyDaysDate));

        if (item.status !== 'Inactive' && allowCancelledPeriod) {
            list.push({ id: 1, icon: CancelSharpIcon, label: 'cancel_policy_button_label', color: 'red' });
        }

        return list;
    }, [item]);

    return (
        <>
            <IconButton aria-label="settings" onClick={handleOpen}>
                <MoreVertIcon />
            </IconButton>
            <CardMenu open={open} anchorEl={anchorEl} handleClose={handleClose} listMenu={listMenu} handleSelect={handleSelect} />
            <DocumentAlert
                open={actionAlert.document}
                handleHeaderAction={handleCloseDocumentAlert}
                policyDocument={item?.policyDocument}
                handleClose={handleCloseDocumentAlert}
            />
            <ConfirmationAlert
                fullWidth
                handlePrimaryAction={handleClosePolicyAlert}
                handleSecondaryAction={handleCancelPolicy}
                open={actionAlert.policy}
                primaryActionTitle={formattedMessages.close_label}
                secondaryActionTitle={formattedMessages.cancel_policy_button_label}
                subtitle={formattedMessages.cancel_policy_description}
                title={formattedMessages.cancel_policy_button_label}
                loading={loading}
                secondaryButtonColor="error"
            />
            <Popper handlePopup={handlePopup} popup={popup} />
        </>
    );
};

ItemMenu.defaultProps = {
    item: null,
};

export default ItemMenu;
