import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import translate from 'i18n/messages/translate';
import React from 'react';

interface TableHeaderProps {
    data?: string[];
}

const TableHeader = ({ data }: TableHeaderProps) => (
    <TableHead>
        <TableRow>
            {data && data?.length > 0
                ? data?.map((item: string, key: number) => {
                      const textAlign = key === data.length - 1 ? 'right' : 'left';

                      return (
                          <TableCell
                              variant="head"
                              align={textAlign}
                              key={item}
                              style={{ minWidth: '200px', fontWeight: 'bold', padding: '16px 4px' }}
                          >
                              {translate(item)}
                          </TableCell>
                      );
                  })
                : null}
        </TableRow>
    </TableHead>
);

TableHeader.defaultProps = {
    data: [],
};

export default TableHeader;
