import React from 'react';
import { Grid } from '@mui/material';
import PageLayout from 'components/PageLayout';
import Header from 'components/Header';
import NavigateButton from 'components/NavigateButton';
import { useMediaQuery } from 'react-responsive';
import ClaimForm from './claimForm';
import PolicyDetails from './PolicyDetails';

const ClaimCreationView = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    return (
        <PageLayout>
            <Header
                title="hearing_aid_header_label"
                subTitle="cochlear_device_insurance"
                rootStyle={{ mx: { xs: 1, md: 0 } }}
                hasBackButton
                backButton={<NavigateButton targetedURL="/dashboard" />}
            />
            <Grid container spacing={2} style={{ marginTop: 5 }}>
                {!isBigScreen && (
                    <Grid item xs={11} md={3} sx={{ mx: 2 }}>
                        <PolicyDetails />
                    </Grid>
                )}
                <Grid item md />
                <Grid item xs={11} md={6}>
                    <ClaimForm />
                </Grid>
                {isBigScreen && (
                    <Grid item xs={12} md={3}>
                        <PolicyDetails />
                    </Grid>
                )}
            </Grid>
        </PageLayout>
    );
};

export default ClaimCreationView;
