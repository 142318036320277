import { productStorageKey } from 'constant/devices';
import { defaultStorageStateProps } from 'interfaces/general';
import { userAuthenticationProps } from 'interfaces/profile';
import { listOfQuoteProps } from 'interfaces/quote';

export const defaultStorageState: defaultStorageStateProps = {
    listQuote: undefined,
    submissionEmailAddress: undefined,
    captcha: undefined,
    listSerialNumber: undefined,
    hasBp: undefined,
};

const storageHandler = (keyToUpdate?: string, valueToUpdate?: listOfQuoteProps | string | string[] | undefined) => {
    const productStore: string | null = sessionStorage.getItem(productStorageKey);
    if (productStore) {
        const product: defaultStorageStateProps = JSON.parse(productStore);
        let updatedProduct = {
            ...product,
        };
        if (keyToUpdate) {
            updatedProduct = {
                ...product,
                [keyToUpdate]: valueToUpdate,
            };
        }
        const JSONProduct = JSON.stringify(updatedProduct);
        sessionStorage.setItem(productStorageKey, JSONProduct);
    }

    if (!productStore) {
        let updatedProduct = {};
        if (keyToUpdate) {
            updatedProduct = {
                [keyToUpdate]: valueToUpdate,
            };
        }
        const JSONProduct = JSON.stringify(updatedProduct);
        sessionStorage.setItem(productStorageKey, JSONProduct);
    }
};

export const productStorage: () => userAuthenticationProps = () => {
    const productStore = sessionStorage.getItem(productStorageKey);
    if (productStore) {
        return JSON.parse(productStore);
    }
    return defaultStorageState;
};

export const clearProductStorage = () => {
    const productStore: string | null = sessionStorage.getItem(productStorageKey);
    if (productStore) {
        const product: defaultStorageStateProps = JSON.parse(productStore);
        if (product) {
            let toClear = false;
            Object.keys(product).forEach((productKey) => {
                if (product[productKey] !== undefined) {
                    toClear = true;
                }
            });

            if (toClear) {
                const JSONProduct = JSON.stringify(defaultStorageState);
                sessionStorage.setItem(productStorageKey, JSONProduct);
            }
        }
    }
};

export default storageHandler;
