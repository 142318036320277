import { Backdrop, Box, Button, Dialog } from '@mui/material';
import React, { FC, useMemo, useState } from 'react';
import { DeviceInfo } from 'interfaces/enrollment';
import DeviceListing from 'views/enrollment/cardComponent/DeviceListing';
import ModalHeader from 'components/ModalHeader';
import SearchSerialNumberInput from 'components/SearchSerialNumberInput';
import getDeviceImage from 'utils/getDeviceImage';
import translate from 'i18n/messages/translate';
import PageLoader from './loader/PageLoader';
import DeviceListLoader from './loader/DeviceListLoader';

interface SearchDeviceModalProps {
    isOpen: boolean;
    addingDevice: boolean;
    confirmingModalUpdate: boolean;
    errorMessage: string;
    deviceArr: DeviceInfo[];
    handleClose: () => void;
    handleRemoveDevice: (input: string) => void;
    handleConfirmButton: () => Promise<void>;
    handleAddButtonClick: (serialNumberInput: string) => Promise<void>;
}
const SearchDeviceModal: FC<SearchDeviceModalProps> = ({
    isOpen,
    addingDevice,
    confirmingModalUpdate,
    errorMessage,
    deviceArr,
    handleClose,
    handleConfirmButton,
    handleRemoveDevice,
    handleAddButtonClick,
}) => {
    const [serialNumber, setSerialNumber] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSerialNumber(event.target.value);
    };

    const handleAddButtonClickInModal = (serialNumberInput: string) => {
        handleAddButtonClick(serialNumberInput).then(() => {
            setSerialNumber('');
        });
    };

    const render = useMemo(() => {
        if (addingDevice) {
            return Array(deviceArr.length + 1)
                .fill(0)
                .map((item, index) => (
                    // using index as keys as we only care about the number of the loaders
                    // eslint-disable-next-line react/no-array-index-key
                    <Box key={index} sx={{ mr: 5 }}>
                        <DeviceListLoader />
                    </Box>
                ));
        }
        return deviceArr.map((addedDevice) => {
            const addedDeviceSerialNumber = Object.keys(addedDevice)[0];

            return (
                <DeviceListing
                    key={addedDeviceSerialNumber}
                    imageUrl={getDeviceImage(addedDeviceSerialNumber)}
                    serialNumber={addedDeviceSerialNumber}
                    deviceName={addedDevice[addedDeviceSerialNumber].name}
                    handleRemoveDevice={() => handleRemoveDevice(addedDeviceSerialNumber)}
                />
            );
        });
    }, [addingDevice, deviceArr]);

    return (
        <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleClose}>
            <ModalHeader />
            <SearchSerialNumberInput
                handleAddButtonOnClick={handleAddButtonClickInModal}
                handleInputChange={handleInputChange}
                errorMessage={errorMessage}
                inputValue={serialNumber}
            />
            <Box display="flex" sx={{ flexDirection: 'column' }}>
                {render}
            </Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={confirmingModalUpdate}>
                <PageLoader rootStyle={{ zIndex: '99' }} />
            </Backdrop>

            <Box display="flex" justifyContent="flex-end">
                <Button sx={{ my: 2, mx: 1 }} size="large" variant="text" onClick={handleClose}>
                    {translate('cancel')}
                </Button>
                <Button sx={{ my: 2, mx: 1 }} size="large" disabled={deviceArr.length === 0} variant="contained" onClick={handleConfirmButton}>
                    {translate('confirm')}
                </Button>
            </Box>
        </Dialog>
    );
};

export default SearchDeviceModal;
