import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface listSideBarProps {
    path: string;
    label: string;
}

interface SidebarProps {
    defaultSelected?: string;
    handleSelect?: (itemPath: string) => void;
    navAriaLabel?: string;
    listSideBar?: listSideBarProps[];
}

const Sidebar = ({ defaultSelected, navAriaLabel, listSideBar, handleSelect }: SidebarProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const onHandleSelect = (itemPath: string) => {
        if (handleSelect) handleSelect(itemPath);
    };

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <nav aria-label={navAriaLabel}>
                <List>
                    {listSideBar && listSideBar?.length > 0
                        ? listSideBar.map((item) => (
                              <ListItem selected={defaultSelected === item.path} key={item.path} disablePadding>
                                  <ListItemButton disabled={defaultSelected === item.path} onClick={() => onHandleSelect(item.path)}>
                                      <ListItemText primary={formattedMessages[item.label as keyof typeof formattedMessages]} />
                                  </ListItemButton>
                              </ListItem>
                          ))
                        : null}
                </List>
            </nav>
        </Box>
    );
};

Sidebar.defaultProps = {
    defaultSelected: '',
    handleSelect: undefined,
    listSideBar: [
        { path: 'profile', label: 'profile' },
        { path: 'devices', label: 'devices' },
    ],
    navAriaLabel: 'Sidebar navigation',
};

export default Sidebar;
