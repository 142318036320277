/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AttachFile } from '@mui/icons-material';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import { Alert, Box, Button, Divider, List, ListItem, Typography } from '@mui/material';
import { ErrorMessage, useField } from 'formik';
import { useClaim } from 'context/ClaimContext';
import { ClaimFormValues, UploadedFile } from 'interfaces/claim';
import { useMediaQuery } from 'react-responsive';
import FormHeader from 'components/FormHeader';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import translate from 'i18n/messages/translate';
import SingleFileUploadWithProgress from './SingleFileUploadWithProgress';

const baseStyle = {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'space-around',
    border: '2px dashed',
    borderRadius: '6px',
    borderColor: 'rgba(63, 81, 181, 0.5)',
};

const focusedStyle = {
    borderColor: '#3F51B5',
    backgroundColor: '#FAFAFA',
};

const FileUploadMessage = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <>
            <Typography variant="caption">{formattedMessages.attachment_alert_header_label}</Typography>
            <List sx={{ my: 0, py: 0 }}>
                <ListItem sx={{ my: 0, py: 0, ml: 0, pl: 0, listStyleType: 'disc', display: 'list-item' }}>
                    <Typography variant="caption">{formattedMessages.attachment_alert_police_report_label}</Typography>
                </ListItem>
                <ListItem sx={{ my: 0, py: 0, ml: 0, pl: 0 }}>
                    <Typography variant="caption">{formattedMessages.attachment_alert_purchase_invoice_label}</Typography>
                </ListItem>
                <ListItem sx={{ my: 0, py: 0, ml: 0, pl: 0 }}>
                    <Typography variant="caption">{formattedMessages.attachment_alert_self_declaration}</Typography>
                </ListItem>
                <ListItem sx={{ my: 0, py: 0, ml: 0, pl: 0 }}>
                    <Typography variant="caption">{formattedMessages.attachment_alert_images}</Typography>
                </ListItem>
            </List>
        </>
    );
};

const FilesUploadField = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const claimDetails = useClaim();
    const [files, setFiles] = useState<UploadedFile[]>([]);

    const [, , helpers] = useField<ClaimFormValues['files']>('files');
    const [field] = useField<ClaimFormValues['dateOfIncident']>('dateOfIncident');
    const fileSizeErrorMessage = {
        code: formattedMessages.video_size_too_large_header,
        message: formattedMessages.video_size_too_large_message,
    };

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const mappedArr = acceptedFiles.map((file) => ({ file, errors: [] }));
        setFiles((prev) => [...prev, ...mappedArr, ...rejectedFiles]);
    }, []);

    const onFileDialogCancel = () => {
        if (files.length === 0) {
            helpers.setTouched(true);
        }
    };

    const validatorFunction = (inputFile: File) => {
        if (inputFile.type.includes('video') && inputFile.size > 10_000_000) {
            return fileSizeErrorMessage;
        }
        return null;
    };
    const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
        onFileDialogCancel,
        validator: validatorFunction,
    });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? focusedStyle : {}),
            ...(!isBigScreen ? { padding: '40px 24px' } : {}),
        }),
        [isDragActive, isBigScreen]
    );
    const onDelete = (file: File) => {
        setFiles((prev) => prev.filter((fw) => fw.file !== file));
    };

    useEffect(() => {
        setFiles(claimDetails.files);
    }, []);

    useEffect(() => {
        helpers.setValue(files);
        if (files.length > 0) {
            helpers.setTouched(true, false);
        }
    }, [files]);
    useEffect(() => {
        if (field.value !== null) {
            setFiles((prev) =>
                prev.map((fileItem) => ({
                    ...fileItem,
                    errors: validatorFunction(fileItem.file) !== null ? ([validatorFunction(fileItem.file)] as FileError[]) : ([] as FileError[]),
                }))
            );
        }
    }, [field.value]);

    return (
        <Box maxWidth="95vw">
            <FormHeader
                title={formattedMessages.attachment_question_label}
                withDivider
                titleVariant="h6"
                rootStyle={{ margin: { xs: '10px 0 10px 0', md: '0 0 20px 0' }, width: '100%' }}
            />
            <Alert sx={{ my: { xs: 1, md: 2 } }} severity="warning">
                <FileUploadMessage />
            </Alert>
            {isBigScreen && (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ textAlign: 'center' }}>
                            <img src="assets/folder.png" alt="folder" />
                            <Typography sx={{ my: 1 }} variant="subtitle2">
                                {formattedMessages.drag_drop_instruction_header}
                            </Typography>
                            <Divider sx={{ my: 1 }}>
                                <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="subtitle2">
                                    {formattedMessages.or_label}
                                </Typography>
                            </Divider>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                type="button"
                                sx={{ color: '#3F51B5', marginBottom: '1rem' }}
                                variant="outlined"
                                onClick={open}
                                startIcon={<AttachFile sx={{ color: '#3F51B5' }} />}
                            >
                                {formattedMessages.browse_file_button_label}
                            </Button>
                            <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="subtitle2">
                                {formattedMessages.drag_drop_instruction_details_line_one}
                            </Typography>
                            <Typography style={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="subtitle2">
                                {formattedMessages.drag_drop_instruction_details_line_two}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
            {!isBigScreen && (
                <Box display="flex" sx={{ flexDirection: 'column', textAlign: 'center' }}>
                    <Button
                        type="button"
                        sx={{ color: '#3F51B5', marginBottom: '1rem' }}
                        variant="outlined"
                        onClick={open}
                        fullWidth
                        startIcon={<AttachFile sx={{ color: '#3F51B5' }} />}
                    >
                        {formattedMessages.browse_file_button_label}
                    </Button>
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="subtitle2">
                        {formattedMessages.drag_drop_instruction_details_line_one}
                    </Typography>
                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="subtitle2">
                        {formattedMessages.drag_drop_instruction_details_line_two}
                    </Typography>
                </Box>
            )}
            <Typography variant="subtitle2" sx={{ color: '#d32f2f', mx: '14px', fontSize: '0.75rem' }}>
                <ErrorMessage name="files">{(err) => translate(err)}</ErrorMessage>
            </Typography>
            {files.map((file) => (
                <SingleFileUploadWithProgress key={file.file.lastModified} onDelete={onDelete} file={file.file} errors={file.errors} />
            ))}
        </Box>
    );
};

export default FilesUploadField;
