import { Button, Grid, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { FC } from 'react';
import { formatBytes } from 'utils/claim';
import { useMediaQuery } from 'react-responsive';

export interface FileHeaderProps {
    file: File;
    onDelete: (file: File) => void;
}

const FileHeader: FC<FileHeaderProps> = ({ file, onDelete }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    return (
        <Grid
            container
            sx={{
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'flex-start', md: 'space-between' },
                alignItems: { xs: 'flex-start', md: 'space-between' },
            }}
        >
            <Grid item>
                <Typography noWrap variant="subtitle2">
                    {file.name}
                </Typography>
                <Typography variant="subtitle2" color="rgba(0, 0, 0, 0.6)">
                    {formatBytes(file.size)}
                </Typography>
            </Grid>
            <Grid item>
                {isBigScreen && (
                    <IconButton onClick={() => onDelete(file)}>
                        <ClearIcon />
                    </IconButton>
                )}
                {!isBigScreen && (
                    <Button sx={{ pl: 0 }} variant="text" color="error" onClick={() => onDelete(file)}>
                        Remove
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

export default FileHeader;
