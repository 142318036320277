import { Close } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

interface EnlargedAttachmentPreview {
    isOpen: boolean;
    handleClose: () => void;
    file: File;
}

const EnlargedAttachmentPreview: FC<EnlargedAttachmentPreview> = ({ isOpen, file, handleClose }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    const renderComponent = useMemo(() => {
        if (file.type.includes('pdf')) {
            return <embed src={URL.createObjectURL(file)} width="1200px" height="800px" />;
        }
        if (file.type.includes('video')) {
            return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video style={{ backgroundSize: 'cover' }} width="100%" height="100%" controls>
                    <source src={URL.createObjectURL(file)} />
                </video>
            );
        }
        return (
            <img
                style={{ backgroundSize: 'cover', width: '100%', height: isBigScreen ? '100%' : '80%' }}
                alt="preview"
                src={URL.createObjectURL(file)}
            />
        );
    }, [file]);
    return (
        <Dialog open={isOpen} maxWidth="lg">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            {renderComponent}
        </Dialog>
    );
};

export default EnlargedAttachmentPreview;
