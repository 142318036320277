import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RoomIcon from '@mui/icons-material/Room';
import { capitalizeFirstLetter } from '_helper/utilities';
import format from 'date-fns/format';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ListItemButton from '@mui/material/ListItemButton';

interface objectProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface ListContentProps {
    handleSelect?: (event: unknown, value: objectProps) => void;
    headerTitle?: string;
    listData?: objectProps[];
    withHeader?: boolean;
    formState?: objectProps[];
}

const ListContent = ({ formState, handleSelect, headerTitle, listData, withHeader }: ListContentProps) => {
    const onHandleSelect = (value?: objectProps) => (event: unknown) => {
        if (handleSelect && value) handleSelect(event, value);
    };

    return (
        <Stack>
            {withHeader ? (
                <Stack style={{ padding: '12px 16px 0px 16px' }}>
                    <Typography component="p" variant="subtitle2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {headerTitle}
                    </Typography>
                </Stack>
            ) : null}
            <List>
                {listData && listData?.length > 0
                    ? listData?.map((item) => {
                          let themeBaseCession = 'primary.main';
                          if (item.cessionType === 'SP') {
                              themeBaseCession = '#006D75';
                          } else if (item.cessionType === 'FB') {
                              themeBaseCession = '#AD4E00';
                          }

                          const checkSelected = formState?.some((currentState) => currentState.sectionName === item.sectionName);

                          return (
                              <ListItem key={item.self.id} style={{ height: 76, padding: '16px 0px 16px 0px' }}>
                                  <ListItemButton
                                      disabled={checkSelected}
                                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'baseline' }}
                                      onClick={onHandleSelect(item)}
                                  >
                                      <Stack direction="row" style={{ alignItems: 'center' }}>
                                          <Typography variant="body1">{capitalizeFirstLetter(item?.sectionName?.toString())}</Typography>
                                          <Chip
                                              sx={{ color: themeBaseCession, borderColor: themeBaseCession }}
                                              label={item?.reformatCessionType?.toString()}
                                              variant="outlined"
                                              style={{ minWidth: 50, marginLeft: 12, cursor: 'unset' }}
                                          />
                                      </Stack>
                                      <Stack direction="row" style={{ marginTop: 5 }}>
                                          <Stack direction="row" style={{ alignItems: 'center' }}>
                                              <DateRangeIcon style={{ width: 15, height: 16.67, color: '#7986CB' }} />
                                              <Typography variant="caption" style={{ marginLeft: 10, color: 'rgba(0, 0, 0, 0.38)' }}>
                                                  {format(new Date(item?.dateFrom?.toString()), 'MMM dd yyyy')} -{' '}
                                                  {format(new Date(item?.dateTo?.toString()), 'MMM dd yyyy')}
                                              </Typography>
                                          </Stack>
                                          <Stack direction="row" style={{ marginLeft: 28, alignItems: 'center' }}>
                                              <RoomIcon style={{ width: 15, height: 16.67, color: '#7986CB' }} />
                                              <Typography variant="caption" style={{ marginLeft: 10, color: 'rgba(0, 0, 0, 0.38)' }}>
                                                  {item?.reformatTerritory?.toString() || '-'}
                                              </Typography>
                                          </Stack>
                                          <Stack direction="row" style={{ marginLeft: 28, alignItems: 'center' }}>
                                              <MonetizationOnIcon style={{ width: 15, height: 16.67, color: '#7986CB' }} />
                                              <Typography variant="caption" style={{ marginLeft: 10, color: 'rgba(0, 0, 0, 0.38)' }}>
                                                  {item?.currency?.toString()} {Number(item?.reinsurerLimit)?.toFixed(2)}
                                              </Typography>
                                          </Stack>
                                      </Stack>
                                  </ListItemButton>
                              </ListItem>
                          );
                      })
                    : null}
            </List>
        </Stack>
    );
};

ListContent.defaultProps = {
    handleSelect: undefined,
    headerTitle: '',
    listData: undefined,
    withHeader: true,
    formState: undefined,
};

export default ListContent;
