import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

interface CardDetailListingProps {
    attribute: string;
    value: string;
}
const CardDetailListing: FC<CardDetailListingProps> = ({ attribute, value }) => (
    <Box display="flex" sx={{ flexDirection: 'column', mb: 0.5 }}>
        <Typography variant="body2" sx={{ fontWeight: 400 }}>
            {attribute}
        </Typography>
        <Typography variant="caption" color="text.secondary">
            {value}
        </Typography>
    </Box>
);

export default CardDetailListing;
