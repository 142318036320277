import LOCALES, { localesCode } from 'i18n/locales';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

const languageContext = createContext('it-it');
const updateLanguageContext = createContext<(newLanguage: string) => void>({} as (newLanguage: string) => void);

export const useLanguage = () => useContext(languageContext);
export const useLanguageUpdate = () => useContext(updateLanguageContext);

const getBrowserLocales = (defaultLocales = LOCALES.ITALY) => navigator.language || defaultLocales;
const getAppLocales = () => {
    const locales = getBrowserLocales();
    const [code] = locales.split('-');
    const localeEntry = Object.entries(localesCode).find(([_, value]) => value === code);
    return localeEntry ? localeEntry[0] : locales;
};

interface useLanguageContextProps {
    children: React.ReactNode;
}

const useLanguageContext = ({ children }: useLanguageContextProps) => {
    const [locales, setLocales] = useState(getAppLocales());

    const updateLanguage = useCallback((newLanguage: string) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sessionStorage.setItem('language', newLanguage);
        setLocales(newLanguage);
    }, []);
    useEffect(() => {
        const selectedLanguage = sessionStorage.getItem('language');
        if (selectedLanguage) setLocales(selectedLanguage);
    }, []);

    return (
        <languageContext.Provider value={locales}>
            <updateLanguageContext.Provider value={updateLanguage}>{children}</updateLanguageContext.Provider>
        </languageContext.Provider>
    );
};

export default useLanguageContext;
