import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { ClickAwayListener, styled } from '@mui/material';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useLanguage, useLanguageUpdate } from 'context/LanguageContext';
import translate from 'i18n/messages/translate';
import { patchBp } from 'services/httpService';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import { localesCode } from 'i18n/locales';

// Workaround for incompatible props type for MUI Popper component
// More info - https://github.com/mui/material-ui/issues/35287#issuecomment-1337250566
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace React {
        interface DOMAttributes<T> {
            onResize?: ReactEventHandler<T> | undefined;
            onResizeCapture?: ReactEventHandler<T> | undefined;
            nonce?: string | undefined;
        }
    }
}

const LanguageMenu = () => {
    const quoteStore = useQuotationStorage();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [openMenu, setOpenMenu] = useState(false);
    const setLocales = useLanguageUpdate();
    const locales = useLanguage();

    const onLangugageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocales((event.target as HTMLInputElement).value);
    };

    const closeOnClickAway = (event: MouseEvent | TouchEvent) => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        if (quoteStore.items.businessPartnerUUID) {
            patchBp(quoteStore.items.businessPartnerUUID, { correspondenceLanguage: localesCode[locales] });
        }
        setOpenMenu(false);
    };

    return (
        <Box>
            <ButtonLanguage ref={anchorRef} onClick={() => setOpenMenu((prev) => !prev)} endIcon={<ExpandMoreIcon />}>
                {translate('languages')}
            </ButtonLanguage>
            <StyledPopper open={openMenu} anchorEl={anchorRef.current} placement="bottom" transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom-end' ? 'right top' : 'left bottom' }}
                        data-testid="languageMenu"
                    >
                        <Paper elevation={8}>
                            <ClickAwayListener onClickAway={closeOnClickAway}>
                                <Container>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="radio-buttons-group-label"
                                            onChange={onLangugageChange}
                                            value={locales}
                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel value="en-us" control={<Radio />} label="English" />
                                            <FormControlLabel value="it-it" control={<Radio />} label="Italiano" />
                                            <FormControlLabel value="de-de" control={<Radio />} label="Deutsch" />
                                        </RadioGroup>
                                    </FormControl>
                                </Container>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </StyledPopper>
        </Box>
    );
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const Container = styled(Box)(() => ({
    width: '220px',
    padding: '8px 20px',
}));

const ButtonLanguage = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export default LanguageMenu;
