import { Box } from '@mui/material';
import { useDevicesUpdate } from 'context/Devices';
import { DeviceInfo } from 'interfaces/enrollment';
import React, { useMemo, FC } from 'react';
import getDeviceImage from 'utils/getDeviceImage';
import DeviceListing from 'views/enrollment/cardComponent/DeviceListing';

interface CustomisedCardBodyProps {
    isDeviceRemovable: boolean;
    deviceArr: DeviceInfo[];
}
const CustomisedCardBody: FC<CustomisedCardBodyProps> = ({ isDeviceRemovable, deviceArr }) => {
    const { removeDevice } = useDevicesUpdate();

    const minimumOneDevice = useMemo(() => deviceArr.length === 1, [deviceArr]);

    return (
        <Box display="flex" sx={{ flexDirection: 'column' }}>
            {deviceArr.map((device) => {
                const serialNumber = Object.keys(device)[0];
                return (
                    <DeviceListing
                        key={serialNumber}
                        imageUrl={getDeviceImage(serialNumber)}
                        deviceName={device[serialNumber].name}
                        serialNumber={serialNumber}
                        minimumOneDevice={minimumOneDevice}
                        handleRemoveDevice={removeDevice}
                        isDeviceRemovable={isDeviceRemovable}
                    />
                );
            })}
        </Box>
    );
};

export default CustomisedCardBody;
