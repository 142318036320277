import { FormControlLabel, Radio, SxProps } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import React, { FC, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

interface CustomisedOutlinedRadioButtonProps {
    value?: string;
    label?: string;
    radioGroupValue?: string;
    style?: SxProps;
}
const CustomisedOutlinedRadioButton: FC<CustomisedOutlinedRadioButtonProps> = ({ value, label, radioGroupValue, style }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const borderColor = useMemo(() => {
        if (radioGroupValue === value) {
            return '#3F51B5';
        }
        return '#bdbdbd';
    }, [radioGroupValue, value]);
    return (
        <FormControlLabel
            sx={{
                border: isBigScreen ? 'solid 1px' : 'none',
                borderColor: { borderColor },
                borderRadius: '4px',
                flex: 1,
                height: '3.4rem',
                ml: 0.2,
                mr: 3,
                ...style,
            }}
            value={value}
            control={<Radio />}
            label={formattedMessages[(label ?? 'myself_label') as keyof typeof formattedMessages]}
        />
    );
};

CustomisedOutlinedRadioButton.defaultProps = {
    value: 'personal',
    label: 'myself_label',
    radioGroupValue: 'personal',
    style: {},
};

export default CustomisedOutlinedRadioButton;
