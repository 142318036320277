import React from 'react';
import { useNavigate } from 'react-router-dom';
import usePolicyOverview from 'hook/usePolicy';
import Cookies from 'js-cookie';
import PageLayout from 'components/PageLayout';
import DashboardHeader from 'components/DashboardHeader';
import NoData from 'components/NoData';
import Content from 'views/dashboard/Content';
import PageLoader from 'components/loader/PageLoader';

export interface policyDocumentProps {
    [key: string]: string;
}

export interface activePolicyProps {
    [key: string]: number | string | string[] | object | undefined;
    coveragePeriod: string;
    insuredItem: string;
    policyDocument?: policyDocumentProps;
    policyHolderAddress: string;
    policyHolderEmail: string[];
    policyHolderName: string;
    policyHolderPhoneNumber: string;
    policyNumber: string;
    policySelfId?: string | number;
    policyVersion: string;
    productName: string;
    serialNumber: string;
    status: string;
    policyVersionHyperlink: string;
}

const PoliciesOverviewView = () => {
    const navigate = useNavigate();
    const { loading, activePolicy, expiredPolicy } = usePolicyOverview();

    const handleStartAction = () => {
        Cookies.remove('token');
        window.location.reload();
    };

    const handleEndAction = () => {
        navigate('/claim-history');
    };

    const handleActiveClaim = (policyNumber: string) => () => {
        navigate(`/claim-creation/${policyNumber}`);
    };

    const handleSecondaryEndAction = () => {
        navigate('/quote', { state: 'addDevice' });
    };

    let render = <PageLoader />;
    if (!loading) {
        render =
            activePolicy && expiredPolicy ? (
                <Content activePolicy={activePolicy} expiredPolicy={expiredPolicy} handleActiveClaim={handleActiveClaim} />
            ) : (
                <NoData />
            );
    }

    return (
        <PageLayout>
            <DashboardHeader
                buttonTitle=""
                handleEndAction={handleEndAction}
                handleSecondaryEndAction={handleSecondaryEndAction}
                handleStartAction={handleStartAction}
                headerSubtitle=""
            />
            {render}
        </PageLayout>
    );
};

export default PoliciesOverviewView;
