import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from 'components/DashboardHeader';
import { format } from 'date-fns';
import {
    getTargetedPolicyVersion,
    getTargetedClaimHolderId,
    getTargetedPolicyVersionItem,
    getTargetedPolicyHolder,
    getTargetedPolicy,
} from 'services/httpService';
import CustomTable from 'components/customTable';
import PageLayout from 'components/PageLayout';
import NoData from 'components/NoData';
import { Claim } from 'interfaces/general';
import PageLoader from 'components/loader/PageLoader';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import { environment } from '../../environments/environment';

export interface statusColorProps {
    [key: string]: string;
}

export const statusColor: statusColorProps = {
    'Action required': '#ED6C02',
    'In progress': '#2196F3',
    Cancelled: '#000000',
    Paid: '#4CAF50',
    Rejected: '#F44336',
};

export interface dataProps {
    [key: string]: string;
    // approvedAmount: string;
    claimedAmount: string;
    date: string;
    device: string;
    formatIncidentDate: string;
    id: string;
    number: string;
    policyNumber: string;
    productName: string;
    status: string;
}

const createData = (
    status: string,
    id: string,
    date: string,
    formatIncidentDate: string,
    productName: string,
    policyNumber: string,
    number: string,
    device: string,
    claimedAmount: string
) => ({ status, id, date, formatIncidentDate, productName, policyNumber, number, device, claimedAmount });

const sortFunction = (a: dataProps, b: dataProps) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();
    return dateA > dateB ? -1 : 1;
};

const ClaimHistory = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const navigate = useNavigate();
    const [claimData, setClaimData] = useState<dataProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const handleTableData = (response: Claim[]) => {
            response.map(async (item: Claim) => {
                // const { self, createdAt, policyVersion, claimedAmount, finalAmount, incidentDate, number } = item;
                const { self, createdAt, policyVersion, claimedAmount, incidentDate, number } = item;
                const id = self?.id || '-';
                const date = format(new Date(createdAt), 'eee MMM dd yyyy') || '-';
                const formatIncidentDate = format(new Date(incidentDate), 'eee MMM dd yyyy') || '-';

                const fetchedPolicyVersion = await getTargetedPolicyVersion(`${policyVersion.id}/`);
                const policyVersionId = fetchedPolicyVersion.policy.id;
                const items = fetchedPolicyVersion?.items || '-';
                const policyVersionItemId = items[0]?.id || '-';

                const fetchedTargetedPolicy = await getTargetedPolicy(`${policyVersionId}/`);
                const policyNumber = fetchedTargetedPolicy?.number || '-';

                /** fetch policies versions item */
                const fetchedPolicyVersionItem = await getTargetedPolicyVersionItem(`${policyVersionItemId}/`);
                const name = fetchedPolicyVersionItem?.name || '-';

                const initClaimAmount = claimedAmount ? `$${claimedAmount}` : '-';
                const claimAmount = 'Claim' || initClaimAmount;
                // const approvedAmount = finalAmount ? `$${finalAmount}` : '-';
                const tableData = createData(
                    item.status,
                    id,
                    date,
                    formatIncidentDate,
                    formattedMessages[environment.REACT_APP_PRODUCT_NAME as keyof typeof formattedMessages],
                    policyNumber,
                    number,
                    name,
                    claimAmount
                );

                let checkId = false;
                setClaimData((prev) => {
                    prev.forEach((prevItem: dataProps) => {
                        if (prevItem.id === id) checkId = true;
                    });

                    return !checkId ? [...prev, tableData] : [...prev];
                });
            });
        };

        const handleFetch = async () => {
            setLoading(true);
            const fetchedPolicyHolder = await getTargetedPolicyHolder().catch((error) => {
                setLoading(false);
                throw error;
            });

            if (fetchedPolicyHolder?.self) {
                const { self } = fetchedPolicyHolder;
                const selfId = self?.id;
                if (selfId) {
                    const claim = await getTargetedClaimHolderId(selfId);
                    if (claim && claim?.length > 0) {
                        handleTableData(claim);
                    }
                }
            }
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };

        handleFetch();
    }, []);

    const handleStartAction = () => {
        navigate('/dashboard');
    };

    let render = <PageLoader />;
    if (!loading) {
        render = claimData ? <CustomTable data={claimData.sort(sortFunction)} /> : <NoData />;
    }

    return (
        <PageLayout>
            <Header
                buttonTitle="back_to_policies_button_label"
                endActionTitle=""
                handleStartAction={handleStartAction}
                headerSubtitle=""
                headerTitle="claim_history_header_label"
                secondaryEndActionTitle=""
            />
            {render}
        </PageLayout>
    );
};

export default ClaimHistory;
