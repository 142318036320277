import { Typography, Box } from '@mui/material';
import translate from 'i18n/messages/translate';
import React, { CSSProperties, FC } from 'react';

interface ModalHeaderProps {
    title?: string;
    titleStyle?: CSSProperties;
    subtitle?: string;
    subtitleStyle?: CSSProperties;
}
const ModalHeader: FC<ModalHeaderProps> = ({ title, titleStyle, subtitle, subtitleStyle }) => (
    <Box display="flex" sx={{ flexDirection: 'column', p: 3 }}>
        <Typography variant="h6" sx={{ ...titleStyle }}>
            {translate(title ?? 'add_device_label')}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ ...subtitleStyle }}>
            {subtitle}
        </Typography>
    </Box>
);

export default ModalHeader;
ModalHeader.defaultProps = {
    title: 'add_device_label',
    titleStyle: {},
    subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    subtitleStyle: {},
};
