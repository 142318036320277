import { TextField, Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { FC } from 'react';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface SearchSerialNumberInputProps {
    handleAddButtonOnClick: (serialNumberInput: string) => void;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errorMessage: string;
    inputValue: string;
}
const SearchSerialNumberInput: FC<SearchSerialNumberInputProps> = ({ handleAddButtonOnClick, handleInputChange, errorMessage, inputValue }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <Box display="flex" sx={{ p: 3 }}>
            <TextField
                fullWidth
                label={formattedMessages.serial_number}
                value={inputValue}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    },
                }}
                onChange={handleInputChange}
                error={!!errorMessage}
                helperText={errorMessage}
                variant="outlined"
            />
            <Button
                variant="contained"
                disabled={inputValue === ''}
                style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                startIcon={<AddIcon />}
                sx={{ height: '56px' }}
                onClick={() => handleAddButtonOnClick(inputValue)}
            >
                {formattedMessages.add}
            </Button>
        </Box>
    );
};

export default SearchSerialNumberInput;
