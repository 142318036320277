import TextField from '@mui/material/TextField';
import { listInputProps } from 'interfaces/profile';
import React, { ChangeEvent, FocusEvent, useMemo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, InputBaseComponentProps, SxProps } from '@mui/material';
import { useField } from 'formik';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

export interface formValueProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface TextInputProps {
    formValue?: formValueProps;
    globalInputDisable?: boolean;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
    item?: listInputProps;
    multiline?: boolean;
    minRows?: number;
    rootStyle?: SxProps;
    withMinNumberSetting?: boolean;
}

function checkDisableInput(globalInputDisable: boolean | undefined, disabledInput: boolean | undefined) {
    if (typeof disabledInput === 'boolean') return disabledInput;
    if (typeof globalInputDisable === 'boolean') return globalInputDisable;
    return false;
}

const TextInput = ({
    formValue,
    globalInputDisable,
    handleBlur,
    helperText,
    handleChange,
    item,
    rootStyle,
    withMinNumberSetting,
    multiline,
    minRows,
}: TextInputProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { error, touched, value } = useField(item?.useState ? item.useState : '')[1];
    const minNumberSetting = item?.minNumber || 0;
    const disableInput = useMemo(() => checkDisableInput(globalInputDisable, item?.disabledInput), [globalInputDisable, item?.disabledInput]);

    const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (item?.type === 'number' && item?.maxNumber && Number(event.target.value) > item?.maxNumber) {
            const newEvent = { ...event, target: { ...event.target, name: item.useState, value: item?.maxNumber?.toString() } };
            if (newEvent && handleChange) handleChange(newEvent);
        } else if (item?.type === 'number' && Number(event.target.value) < minNumberSetting) {
            const newEvent = { ...event, target: { ...event.target, name: item.useState, value: minNumberSetting?.toString() } };
            if (newEvent && handleChange) handleChange(newEvent);
        } else if (handleChange) handleChange(event);
    };

    let endAdornmentValue = item?.endAdornment?.label;
    if (item?.endAdornment?.type === 'refer-value') {
        endAdornmentValue = formValue && item.endAdornment?.useState ? formValue[item.endAdornment.useState] : '';
    }

    let inputPropsSetting: InputBaseComponentProps = {};
    if (withMinNumberSetting) {
        inputPropsSetting = {
            ...inputPropsSetting,
            min: minNumberSetting,
        };
    }
    if (item?.maxNumber) {
        inputPropsSetting = {
            ...inputPropsSetting,
            max: item?.maxNumber,
        };
    }
    return (
        <Box
            sx={{
                ...rootStyle,
            }}
        >
            <TextField
                inputProps={{
                    autoComplete: 'off',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                    endAdornment: <InputAdornment position="end">{endAdornmentValue}</InputAdornment>,
                    inputProps: inputPropsSetting,
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        ...item?.muiOutlinedInputRootStyle,
                    },
                }}
                disabled={disableInput}
                error={!!touched && (!!error || !!helperText)}
                helperText={
                    touched && (error || helperText)
                        ? formattedMessages[(error ?? '') as keyof typeof formattedMessages] ||
                          formattedMessages[(helperText ?? '') as keyof typeof formattedMessages]
                        : ''
                }
                id={item?.useState}
                label={formattedMessages[item?.placeholder as keyof typeof formattedMessages]}
                name={item?.useState}
                onBlur={handleBlur}
                onChange={onHandleChange}
                style={item?.style}
                type={item?.type}
                value={value}
                variant={item?.variant}
                multiline={multiline}
                minRows={minRows}
            />
        </Box>
    );
};

TextInput.defaultProps = {
    formValue: undefined,
    globalInputDisable: undefined,
    handleBlur: undefined,
    handleChange: undefined,
    helperText: undefined,
    item: undefined,
    rootStyle: { position: 'relative', display: 'flex', flexDirection: 'row' },
    withMinNumberSetting: true,
    multiline: false,
    minRows: undefined,
};

export default TextInput;
