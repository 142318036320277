import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { useObservableState } from 'observable-hooks';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { LoaderSubject } from '.';

const Wrapper = styled.div<{ show: boolean }>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: ${(props) => (props.show ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    background: #ffffff94;
    z-index: 999999;
`;

export const LoaderComponent = () => {
    const { isLoading, message, messageStyle, delay, loaderStyle } = useObservableState(LoaderSubject);
    const [isMessageVisible, setIsMessageVisible] = useState(!!message && !delay);
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            setIsMessageVisible(true);
        }, delay);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = undefined;
            }
        };
    }, [message, delay]);

    return (
        <Wrapper show={isLoading}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', rowGap: '5px' }}>
                <CircularProgress sx={loaderStyle} />
                {isMessageVisible && (
                    <Typography
                        sx={messageStyle}
                        aria-label="loader-message"
                        data-testid="loader-message" // Add the data-testid here
                    >
                        {message}
                    </Typography>
                )}
            </Box>
        </Wrapper>
    );
};

export default LoaderComponent;
