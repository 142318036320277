import { ComponentType } from 'react';
import { Navigate } from 'react-router-dom';
import { productStorage } from 'utils/storageHandler';

const paymentValidation = <P extends object>(WrappedComponent: ComponentType<P>) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function withPaymentValidation(componentProps: P | any) {
        const { listQuote, submissionEmailAddress } = productStorage();
        const nonValidatePath = submissionEmailAddress ? '/enrollment' : '/quote';

        return listQuote ? (
            <WrappedComponent {...componentProps}>{componentProps.children}</WrappedComponent>
        ) : (
            <Navigate replace to={nonValidatePath} />
        );
    };

export default paymentValidation;
