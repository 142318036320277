/* eslint-disable no-shadow */

type BusinessPartnerCategory = 'Person' | 'Company';

interface Link {
    rel: string;
    href: string;
}

export interface I2GPolicyAttribute {
    key: string;
    value: any;
    key_display: string;
    value_display?: any;
}

export type DuplicateCheckAction = 'SILENT_MATCH' | 'BLOCKED' | 'PROMPT_LOGIN' | 'CREATE_NEW_PARTNER';

export type DuplicateCheckPayloadField = 'email' | 'dob' | 'last_name' | 'postal_code' | 'code';

export type DuplicateCheckValidationError<F = any> = {
    [F in DuplicateCheckPayloadField]?: string[];
};

export interface DuplicateCheckData {
    valid: boolean;
    partner?: any;
    action: DuplicateCheckAction;
}

export interface DuplicateCheck {
    message: string;
    status: number;
    data: DuplicateCheckData | DuplicateCheckValidationError;
}

export interface DuplicateCheckPayload {
    email: string;
}

export interface Self {
    href: string;
    id: string;
}

interface BusinessPartnerAddressesDetails {
    building: string | null;
    city: string;
    country: string;
    fullAddress: string;
    postalCode: string;
    id: string;
}

interface BusinessPartnerAddresses {
    self: Self;
    details: BusinessPartnerAddressesDetails;
}

export interface BusinessPartnerGroup {
    self?: Self;
    fullName: string;
    id: string;
}

export interface BusinessPartnerCompany {
    self?: Self;
    fullName: string;
}

export interface BusinessPartnerUserTypeGroup {
    self: Self;
    fullName: string;
    company: BusinessPartnerCompany;
    childCount: number;
}

export interface BusinessPartner {
    self?: Self;
    number?: string | undefined;
    title?: any;
    firstName: string;
    name: string;
    middleName?: any;
    lastName: string;
    fullName?: string;
    nickname: string;
    profilePicture?: any;
    gender?: any;
    email?: string;
    dob?: any;
    maritalStatus?: any;
    occupation: string;
    nationality?: any;
    registrationName: string;
    registrationCode: string;
    companyLegalStructure?: any;
    companyFoundingDate?: any;
    companySize: string;
    category: string;
    company?: BusinessPartnerCompany;
    correspondenceLanguage: string;
    emailPrimary: string;
    emailCorrespondence?: any;
    emailBilling: string;
    emailWork: string;
    emailOther: string;
    phoneMobile: string;
    phoneWork: string;
    phoneHome: string;
    phoneFax: string;
    preferredCommunicationChannels?: any;
    prefersNoContact: boolean;
    roles: any[];
    group: BusinessPartnerGroup;
    addresses: BusinessPartnerAddresses[];
    incompleteOrders: any[];
    policies: any[];
    activePolicyRelations: any[];
    pendingPolicyRelations: any[];
    consentManagement: any[];
    idType?: any;
    idNumber: string;
    idIssueDate?: any;
    telematicsEnabled: boolean;
    duplicateCheckResponse?: any;
    extraData: any;
    createdAt: string;
    updatedAt: string;
    links: Link[];
    paperDocumentEnabled: boolean;
    policyAutoRenewal: boolean;
    bankAccount?: any;
    isActive: boolean;
    creator: string;
}

export type BaseBusinessPartner = Pick<BusinessPartner, 'self' | 'number' | 'fullName'>;

export interface ProductPlan {
    self: Self;
    name: string;
    slug: string;
    description: string;
    product: Self;
    isDefault: boolean;
    attributes: any;
    links: Link[];
    updatedAt: string;
    createdAt: string;
}
interface Agent {
    self: Self;
    name: string;
    reseller: BusinessPartner | null;
    salesUnit: BusinessPartner;
}

export interface IncompleteOrder {
    self: Self;
    number?: string;
    agent?: Agent;
    holder: {
        self: Self;
        fullName: string;
        emailPrimary: string;
        addresses?: {
            details: {
                fullAddress: string;
                city: string;
                country: string;
                line1: string;
                postalCode: string;
                state: string;
            };
        }[];
    }; // href
    product: {
        self: Self;
        name: string;
        currency: string;
    }; // href
    productPlan: ProductPlan;
    status?: string;
    paymentScheme?: string;
    paymentDay?: any;
    classification?: any;
    startDate?: string;
    endDate?: string;
    progress?: number;
    netAmount?: string;
    loadingAmount?: string;
    netLoadAmount?: string;
    grossAmount?: string;
    iptAmount?: any;
    finalAmount?: string;
    totalPayableAmount?: string;
    proratedAmount?: string;
    adminFee?: any;
    pricingBreakdown?: any;
    voucherAttributes?: any;
    campaignAttributes?: any;
    businessPartnerRelations?: Self[];
    policyTerm?: string;
    recurrenceScheme?: string;
    policyVersion?: Self;
    items?: Self[]; // href[]
    headers?: any[];
    attributes?: I2GPolicyAttribute[];
    declarations?: any[];
    lostStatusReason?: any;
    otherLostStatusReasons?: any;
    leadType?: string;
    leadTypeReason?: any;
    leadClassification?: string;
    policy: {
        self: Self;
        number: string;
        status: string;
    };
    transactionType?: string;
    transactions?: any[];
    latestVersion?: any;
    currentVersion?: any;
    rootVersion?: Self;
    rating?: any;
    pdfFileUrl?: any;
    endorsementNote?: any;
    fileAttachments?: any[];
    links?: Link[];
    createdAt?: string;
    updatedAt?: string;
    extraData?: any;
    mtaDates?: string[];
}
export interface User {
    pk: string;
    email: string | null;
    name: string | null;
    isActive: boolean;
    dateJoined: string;
}

export interface Choice {
    self: Self;
    key: string | null;
    value: string | null;
    isActive: boolean;
    choiceType: Self;
    ordering: number;
    parent: Self | null;
    createdAt: string;
    updatedAt: string | null;
}

export interface Note {
    self: Self;
    number: string | null;
    kind: string;
    attachedTo: Self | null;
    title: string | null;
    body: string;
    startDate: string | null;
    endDate: string | null;
    /** expand: {@linkcode Choice} */
    subcategoryChoices: Self | null;
    createdAt: string;
    updatedAt: string | null;
    isTemplate: boolean;
    isActive: boolean;
    createdBy: User | null;
}

export interface NoteCreatePayload {
    kind: string;
    attachedTo: string;
    title?: string;
    body: string;
    isTemplate: boolean;
    subcategoryChoices?: string;
}

export interface NoteUpdatePayload {
    kind: string;
    attachedTo: string;
    title: string;
    body: string;
    isTemplate?: boolean;
    subcategoryChoices?: string;
}

export interface ChoiceCreatePayload {
    choiceType: string;
    parent?: string;
    ordering?: number | null;
    key: string;
    value: string;
}

export interface ChoiceTypeCreatePayload {
    name: string;
    slug: string;
    description: string;
    copyToPolicy?: boolean;
    product?: string;
}

export interface RegisterUserRequest {
    token: string;
    email: string;
    new_password: string;
    confirm_password: string;
    submit: boolean;
    product: string;
}

export interface AccountLoginFormType {
    email: string;
    password: string;
}

export type ResetPasswordFormType = Pick<AccountLoginFormType, 'email'>;
export interface AccountLoginPayload extends AccountLoginFormType {
    product: string;
}
export interface AccountLoginResponse {
    token: string;
}

export interface PasswordRecoveryPayload {
    product: string;
    skip_email: boolean;
    submit: boolean;
    user: string;
}

export interface PasswordResetPayload {
    token: string;
    new_password: string;
    confirm_password: string;
    product: string;
    submit: boolean;
}

export interface PasswordChangePayload {
    current_password: string;
    new_password: string;
    confirm_password: string;
    submit: boolean;
}

export interface PasswordResetResponse {
    message: string;
    status: number;
    data: any;
}

export interface PasswordRecoveryResponse {
    data: {
        user?: string[];
    };
    message: string;
    status: number;
}

interface AuthenticationPayload {
    'Content-Type': string;
    Authorization: string;
}

export interface Product {
    self: Self;
    name: string;
    slug: string;
    code: string;
    description: string;
    insurer: Self;
    currency: string;
    loadingEnabled: boolean;
    links: Link[];
    ratingEndpoint: string;
    authenticationPayload: AuthenticationPayload;
    policyTemplateName?: any;
    quotationTemplateName?: any;
    policyEmailSender?: any;
    cancellationEnabled: boolean;
    riskAssessmentEnabled: boolean;
    icon?: any;
    iconUuid?: any;
    status: string;
    primaryColor?: any;
    coverages: any[];
    nonCoverageQuestions: any[];
    activeFrom: string;
    activeUntil?: any;
    createdAt: string;
    updatedAt: string;
}

export interface FileMetadata {
    fileSize: number;
    fileType: string;
}

export interface FileAttachment {
    self: Self;
    number: string;
    title: string;
    name: string;
    publicAttachment: string | null;
    privateAttachment: string | null;
    attachedTo: Self;
    metadata: FileMetadata | null;
    createdAt: string;
    updatedAt: string | null;
    isInternal: boolean;
    category: Choice | null;
}

export interface Activity {
    self: Self;
    category: Self;
    number: string;
    description: string | null;
    priority: Self | null;
    statuses: Self[];
    assignee: string | null;
    linkedTo: Self | null;
    values: unknown[];
    status: string;
    dueDate: string | null;
    referenceNumber: string;
    product: {
        uuid: string;
        name: string;
    } | null;
    createdAt: string;
    createdBy: string | null;
    updatedAt: string | null;
    updatedBy: string | null;
}

export interface ActivityStatusType {
    self: Self;
    title: string;
    slug: string;
}

export interface ActivityCategory {
    self: Self;
    title: string;
    slug: string;
    companyRoles: unknown[];
    groupRoles: unknown[];
    personRoles: unknown[];
    /** Expand: {@linkcode ActivityStatusType ActivityStatusType[]} */
    statuses: Self[];
    createdAt: string;
    updatedAt: string | null;
    /** Whether the priority and due date are required */
    enablePriorityDueDate: boolean;
}

export interface ActivityCreationPayload {
    category: string;
    linkedTo: string;
    assignee: string;
    priority?: string;
    description?: string;
    new_status?: string;
    dueDate?: string;
    values: { attribute: string; value: unknown }[];
    attachments?: File[];
    objectAttachments?: string[];
    sendNotification?: boolean;
}

export interface ActivityPriority {
    self: Self;
    name: string;
    displayName: string;
    /** Format: [DD] [[HH:]MM:]ss[.uuuuuu], e.g. "1 00:00:00" */
    duration: string;
    createdAt: string;
    updatedAt: string | null;
}

export interface ActivityAttribute {
    self: Self;
    title: string;
    slug: string;
    /** Reference: ActivityAttribute model */
    valueType: 'boolean' | 'text' | 'number' | 'date' | 'datetime' | 'select' | 'multi-select' | 'json';
    selectValues: string[];
    required: boolean;
}

export interface BackOfficeUser {
    self: Self;
    number: string;
    fullName: string;
    category: string;
    email: string;
    phoneNumber: string | null;
    roles: Self[];
    createdAt: string;
    updatedAt: string;
}
