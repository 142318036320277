import React from 'react';
import { FormControl, RadioGroup } from '@mui/material';
import { Field, FieldProps } from 'formik';
import CustomisedOutlinedRadioButton from 'components/CustomisedOutlinedRadioButton';
import FormHeader from 'components/FormHeader';

const ClaimRadioButtonField = () => (
    <Field name="theftOrDamaged">
        {({ field }: FieldProps) => (
            <FormControl fullWidth sx={{ mx: { xs: 1, md: 0 }, mb: { xs: 0, md: 3 }, mt: { md: 2 } }}>
                <FormHeader
                    title="hearing_aid_header_label"
                    withDivider
                    titleVariant="h6"
                    rootStyle={{ margin: { xs: '20px 0 0 0', md: '0 0 20px 0' }, width: { xs: '96%', md: '100%' } }}
                />

                <RadioGroup sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} row {...field}>
                    <CustomisedOutlinedRadioButton style={{ mr: 0.5 }} label="damaged_label" value="Damaged" radioGroupValue={field.value} />
                    <CustomisedOutlinedRadioButton style={{ mr: 0.5 }} label="theft_label" value="Theft" radioGroupValue={field.value} />
                    <CustomisedOutlinedRadioButton style={{ mr: 0 }} label="lost_label" value="Lost" radioGroupValue={field.value} />
                </RadioGroup>
            </FormControl>
        )}
    </Field>
);

export default ClaimRadioButtonField;
