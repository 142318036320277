import { AuthenticationCallback, AuthenticationStore } from 'context/Authentication';
import { authenticationDispatchProps, AuthenticationStateProps, deviceStateProps, providerProps } from 'interfaces/profile';
import { useContext, useEffect, useState } from 'react';
import { getTargetedPolicyHolder, getTargetedBusinessPartnerAddress, getTargetedPolicyVersionItemWithoutId } from 'services/httpService';
import { clearProductStorage, productStorage } from 'utils/storageHandler';
import { Attribute, targetedPolicyVersionItemProps } from 'interfaces/devices';
import { capitalizeFirstLetter } from '_helper/utilities';

interface useUserProps {
    withClearStorage?: boolean;
}

const handleFormattedDeviceStructure = (data: targetedPolicyVersionItemProps[]) => {
    let returnData: deviceStateProps[] = [];
    data?.forEach((item: targetedPolicyVersionItemProps, key: number) => {
        const { attributes } = item;
        const getSerialNumber: Attribute | undefined = attributes?.find((attr: Attribute) => attr.key === 'serial_number');
        const getDeviceModel: Attribute | undefined = attributes?.find((attr: Attribute) => attr.key === 'model');
        returnData = [
            ...returnData,
            {
                id: `${key}${getSerialNumber?.value}`,
                deviceImage: 'assets/cochlear_1.png',
                // deviceStatus: 'active',
                deviceName: capitalizeFirstLetter(item.name),
                deviceSerialNumber: getSerialNumber?.value?.toString() || '-',
                deviceBrand: capitalizeFirstLetter(item.slug),
                // deviceYear: '-',
                deviceModel: capitalizeFirstLetter(getDeviceModel?.value?.toString() || '') || '-',
                // deviceColor: '-',
                // devicePatient: '-',
            },
        ];

        return returnData;
    });

    return returnData;
};

export const getContextDetails = async (authenticationDispatch: React.Dispatch<authenticationDispatchProps>) => {
    const policyHolderDetail = await getTargetedPolicyHolder();
    const targetedBusinessPartnerAddress = await getTargetedBusinessPartnerAddress();
    const targetedPolicyVersionItemWithoutId = await getTargetedPolicyVersionItemWithoutId();
    let userDetail: AuthenticationStateProps = {
        firstName: '-',
        lastName: '-',
        idNo: '-',
        dateOfBirth: '-',
        emailAddress: '-',
        address: '-',
        zipCode: '-',
        city: '-',
        state: '-',
        country: '-',
        devices: undefined,
    };
    if (policyHolderDetail) {
        userDetail = {
            ...userDetail,
            firstName: policyHolderDetail?.firstName || '-',
            lastName: policyHolderDetail?.lastName || '-',
            idNo: policyHolderDetail?.idNumber || policyHolderDetail?.number || '-',
            dateOfBirth: policyHolderDetail?.dob || '-',
            emailAddress: policyHolderDetail?.emailPrimary || '-',
        };
        authenticationDispatch({ type: AuthenticationCallback.handle_authentication, value: userDetail });
    }
    if (targetedBusinessPartnerAddress[0]) {
        const { details } = targetedBusinessPartnerAddress[0];
        userDetail = {
            ...userDetail,
            address: details?.line1 || '-',
            zipCode: details?.postalCode || '-',
            city: details?.city || '-',
            state: details?.state || '-',
            country: details?.country || '-',
        };
    }
    if (targetedPolicyVersionItemWithoutId) {
        const formattedDeviceStructure: deviceStateProps[] | undefined = handleFormattedDeviceStructure(targetedPolicyVersionItemWithoutId);
        userDetail = {
            ...userDetail,
            devices: formattedDeviceStructure,
        };
    }
    authenticationDispatch({ type: AuthenticationCallback.handle_authentication, value: userDetail });
    return userDetail;
};

const useUser = ({ withClearStorage }: useUserProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { authenticationDispatch }: providerProps = useContext(AuthenticationStore);
    const { hasBp } = productStorage();
    const isExistingBp = JSON.parse(hasBp ?? 'false');

    useEffect(() => {
        const fetchData = async () => {
            if (withClearStorage) clearProductStorage();
            setLoading(true);

            await getContextDetails(authenticationDispatch);
            setLoading(false);
        };

        if (isExistingBp) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [isExistingBp, withClearStorage]);

    return { loading };
};

useUser.defaultProps = {
    withClearStorage: true,
};

export default useUser;
