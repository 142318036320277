import { useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ClickAwayListener, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import translate from 'i18n/messages/translate';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import useVerifiedUser from 'v2/hooks/useVerifiedUser';
import Cookies from 'js-cookie';

const AccountMenu = () => {
    const quoteStore = useQuotationStorage();
    const verifiedUser = useVerifiedUser();
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [openMenu, setOpenMenu] = useState(false);
    const navigate = useNavigate();

    const closeOnClickAway = (event: MouseEvent | TouchEvent) => {
        if (event && anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenMenu(false);
    };

    const onManageAccountClick = () => {
        setOpenMenu(false);
        navigate('/account-setting/profile');
    };

    const onLogoutClick = () => {
        setOpenMenu(false);
        quoteStore.resetItem();
        Cookies.remove('token');
        window.location.reload();
    };

    return (
        <Box>
            <ButtonAccount ref={anchorRef} onClick={() => setOpenMenu((prev) => !prev)} endIcon={<ExpandMoreIcon />}>
                {translate('accounts')}
            </ButtonAccount>
            <StyledPopper open={openMenu} anchorEl={anchorRef.current} placement="bottom" transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom-end' ? 'right top' : 'left bottom' }}
                        data-testid="accountMenu"
                    >
                        <Paper elevation={8}>
                            <ClickAwayListener onClickAway={closeOnClickAway}>
                                <Container>
                                    <Stack>
                                        <UserInfoBox>
                                            <UserNameText>
                                                {verifiedUser?.bpInfo?.firstName} {verifiedUser?.bpInfo?.lastName}
                                            </UserNameText>
                                            <UserEmailText>{verifiedUser?.emailAddress}</UserEmailText>
                                        </UserInfoBox>
                                        <Divider />
                                        <FlexContainer direction="row" onClick={onManageAccountClick}>
                                            <Avatar sx={{ width: '24px', height: '24px', color: '#0000008F' }} />
                                            <Text>{translate('manage_account')}</Text>
                                        </FlexContainer>
                                        <Divider />
                                        <FlexContainer direction="row" onClick={onLogoutClick}>
                                            <LogoutIcon sx={{ width: '24px', height: '24px', color: '#0000008F' }} />
                                            <Text>{translate('logout')}</Text>
                                        </FlexContainer>
                                    </Stack>
                                </Container>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </StyledPopper>
        </Box>
    );
};

const ButtonAccount = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const Text = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000DE',
}));

const FlexContainer = styled(Stack)(({ theme }) => ({
    padding: '10px 16px',
    gap: '10px',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer',
        background: theme.palette.grey[100],
    },
}));

const UserNameText = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000DE',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
}));

const UserEmailText = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: '14px',
    color: '#00000099',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const UserInfoBox = styled(Box)(() => ({
    padding: '10px 16px',
}));

const Container = styled(Box)(() => ({
    width: '220px',
}));

export default AccountMenu;
