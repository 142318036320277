import Typography from '@mui/material/Typography';
import translate from 'i18n/messages/translate';
import React from 'react';

interface NoDataProps {
    endTitle?: string;
    width?: string;
    height?: string;
    imgSource?: string;
    subHeading?: string;
    title?: string;
}

const NoData = ({ endTitle, width, height, imgSource, subHeading, title }: NoDataProps) => (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
        <img alt="nodata" src={imgSource} width={width} height={height} />
        <Typography variant="h6" style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center', marginTop: 12 }}>
            {translate(title ?? 'no_data_label')}
        </Typography>
        <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center' }}>
            {translate(subHeading ?? 'no_data_description')}
        </Typography>
        <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.38)', textAlign: 'center' }}>
            {endTitle}
        </Typography>
    </div>
);

NoData.defaultProps = {
    endTitle: 'Please try to view other tabs.',
    width: '29.17px',
    height: '26.25px',
    imgSource: 'assets/missing.png',
    subHeading: 'no_data_description',
    title: 'no_data_label',
};

export default NoData;
