import { Box, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import translate from 'i18n/messages/translate';
import React from 'react';

const Within60DaysDisclaimer = () => (
    <Field name="within60DaysDisclaimer">
        {({ field }: FieldProps) => (
            <Box sx={{ mb: { xs: 1, md: 0 }, ml: { xs: 1, md: 0 } }}>
                <Divider sx={{ width: { xs: '98%', md: '100%' }, pt: { xs: 0, md: 1 }, pb: 2, mb: 3, mx: { xs: 'auto' } }} />
                <FormControlLabel
                    sx={{ alignItems: { xs: 'flex-start', md: 'center' } }}
                    control={<Checkbox checked={field.value} {...field} />}
                    label={
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>{translate('device_purchase_acknowledgement')}</Typography>
                    }
                />
            </Box>
        )}
    </Field>
);

export default Within60DaysDisclaimer;
