import { Box } from '@mui/system';
import CustomInput from 'components/customInput';
import { formValueProps } from 'components/customInput/TextInput';
import FormHeader from 'components/FormHeader';
import { billingPersonalDetailsInputList } from 'constant/payment';
import { useFormikContext } from 'formik';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const BillingAddressDetails = () => {
    const { values, handleBlur, handleChange } = useFormikContext();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    return (
        <Box display="flex" sx={{ mt: 4, flexDirection: 'column' }}>
            <FormHeader titleVariant="h6" title="billing_address_details_label" />
            <CustomInput
                boxFlexDirection={isBigScreen ? 'row' : 'column'}
                rootStyle={{ flexWrap: 'wrap', display: 'flex', width: '100%', marginTop: '2rem' }}
                // overwrite the default styling
                boxStyle={{}}
                textInputRootStyle={{ marginRight: isBigScreen ? '1.5rem' : '0rem', marginBottom: '2rem', flex: 1 }}
                listInput={billingPersonalDetailsInputList}
                formValue={values as formValueProps}
                handleBlur={handleBlur}
                handleChange={handleChange}
            />
        </Box>
    );
};

export default BillingAddressDetails;
