import { useMemo, useContext } from 'react';
import Cookies from 'js-cookie';
import useUser from 'hook/useUser';
import { providerProps } from 'interfaces/profile';
import { AuthenticationStore } from 'context/Authentication';
import useQuotationStorage from './useQuotationStorage';

function useVerifiedUser() {
    const quoteStore = useQuotationStorage();
    const token = Cookies.get('token');

    useUser({ withClearStorage: false });
    const { authenticationState }: providerProps = useContext(AuthenticationStore);

    const loggedInUser = useMemo(() => {
        if (token && quoteStore.items.submissionEmailAddress) {
            return { emailAddress: quoteStore.items.submissionEmailAddress, hasBp: quoteStore.items.hasBp, bpInfo: authenticationState ?? {} };
        }
        return {};
    }, [quoteStore.items, token, authenticationState]);

    return loggedInUser;
}

export default useVerifiedUser;
