import { ImageList } from '@mui/material';
import NoData from 'components/NoData';
import { useClaim } from 'context/ClaimContext';
import { UploadedFile } from 'interfaces/claim';
import { AttachmentStatus } from 'interfaces/claimReview';
import React, { FC, useEffect, useState } from 'react';
import AttachmentPreviewItem from './AttachmentPreviewItem';

interface AttachmentPreviewProps {
    isEditable: boolean;
    handleAttachmentSelected: (urlArr: string[]) => void;
}

const AttachmentsPreview: FC<AttachmentPreviewProps> = ({ isEditable, handleAttachmentSelected }) => {
    const { files } = useClaim();
    const [attachmentsStatuses, setAttachmentsStatuses] = useState<AttachmentStatus>({});
    const initializeCheckboxStatus = (inputFile: UploadedFile[]) => {
        let attachmentStatus: AttachmentStatus = {};
        inputFile.forEach((item) => {
            attachmentStatus = { ...attachmentStatus, [item.file.name as string]: false };
        });
        return attachmentStatus;
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAttachmentsStatuses((prev) => ({ ...prev, [event.target.name]: event.target.checked }));
    };

    useEffect(() => {
        if (files.length > 0) {
            setAttachmentsStatuses(initializeCheckboxStatus(files));
        }
    }, [files]);

    useEffect(() => {
        const attachmentSelectedArr = Object.keys(attachmentsStatuses).filter((item) => attachmentsStatuses[item] === true);
        handleAttachmentSelected(attachmentSelectedArr);
    }, [attachmentsStatuses]);
    return (
        <>
            <ImageList
                cols={3}
                gap={12}
                rowHeight={170}
                sx={{
                    maxWidth: '500px',
                    '& .MuiImageList-standard': {
                        overflowY: 'none',
                    },
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {files.map((item) => (
                    <AttachmentPreviewItem
                        key={item.file.name}
                        uploadedFile={item}
                        isEditable={isEditable}
                        attachmentsStatuses={attachmentsStatuses}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                ))}
            </ImageList>
            {files.length === 0 && <NoData subHeading="no_attachment_description" title="no_attachment_label" />}
        </>
    );
};

export default AttachmentsPreview;
