import { SxProps, TextField } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import { Field, FieldProps } from 'formik';
import messages from 'i18n/messages';
import React, { FC } from 'react';

interface FormTextFieldProps {
    name: string;
    inputLabel: string;
    style?: SxProps;
    inputProps?: Record<string, string | number>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    valueFormatter?: any;
    disabled?: boolean;
}
const FormTextField: FC<FormTextFieldProps> = ({ name, inputLabel, style, inputProps, valueFormatter, disabled }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps) => {
                const { touched, error } = meta;
                return (
                    <TextField
                        sx={{
                            flex: { xs: 1, md: 0.5 },
                            ml: { xs: 1, md: 0.2 },
                            mr: { xs: 1, md: 2 },
                            mt: 2,
                            ...style,
                        }}
                        inputProps={inputProps}
                        variant="outlined"
                        label={inputLabel}
                        helperText={touched && error && formattedMessages['required_field' as keyof typeof formattedMessages]}
                        error={!!touched && !!error}
                        {...field}
                        disabled={disabled}
                        value={typeof valueFormatter === 'function' ? valueFormatter(field.value) : field.value}
                    />
                );
            }}
        </Field>
    );
};

export default FormTextField;

FormTextField.defaultProps = {
    style: {},
    inputProps: {},
    valueFormatter: '',
    disabled: false,
};
