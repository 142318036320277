import { render } from 'react-dom';
import { BehaviorSubject } from 'rxjs';
import { OptionsObject, SnackbarKey } from 'notistack';
import { MouseEvent } from 'react';
import ToastComponent from './ToastComponent';

export type ExtraConfig = {
    undoEvent?: () => void;
    closeEvent?: () => void;
    actionButton?: {
        label: string;
        onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    };
    enableCloseAction?: boolean;
};
export interface ShowToastParams {
    message: string;
    enqueueSnackbarConfig: Partial<OptionsObject>;
    extraConfig?: ExtraConfig;
}

export interface ActionShowToastProps {
    closeSnackbar: (snackbarKey: SnackbarKey) => void;
    snackbarKey: SnackbarKey;
}

export type ToastState = { message: string | undefined; config: Partial<OptionsObject> | undefined; extraConfig: ExtraConfig };

declare global {
    interface Window {
        Toast: {
            ToastSubject: BehaviorSubject<ToastState> | undefined;
            isToastRendered: boolean;
            toastTarget: HTMLElement | null;
            showToast: (message: string, config: OptionsObject, extraConfig: { [key: string]: any }) => void;
        };
    }
}

function initializedGlobalToast() {
    window.Toast = {
        ToastSubject: undefined,
        isToastRendered: false,
        toastTarget: null,
        showToast: () => undefined,
    };
}

if (!window.Toast) {
    initializedGlobalToast();
}

const OVERLAY_TOAST_ID = 'overlay-target-i2go-toast';

function buildToastTarget() {
    const foundExistingTarget = document.getElementById(OVERLAY_TOAST_ID);

    if (foundExistingTarget) return foundExistingTarget;

    const builtTarget = document.createElement('div');
    builtTarget.id = OVERLAY_TOAST_ID;

    (document.getElementById('content') || document.body).appendChild(builtTarget);
    return builtTarget;
}

function createContainer() {
    if (window.Toast.isToastRendered) return undefined;
    if (!window.Toast.toastTarget || !document.body.contains(window.Toast.toastTarget)) {
        window.Toast.toastTarget = buildToastTarget();
    }

    window.Toast.isToastRendered = true;
    render(<ToastComponent />, window.Toast.toastTarget);

    return undefined;
}

function getToastSubject() {
    if (window.Toast.ToastSubject) return window.Toast.ToastSubject;
    window.Toast.ToastSubject = new BehaviorSubject<ToastState>({ message: undefined, config: {}, extraConfig: {} });
    return window.Toast.ToastSubject;
}

export const ToastSubject = getToastSubject();

export function showToast({ message, extraConfig, enqueueSnackbarConfig }: ShowToastParams) {
    createContainer();
    ToastSubject.next({ message, config: enqueueSnackbarConfig, extraConfig: extraConfig ?? {} });
}
