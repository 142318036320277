import { Card, Divider, Grid, Typography } from '@mui/material';
import NoData from 'components/NoData';
import PolicyDetailListing from 'components/PolicyDetailListing';
import usePolicyOverview from 'hook/usePolicy';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PolicyDetailsCardLoader from 'components/loader/PolicyDetailsCardLoader';
import translate from 'i18n/messages/translate';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface PolicyDetailsProps {
    headerTitle?: string;
}

const PolicyDetails = ({ headerTitle }: PolicyDetailsProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { loading, activePolicy } = usePolicyOverview();
    const { policyId } = useParams();
    const specificPolicy = activePolicy?.find((policy) => policy.policySelfId === policyId);
    const cardDetails = useMemo(() => {
        if (specificPolicy) {
            const { policyNumber, coveragePeriod, insuredItem, serialNumber, policyHolderName } = specificPolicy;
            return { policyNumber, coveragePeriod, insuredItem, serialNumber, policyHolderName };
        }
        return {
            policyNumber: '-',
            coveragePeriod: '-',
            insuredItem: '-',
            serialNumber: '-',
            policyHolderName: '-',
        };
    }, [specificPolicy]);

    const { policyNumber, coveragePeriod, insuredItem, serialNumber, policyHolderName } = cardDetails;

    let render = <PolicyDetailsCardLoader />;
    if (!loading) {
        render = activePolicy ? (
            <>
                <PolicyDetailListing title={`${formattedMessages.policy_number_label}: `} value={policyNumber ?? '-'} />
                <PolicyDetailListing title={`${formattedMessages.coverage_period_label}: `} value={coveragePeriod} />
                <PolicyDetailListing title={`${formattedMessages.insured_item_label}: `} value={insuredItem} />
                <PolicyDetailListing title={`${formattedMessages.serial_number}: `} value={serialNumber} />
                <PolicyDetailListing title={`${formattedMessages.full_name_label}: `} value={policyHolderName} />
            </>
        ) : (
            <NoData />
        );
    }

    return (
        <Card sx={{ p: '20px', backgroundColor: '#FAFAFA', position: 'sticky', top: '1rem', maxWidth: '540px' }} elevation={1}>
            <Grid container spacing={2}>
                <Grid item xs={12} xl={12}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {translate(headerTitle ?? 'policy_details_card_header_label')}
                    </Typography>
                    <Divider sx={{ marginBottom: '1rem' }} />
                    {render}
                </Grid>
            </Grid>
        </Card>
    );
};

PolicyDetails.defaultProps = {
    headerTitle: 'policy_details_card_header_label',
};

export default PolicyDetails;
