/* eslint-disable import/no-duplicates */
import NoData from 'components/NoData';
import PageLoader from 'components/loader/PageLoader';
import PolicyDetailListing from 'components/PolicyDetailListing';
import { useClaim } from 'context/ClaimContext';
import usePolicyOverview from 'hook/usePolicy';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toggleDateFormat } from 'utils/enrollment';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import { format } from 'date-fns';
import { enUS, it } from 'date-fns/locale';

const ClaimInformation = () => {
    // langauge settings
    const locale = useLanguage();
    const formattedMessages = messages[locale];

    // get values from contexts
    const claimFormValues = useClaim();
    const { loading, activePolicy } = usePolicyOverview();

    // react-router-dom state
    const { policyId } = useParams();

    // component state
    const specificPolicy = activePolicy?.find((policy) => policy.policySelfId === policyId);
    const cardDetails = useMemo(() => {
        if (specificPolicy) {
            const { policyNumber, coveragePeriod, insuredItem, serialNumber, policyHolderName } = specificPolicy;
            return { policyNumber, coveragePeriod, insuredItem, serialNumber, policyHolderName };
        }
        return {
            policyNumber: '-',
            coveragePeriod: '-',
            insuredItem: '-',
            serialNumber: '-',
            policyHolderName: '-',
        };
    }, [specificPolicy]);

    // component constants
    const { dateOfIncident, incidentDescription, theftOrDamaged } = claimFormValues;
    const { insuredItem, serialNumber } = cardDetails;

    const formattedClaimReason = (input: 'Damaged' | 'Theft' | 'Lost') => {
        switch (input) {
            case 'Damaged':
                return formattedMessages.damaged_label;
            case 'Theft':
                return formattedMessages.theft_label;
            case 'Lost':
                return formattedMessages.lost_label;
            default:
                return '';
        }
    };

    const formatLocalizedDate = (inputDate: string) => {
        if (inputDate === '') {
            return locale === 'it-it'
                ? format(new Date(), 'eee dd MMM yyyy HH:mm:ss (zzz)', { locale: it })
                : format(new Date(), 'eee dd MMM yyyy HH:mm:ss (zzz)', { locale: enUS });
        }
        return locale === 'it-it'
            ? format(toggleDateFormat(dateOfIncident) as Date, 'eee dd MMM yyyy HH:mm:ss (zzz)', { locale: it })
            : format(toggleDateFormat(dateOfIncident) as Date, 'eee dd MMM yyyy HH:mm:ss (zzz)', { locale: enUS });
    };

    let render = <PageLoader loaderSize="30px" />;
    if (!loading) {
        render = activePolicy ? (
            <>
                <PolicyDetailListing title={formattedMessages.claim_date_application_header_label} value={formatLocalizedDate('')} />
                <PolicyDetailListing title={`${formattedMessages.claim_review_accident_date}: `} value={formatLocalizedDate(dateOfIncident)} />
                <PolicyDetailListing title={formattedMessages.damaged_stolen_item_name_header_label} value={insuredItem} />
                <PolicyDetailListing title={formattedMessages.item_serial_number_header_label} value={serialNumber} />
                <PolicyDetailListing title={formattedMessages.claim_reason_header_label} value={formattedClaimReason(theftOrDamaged)} />
                <PolicyDetailListing title={formattedMessages.incident_description_header_label} value={incidentDescription} />
                {/* <PolicyDetailListing title={formattedMessages.practitioner_store_selected_header_label} value={practitioner.split(':')[1]} />
                <PolicyDetailListing title={`${formattedMessages.location_label}: `} value={location.split(':')[1]} /> */}
            </>
        ) : (
            <NoData />
        );
    }
    return render;
};

export default ClaimInformation;
