/* eslint-disable @typescript-eslint/ban-types */
import { httpClient, TokenType } from '../lib/httpClient';
import {
    Choice,
    ChoiceCreatePayload,
    ChoiceTypeCreatePayload,
    DuplicateCheck,
    DuplicateCheckPayload,
    Note,
    NoteCreatePayload,
    NoteUpdatePayload,
    AccountLoginPayload,
    AccountLoginResponse,
    BusinessPartner,
    PasswordRecoveryPayload,
    PasswordRecoveryResponse,
    ProductPlan,
    RegisterUserRequest,
    PasswordResetResponse,
    PasswordResetPayload,
    PasswordChangePayload,
    FileAttachment,
    ActivityCreationPayload,
    Activity,
    ActivityCategory,
    ActivityStatusType,
    ActivityPriority,
    BackOfficeUser,
    ActivityAttribute,
    Product,
} from '../interfaces/api';
import Endpoints from '../lib/endpoints';
import {
    PersonalPartnerV2Response,
    PoliciesV2Response,
    QuotationV2Response,
    V2BusinessPartner,
    V2IncompleteOrderInterface,
    V2PolicyInterface,
} from '../interfaces/api-v2';

function ucfirst(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function displayQueryValue(value: string | string[] | number | boolean): string {
    if (Array.isArray(value)) {
        return value.join(',');
    }
    if (typeof value === 'boolean') {
        return ucfirst(value.toString());
    }
    return value.toString();
}

interface QueryParams {
    [key: string]: string | string[] | number | boolean | undefined;
}
/**
 * Converts an object to a URLSearchParams object which can be used in a URL.
 * @example
 * const params = { emailPrimary: 'foo@bar.com', limit: undefined, expand: ['addresses', 'policies'] };
 * const queryParams = createQueryParams(params);
 * console.log(queryParams.toString());
 * // emailPrimary=foo%40bar.com&expand=addresses%2Cpolicies
 * // emailPrimary=foo@bar&expand=addresses,policies (escaped)
 * @note
 * - No special handling for null values, they will be converted to a string ("null").
 */
function createQueryParams(options?: QueryParams) {
    return new URLSearchParams(
        Object.keys(options ?? {}).reduce((acc, key) => {
            const value = options?.[key];
            return {
                ...acc,
                ...(value !== undefined && {
                    [key]: displayQueryValue(value),
                }),
            };
        }, {})
    );
}

/**
 * Constructs an endpoint URL with query parameters.
 * @example
 * ```ts
 * const url = endpointWithParams('https://example.com/foo', { emailPrimary: 'foo@bar' });
 * console.log(url); // https://example.com/foo?emailPrimary=foo%40bar.com
 * ```
 */
function endpointWithParams(endpoint: string, params: QueryParams): string {
    const queryString = createQueryParams(params).toString();
    return `${endpoint}${!queryString ? '' : `?${queryString}`}`;
}

export async function getBusinessPartnersFromEmail(email: string, extraParams: QueryParams = {}): Promise<BusinessPartner[]> {
    try {
        const params = {
            emailPrimary: email,
            ...extraParams,
        };
        const { data } = await httpClient.get<BusinessPartner[]>(endpointWithParams(Endpoints.businessPartner, params));
        return data ?? [];
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getBusinessPartner<T extends {} = BusinessPartner>(uuid: string, extraParams: QueryParams = {}): Promise<T> {
    try {
        const { data } = await httpClient.get<T>(endpointWithParams(`${Endpoints.businessPartner}${uuid}/`, extraParams));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function checkDuplicateEmail(payload: DuplicateCheckPayload): Promise<DuplicateCheck> {
    // NOTE: Error handling is to be done in the calling function
    const { data } = await httpClient.post<DuplicateCheck>(Endpoints.duplicateCheck, payload);
    return data ?? [];
}

export async function getNotes<T = Note>(extraParams: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(
            endpointWithParams(Endpoints.notes, {
                ...extraParams,
            })
        );
        return data ?? null;
    } catch {
        throw new Error('Something weng wrong.');
    }
}

export async function createNote<T = Note>(payload: NoteCreatePayload): Promise<T> {
    try {
        const { data } = await httpClient.post(Endpoints.notes, payload);
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function updateNote<T = Note>(noteId: string, payload: NoteUpdatePayload): Promise<T> {
    try {
        const { data } = await httpClient.patch(`${Endpoints.notes}${noteId}/`, payload);
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function deleteNote<T = Note>(noteId: string): Promise<T> {
    try {
        const { data } = await httpClient.delete(`${Endpoints.notes}${noteId}/`);
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function createOrUpdateNote<T = Note>(noteId: string, payload: NoteCreatePayload | NoteUpdatePayload): Promise<T> {
    if (noteId) {
        return updateNote(noteId, payload as NoteUpdatePayload);
    }
    return createNote(payload as NoteCreatePayload);
}

export async function getChoices<T = Choice>(extraParams: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(endpointWithParams(Endpoints.choices, extraParams));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function createChoice<T = Choice>(payload: ChoiceCreatePayload): Promise<T> {
    try {
        const { data } = await httpClient.post(Endpoints.choices, payload);
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getChoiceTypes<T = Choice>(extraParams: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(endpointWithParams(Endpoints.choiceTypes, extraParams));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function createChoiceType<T = Choice>(payload: ChoiceTypeCreatePayload): Promise<T> {
    try {
        const { data } = await httpClient.post(Endpoints.choiceTypes, payload);
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function registerUser(payload: RegisterUserRequest): Promise<unknown> {
    const http = httpClient.post(Endpoints.registration, payload);
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const message = error?.response?.data?.message || error?.message || 'Something went wrong.';
        throw new Error(message);
    }
}
export async function loginUser(payload: AccountLoginPayload): Promise<AccountLoginResponse> {
    const http = httpClient.post(Endpoints.login, payload);
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        throw new Error('Login failed. Please check your email and password and try again');
    }
}
export async function recoverPassword(payload: PasswordRecoveryPayload): Promise<PasswordRecoveryResponse> {
    const http = httpClient.post(Endpoints.passwordRecovery, payload);
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        throw new Error('Recovery email failed to be sent. Please use a valid email address or attempt later');
    }
}

export async function resetPassword(payload: PasswordResetPayload): Promise<PasswordResetResponse> {
    const http = httpClient.post(Endpoints.passwordReset, payload);
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        throw new Error('Recovery email failed to be sent. Please use a valid email address or attempt later');
    }
}

export async function changePassword(payload: PasswordChangePayload): Promise<PasswordResetResponse> {
    const http = httpClient.withAuthMode(TokenType.JWT, (client) => client.post(Endpoints.passwordUpdate, payload));
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        throw new Error('Failed to change password. Please check your current password and try again');
    }
}

export async function verifyToken(payload: AccountLoginResponse): Promise<AccountLoginResponse> {
    const http = httpClient.post(Endpoints.verifyToken, payload);
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw new Error('Token failed to be verified or has expired, please relogin again');
    }
}

export async function refreshToken(payload: AccountLoginResponse): Promise<AccountLoginResponse> {
    const http = httpClient.post(Endpoints.refreshToken, payload);
    try {
        const { data } = await http;

        return data ?? null;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        throw new Error('Token failed to be verified or has expired, please relogin again');
    }
}

export async function getPersonalBpInfo(): Promise<V2BusinessPartner> {
    try {
        const { data } = await httpClient.withAuthMode(TokenType.JWT, (client) =>
            client.get<PersonalPartnerV2Response>(Endpoints.personalBusinessPartner)
        );
        return data.data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getBpPolicies(params: QueryParams = {}): Promise<V2PolicyInterface[]> {
    try {
        const { data } = await httpClient.withAuthMode(TokenType.JWT, (client) =>
            client.get<PoliciesV2Response>(endpointWithParams(Endpoints.personalPolicies, params))
        );
        return data.data ?? [];
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getBpQuotations(params: QueryParams = {}): Promise<V2IncompleteOrderInterface[]> {
    try {
        const { data } = await httpClient.withAuthMode(TokenType.JWT, (client) =>
            client.get<QuotationV2Response>(endpointWithParams(Endpoints.personalQuotations, params))
        );

        return data.data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getProductPlan<T = ProductPlan>(params: QueryParams = {}) {
    try {
        const { data } = await httpClient.get<T[]>(endpointWithParams(Endpoints.productPlans, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getProducts<T = Product>(params: QueryParams = {}) {
    try {
        const { data } = await httpClient.get<T[]>(endpointWithParams(Endpoints.products, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getFileAttachments<T = FileAttachment>(params: QueryParams = {}) {
    try {
        const { data } = await httpClient.get<T[]>(endpointWithParams(Endpoints.fileAttachments, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function createActivity<T = Activity>(payload: ActivityCreationPayload): Promise<T> {
    try {
        const { data } = await httpClient.post(Endpoints.activities, payload);
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getActivityCategories<T = ActivityCategory>(params: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(endpointWithParams(Endpoints.categories, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getActivityStatusTypes<T = ActivityStatusType>(params: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(endpointWithParams(Endpoints.statusTypes, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getActivityPriorities<T = ActivityPriority>(params: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(endpointWithParams(Endpoints.priorities, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getActivityAttributes<T = ActivityAttribute>(params: QueryParams = {}): Promise<T[]> {
    try {
        const { data } = await httpClient.get(endpointWithParams(Endpoints.attributes, params));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}

export async function getUsers<T extends {} = BackOfficeUser[]>(extraParams: QueryParams = {}): Promise<T> {
    try {
        const { data } = await httpClient.get<T>(endpointWithParams(Endpoints.user, extraParams));
        return data ?? null;
    } catch {
        throw new Error('Something went wrong.');
    }
}
