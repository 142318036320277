import { useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import OTPInput from 'otp-input-react';
import { useMutation } from '@tanstack/react-query';
import { verifyOtp, fetchBp } from 'services/httpService';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import { delay } from 'v2/utils/cookies';
import translate from 'i18n/messages/translate';
import { showToast } from '@shared/core';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import VerificationWrapper from './VerificationWrapper';

interface OTPVerificationProps {
    previousHandler: () => void;
}

const OTPVerification = ({ previousHandler }: OTPVerificationProps) => {
    const quoteStore = useQuotationStorage();
    const [otp, setOtp] = useState('');
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const disabledContinue = useMemo(() => otp.length < 6, [otp]);

    const otpMutation = useMutation({
        mutationFn: async (value: string) => {
            if (quoteStore.items.submissionEmailAddress) {
                try {
                    const response = await verifyOtp(quoteStore.items.submissionEmailAddress, value);
                    Cookies.set('token', response.token);

                    if (quoteStore.items.hasBp) {
                        try {
                            const bp = await fetchBp(
                                { emailPrimary: quoteStore.items.submissionEmailAddress, fields: ['number', 'self'] },
                                { headers: { Authorization: `i2go ${response.token}` } }
                            );
                            quoteStore.addItem('businessPartnerUUID', bp?.self.id);
                        } catch (error) {
                            //
                        }
                    }

                    let nextPath = '/enrollment';
                    if (!quoteStore.items.quotedItems.length) nextPath = '/dashboard';

                    // this is to ensure token is set before we made any API call
                    // in dashboard or enrollemnt page
                    await delay(() => {
                        window.location.pathname = nextPath;
                    }, 1000);
                } catch (error) {
                    showToast({
                        message: formattedMessages.verify_otp,
                        enqueueSnackbarConfig: { variant: 'error' },
                        extraConfig: { enableCloseAction: true },
                    });
                }
            }
        },
    });

    const editEmailHandler = () => {
        previousHandler();
    };

    const continueHandler = () => {
        otpMutation.mutate(otp);
    };

    return (
        <VerificationWrapper
            disabled={otpMutation.isLoading}
            processing={otpMutation.isLoading}
            goBackButtonLabel={translate('edit_email')}
            textH5={translate('otp_verification_header')}
            continueHandler={continueHandler}
            goBackHandler={editEmailHandler}
            disabledContinue={disabledContinue}
            textBody1={translate('otp_verification_description')}
        >
            <OTPInput
                value={otp}
                onChange={(otpValue: string) => setOtp(otpValue)}
                inputClassName="OtpFormInput"
                inputStyles={{
                    height: 56,
                    width: 50,
                    maxWidth: 50,
                    marginRight: 10,
                    flex: 1,
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: 4,
                }}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
            />
        </VerificationWrapper>
    );
};
export default OTPVerification;
