import React, { ChangeEvent } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { listInputProps } from 'interfaces/profile';
import { formValueProps } from 'components/customInput/TextInput';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface objectProps {
    [key: string]: string;
}

interface SelectCheckboxProps {
    formValue?: formValueProps;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    item?: listInputProps;
    limitTags?: number;
    listCheckbox?: objectProps[];
}

const SelectCheckbox = ({ formValue, handleChange, item, limitTags, listCheckbox }: SelectCheckboxProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onHandleChange = (event: any, values: objectProps[]) => {
        if (handleChange)
            handleChange({
                ...event,
                target: {
                    ...event.target,
                    id: item?.useState,
                    name: item?.useState,
                    value: values,
                },
            });
    };

    return (
        <Autocomplete
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            id="checkboxes-tags-reinsurer"
            limitTags={limitTags}
            multiple
            onChange={onHandleChange}
            options={listCheckbox || []}
            value={formValue && item?.useState ? formValue[item.useState] : []}
            renderOption={(props, option, { selected }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.label}
                </li>
            )}
            renderInput={(params) => <TextField {...params} name={item?.useState} label={item?.placeholder} />}
            style={{ width: '100%' }}
        />
    );
};

SelectCheckbox.defaultProps = {
    formValue: undefined,
    handleChange: undefined,
    item: undefined,
    limitTags: 2,
    listCheckbox: [
        { label: 'Fire', value: 'fire' },
        { label: 'Earthquake', value: 'earthquake' },
        { label: 'Flood', value: 'flood' },
        { label: 'Tornado', value: 'tornado' },
        { label: 'Asteroid', value: 'asteroid' },
        { label: 'Root Damage', value: 'roofDamage' },
    ],
};

export default SelectCheckbox;
