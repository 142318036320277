import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import ListContent from 'components/customInput/selectAutocomplete/ListContent';
import ListHeader from 'components/customInput/selectAutocomplete/ListHeader';
import { listInputProps } from 'interfaces/profile';
import React, { useEffect } from 'react';

interface objectProps {
    [key: string]: string;
}

interface CustomListProps {
    customPageScroll?: number;
    formValueState: objectProps[];
    handleBlur: (status: boolean) => (event: unknown) => void;
    handleSelectedChip?: (value: string) => void;
    item?: listInputProps;
    listData?: objectProps[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onHandleChange?: (event: any, values: objectProps[] | objectProps) => void;
    selectedCession?: string[];
}

const CustomList = ({
    customPageScroll,
    formValueState,
    handleBlur,
    handleSelectedChip,
    item,
    listData,
    onHandleChange,
    selectedCession,
}: CustomListProps) => {
    useEffect(() => {
        const ref = document.getElementById('SelectAutocompleteList');
        if (ref && customPageScroll) ref.scrollTo(0, customPageScroll);
    }, [customPageScroll]);

    return (
        <ClickAwayListener onClickAway={handleBlur(false)}>
            <Paper style={{ paddingTop: 21, height: 400, overflow: 'auto' }} id="SelectAutocompleteList">
                <ListHeader handleSelectedChip={handleSelectedChip} listFilter={item?.listFilter} selectedChip={selectedCession} />
                <ListContent handleSelect={onHandleChange} listData={listData} formState={formValueState} />
            </Paper>
        </ClickAwayListener>
    );
};

CustomList.defaultProps = {
    customPageScroll: 0,
    handleSelectedChip: undefined,
    item: undefined,
    listData: undefined,
    onHandleChange: undefined,
    selectedCession: undefined,
};

export default CustomList;
