import React from 'react';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import NoData from 'components/NoData';
import { activePolicyProps } from 'views/dashboard';
import Details from 'views/dashboard/customCard/Details';
import Header from 'views/dashboard/customCard/Header';
import { Box } from '@mui/material';
import translate from 'i18n/messages/translate';

interface CustomCardProps {
    data: activePolicyProps[] | null;
    buttonTitle?: string;
    handleClaim?: (item: string) => () => void;
    withActionButton?: boolean;
}

const CustomCard = ({ data, buttonTitle, handleClaim, withActionButton }: CustomCardProps) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            marginTop: '40px',
            flexWrap: 'wrap',
        }}
    >
        {data && data?.length > 0 ? (
            data?.map((item: activePolicyProps) => (
                <Box key={Math.random()} sx={{ m: { xs: '0 auto 48px', md: '0px 48px 48px 0px' } }}>
                    <Card
                        elevation={0}
                        sx={{
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            width: '360px',
                            borderRadius: '4px',
                        }}
                    >
                        <Header item={item} />
                        <Divider />
                        <Details item={item} />
                        {item.status === 'Active' && withActionButton ? (
                            <CardActions style={{ margin: '0px 12px 12px 12px' }}>
                                <Button
                                    disabled={item.status !== 'Active'}
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClaim?.(item?.policySelfId as string)}
                                >
                                    {translate(buttonTitle ?? 'claim_now_button_label')}
                                </Button>
                            </CardActions>
                        ) : null}
                    </Card>
                </Box>
            ))
        ) : (
            <NoData />
        )}
    </Box>
);

CustomCard.defaultProps = {
    buttonTitle: 'claim_now_button_label',
    handleClaim: undefined,
    withActionButton: false,
};

export default CustomCard;
