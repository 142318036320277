import { Checkbox, FormControlLabel, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { UploadedFile } from 'interfaces/claim';
import { AttachmentStatus } from 'interfaces/claimReview';
import React, { FC, useMemo, useState } from 'react';
import { formatBytes } from 'utils/claim';
import EnlargedAttachmentPreview from './EnlargedAttachmentPreview';

interface AttachmentPreviewItemProps {
    uploadedFile: UploadedFile;
    isEditable: boolean;
    attachmentsStatuses: AttachmentStatus;
    handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const AttachmentPreviewItem: FC<AttachmentPreviewItemProps> = ({ uploadedFile, isEditable, attachmentsStatuses, handleCheckboxChange }) => {
    const [isItemClicked, setIsItemClicked] = useState<boolean>(false);
    const attachmentClicked: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void = () => {
        setIsItemClicked(true);
    };

    const handleEnlargedPreviewClose = () => {
        setIsItemClicked(false);
    };

    const renderComponent = useMemo(() => {
        if (uploadedFile.file.type.includes('pdf')) {
            return <embed src={URL.createObjectURL(uploadedFile.file)} width="140px" height="105px" />;
        }
        if (uploadedFile.file.type.includes('video')) {
            return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video style={{ backgroundSize: 'cover' }} width="140px" height="105px" controls>
                    <source src={URL.createObjectURL(uploadedFile.file)} />
                </video>
            );
        }
        return (
            <img style={{ backgroundSize: 'cover', width: '140px', height: '105px' }} alt="preview" src={URL.createObjectURL(uploadedFile.file)} />
        );
    }, [uploadedFile.file]);

    return (
        <>
            <EnlargedAttachmentPreview isOpen={isItemClicked} file={uploadedFile.file} handleClose={handleEnlargedPreviewClose} />
            <ImageListItem key={uploadedFile.file.name} sx={{ width: '140px', height: '105px' }}>
                {isEditable ? (
                    <FormControlLabel
                        sx={{ position: 'relative' }}
                        control={
                            <Checkbox
                                name={uploadedFile.file.name}
                                sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '-6px',
                                    left: '4px',
                                    '&.Mui-checked': {
                                        color: '#fff',
                                    },
                                    '&.MuiCheckbox-root': {
                                        color: '#fff',
                                    },
                                }}
                                checked={attachmentsStatuses[uploadedFile.file.name as string]}
                                onChange={handleCheckboxChange}
                            />
                        }
                        labelPlacement="end"
                        label={renderComponent}
                    />
                ) : (
                    renderComponent
                )}
                <ImageListItemBar
                    sx={{
                        maxWidth: '140px',
                        cursor: 'pointer',
                        '& .MuiImageListItemBar-titleWrap': {
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        },
                    }}
                    title={
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
                            {uploadedFile.file.name}
                        </Typography>
                    }
                    position="below"
                    subtitle={
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }} variant="body2">
                            {formatBytes(uploadedFile.file.size)}
                        </Typography>
                    }
                    onClick={attachmentClicked}
                />
            </ImageListItem>
        </>
    );
};

export default AttachmentPreviewItem;
