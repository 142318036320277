import { AxiosRequestConfig } from 'axios';
import { Policy, PolicyVersion, PolicyVersionItem, PolicyContract, Claim, FileAttachmentResponse } from 'interfaces/endpoints';
import {
    BusinessPartner,
    BusinessPartnerAddress,
    BusinessPartnerCreation,
    CreateBusinessPartnerAddress,
    AddressDetails,
    CreatePolicyItem,
    CreateIncompleteOrder,
    CreateBusinessPartnerRelationType,
    BusinessPartnerRelationType,
    PatientCreation,
    CreateBusinessPartnerRelation,
    BusinessPartnerRelation,
    CreatePolicyRelation,
    PolicyRelation,
} from 'interfaces/enrollment';
import { listOfQuoteProps } from 'interfaces/quote';
import { IncompleteOrder, LocationChoicesResponse } from 'interfaces/general';
import { CreateOfflinePayment, CreatePaymentIntent } from 'interfaces/payment';
import { targetedPolicyVersionItemProps } from 'interfaces/devices';
import { CreateClaim } from 'interfaces/claim';
import { getPaymentErrorDetail } from 'utils/payment';
import { httpClient } from './httpClient';
import { getSpecificClaimURL, END_POINTS } from './endpoints';
import { environment } from '../../environments/environment';

export async function getAllPolicies(): Promise<Policy[]> {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policies.href}`, { params: { productId: END_POINTS.product.id } });
        return data ?? null;
    } catch {
        throw new Error('get_all_policies');
    }
}

export async function getAllPoliciesWithExpand(): Promise<Policy[]> {
    try {
        const { data } = await httpClient.get(
            `${END_POINTS.policies.href}?expand=holder,holder.address,versions,versions.items,versions.incomplete_order`,
            { params: { productId: END_POINTS.product.id } }
        );
        return data ?? null;
    } catch {
        throw new Error('get_all_policies');
    }
}

export const getTargetedPolicy: (uuid?: string) => Promise<Policy> = async (uuid = '0bb675f1-b555-42bd-9d9a-fde63749e3f2') => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policies.href}${uuid}`);
        return data ?? null;
    } catch {
        throw new Error('get_targeted_policy');
    }
};

export const cancelTargetedPolicy: (uuid?: string) => Promise<Policy> = async (uuid = '0bb675f1-b555-42bd-9d9a-fde63749e3f2') => {
    try {
        const { data } = await httpClient.delete(`${END_POINTS.policies.href}${uuid}`);
        return data ?? null;
    } catch {
        throw new Error('delete_targeted_policy');
    }
};

export const getTargetedPolicyVersion: (uuid: string) => Promise<PolicyVersion> = async (uuid) => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policiesVersion.href}${uuid}`);
        return data ?? null;
    } catch {
        throw new Error('get_targeted_policy_version');
    }
};

export const getTargetedPolicyIncompleteOrder: (uuid: string) => Promise<unknown> = async (uuid) => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policyIncompleteOrder.href}${uuid}`);
        return data ?? null;
    } catch {
        throw new Error('get_targeted_incomplete_order');
    }
};

export const getTargetedPolicyVersionItem: (uuid: string) => Promise<PolicyVersionItem> = async (uuid) => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policyVersionItem.href}${uuid}`);
        return data ?? null;
    } catch (err) {
        throw new Error('get_targeted_policy_version_item');
    }
};

export const getTargetedPolicyVersionItemWithoutId: () => Promise<targetedPolicyVersionItemProps[]> = async () => {
    try {
        const { data } = await httpClient.get(END_POINTS.policyVersionItem.href);
        return data ?? null;
    } catch (err) {
        throw new Error('get_targeted_policy_version_item');
    }
};

export const getTargetedPolicyHolder: (uuid?: string) => Promise<BusinessPartner> = async (uuid) => {
    try {
        // add in the query params such that only BP with Customer role will be returned
        const { data } = await httpClient.get<BusinessPartner[]>(`${END_POINTS.policyHolder.href}${uuid || ''}?roles=CUSTOMER`);
        return data[0] ?? null;
    } catch {
        throw new Error('get_targeted_business_partner');
    }
};

export const createBussinessPartner: (bussinessPartnerPayload: BusinessPartnerCreation | PatientCreation) => Promise<BusinessPartner> = async (
    businessPartnerPayload
) => {
    try {
        const result = await httpClient.post(END_POINTS.policyHolder.href, { ...businessPartnerPayload });

        return result.data ?? null;
    } catch {
        throw new Error('post_business_partner');
    }
};

export const createAddress: (addressPayload: CreateBusinessPartnerAddress) => Promise<AddressDetails> = async (addressPayload) => {
    try {
        const result = await httpClient.post(END_POINTS.businessPartnerAddress.href, addressPayload);
        return result.data ?? null;
    } catch {
        throw new Error('post_business_partner_address');
    }
};

export const createPolicyVersionItem: (policyItemPayload: CreatePolicyItem) => Promise<PolicyVersionItem> = async (policyItemPayload) => {
    try {
        const result = await httpClient.post(END_POINTS.policyVersionItem.href, policyItemPayload);
        return result.data ?? null;
    } catch {
        throw new Error('post_policy_version_item');
    }
};

export const createIncompleteOrder: (incompleteOrderPayload: CreateIncompleteOrder) => Promise<IncompleteOrder> = async (incompleteOrderPayload) => {
    try {
        const result = await httpClient.post(END_POINTS.policyIncompleteOrder.href, incompleteOrderPayload);
        return result.data ?? null;
    } catch {
        throw new Error('post_incomplete_order');
    }
};

export const createBusinessPartnerRelationTypes: (
    businessPartnerRelationTypePayload: CreateBusinessPartnerRelationType
) => Promise<BusinessPartnerRelationType> = async (businessPartnerRelationTypePayload) => {
    try {
        const result = await httpClient.post(END_POINTS.businessPartnerRelationType.href, businessPartnerRelationTypePayload);
        return result.data ?? null;
    } catch {
        throw new Error('post_business_partner_relation_type');
    }
};

export const createBusinessPartnerRelation: (
    businessPartnerRelationPayload: CreateBusinessPartnerRelation
) => Promise<BusinessPartnerRelation> = async (createBusinessPartnerRelationPayload) => {
    try {
        const result = await httpClient.post(END_POINTS.businessPartnerRelations.href, createBusinessPartnerRelationPayload);
        return result.data ?? null;
    } catch {
        throw new Error('post_business_partner_relation');
    }
};

export const createPolicyRelation: (policyRelationPayload: CreatePolicyRelation) => Promise<PolicyRelation> = async (policyRelationPayload) => {
    try {
        const result = await httpClient.post(END_POINTS.policyRelation.href, policyRelationPayload);
        return result.data ?? null;
    } catch {
        throw new Error('post_business_partner_relation');
    }
};

export const getTargetedPolicyContract: (uuid: string) => Promise<PolicyContract> = async (uuid) => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policyContract.href}${uuid}`);
        return data ?? null;
    } catch {
        throw new Error('get_targeted_policy');
    }
};

export const getServiceRatingPricing: (serialNumbers: string[]) => Promise<listOfQuoteProps> = async (serialNumbers) => {
    try {
        const { data } = await httpClient.post(END_POINTS.ratingService.href, {
            product: `${environment.REACT_APP_API_BASE_URL || 'http://localhost:3000/api/v3'}/product-service/products/${
                environment.REACT_APP_PRODUCT_UUID
            }/`,
            serviceEndpoint: '/get-rating/',
            payload: { serialNumbers },
        });
        return data ?? null;
    } catch {
        throw new Error('ineligible_device');
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const rateIncompleOrder: (incompleOrderId: string) => Promise<any> = async (incompleteOrderId) => {
    const payload = {
        createNewVersion: false,
        checkBusinessRules: false,
        endorsementNote: '',
    };
    try {
        const { data } = await httpClient.put(`${END_POINTS.rateIncompleteOrder.href}${incompleteOrderId}/`, payload);
        return data ?? null;
    } catch (error) {
        throw new Error('rate_quotation');
    }
};

export const getTargetedBusinessPartnerAddress: () => Promise<BusinessPartnerAddress[]> = async () => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.businessPartnerAddress.href}`);
        return data ?? null;
    } catch {
        throw new Error('get_business_partner_address');
    }
};

export const getTargetedClaim = async (claimId = 'd2af3fd8-a17a-4b64-8a51-502db05616c8'): Promise<unknown> =>
    httpClient
        .get(getSpecificClaimURL(claimId))
        .then((res) => res)
        .catch((e) => {
            throw e;
        });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTargetedClaimHolderId = async (claimId = 'd2af3fd8-a17a-4b64-8a51-502db05616c8'): Promise<any> => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.claim.href}?holderId=${claimId}`);
        return data ?? null;
    } catch {
        throw new Error('get_targeted_business_partner');
    }
};

export async function getAllClaims(): Promise<Claim[]> {
    try {
        const { data } = await httpClient.get(`${END_POINTS.claim.href}`);
        return data ?? null;
    } catch {
        throw new Error('get_all_claims');
    }
}

export async function fetchBp(queryParams: { [key: string]: any }, config: AxiosRequestConfig = {}): Promise<BusinessPartner | null> {
    try {
        const params = new URLSearchParams(queryParams).toString();
        const { data } = await httpClient.get<BusinessPartner[]>(`${END_POINTS.policyHolder.href}?${params}`, config);

        return data ? data[0] : null;
    } catch {
        return null;
    }
}

export async function patchBp(uuid: string, payloads: { [key: string]: any }): Promise<any> {
    try {
        const { data } = await httpClient.patch(`${END_POINTS.policyHolder.href}${uuid}/`, payloads);
        return data ?? null;
    } catch {
        return null;
    }
}

export async function requestOtp(email: string, language: string): Promise<{ detail: string; newUser: boolean; hasBp: boolean }> {
    try {
        const { data } = await httpClient.post(`${END_POINTS.requestOtp.href}`, { email, language });

        // newUser will only return true if it is the FIRST time an email is used to request otp
        return data ?? null;
    } catch {
        throw new Error('get_otp');
    }
}

export async function verifyOtp(email: string, token: string): Promise<{ token: string }> {
    try {
        const { data } = await httpClient.post(`${END_POINTS.verifyOtp.href}`, { email, token });
        return data ?? null;
    } catch {
        throw new Error('verify_otp');
    }
}

export const createClaim: (payload: CreateClaim) => Promise<Claim> = async (payload) => {
    try {
        const { data } = await httpClient.post(`${END_POINTS.claim.href}`, payload);
        return data ?? null;
    } catch {
        throw new Error('create_claim');
    }
};

export const createFileAttachment: (payload: FormData) => Promise<FileAttachmentResponse> = async (payload) => {
    try {
        const { data } = await httpClient.post(`${END_POINTS.fileAttachment.href}`, payload, { headers: { 'Content-Type': 'application/json' } });
        return data ?? null;
    } catch {
        throw new Error('create_file_attachment');
    }
};

export const manualRefund: (uuid: string) => Promise<unknown> = async (uuid) => {
    try {
        const { data } = await httpClient.post(END_POINTS.manualRefund.href, {
            action: 'Manual',
            policy: uuid,
            skip_invoice: true,
        });
        return data ?? null;
    } catch {
        throw new Error('create_manual_refund');
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPolicyDocument: (uuid: string) => Promise<any> = async (uuid) => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.policyDocument.href}${uuid}/?generateFor=all&regenerate=false`);
        return data ?? null;
    } catch {
        throw new Error('get_policy_docs');
    }
};

export const getDevicesQuote: (serialNumberArr: string[]) => Promise<listOfQuoteProps> = async (serialNumberArr) => {
    const quickRatePayload = {
        product: `${environment.REACT_APP_API_BASE_URL}/product-service/products/${environment.REACT_APP_PRODUCT_UUID}/`,
        serviceEndpoint: '/get-rating/',
        payload: {
            serialNumbers: serialNumberArr,
        },
    };
    try {
        const { data } = await httpClient.post(`${END_POINTS.ratingService.href}`, quickRatePayload);
        return data ?? null;
    } catch (error) {
        throw new Error('get_device_quote');
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createPaymentIntent: (paymentItentPayload: CreatePaymentIntent) => Promise<any> = async (paymentIntentPayload) => {
    try {
        const { data } = await httpClient.post(`${END_POINTS.paymentIntent.href}`, paymentIntentPayload);
        return data ?? null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // e.g. message: 'Request req_ABCD3FGH1JKLmn: Your card has insufficient funds.'
        const errorMessage = error.response.data.message.split(': ')[1];
        const errorKey = getPaymentErrorDetail(errorMessage);
        throw new Error(errorKey);
    }
};

export const confirmPaymentIntent = async (paymentIntentCreatedId: string) => {
    try {
        const result = await httpClient.post(
            `https://api.stripe.com/v1/payment_intents/${paymentIntentCreatedId}/confirm`,
            {},
            {
                headers: {
                    Authorization: `${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                },
            }
        );
        return result ?? null;
    } catch (error) {
        throw new Error('confirm_payment_intent');
    }
};

// this is needed to make payment offline so that i can get an active policy locally
export const createOfflinePayment = async (createOfflinePaymentPayload: CreateOfflinePayment) => {
    try {
        const { data } = await httpClient.post(`${END_POINTS.offlinePayment.href}`, createOfflinePaymentPayload);
        return data ?? null;
    } catch {
        throw new Error('create_offline_payment');
    }
};

export const getDevicePurchasePractitioner: () => Promise<LocationChoicesResponse[]> = async () => {
    try {
        const { data } = await httpClient.get(`${END_POINTS.choices.href}?choice_type_slug=practitioner`);
        return data ?? null;
    } catch (error) {
        throw new Error('get_practitioner_locations');
    }
};
export const getDevicePurchaseLocation: (practitionerStore: string) => Promise<LocationChoicesResponse[]> = async (practitionerStore) => {
    try {
        // there are keys with '&' inside which will failed the API calling, convert it into the corresponsing code
        const modifiedPractitionerStore = practitionerStore.replace('&', '%26');
        const { data } = await httpClient.get(
            `${END_POINTS.choices.href}?choice_type_slug=location&parent_key=${modifiedPractitionerStore}&sort=key`
        );
        return data ?? null;
    } catch (error) {
        throw new Error('get_practitioner_locations');
    }
};
