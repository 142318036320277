import React, { useMemo, useReducer, createContext } from 'react';
import { policyStateProps } from 'interfaces/policyContext';

const initialState: policyStateProps = {
    policyData: undefined,
    activePolicy: undefined,
    expiredPolicy: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PolicyStore = createContext(initialState) as any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: policyStateProps, action: any) => {
    switch (action.type) {
        case 'handle_policy':
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const PolicyProvider = ({ children }: { children: React.ReactNode }) => {
    const [policyState, policyDispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => ({ policyState, policyDispatch }), [policyState, policyDispatch]);

    return <PolicyStore.Provider value={value}>{children}</PolicyStore.Provider>;
};
