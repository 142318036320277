import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import { useLanguage, useLanguageUpdate } from 'context/LanguageContext';
import translate from 'i18n/messages/translate';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import useVerifiedUser from 'v2/hooks/useVerifiedUser';
import Cookies from 'js-cookie';
import { patchBp } from 'services/httpService';
import { localesCode } from 'i18n/locales';

interface SidebarProps {
    closeDrawer: () => void;
}

const Sidebar = ({ closeDrawer }: SidebarProps) => {
    const setLocales = useLanguageUpdate();
    const locales = useLanguage();
    const quoteStore = useQuotationStorage();
    const verifiedUser = useVerifiedUser();
    const navigate = useNavigate();

    const onLangugageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocales((event.target as HTMLInputElement).value);

        if (quoteStore.items.businessPartnerUUID) {
            patchBp(quoteStore.items.businessPartnerUUID, { correspondenceLanguage: localesCode[locales] });
        }
    };

    const onManageAccountClick = () => {
        closeDrawer();
        navigate('/account-setting/profile');
    };

    const onLogoutClick = () => {
        closeDrawer();
        quoteStore.resetItem();
        Cookies.remove('token');
        window.location.reload();
    };

    return (
        <Container>
            <LanguageContainer>
                <LanguageHeader>{translate('languages')}</LanguageHeader>
                <FormControl>
                    <RadioGroup aria-labelledby="radio-buttons-group-label" onChange={onLangugageChange} value={locales} name="radio-buttons-group">
                        <FormControlLabel value="en-us" control={<Radio />} label="English" />
                        <FormControlLabel value="it-it" control={<Radio />} label="Italiano" />
                        <FormControlLabel value="de-de" control={<Radio />} label="Deutsch" />
                    </RadioGroup>
                </FormControl>
            </LanguageContainer>
            {verifiedUser.hasBp && (
                <AccountContainer>
                    <Stack>
                        <Divider />
                        <UserInfoBox>
                            <UserNameText>
                                {verifiedUser?.bpInfo?.firstName} {verifiedUser?.bpInfo?.lastName}
                            </UserNameText>
                            <UserEmailText>{verifiedUser?.emailAddress}</UserEmailText>
                        </UserInfoBox>
                        <Divider />
                        <FlexContainer direction="row" onClick={onManageAccountClick}>
                            <Avatar sx={{ width: '24px', height: '24px', color: '#0000008F' }} />
                            <Text>{translate('manage_account')}</Text>
                        </FlexContainer>
                        <Divider />
                        <FlexContainer direction="row" onClick={onLogoutClick}>
                            <LogoutIcon sx={{ width: '24px', height: '24px', color: '#0000008F' }} />
                            <Text>{translate('logout')}</Text>
                        </FlexContainer>
                    </Stack>
                </AccountContainer>
            )}
        </Container>
    );
};

const Text = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000DE',
}));

const FlexContainer = styled(Stack)(({ theme }) => ({
    padding: '10px 16px',
    gap: '10px',
    alignItems: 'center',
    '&:hover': {
        cursor: 'pointer',
        background: theme.palette.grey[100],
    },
}));

const UserNameText = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000DE',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
}));

const UserEmailText = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: '14px',
    color: '#00000099',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
}));

const UserInfoBox = styled(Box)(() => ({
    padding: '10px 16px',
}));

const AccountContainer = styled(Box)(() => ({
    //
}));

const LanguageHeader = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: '12px',
    color: '#00000099',
    textTransform: 'uppercase',
}));

const LanguageContainer = styled(Box)(() => ({
    padding: '10px 16px',
    minHeight: 'calc(100vh - 220px)',
}));

const Container = styled(Box)(({ theme }) => ({
    width: '220px',
}));

export default Sidebar;
