import { useEffect, useState } from 'react';

type ObjectProps = { [key: string]: any };

/**
 * Converts the values in an object based on their type. Specifically, it converts:
 * - String 'true'/'false' to boolean
 * - String '123' to number
 * - Other values are copied as-is.
 *
 * @param {ObjectProps} obj - The object whose values will be converted.
 * @returns {ObjectProps} The object with converted values.
 */
function convertObjectValues(obj: ObjectProps): ObjectProps {
    // Convert object values based on their type
    const convertedObj: ObjectProps = {};

    Object.entries(obj).forEach(([key, value]) => {
        if (value === 'true' || value === 'false') {
            // Convert string 'true'/'false' to boolean
            convertedObj[key] = value === 'true';
        } else if (Number.isNaN(value)) {
            convertedObj[key] = Number(value);
        } else {
            // For other string values, just copy them as-is
            convertedObj[key] = value;
        }
    });

    return convertedObj;
}

/**
 * Sanitizes a dataset by converting the values in the DOMStringMap using
 * the convertObjectValues function.
 *
 * @param {DOMStringMap} values - The DOMStringMap whose values will be converted.
 * @returns {Object<string, any>} The sanitized object with converted values.
 */
function sanitizeDataSet(values: DOMStringMap) {
    return convertObjectValues(Object.fromEntries(Object.entries(values)));
}

/**
 * @typedef {Object} Props
 * @property {any} [key] - A dynamic key-value pair for attributes.
 */

/**
 * Custom hook to retrieve and observe data attributes from a DOM element.
 *
 * @param {string} selector - The ID of the DOM element to observe.
 * @returns {Props} - The current data attributes of the element as an object.
 */
function useAttributes(selector: string) {
    const [attributes, setAttributes] = useState<ObjectProps>();
    const element = document.getElementById(selector);

    useEffect(() => {
        if (element) {
            setAttributes(sanitizeDataSet(element.dataset));
            const mutationObserver = new MutationObserver(() => {
                setAttributes(sanitizeDataSet(element.dataset));
            });
            mutationObserver.observe(element, { attributes: true });
        }
    }, []);

    return attributes;
}

/**
 * Higher-Order Component (HOC) to inject data attributes from a DOM element into a component's props.
 *
 * @template T
 * @param {React.FunctionComponent<T>} Component - The component to wrap with additional props.
 * @param {string} selector - The ID of the DOM element to retrieve data attributes from.
 * @returns {React.FunctionComponent} - A new component with the data attributes passed as props.
 */
export function withAttributes<T extends ObjectProps>(Component: React.FunctionComponent<T>, selector: string) {
    return function AttributesProps(props: { [key: string]: any }) {
        const attributes = useAttributes(selector);
        const componentProps = { ...attributes, ...props };
        return <Component {...(componentProps as T)} />;
    };
}
