import { listInputProps } from 'interfaces/profile';

export const billingPersonalDetailsInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'first_name_label',
        type: 'text',
        useState: 'firstName',
        variant: 'outlined',
        disabledInput: true,
    },
    {
        id: 1,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'last_name_label',
        type: 'text',
        useState: 'lastName',
        variant: 'outlined',
        disabledInput: true,
    },
    {
        id: 2,
        inputType: 'text',
        style: { minWidth: '45.6vw', width: '100%' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'address',
        variant: 'outlined',
    },
    {
        id: 3,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'zipCode',
        variant: 'outlined',
    },
    {
        id: 4,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'city',
        variant: 'outlined',
    },
    {
        id: 5,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'state',
        variant: 'outlined',
    },
    {
        id: 6,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'country',
        variant: 'outlined',
    },
];

export const submissionApi = '';
