const getDeviceImage = (serialNumber: string) => {
    const first6characters = serialNumber.substring(0, 6);
    switch (first6characters) {
        case '101013':
            return 'assets/devices/cp950-kanso.png';
        case '101014':
            return 'assets/devices/cp1000.png';
        case '101015':
            return 'assets/devices/cp1150.png';
        case '101016':
            return 'assets/devices/cp1200.png';
        case '301014':
        case '301016':
            return 'assets/devices/baha-5-superpower.png';
        case '301017':
            return 'assets/devices/baha-6-max.png';
        case '117002':
            return 'assets/devices/osia.png';
        case '117003':
            return 'assets/devices/osia_sp.png';
        case '101020':
            return 'assets/devices/nucleus-kanso.png';
        default:
            return 'assets/devices/cp1000.png';
    }
};

export default getDeviceImage;
