/* eslint-disable prettier/prettier */
import LOCALES from '../locales';

export default {
    [LOCALES.DEUSTSCH]: {
        // navbar
        menu: 'Menü',
        accounts: 'Konten',
        policies: 'Richtlinien',
        profile: 'Profil',
        devices: 'Geräte',
        languages: 'Sprachen',
        english: 'English',
        italian: 'Italian',
        deutsch: 'Deutsch',
        signout: 'Abmelden',
        login: 'Anmelden',
        logout: 'Abmelden',
        manage_account: 'Konto verwalten',

        // footer
        about_tigerlab:
            'tigerlab ist der führende Anbieter von Versicherungssoftwarelösungen und eingebetteter Versicherung. Unsere modulare Software ermöglicht es Kunden, Anwendungen schnell bereitzustellen und einfach zu erweitern oder zu ändern, um strategische Geschäftsbedürfnisse zu erfüllen. Seit über 10 Jahren konzentrieren wir uns darauf, was Unternehmen wertvoller macht, und haben mit vielen unserer Kunden zusammengearbeitet, um ihnen zu helfen, bahnbrechende Geschäftsergebnisse zu erzielen.',
        copyright_message:
            'tigerlab GmbH ist ein registrierter Vermittler, ein Versicherungsvertreter mit Erlaubnis gemäß § 34d Abs. 1 GewO [Gewerbeordnung]',
        privacy_policy: 'Datenschutzerklärung',
        term_of_use: 'Nutzungsbedingungen',
        legal_notice: 'Impressum',
        company: 'Unternehmen',

        // get quote page
        get_a_quote: 'Ein Angebot anfordern',
        get_quote_description_v2: 'Fügen Sie Ihr Gerät hinzu und erhalten Sie eine jährliche Abdeckung. Sie können mehrere Geräte hinzufügen.',
        get_quote_description:
            "Fordern Sie ein Angebot für die jährliche Abdeckung an. Um ein zusätzliches Gerät hinzuzufügen, klicken Sie auf die Schaltfläche 'HINZUFÜGEN' unten.",
        serial_number: 'Seriennummer',
        add: 'Hinzufügen',
        cochlear_device_insurance: 'Versicherung für Cochlea-Implantate',
        enroll_now: 'Jetzt anmelden',
        your_quote: 'Ihr Angebot',
        remove: 'Entfernen',
        total_premium_label: 'Gesamtprämie',
        total_premium_description: 'Die Gesamtprämie versteht sich zuzüglich Steuern.',
        protection_plan: 'Schutzplan',
        total: 'Gesamt',
        image_header: 'Wir glauben daran, Versicherung zu vereinfachen.',
        image_description: 'Einfach und erschwinglich, auf Ihre Bedürfnisse zugeschnittene Versicherung.',
        captcha_verification: 'CAPTCHA-Verifizierung fehlgeschlagen',
        error_similar_device: 'Gerät mit ähnlicher Seriennummer kann nicht hinzugefügt werden.',

        // enter email page
        back: 'Zurück',
        enter_email: 'Geben Sie Ihre E-Mail-Adresse ein',
        enter_email_description: 'Bitte geben Sie Ihre E-Mail-Adresse unten ein, um den Anmeldeprozess fortzusetzen.',
        email_address_label: 'E-Mail-Adresse',
        continue: 'Fortsetzen',
        login_title: 'Mit E-Mail fortfahren',
        login_description: 'Bitte geben Sie Ihre E-Mail-Adresse ein, um ein einmaliges Passwort zu erhalten.',
        adjust_quote: 'Angebot anpassen',
        email: 'E-Mail',
        login_disclaimer:
            'Durch die Fortsetzung stimmen Sie zu, dass wir ein Konto für Sie erstellen (sofern noch nicht erstellt), und akzeptieren unsere {terms_and_condition} und {privacy_policy}',
        term_and_condition: 'Allgemeine Geschäftsbedingungen',

        // otp page
        enter_otp: 'Geben Sie das einmalige Passwort ein',
        enter_otp_description: 'Ein einmaliges Passwort (OTP) wurde an Ihre E-Mail-Adresse gesendet.',
        otp_verification_header: 'OTP-Verifizierung',
        otp_verification_description: 'Bitte geben Sie das einmalige Passwort aus der E-Mail ein, die Sie erhalten haben.',
        edit_email: 'E-Mail bearbeiten',

        // enrollment page
        enrollment_label: 'Anmeldung',
        enrollment_alert_header: 'Kontoerstellung.',
        enrollment_alert_description: 'Ihr Konto wird mit der in der Rubrik Persönliche Angaben angegebenen E-Mail-Adresse erstellt.',
        personal_details_label: 'Persönliche Angaben.',
        purchase_entity_label: `Ich kaufe im Auftrag von...`,
        myself_label: 'mir selbst',
        someone_else_label: 'jemand anderem.',
        same_owner_checkbox_label: 'Alle Geräte gehören demselben Besitzer.',
        first_name_label: 'Vorname.',
        last_name_label: 'Nachname.',
        id_no_label: 'ID-Nr.',
        dob_label: 'Geburtsdatum',
        confirm_email_label: 'E-Mail-Adresse bestätigen.',
        address_label: 'Adresse.',
        zip_label: 'Postleitzahl',
        city_label: 'Stadt',
        state_label: 'Bundesland',
        country_label: 'Land',
        enrollment_practitioner_center_label: 'Praxiszentrum/ Geschäft',
        location_label: 'Standort',

        guardian_details_label: 'Erziehungsberechtigte Angaben',
        guardian_first_name_label: 'Vorname des Erziehungsberechtigten',
        guardian_last_name_label: 'Nachname des Erziehungsberechtigten',
        device_owner_details_label: 'Gerätebesitzer-Daten',
        patient_first_name_label: 'Vorname des Patienten',
        patient_last_name_label: 'Nachname des Patienten',
        patient_id_label: 'Patienten-ID-Nummer',
        device_purchase_place_label: 'Ort des Geräte Kaufs',

        your_protection_policy_label: 'Ihre Schutzrichtlinie',
        coverage_period_label: 'Deckungszeitraum',
        tax_label: 'Steuer',
        transaction_fee_label: 'Transaktionsgebühr',
        add_device_label: 'Gerät(e) hinzufügen',
        cancel: 'Abbrechen',
        confirm: 'Bestätigen',

        where_hear_insurance_label: 'Wie haben Sie von der Versicherung erfahren?',
        device_purchase_acknowledgement: 'Ich bestätige, dass alle versicherten Geräte innerhalb der letzten 60 Tage gekauft wurden.',
        read_docs_acknowledgement: `Ich habe das {document_link} sorgfältig gelesen, bevor ich mit dem Kauf der Versicherungspolice fortfahre. Ich bin mir bewusst, dass die gedruckte Version des Informationssatzes jederzeit auf Anfrage verfügbar ist.`,
        continue_to_payment_label: 'Zur Bezahlung fortfahren',

        // checkout page
        checkout_label: 'Kasse',
        payment_details_label: 'Zahlungsdetails',
        card_number_label: 'Kartennummer',
        expiry_date_label: 'Ablaufdatum',
        cvv_cvc_label: 'CVV/CVC',
        name_on_card_label: 'Name auf der Karte',
        same_billing_address_label: 'Rechnungsadresse wie in den persönlichen Angaben',

        billing_address_details_label: 'Rechnungsadresse',
        owner_label: 'Inhaber',
        place_order_label: 'Bestellen',
        checkout_acknowledgement_first_line:
            'Ich habe das gesamte Informationsset gelesen, verstanden, akzeptiert und auf meinem Computer heruntergeladen:',
        ipid_label: 'IPID',
        additional_ipid_label: 'Zusätzliches IPID',
        policy_terms_conditions_label: 'Versicherungsbedingungen',
        privacy_notice_label: 'Datenschutzerklärung',
        annexes_label: 'Anlagen 3, 4 und 4 ter',
        checkout_acknowledgement_last_line: `Hiermit akzeptiere ich die Versicherungsbedingungen der von AIG Europe S.A. - Rappresentanza Generale per l'Italia ausgestellten Versicherung und erkläre mich bereit, die Cochlear-Geräteversicherung zu erwerben.`,

        // dashboard page
        active_tab_label: 'Aktiv',
        inactive_tab_label: 'Inaktiv',
        claim_now_button_label: 'Jetzt beantragen',
        policy_number_label: 'Policennummer',
        insured_item_label: 'Versichertes Objekt',
        client_name_label: 'Kundenname',
        view_docs_button_label: 'Dokumente anzeigen',
        claim_history_button_label: 'Schadenhistorie',
        add_new_device_button_label: 'Ein neues Gerät hinzufügen',
        terms_conditions_label: 'Allgemeine Geschäftsbedingungen',
        policy_document: 'richtliniendokument',

        // claim creation page
        hearing_aid_header_label: 'Hörgerät',
        claim_reason_label: 'Was ist der Grund für den Anspruch?',
        damaged_label: 'Beschädigt',
        theft_label: 'Diebstahl',
        lost_label: 'Verloren',

        incident_occurence_date_question_label: 'Wann ist der Vorfall eingetreten?',
        incident_description_question_label: 'Beschreiben Sie den Vorfall näher...',
        incident_occurence_date_label: 'Datum des Vorfalls',
        attachment_question_label: 'Haben Sie Fotos, Videos oder Dokumente, die für Ihre Ansprüche relevant sind?',
        attachment_alert_header_label:
            'Um einen Anspruch im Zusammenhang mit einem gestohlenen Gegenstand einzureichen, stellen Sie bitte sicher, dass die folgenden Dokumente hochgeladen werden:',
        attachment_alert_police_report_label: '(i) Polizeibericht über den gestohlenen Gegenstand',
        attachment_alert_purchase_invoice_label: '((ii) Kaufrechnung',
        attachment_alert_self_declaration: `(iii) Selbstbeschreibung, was passiert ist (im Falle von versehentlicher Beschädigung)`,
        attachment_alert_images: `(iv) Fotos oder Bilder des beschädigten Geräts, die den Schaden hervorheben (im Falle von versehentlicher Beschädigung)`,
        drag_drop_instruction_header: 'Ziehen Sie Ihre Fotos, Videos oder Dokumente hierher, um mit dem Hochladen zu beginnen',
        drag_drop_instruction_details_line_one: `Sie können JPG- und PNG-Bilder, MP4-Videos oder PDF-Dateien bis zu 200 MB hochladen.`,
        drag_drop_instruction_details_line_two: 'Bitte stellen Sie sicher, dass jede Ihrer Videodateien weniger als 10 MB groß ist.',
        browse_file_button_label: 'Dateien durchsuchen',
        repair_center_question_label: 'Wo möchten Sie Ihr Gerät reparieren lassen?',
        claim_repair_center_label: 'Praxiszentrum/ Geschäft',
        video_size_too_large_header: 'Dateigröße ist zu groß',
        video_size_too_large_message: 'Die Dateigröße für Videos darf 10 MB nicht überschreiten',
        or_label: 'ODER',

        policy_details_card_header_label: 'Policendetails',
        full_name_label: 'Vollständiger Name',

        // claim review page
        back_to_claim_form_button_label: 'Zurück zum Schadensformular',
        review_submit_header_label: 'Überprüfen und Abschicken',
        claim_info_header_label: 'Schadeninformationen',
        claim_date_application_header_label: 'Datum der Schadensmeldung:',
        claim_review_accident_date: `Datum des Vorfalls:`,
        damaged_stolen_item_name_header_label: 'Name des beschädigten/gestohlenen Gegenstands:',
        item_serial_number_header_label: 'Seriennummer des Gegenstands:',
        claim_reason_header_label: 'Grund des Anspruchs:',
        incident_description_header_label: 'Beschreibung des Vorfalls:',
        practitioner_store_selected_header_label: 'Ausgewähltes Praxiszentrum/Geschäft',
        edit_button_label: 'Bearbeiten',
        attachments_header_label: 'Anlagen',
        delete_button_label: 'Löschen',
        next_button_label: 'Weiter',

        // claim history page
        back_to_policies_button_label: 'Zurück zu den Policen',
        claim_history_header_label: 'Hier ist Ihre Schadenshistorie',
        claim_date_column_header: 'Anspruchsdatum',
        incident_date_column_header: 'Vorfalldatum',
        insurance_product_name_column_header: 'Name des Versicherungsprodukts',
        claim_number_column_header: 'Schadensnummer',
        item_column_header: 'Gegenstand',
        status_column_header: 'Status',
        type_of_notification_column_header: 'Art der Benachrichtigung',

        // account setting page
        account_header_label: 'KONTO',
        account_settings_header_label: 'Kontoeinstellungen',
        brand_label: 'Marke',
        model_label: 'Modell',
        colour_label: 'Farbe',
        patient_label: 'Patient',

        // mobile extra details
        learn_more_button_label: 'Mehr erfahren',

        // HTTP service error message
        ineligible_device: 'Dieses Gerät ist nicht für die Deckung berechtigt',
        get_all_policies: 'Alle Policen konnten nicht abgerufen werden',
        get_targeted_policy: 'Die angeforderte Police konnte nicht abgerufen werden',
        delete_targeted_policy: 'Die angestrebte Police konnte nicht storniert werden',
        get_targeted_policy_version: 'Die Policenversion konnte nicht abgerufen werden',
        get_targeted_incomplete_order: 'Der angestrebte unvollständige Auftrag konnte nicht abgerufen werden',
        get_targeted_policy_version_item: 'Das Element der Policenversion konnte nicht abgerufen werden.',
        get_targeted_business_partner: 'Geschäftspartner konnte nicht abgerufen werden',
        post_business_partner: 'Geschäftspartner konnte nicht erstellt werden',
        get_business_partner_address: 'Die Adresse des Geschäftspartners konnte nicht abgerufen werden',
        post_business_partner_address: 'Die Adresse des Geschäftspartners konnte nicht erstellt werden',
        post_policy_version_item: 'Das Element der Policenversion konnte nicht erstellt werden',
        post_incomplete_order: 'Der unvollständige Auftrag konnte nicht erstellt werden',
        post_business_partner_relation_type: 'Der Beziehungstyp des Geschäftspartners konnte nicht erstellt werden',
        post_business_partner_relation: 'Die Geschäftspartnerbeziehung konnte nicht erstellt werden',
        rate_quotation: 'Bewertung des Angebots fehlgeschlagen',
        get_all_claims: 'Es konnte nicht alle Ansprüche abgerufen werden',
        create_claim: 'Anspruch konnte nicht erstellt werden',
        get_otp: 'Anforderung der OTP fehlgeschlagen',
        verify_otp: '"Überprüfung der OTP fehlgeschlagen',
        create_file_attachment: 'Datei(en) konnten nicht angehängt werden',
        create_manual_refund: 'Manuelle Rückerstattung konnte nicht erstellt werden',
        get_policy_docs: 'Es konnten keine Policendokumente abgerufen werden',
        get_device_quote: 'Keine Gerätedetails verfügbar',
        create_payment_intent: 'Zahlungsabsicht konnte nicht erstellt werden',
        confirm_payment_intent: 'Zahlungsabsicht konnte nicht bestätigt werden',
        create_offline_payment: 'Offline-Zahlung konnte nicht erstellt werden',
        get_practitioner_locations: 'Standorte konnten nicht abgerufen werden',
        something_wrong: 'Etwas ist schiefgelaufen',

        // pop-up messages in snack bar
        no_uuid: 'Keine UUID gefunden',
        duplicated_card_device: 'Dupliziertes Gerät zur Karte hinzugefügt',
        duplicated_modal_device: 'Dupliziertes Gerät im Modal hinzugefügt',
        unregistered_email: `Ihre E-Mail-Adresse ist nicht registriert`,
        successful_otp_verification: 'Token erfolgreich verifiziert',

        // yup validation error message
        valid_email_required: 'Muss eine gültige E-Mail-Adresse sein',
        required_field: 'Dieses Feld ist erforderlich',
        required_checked_field: 'Feld muss ausgewählt werden',
        mismatch_email: 'Die eingegebene E-Mail-Adresse stimmt nicht überein',
        european_date: 'Das europäische Datumsformat tt/mm/jjjj wird erwartet',
        required_card_holder_name: 'Bitte geben Sie den Namen des Karteninhabers an"',
        incident_details_required: 'Bitte geben Sie weitere Informationen zum Vorfall an',
        police_report_invoice_required:
            'Polizeibericht und Rechnung sind erforderlich, wenn ein Anspruch auf ein gestohlenes oder verlorenes Gerät geltend gemacht wird',
        minimum_one_file_required: 'Mindestens eine Datei wird benötigt',
        minimum_age: 'Das Alter sollte 18 Jahre oder älter sein',
        fiscale_id: "16 Zeichen erforderlich: LLLLLLNNLNNLNNNL, wobei L für 'Buchstabe' und N für 'Zahl' steht",

        // pop up messages in modal
        successful_payment_label: 'Zahlung erfolgreich',
        successful_payment_description: `Ihre Zahlung war erfolgreich! Wir erstellen nun Ihre Police. Bitte warten Sie, da dies einige Minuten dauern kann...`,
        okay_label: 'OK',

        failed_payment_label: 'Zahlung fehlgeschlagen',
        failed_payment_description: 'Bitte versuchen Sie es erneut, indem Sie die neue Zahlungsmethode hinzufügen.',
        close_try_label: 'Schließen und erneut versuchen',

        cancel_policy_label: 'Sind Sie sicher, dass Sie diese Police kündigen möchten?',
        cancel_policy_description: 'Wenn die Police innerhalb von 14 Tagen nach dem Kauf gekündigt wird, erfolgt eine vollständige Rückerstattung',
        close_label: 'Schließen',
        cancel_policy_button_label: 'Police kündigen',

        download_documents_header_label: 'Dokumente herunterladen',
        download_all_documents_button_label: 'Alle herunterladen',
        download_documents_description: 'Die folgenden Dokumente sind mit der Police verbunden',
        no_data_label: 'Noch keine Daten vorhanden',
        no_data_description: 'Es scheint, dass wir hier keine Daten zum Anzeigen haben.',
        file_name_label: 'Dateiname',
        certificate_of_insurance_label: 'Versicherungszertifikat',
        invoice_label: 'Rechnung',
        download_button_label: 'Herunterladen',

        no_attachment_label: 'Noch keine Anhänge vorhanden',
        no_attachment_description: 'Es scheint, dass Sie keine Anhänge hochgeladen haben',

        success_claim_creation_header_label: 'Ihr Antrag wurde erfolgreich eingereicht',
        success_claim_creation_description: 'Sie können jetzt den Status Ihrer Zahlung einsehen oder einen neuen Antrag stellen',
        view_status_button_label: 'Status anzeigen',
        new_claim_button_label: 'Neuer Antrag',

        claim_approved_header_line_one: 'Herzlichen Glückwunsch!',
        claim_approved_header_line_two: 'Ihr Antrag wurde genehmigt!',

        attachment_upload_inprogress_header: 'Fast geschafft...',
        attachment_upload_inprogress_description: 'Bitte warten Sie, während wir Ihre Anfrage bearbeiten.',

        failed_claim_creation_header: 'Oh nein!',
        failed_claim_creation_description: 'Ihr Antrag wurde nicht eingereicht. Bitte wenden Sie sich an unser Schadens-Team:',
        try_again_button_label: 'Versuchen Sie es erneut',
    },
};
