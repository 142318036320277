import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { activePolicyProps } from 'views/dashboard';
import getDeviceImage from 'utils/getDeviceImage';
import Modal from '@mui/material/Modal';
import { Box, Divider } from '@mui/material';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import CardDetailListing from './CardDetailListing';
import Header from '../Header';
import { cloneItemProps } from '..';
import Content from '../Content';

interface SingleMobileCardProps {
    singleDeviceData: activePolicyProps;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '4px',
};

const keyToShowInShow = [
    { key: 'policy_number_label', value: 'policyNumber' },
    { key: 'brand_label', value: 'productBrand' },
    { key: 'model_label', value: 'insuredItem' },
    { key: 'coverage_period_label', value: 'coveragePeriod' },
    { key: 'serial_number', value: 'serialNumber' },
    { key: 'colour_label', value: '' },
    { key: 'patient_label', value: 'policyHolderName' },
];
const SingleMobileCard: FC<SingleMobileCardProps> = ({ singleDeviceData }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { serialNumber, insuredItem, policyNumber } = singleDeviceData;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let cloneItem: cloneItemProps = {};
    if (keyToShowInShow && keyToShowInShow?.length > 0) {
        keyToShowInShow?.forEach((ktr) => {
            cloneItem = {
                ...cloneItem,
                [ktr?.key || '']: singleDeviceData[ktr?.value || ''],
            };
        });
    }
    return (
        <>
            <Card sx={{ width: { xs: '40%', sm: '45%', md: '25%' }, m: 2 }}>
                <CardMedia component="img" height="80" sx={{ objectFit: 'contain' }} image={getDeviceImage(serialNumber)} alt={insuredItem} />
                <CardContent sx={{ mb: 0, pb: 0 }}>
                    <Typography gutterBottom variant="body1" component="div" sx={{ fontWeight: 500 }}>
                        {insuredItem}
                    </Typography>
                    <CardDetailListing attribute="Serial Number:" value={serialNumber} />
                    <CardDetailListing attribute="Policy Number:" value={policyNumber} />
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={handleOpen}>
                        {formattedMessages.learn_more_button_label}
                    </Button>
                </CardActions>
            </Card>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Header data={singleDeviceData} imageLabel={serialNumber} />
                    <Divider />
                    <Content data={cloneItem} />
                </Box>
            </Modal>
        </>
    );
};

export default SingleMobileCard;
