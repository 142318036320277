/* eslint no-await-in-loop: "error" */
import { useLanguage } from 'context/LanguageContext';
import { PolicyStore } from 'context/Policy';
import messages from 'i18n/messages';
import translate from 'i18n/messages/translate';
import { BusinessPartnerAddress, Policy } from 'interfaces/endpoints';
import { providerProps } from 'interfaces/profile';
import Cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import {
    getAllPoliciesWithExpand,
    getPolicyDocument,
    getTargetedBusinessPartnerAddress,
    getTargetedPolicy,
    getTargetedPolicyHolder,
    getTargetedPolicyVersion,
    getTargetedPolicyVersionItem,
} from 'services/httpService';
import convertISODateFormat from 'utils/convertISODateFormat';
import { activePolicyProps } from 'views/dashboard';
import { environment } from '../../environments/environment';

export const handlePolicyData = async (policy?: Policy, policyId?: string) => {
    // const { formValues } = useClaim();
    /** fetch policies */
    const locale = useLanguage();
    const selfId = policy ? `${policy?.self?.id}/` : policyId || '';
    const fetchedPolicy = policy?.self?.id ? policy : await getTargetedPolicy(policyId);
    const { number, versions } = fetchedPolicy;
    const activePolicyVersion = versions[versions.length - 1];

    /** fetch policies versions */
    const fetchedPolicyVersion = await getTargetedPolicyVersion(`${activePolicyVersion.id}/`);
    const { startDate, endDate, items } = fetchedPolicyVersion;
    const policyVersionItemId = items[0]?.id;

    /** fetch policies versions item */
    const fetchedPolicyVersionItem = policyVersionItemId ? await getTargetedPolicyVersionItem(`${policyVersionItemId}/`) : { name: '-' };
    const { name } = fetchedPolicyVersionItem;

    /** fetch policy holder */
    const fetchedPolicyHolder = await getTargetedPolicyHolder();
    const { fullName, emailPrimary, phoneMobile } = fetchedPolicyHolder;

    /** fetch policy holder address */
    const fetchedBusinessPartnerAddress = await getTargetedBusinessPartnerAddress();
    const { fullAddress } =
        fetchedBusinessPartnerAddress.length > 0 ? fetchedBusinessPartnerAddress[0].details : ({} as BusinessPartnerAddress['details']);

    let policyDocument = {};
    if (selfId) {
        const fetchPolicyDocument = await getPolicyDocument(versions[0].id);
        const { ipid, schedule } = fetchPolicyDocument;
        if (ipid) {
            const policyFileName = ipid?.split('?')[0]?.split('/')?.pop();
            policyDocument = {
                ...policyDocument,
                [policyFileName || 'ipid']: ipid,
            };
        }

        if (schedule) {
            const scheduleFileName = schedule?.split('?')[0]?.split('/')?.pop();
            policyDocument = {
                ...policyDocument,
                [scheduleFileName || 'schedule']: schedule,
            };
        }
    }
    return {
        coveragePeriod: `${convertISODateFormat(startDate, locale)} - ${convertISODateFormat(endDate, locale)}`,
        insuredItem: name,
        policyDocument,
        policyHolderAddress: fullAddress,
        policyHolderEmail: [emailPrimary],
        policyHolderName: fullName,
        policyHolderPhoneNumber: phoneMobile,
        policyNumber: number,
        policyVersion: activePolicyVersion.href,
        productName: translate(environment.REACT_APP_PRODUCT_NAME ?? ''),
        serialNumber: selfId,
        status: policy?.status || 'Inactive',
        policySelfId: policy?.self?.id || undefined,
    };
};

const usePolicy = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { policyState, policyDispatch }: providerProps = useContext(PolicyStore);
    const [policyData, setPolicyData] = useState<Policy[] | null>(null);
    const [activePolicy, setActivePolicy] = useState<activePolicyProps[]>([]);
    const [expiredPolicy, setExpiredPolicy] = useState<activePolicyProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const formatPolicyDocuments = (policyDocuments: Record<string, string | { href: string; rel: string }>) => {
        const extractedDocuments = Object.keys(policyDocuments).filter((item) => item !== 'links');
        let documentsNeeded = {};
        extractedDocuments.forEach((docs) => {
            switch (docs) {
                case 'certificato assicurativo':
                    documentsNeeded = {
                        ...documentsNeeded,
                        [formattedMessages.certificate_of_insurance_label]:
                            process.env.NODE_ENV === 'development' ? `http://localhost:8000${policyDocuments[docs]}` : policyDocuments[docs] || '',
                    };
                    return;
                case 'dip':
                    documentsNeeded = {
                        ...documentsNeeded,
                        [formattedMessages.ipid_label]:
                            process.env.NODE_ENV === 'development' ? `http://localhost:8000${policyDocuments[docs]}` : policyDocuments[docs] || '',
                    };
                    return;
                case 'dip aggiuntivo':
                    documentsNeeded = {
                        ...documentsNeeded,
                        [formattedMessages.additional_ipid_label]:
                            process.env.NODE_ENV === 'development' ? `http://localhost:8000${policyDocuments[docs]}` : policyDocuments[docs] || '',
                    };
                    return;
                case 'condizione di assicurazione':
                    documentsNeeded = {
                        ...documentsNeeded,
                        [formattedMessages.terms_conditions_label]:
                            process.env.NODE_ENV === 'development' ? `http://localhost:8000${policyDocuments[docs]}` : policyDocuments[docs] || '',
                    };
                    return;
                default:
                    documentsNeeded = {
                        ...documentsNeeded,
                        [formattedMessages.invoice_label]:
                            process.env.NODE_ENV === 'development' ? `http://localhost:8000${policyDocuments[docs]}` : policyDocuments[docs] || '',
                    };
            }
        });
        return documentsNeeded;
    };

    useEffect(() => {
        if (policyData) {
            policyDispatch({
                type: 'handle_policy',
                value: { activePolicy, expiredPolicy, policyData },
            });
        }
    }, [activePolicy, expiredPolicy, policyData]);

    useEffect(() => {
        const handleFetch = async () => {
            setLoading(true);
            const policies: any[] = await getAllPoliciesWithExpand()
                .then((data) => data.filter((policy) => policy.versions.some((version: any) => version.state === 'Published')))
                .catch((error) => {
                    setLoading(false);
                    throw error;
                });
            if (policies && policies?.length > 0) {
                setPolicyData(policies);
                let listOfActivePolicy: activePolicyProps[] = [];
                let listOfExpiredPolicy: activePolicyProps[] = [];

                await Promise.all(
                    policies?.map(async (policyModel: any) => {
                        const policyVersions = policyModel?.versions?.find((v: any) => v.state === 'Published');
                        const policyHolder = policyModel?.holder;
                        const policyDocument = await getPolicyDocument(policyVersions.self.id);
                        const policyVersionsItems = policyVersions?.items?.[0];
                        const policyVersionsItemsAttributes = policyVersionsItems?.attributes;
                        const policyObject = {
                            coveragePeriod:
                                `${convertISODateFormat(policyVersions?.startDate, locale)} - ${convertISODateFormat(
                                    policyVersions?.endDate,
                                    locale
                                )}` || '',
                            insuredItem: (policyVersionsItems?.name as string) || '',
                            policyDocument: formatPolicyDocuments(policyDocument),
                            policyHolderAddress: policyHolder?.policyHolder || '',
                            policyHolderEmail: policyHolder?.policyHolder || '',
                            policyHolderName: policyHolder?.fullName || '',
                            policyHolderPhoneNumber: policyHolder?.phoneMobile || '',
                            policyNumber: policyModel?.number || '',
                            // this is the policy uuid NOT the policy version uuid
                            policyVersion: policyModel?.self?.href || '',
                            productName: formattedMessages[environment.REACT_APP_PRODUCT_NAME as keyof typeof formattedMessages],

                            serialNumber: policyVersionsItemsAttributes?.[0]?.value || '',
                            status: policyModel?.status || 'Inactive',
                            policySelfId: policyModel?.self?.id || undefined,
                            productModel: policyVersionsItemsAttributes?.[1]?.value || '',
                            productBrand: 'Cochlear',

                            // need the policy version hyperlink for claim creation
                            policyVersionHyperlink: policyVersions.self.href,
                        };
                        if (policyModel.status === 'Inactive' && policyVersions?.state === 'Published') {
                            listOfExpiredPolicy = [...listOfExpiredPolicy, policyObject];
                        }
                        if (policyModel.status === 'Active' && policyVersions?.state === 'Published') {
                            listOfActivePolicy = [...listOfActivePolicy, policyObject];
                        }
                    })
                );

                setExpiredPolicy(listOfExpiredPolicy);
                setActivePolicy(listOfActivePolicy);
            }

            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };
        if (!policyState.policyData && Cookies.get('token')) {
            handleFetch();
        }
    }, [policyState.policyData]);

    return { loading, activePolicy: policyState.activePolicy, expiredPolicy: policyState.expiredPolicy, policyData: policyState.policyData };
};

export default usePolicy;
