import React from 'react';
import Typography from '@mui/material/Typography';
import { activePolicyProps } from 'views/dashboard';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface DetailsProps {
    item: activePolicyProps;
}

interface modelProps {
    [key: string]: string | number;
}

const Details = ({ item }: DetailsProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const model: modelProps = {
        policy_number_label: item?.policyNumber,
        coverage_period_label: item.coveragePeriod,
        insured_item_label: item.insuredItem,
        // 'Serial Number': item.serialNumber,
        client_name_label: item.policyHolderName,
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
            {Object.keys(model).map((key: string) => (
                <div key={key} style={{ display: 'flex', marginBottom: 12, alignItems: 'center' }}>
                    <Typography component="span" variant="body2" style={{ color: 'rgba(0, 0, 0, 0.6)', width: '55%' }}>
                        {formattedMessages[key as keyof typeof formattedMessages]}:
                    </Typography>
                    <Typography
                        component="span"
                        style={{ width: '100%', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                        variant="body2"
                    >
                        {model[key] || '-'}
                    </Typography>
                </div>
            ))}
        </div>
    );
};

export default Details;
