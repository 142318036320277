import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ActionShowToastProps, ExtraConfig } from '.';

type ActionComponentProps = ActionShowToastProps & ExtraConfig;

const ActionComponent = ({ closeSnackbar, actionButton, snackbarKey, undoEvent, closeEvent, enableCloseAction }: ActionComponentProps) => (
    <Box display="inline-flex">
        {actionButton && (
            <Button
                sx={{ fontSize: '14px' }}
                size="small"
                onClick={(event) => {
                    actionButton.onClick(event);
                    event.stopPropagation();
                    closeSnackbar(snackbarKey);
                }}
            >
                {actionButton.label}
            </Button>
        )}
        {undoEvent && (
            <Button
                sx={{ fontSize: '14px' }}
                size="small"
                onClick={() => {
                    undoEvent();
                    closeSnackbar(snackbarKey);
                }}
            >
                Undo
            </Button>
        )}
        {(enableCloseAction || closeEvent) && (
            <IconButton
                sx={{ color: 'white' }}
                onClick={() => {
                    if (closeEvent) closeEvent();
                    closeSnackbar(snackbarKey);
                }}
            >
                <CloseIcon />
            </IconButton>
        )}
    </Box>
);

ActionComponent.defaultProps = {
    undoEvent: undefined,
    actionButton: undefined,
    closeEvent: undefined,
    enableCloseAction: undefined,
};

export default ActionComponent;
