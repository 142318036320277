import { Box, Button, Link, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import WestIcon from '@mui/icons-material/West';

interface BaseProps {
    img?: string;
    title?: string;
    subtitle?: string | JSX.Element;
    callToAction?: 'reload' | 'redirect';
    redirectUrl?: string;
    redirectLabel?: string;
}

const ErrorFallback: React.FunctionComponent<BaseProps> = ({ img, title, subtitle, callToAction, redirectUrl, redirectLabel }: BaseProps) => (
    <Box id="error-fallback" minHeight={400} display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
        <Box mb={4} component="img" src={img} />
        <Typography variant="h6">{title}</Typography>
        <Box component="p" mb={4} mt={1}>
            {subtitle && (
                <Typography variant="body1" color="GrayText">
                    {subtitle}
                </Typography>
            )}
        </Box>
        {callToAction === 'reload' && (
            <Button variant="text" startIcon={<RefreshIcon />} onClick={() => window.location.reload()}>
                Reload page
            </Button>
        )}
        {callToAction === 'redirect' && (
            <Button
                variant="text"
                startIcon={callToAction === 'redirect' ? <WestIcon /> : <RefreshIcon />}
                onClick={() => window.location.replace(redirectUrl || window.location.href)}
            >
                {redirectLabel || 'Go back to previous page'}
            </Button>
        )}
    </Box>
);

ErrorFallback.defaultProps = {
    img: 'assets/Uncaught-Error.svg',
    title: 'Something went wrong',
    subtitle: (
        <>
            We&apos;re experiencing some technical difficulties loading this page.
            <br />
            Please try again later or contact our support team at <br />{' '}
            <Link href="mailto:clientsupport@tigerlab.com">clientsupport@tigerlab.com</Link> if the issue persist.
        </>
    ),
    callToAction: 'reload',
    redirectUrl: '',
    redirectLabel: '',
};

export default ErrorFallback;
