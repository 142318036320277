import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { listInputProps } from 'interfaces/profile';
import { useField, useFormikContext } from 'formik';
import { format } from 'date-fns';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface formValueProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface DateInputProps {
    item?: listInputProps;
    globalInputDisable?: boolean;
    formValue?: formValueProps;
    disableFuture?: boolean;
}

const DateInput = ({ item, formValue, globalInputDisable, disableFuture }: DateInputProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { setFieldValue } = useFormikContext();
    const { touched, error, value } = useField(item?.useState ? item.useState : '')[1];
    const field = useField(item?.useState ? item.useState : '')[0];
    const [localDateValue, setLocalDateValue] = useState<string>(value);

    const conditionKey = item?.condition?.key;
    const conditionLabel = item?.condition?.label;
    const mainDateCondition = formValue && conditionKey;
    const disableMinDate = mainDateCondition && conditionLabel === 'minDate' ? formValue[conditionKey] : undefined;
    const disableMaxDate = mainDateCondition && conditionLabel === 'maxDate' ? formValue[conditionKey] : undefined;

    const disabledCondition = !(formValue && item?.disabled && formValue[item?.disabled]);
    const disabledDate = item?.disabled ? disabledCondition : false;
    const globalDisableCheck = typeof globalInputDisable === 'boolean' ? globalInputDisable : disabledDate;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const datePickerOnAccept = (newValue: any) => {
        if (item?.useState) {
            if (newValue !== null) {
                setFieldValue(item.useState, format(newValue, 'dd/MM/yyyy'));
            } else {
                setFieldValue(item.useState, '');
            }
        }
    };
    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', margin: '8px 0px 8px 8px', ...item?.inputContainerStyle }}>
            <DatePicker
                disabled={globalDisableCheck}
                minDate={disableMinDate}
                maxDate={disableMaxDate}
                label={formattedMessages[(item?.placeholder ?? 'dob_label') as keyof typeof formattedMessages]}
                disableFuture={disableFuture}
                // the value returned from Formik state is in dd/MM/yyyy, we need to modify it
                // slightly before we can pass in as value into <DatePicker />
                value={localDateValue}
                onChange={(newValue) => {
                    setLocalDateValue(newValue);
                }}
                onAccept={datePickerOnAccept}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                    <TextField
                        {...field}
                        {...params}
                        style={{ width: '100%', ...item?.style }}
                        helperText={touched && !!error && formattedMessages[error as keyof typeof formattedMessages]}
                        error={touched && !!error}
                    />
                )}
            />
        </div>
    );
};

DateInput.defaultProps = {
    item: undefined,
    globalInputDisable: undefined,
    formValue: undefined,
    disableFuture: false,
};

export default DateInput;
