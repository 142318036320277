import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { activePolicyProps } from 'views/dashboard';
import { SyntheticEvent, useState } from 'react';
import CustomCard from 'views/dashboard/customCard';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface TabPanelProps {
    children: React.ReactNode;
    index: number;
    value: number;
}

interface policyTypeProps {
    id: number;
    label: string;
}

const policyType: policyTypeProps[] = [
    { id: 0, label: 'active_tab_label' },
    { id: 1, label: 'inactive_tab_label' },
];

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface ContentProps {
    activePolicy: activePolicyProps[] | null;
    expiredPolicy: activePolicyProps[] | null;
    handleActiveClaim?: (item: string) => () => void;
}

const Content = ({ activePolicy, expiredPolicy, handleActiveClaim }: ContentProps) => {
    const [value, setValue] = useState(0);
    const locale = useLanguage();
    const formattedMessages = messages[locale];

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', mt: { xs: 2, md: 5 } }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {policyType?.map((item: policyTypeProps) => (
                        <Tab
                            key={item.id}
                            sx={{ minWidth: 144 }}
                            label={formattedMessages[item.label as keyof typeof formattedMessages]}
                            {...a11yProps(item.id)}
                        />
                    ))}
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <CustomCard data={activePolicy} handleClaim={handleActiveClaim} withActionButton />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CustomCard data={expiredPolicy} />
            </TabPanel>
        </Box>
    );
};

Content.defaultProps = {
    handleActiveClaim: undefined,
};

export default Content;
