import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import React, { useState } from 'react';
import { useClaim, useClaimUpdate } from 'context/ClaimContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import ClaimInformation from './ClaimInformation';
import AttachmentsPreview from './attachmentPreview';

const ClaimDetails = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const claimValues = useClaim();
    const { files } = claimValues;
    const claimUpdate = useClaimUpdate();
    const [isAttachmentEditable, setIsAttachmentEditable] = useState<boolean>(false);
    const [attachmentsSelected, setAttachmentsSelected] = useState<string[]>([]);
    const navigate = useNavigate();
    const { policyId } = useParams();

    const handleAttachmentsEdit = () => {
        setIsAttachmentEditable((prev) => !prev);
    };

    const handleAttachmentSelected = (urlArr: string[]) => {
        setAttachmentsSelected(urlArr);
    };

    const handleAttachmentDelete = () => {
        const remainingFiles = files.filter((item) => !attachmentsSelected.includes(item.file.name as string));
        const newFormValues = { ...claimValues, files: remainingFiles };
        claimUpdate(newFormValues);
    };

    return (
        <Card sx={{ p: '20px' }} elevation={0}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                            {formattedMessages.claim_info_header_label}
                        </Typography>
                        <Button startIcon={<Edit />} onClick={() => navigate(`/claim-creation/${policyId}`)}>
                            {formattedMessages.edit_button_label}
                        </Button>
                    </Box>
                    <Divider sx={{ marginBottom: '1rem' }} />
                </Grid>

                <ClaimInformation />
                {/* <PolicyDetailListing title="Claim amount: " value={claimedAmount} /> */}
                {/* <PolicyDetailListing title="Incident Description: " value={incidentDescription} /> */}
                <Grid item xs={12} lg={12}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" gutterBottom sx={{ color: 'primary.main' }}>
                            {formattedMessages.attachments_header_label}
                        </Typography>
                        {isAttachmentEditable ? (
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Button onClick={() => setIsAttachmentEditable(false)}>{formattedMessages.cancel}</Button>
                                <Button
                                    disabled={attachmentsSelected.length === 0 || files.length === 0}
                                    color="error"
                                    startIcon={<Delete />}
                                    onClick={handleAttachmentDelete}
                                >
                                    {formattedMessages.delete_button_label}
                                </Button>
                            </Box>
                        ) : (
                            <Button startIcon={<Edit />} onClick={handleAttachmentsEdit} disabled={files.length <= 1}>
                                {formattedMessages.edit_button_label}
                            </Button>
                        )}
                    </Box>
                    <Divider sx={{ marginBottom: '1rem' }} />
                    <AttachmentsPreview isEditable={isAttachmentEditable} handleAttachmentSelected={handleAttachmentSelected} />
                </Grid>
            </Grid>
        </Card>
    );
};

export default ClaimDetails;
