import { Box, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { claimCreationFormvalidationSchema, claimsCreationInputList } from 'constant/claims';
import CustomInput from 'components/customInput';
import { claimCreationFormInitValues, ClaimFormValues } from 'interfaces/claim';
import { useNavigate, useParams } from 'react-router-dom';
import { useClaim, useClaimUpdate } from 'context/ClaimContext';
// import DeviceLocationSelect from 'views/enrollment/purchaseLocation/DeviceLocationSelect';
// import { LocationSelect } from 'interfaces/enrollment';
// import { getDevicePurchaseLocation, getDevicePurchasePractitioner } from 'services/httpService';
// import { extractChoicesInfo } from 'utils/enrollment';
import FormHeader from 'components/FormHeader';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import FilesUploadField from '../filesUpload';
import ReviewButton from './ReviewButton';
import ClaimRadioButtonField from '../ClaimRadioButtonField';

const ClaimForm = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const navigate = useNavigate();
    const claimFormValues = useClaim();
    const { dateOfIncident, incidentDescription, files, practitioner, location } = claimFormValues;
    const updateClaimDetails = useClaimUpdate();
    const { policyId } = useParams();
    // const [practitionerStoresArr, setPractitionerStoresArr] = useState<LocationSelect[]>([]);
    // const [practitionerStoreSelected, setPractitionerStoreSelected] = useState<string>(practitioner);
    // const [locationsArr, setLocationsArr] = useState<LocationSelect[]>([]);
    // const [locationsSelected, setLocationsSelected] = useState<string>(location);

    // const updateLocationsArr = (practitionerSelected: string) => {
    //     getDevicePurchaseLocation(practitionerSelected).then((res) => {
    //         const modifiedLocationsArr = extractChoicesInfo(res);
    //         setLocationsArr(modifiedLocationsArr);
    //     });
    // };
    // useEffect(() => {
    //     // get the complete list of practitioner stores when page rendered

    //     getDevicePurchasePractitioner().then((res) => {
    //         const modifiedPractitionersArr: LocationSelect[] = extractChoicesInfo(res);
    //         setPractitionerStoresArr(modifiedPractitionersArr);
    //         if (practitionerStoreSelected) {
    //             const practitionerSlug = practitionerStoreSelected.split(':')[0];
    //             updateLocationsArr(practitionerSlug);
    //             if (location) setLocationsSelected(location);
    //         }
    //     });
    // }, []);

    // const handlePractitionerStoreChange = (practitionerSelected: string) => {
    //     const [slug] = practitionerSelected.split(':');
    //     setPractitionerStoreSelected(practitionerSelected);
    //     updateLocationsArr(slug);
    // };

    // const handleLocationChange = (locationInput: string) => {
    //     setLocationsSelected(locationInput);
    // };

    const initialValues = useMemo(() => {
        if (dateOfIncident !== '' || incidentDescription !== '' || files.length !== 0 || practitioner !== '' || location !== '') {
            return { ...claimCreationFormInitValues, dateOfIncident, incidentDescription, files, practitioner, location };
        }
        return claimCreationFormInitValues;
    }, [dateOfIncident, incidentDescription, files, practitioner, location]);

    const onSubmit = (values: ClaimFormValues) => {
        updateClaimDetails(values);
        navigate(`/claim-review/${policyId}`);
    };
    return (
        <Box sx={{ width: { xs: '100%', md: '540px' }, margin: { xs: '0 0.5rem', md: '0 auto' }, maxWidth: '100%' }}>
            <Formik initialValues={initialValues} enableReinitialize validationSchema={claimCreationFormvalidationSchema} onSubmit={onSubmit}>
                {({ values, handleChange, handleBlur, isValid }) => (
                    <Form id="claim-creation-form">
                        <Grid container spacing={2} direction="column">
                            <ClaimRadioButtonField />
                            {/* <FormControl fullWidth sx={{ mx: { xs: 1, md: 0 } }}> */}
                            <FormHeader
                                title={formattedMessages.incident_occurence_date_question_label}
                                withDivider
                                titleVariant="h6"
                                rootStyle={{ margin: { xs: '20px 0 0 0', md: '0 0 5px 0' }, width: '100%' }}
                            />

                            <CustomInput
                                rootStyle={{ width: '100%', marginTop: { xs: '10px', md: '15px' } }}
                                boxStyle={{}}
                                boxFlexDirection="column"
                                formValue={values}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                listInput={claimsCreationInputList}
                            />
                            {/* </FormControl> */}
                            <FilesUploadField />
                            {/* <Box sx={{ maxWidth: '100%' }}>
                                    <FormHeader
                                        title={formattedMessages.repair_center_question_label}
                                        withDivider
                                        titleVariant="h6"
                                        rootStyle={{ margin: { xs: '20px 0 0 0', md: '20px 0 5px 0' }, width: '100%' }}
                                    />

                                    <Box display="flex" sx={{ mb: 3, flexDirection: { xs: 'column', md: 'row' }, mt: { xs: 1, md: 2 } }}>
                                        <DeviceLocationSelect
                                            itemsArr={practitionerStoresArr}
                                            name="practitioner"
                                            valueSelected={practitionerStoreSelected}
                                            selectLabel={formattedMessages.claim_repair_center_label}
                                            handleChange={handlePractitionerStoreChange}
                                            rootStyle={{ mr: 0.2, ml: 0, mt: 0 }}
                                        />
                                        <DeviceLocationSelect
                                            itemsArr={locationsArr}
                                            name="location"
                                            selectLabel={formattedMessages.location_label}
                                            valueSelected={locationsSelected}
                                            handleChange={handleLocationChange}
                                            // disable when practitioner store not selected
                                            isDisabled={practitionerStoreSelected === ''}
                                            rootStyle={{ mr: 0.2, ml: { xs: 0, md: 0.5 }, mt: { xs: 1, md: 0 } }}
                                        />
                                    </Box>
                                </Box> */}
                            <ReviewButton buttonTitle="next_button_label" isValid={isValid} />
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default ClaimForm;
