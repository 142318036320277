import * as Yup from 'yup';

export const profileValidate = {
    firstName: Yup.string().min(1, 'Too short!').required('Required to fill'),
    lastName: Yup.string().min(1, 'Too short!').required('Required to fill'),
    idNo: Yup.string().min(1, 'Too short!').required('Select one of the following options'),
    dateOfBirth: Yup.string().required('Select one of the following options'),
    emailAddress: Yup.string().min(1, 'Too short!').required('Select one of the following options'),
};

const profileValidator = Yup.object().shape({ ...profileValidate });

export default profileValidator;
