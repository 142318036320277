import { Box, Button, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import React, { useContext } from 'react';
import { AuthenticationStore } from 'context/Authentication';
import { providerProps } from 'interfaces/profile';
import { useLocation } from 'react-router-dom';
import translate from 'i18n/messages/translate';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import DynamicOwnerNaming from './DynamicOwnerNaming';
import StaticOwnerNaming from './StaticOwnerNaming';

interface DeviceListingProps {
    deviceName: string;
    imageUrl: string;
    serialNumber: string;
    handleRemoveDevice: (deviceSerialNumber: string) => void;
    minimumOneDevice?: boolean;
    isDeviceRemovable?: boolean;
}

const DeviceListing = ({ deviceName, imageUrl, serialNumber, handleRemoveDevice, minimumOneDevice, isDeviceRemovable }: DeviceListingProps) => {
    const { pathname } = useLocation();
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { firstName, lastName } = authenticationState;
    return (
        <Box display="flex" sx={{ background: '#FAFAFA', p: 2, mx: { xs: 0, md: 2 }, my: 0.5, pb: 1, alignItems: 'flex-start' }}>
            <Box component="img" src={imageUrl} sx={{ width: '65px', height: '70px', objectFit: 'contain', pt: 2, pr: 1 }} />
            <Box display="flex" sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="subtitle1" sx={{ pl: 1, fontWeight: 500 }}>
                    {deviceName}
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ pl: 1, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, color: 'rgba(0, 0, 0, 0.6)', mb: { xs: 0.5, md: 0 } }}
                >
                    <span style={{ minWidth: 110 }}>{`${formattedMessages.serial_number}: `}</span> {serialNumber}
                </Typography>
                {firstName && lastName && pathname === '/enrollment' ? <DynamicOwnerNaming serialNumber={serialNumber} /> : null}
                {firstName && lastName && pathname !== '/enrollment' ? <StaticOwnerNaming serialNumber={serialNumber} /> : null}
                {isDeviceRemovable && (
                    <Button
                        variant="text"
                        startIcon={<RemoveCircleOutlineIcon />}
                        color="secondary"
                        onClick={() => handleRemoveDevice(serialNumber)}
                        disabled={minimumOneDevice}
                    >
                        {translate('remove')}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default DeviceListing;

DeviceListing.defaultProps = {
    minimumOneDevice: false,
    isDeviceRemovable: true,
};
