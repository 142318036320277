import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './app/v2';
import { environment } from './environments/environment';

Sentry.init({
    enabled: environment.production,
    dsn: environment.sentryDSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
