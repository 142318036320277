import { DeviceInfo } from 'interfaces/enrollment';
import { listOfQuoteProps } from 'interfaces/quote';

const extractDeviceDetails = (input: listOfQuoteProps) => {
    if (input) {
        const inputKeysArr = Object.keys(input);
        if (inputKeysArr.length > 0) {
            const deviceKeys = inputKeysArr.filter((key) => key !== 'totalAmounts' && key !== 'step');
            const devicesArr = deviceKeys.map((serialNumber) => ({
                [serialNumber]: input[serialNumber],
            }));
            return devicesArr as unknown as DeviceInfo[];
        }
    }
    return [] as DeviceInfo[];
};

export default extractDeviceDetails;
