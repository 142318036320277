/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

function getCSRFToken() {
    return Cookies.get('csrftoken');
}

function isAbsoluteUrl(url: string) {
    return /(http(s?)):\/\//i.test(url);
}

const CORE_AUTH_KEYWORD = 'i2go';

export enum TokenType {
    /** CSRF token retrieved from browser cookie set by Django in the response headers. Mostly used in BO */
    CSRF = 'csrfToken',

    /** rest_framework TokenAuthentication token (e.g. from /tiger-site-admin/authtoken/tokenproxy/). Mostly used in CSP  */
    CORE = 'coreToken',

    /** JWT token, retrieved via POST to api/v2/users/auth/. Mostly used in CSP */
    JWT = 'jwtToken',
}

interface Tokens {
    [TokenType.CSRF]?: string;
    [TokenType.CORE]?: string;
    [TokenType.JWT]?: string;
}

/** Mode for the HTTP client */
type HttpClientMode = 'bo' | 'csp';

class HttpClientService {
    // e.g. http://localhost:8000/,
    private baseOrigin: string | undefined;

    private tokens: Tokens;

    private authMode: TokenType;

    private mode: HttpClientMode;

    private queryClient: QueryClient | undefined;

    constructor(baseOrigin?: string) {
        this.baseOrigin = baseOrigin ?? window.location.origin;
        this.mode = 'bo';
        this.authMode = TokenType.CSRF;
        this.tokens = {
            [TokenType.CSRF]: getCSRFToken(),
        };
    }

    public setToken(tokenType: TokenType, token: string) {
        if (tokenType === TokenType.JWT) {
            this.tokens[TokenType.JWT] = `JWT ${token}`;
        } else if (tokenType === TokenType.CORE) {
            this.tokens[TokenType.CORE] = `${CORE_AUTH_KEYWORD} ${token}`;
        } else {
            this.tokens[tokenType] = token;
        }
    }

    public setBaseOrigin(baseOrigin: string) {
        this.baseOrigin = baseOrigin;
    }

    public setMode(mode: HttpClientMode) {
        this.mode = mode;
    }

    public setAuthMode(authMode: TokenType) {
        this.authMode = authMode;
    }

    public setQueryClient(queryClient: QueryClient) {
        this.queryClient = queryClient;
    }

    public getQueryClient() {
        return this.queryClient;
    }

    private getDefaultOptions(url: string, authMode: TokenType): AxiosRequestConfig {
        const absoluteUrl = isAbsoluteUrl(url) ? url : new URL(url, this.baseOrigin).toString();
        const { pathname } = new URL(absoluteUrl);
        const baseURL = this.baseOrigin;
        const headerField = authMode === TokenType.CSRF ? 'X-CSRFToken' : 'Authorization';
        let headerValue = this.tokens[authMode] ?? '';

        // sanity check; don't allow JWT for API v3
        if (this.mode === 'csp' && authMode === TokenType.JWT && pathname.startsWith('/api/v3/')) {
            // eslint-disable-next-line no-console
            console.warn(`httpClient: JWT unsupported for ${pathname}. Using core token instead.`);
            headerValue = this.tokens[TokenType.CORE] ?? '';
        }
        return {
            ...(isAbsoluteUrl(url) ? {} : { baseURL }),
            headers: {
                [headerField]: headerValue,
            },
        };
    }

    private createClient(url: string, cb: (client: AxiosInstance) => any) {
        if (!this.baseOrigin) throw new Error('Base origin is not set');

        const options = this.getDefaultOptions(url, this.authMode);

        return cb(axios.create(options));
    }

    public async withAuthMode<T>(tokenType: TokenType, cb: (client: AxiosInstance) => Promise<T>): Promise<T> {
        if (!this.baseOrigin) throw new Error('Base origin is not set');

        const options = {
            ...this.getDefaultOptions(this.baseOrigin, tokenType),
            baseURL: this.baseOrigin,
        };

        return cb(axios.create(options));
    }

    public async get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.createClient(url, (client) => client.get(url, config));
    }

    public async post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.createClient(url, (client) => client.post(url, data, config));
    }

    public async patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.createClient(url, (client) => client.patch(url, data, config));
    }

    public async delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.createClient(url, (client) => client.delete(url, config));
    }

    public async put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
        return this.createClient(url, (client) => client.put(url, data, config));
    }
}

export const httpClient = Object.seal(new HttpClientService());

export async function withAuthMode<T>(tokenType: TokenType, fn: () => Promise<T>) {
    return httpClient.withAuthMode(tokenType, fn);
}
