import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const ErrorTypography = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: '#D32F2F',
    padding: '5px',
});

export default ErrorTypography;
