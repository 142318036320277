import { Backdrop, Box, Button } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import FormTextField from 'components/FormTextField';
import ConfirmationAlert from 'components/customAlert/ConfirmationAlert';
import PageLoader from 'components/loader/PageLoader';
import { AuthenticationStore } from 'context/Authentication';
import { useLanguage } from 'context/LanguageContext';
import { PolicyStore } from 'context/Policy';
import { format } from 'date-fns/esm';
import { Form, Formik } from 'formik';
import messages from 'i18n/messages';
import { CreateBusinessPartnerAddress } from 'interfaces/enrollment';
import { CreateOfflinePayment, CreatePaymentIntent, PaymentFormValues } from 'interfaces/payment';
import { providerProps } from 'interfaces/profile';
import { useContext, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { createAddress, createOfflinePayment, createPaymentIntent } from 'services/httpService';
import storageHandler, { productStorage } from 'utils/storageHandler';
import * as Yup from 'yup';
import { StripeError } from '@stripe/stripe-js';
import { I18nMessageKey } from 'utils/payment';
import DisclaimerComponent from '../disclaimer/DisclaimerComponent';
import AddressCheckbox from './AddressCheckbox';
import BillingAddressDetails from './BillingAddressDetails';
import StripeComponentWrapper from './StripeComponentWrapper';

const StripePaymentForm = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { policyDispatch }: providerProps = useContext(PolicyStore);
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    const options = {
        style: {
            base: {
                fontSize: '16px',
                backgroundColor: '#fff',
                color: '#424770',
                letterSpacing: '0.00938em',
                fontFamily: 'Roboto,Helvetica,Arial,sans-serif',

                '::placeholder': {
                    color: '#757575',
                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                    fontSize: '16px',
                },
            },
            invalid: {
                color: '#e53935',
            },
        },
    };
    // stripe payment
    const stripe = useStripe();
    const elements = useElements();

    // general state/ context
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { firstName, lastName, address, zipCode, city, state, country, incompleteOrderHrefArr, businessPartnerHref } = authenticationState;
    const { submissionEmailAddress } = productStorage();

    // react-router-dom
    const navigate = useNavigate();

    // states
    const [isCardNumberFocused, setIsCardNumberFocused] = useState<boolean>(false);
    const [isExpiryDateFocused, setIsExpiryDateFocused] = useState<boolean>(false);
    const [isCVCFocused, setIsCVCFocused] = useState<boolean>(false);
    const [isCardNumberEmpty, setIsCardNumberEmpty] = useState<boolean>(true);
    const [isExpiryDateEmpty, setIsExpiryDateEmpty] = useState<boolean>(true);
    const [isCVCEmpty, setIsCVCEmpty] = useState<boolean>(true);
    const [cardNumberError, setCardNumberError] = useState<string>('');
    const [expiryDateError, setExpiryDateError] = useState<string>('');
    const [cVVError, setCVVError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<boolean>(false);
    const [popperErrorMessage, setPopperErrorMessage] = useState<string>('');

    const noAddress = useMemo(() => address === '-' && zipCode === '-' && country === '-' && state === '-', [address, zipCode, country, state]);

    const stripeComponentErrors = useMemo(() => {
        if (isCardNumberEmpty || isExpiryDateEmpty || isCVCEmpty || cardNumberError || cVVError || expiryDateError) {
            return true;
        }
        return false;
    }, [isCardNumberEmpty, isExpiryDateEmpty, isCVCEmpty, cardNumberError, cVVError, expiryDateError]);

    const cardPaymentInitialValues: PaymentFormValues = {
        isSameAddress: true,
        cardHolderName: '',
        firstName: firstName || '',
        lastName: lastName || '',
        address: '',
        zipCode: '',
        city: '',
        state: '',
        country: '',
        checkoutDisclaimer: false,
    };

    const paymentSchema = Yup.object().shape({
        isSameAdress: Yup.boolean(),
        cardHolderName: Yup.string().required('required_card_holder_name'),
        firstName: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        lastName: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        address: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        zipCode: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        city: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        state: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        country: Yup.string().when('isSameAddress', {
            is: false,
            then: Yup.string().required('required_field'),
        }),
        checkoutDisclaimer: Yup.boolean().oneOf([true], 'required_checked_field'),
    });

    const handleSubmit = async (values: PaymentFormValues) => {
        const handleError = (error: StripeError | Error) => {
            if (error.message && Object.keys(formattedMessages).includes(error.message)) {
                setPopperErrorMessage(formattedMessages[error.message as I18nMessageKey]);
            } else if (error.message) {
                setPopperErrorMessage(error.message);
            } else {
                setPopperErrorMessage(formattedMessages.something_wrong);
            }
            setIsSubmitting(false);
        };

        const handleDone = () => {
            setIsPaymentSuccessful(true);
            setIsSubmitting(false);
        };

        try {
            setIsSubmitting(true);
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                setIsSubmitting(false);
                return;
            }
            // create billing address when user unchecked the checkbox
            if (!values.isSameAddress) {
                const billingAddressPayload: CreateBusinessPartnerAddress = {
                    type: 'ADDRR',
                    isDefault: false,
                    details: {
                        line1: values.address,
                        state: values.state,
                        country: values.country,
                        postalCode: values.zipCode,
                        city: values.city,
                    },
                    businessPartner: businessPartnerHref ?? '',
                };
                createAddress(billingAddressPayload).catch((e) => {
                    setPopperErrorMessage(formattedMessages[e.message as I18nMessageKey]);
                });
            }

            // create stripe payment method
            const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
                type: 'card',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                card: elements.getElement(CardNumberElement) as any,
                billing_details: {
                    name: values.cardHolderName,
                },
            });
            if (paymentMethodError) {
                setIsSubmitting(false);
                return;
            }
            // when in localhost, execute offline payments
            if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && incompleteOrderHrefArr) {
                await Promise.all(
                    incompleteOrderHrefArr.map(async (incompleteOrder) => {
                        const offlinePaymentPayload: CreateOfflinePayment = {
                            incompleteOrder,
                            startDate: format(new Date(), 'yyyy-MM-dd'),
                            paymentOption: 'Others',
                            sendEmail: true,
                        };
                        createOfflinePayment(offlinePaymentPayload).then(handleDone).catch(handleError);
                    })
                );
            } else if (
                (!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') &&
                !window.location.origin.includes('localhost') &&
                paymentMethod &&
                !paymentMethodError
            ) {
                // create payment intent & confirm payment
                const { id: paymentMethodId } = paymentMethod;
                const paymentIntentPayload: CreatePaymentIntent = {
                    paymentMethod: paymentMethodId,
                    incompleteOrders: incompleteOrderHrefArr ?? [],
                };
                createPaymentIntent(paymentIntentPayload)
                    .then((createdIntent) => {
                        const paymentIntentId = createdIntent.clientSecret;
                        stripe.confirmCardPayment(paymentIntentId).then((result) => {
                            if (result.paymentIntent) {
                                handleDone();
                            }
                            if (result.error) {
                                handleError(result.error);
                            }
                        });
                    })
                    .catch(handleError);
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            handleError(error);
        }
    };

    const handleSuccessfulPaymentClose = () => {
        setIsPaymentSuccessful(false);
        navigate('/login');
        // need user needs to login to again to view policy
        policyDispatch({ type: 'handle_policy', value: { policyData: undefined } });
        if (submissionEmailAddress) {
            storageHandler('listSerialNumber', undefined);
            storageHandler('listQuote', undefined);
            storageHandler('quotedItems', []);
            storageHandler('emailAdress', submissionEmailAddress);
        } else {
            // existing use can directly redirect to dashboard
            storageHandler('listSerialNumber', undefined);
            navigate('/dashboard', { replace: true });
        }
    };

    const handleErrorAlert = () => {
        setPopperErrorMessage('');
    };
    return (
        <>
            <Formik initialValues={cardPaymentInitialValues} enableReinitialize validationSchema={paymentSchema} onSubmit={handleSubmit}>
                {(formikProps) => {
                    const { values, isValid, dirty } = formikProps;
                    return (
                        <Form>
                            <Box sx={{ flexDirection: 'column', pb: '40%', minWidth: '20vw' }} justifyContent="center" display="flex">
                                <Box sx={{ mt: 3 }} display="flex">
                                    <Box sx={{ mx: '3px' }} component="img" src="assets/visa.png" />
                                    <Box sx={{ mx: '3px' }} component="img" src="assets/master.png" />
                                    <Box sx={{ mx: '3px' }} component="img" src="assets/discover.png" />
                                    <Box sx={{ mx: '3px' }} component="img" src="assets/amex.png" />
                                </Box>
                                <Box display="flex" alignItems="flex-start" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                                    <Box display="flex" sx={{ flexDirection: 'column', mr: 2, minWidth: '20vw', width: '100%' }}>
                                        <StripeComponentWrapper
                                            label={formattedMessages.card_number_label}
                                            isFocused={isCardNumberFocused}
                                            customisedContainerStyle={{ marginTop: '1rem' }}
                                            isEmpty={isCardNumberEmpty}
                                            errorMessage={cardNumberError}
                                        >
                                            <CardNumberElement
                                                options={{ ...options, placeholder: isCardNumberFocused ? '' : formattedMessages.card_number_label }}
                                                onFocus={() => setIsCardNumberFocused(true)}
                                                onBlur={() => setIsCardNumberFocused(false)}
                                                onChange={(e) => {
                                                    setIsCardNumberEmpty(e.empty);
                                                    setCardNumberError(e.error?.message as string);
                                                }}
                                            />
                                        </StripeComponentWrapper>
                                        <Box display="flex" sx={{ flex: 1 }}>
                                            <StripeComponentWrapper
                                                label={formattedMessages.expiry_date_label}
                                                isFocused={isExpiryDateFocused}
                                                isEmpty={isExpiryDateEmpty}
                                                customisedContainerStyle={{ flex: 1 }}
                                                errorMessage={expiryDateError}
                                            >
                                                <CardExpiryElement
                                                    options={{
                                                        ...options,
                                                        placeholder: isExpiryDateFocused ? '' : formattedMessages.expiry_date_label,
                                                    }}
                                                    onFocus={() => setIsExpiryDateFocused(true)}
                                                    onBlur={() => setIsExpiryDateFocused(false)}
                                                    onChange={(e) => {
                                                        setIsExpiryDateEmpty(e.empty);
                                                        setExpiryDateError(e.error?.message as string);
                                                    }}
                                                />
                                            </StripeComponentWrapper>
                                            <StripeComponentWrapper
                                                label="CVC/CVV"
                                                isFocused={isCVCFocused}
                                                isEmpty={isCVCEmpty}
                                                customisedContainerStyle={{ flex: 1 }}
                                                errorMessage={cVVError}
                                            >
                                                <CardCvcElement
                                                    options={{ ...options, placeholder: isCVCFocused ? '' : formattedMessages.cvv_cvc_label }}
                                                    onFocus={() => setIsCVCFocused(true)}
                                                    onBlur={() => setIsCVCFocused(false)}
                                                    onChange={(e) => {
                                                        setIsCVCEmpty(e.empty);
                                                        setCVVError(e.error?.message as string);
                                                    }}
                                                />
                                            </StripeComponentWrapper>
                                        </Box>
                                        <Box display="flex">
                                            <FormTextField
                                                inputLabel={formattedMessages.name_on_card_label}
                                                name="cardHolderName"
                                                style={{ ml: 0, mr: { xs: 2.5, md: '2.5rem' } }}
                                            />
                                        </Box>
                                    </Box>
                                    <AddressCheckbox
                                        policyHolderName={`${firstName} ${lastName}`}
                                        originalDefaultAddress={noAddress ? '' : `${address}, ${city}, ${state}, ${zipCode}, ${country}`}
                                    />
                                </Box>
                                {!values.isSameAddress && <BillingAddressDetails />}
                                <DisclaimerComponent />
                                <Box display="flex" justifyContent="flex-end" sx={{ m: { xs: 1, md: 3 } }}>
                                    <Button
                                        disabled={!(isValid && dirty) || stripeComponentErrors}
                                        fullWidth={!isBigScreen}
                                        variant="contained"
                                        type="submit"
                                    >
                                        {formattedMessages.place_order_label}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isSubmitting}>
                <PageLoader />
            </Backdrop>
            <ConfirmationAlert
                fullWidth
                handlePrimaryAction={handleSuccessfulPaymentClose}
                open={isPaymentSuccessful}
                primaryActionTitle={formattedMessages.okay_label}
                secondaryActionTitle=""
                subtitle={formattedMessages.successful_payment_description}
                title={formattedMessages.successful_payment_label}
                titleStyle={{ fontWeight: 700, paddingTop: '1rem', paddingBottom: 0, marginBottom: 0 }}
            />
            <ConfirmationAlert
                fullWidth
                handlePrimaryAction={handleErrorAlert}
                open={!!popperErrorMessage}
                primaryActionTitle={formattedMessages.close_try_label}
                secondaryActionTitle=""
                subtitle={`${popperErrorMessage}. ${formattedMessages.failed_payment_description}`}
                title={formattedMessages.failed_payment_label}
            />
            {/* <Popper popup={{ status: !!popperErrorMessage, message: popperErrorMessage, severity: 'error' }} handlePopup={handlePopperClose} /> */}
        </>
    );
};

export default StripePaymentForm;
