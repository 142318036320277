import { Box } from '@mui/material';
import FormHeader from 'components/FormHeader';
import { useLanguage } from 'context/LanguageContext';
import { Field } from 'formik';
import messages from 'i18n/messages';
import React, { FC } from 'react';
import FormTextField from '../../../components/FormTextField';

interface PatientDetailsProps {
    deviceModel: string;
    formikFieldName: string;
    deviceSerialNumber: string;
    hasHeader?: boolean;
    primaryHeader?: string;
    secondaryHeader?: string;
}
const PatientDetails: FC<PatientDetailsProps> = ({ deviceModel, formikFieldName, deviceSerialNumber, hasHeader, primaryHeader, secondaryHeader }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];

    return (
        <Field name={formikFieldName}>
            {() => (
                <Box sx={{ mb: 3 }}>
                    {hasHeader && (
                        <FormHeader
                            title={primaryHeader}
                            titleVariant="subtitle1"
                            titleStyle={{ fontWeight: 500, fontSize: '1.1rem' }}
                            subtitle={deviceModel && deviceSerialNumber ? secondaryHeader : ''}
                            subtitleStyle={{ fontWeight: 400 }}
                            subtitleVariant="body1"
                            rootStyle={{ mx: { xs: 1 }, mt: { xs: 2, md: 0 } }}
                            withDivider={false}
                        />
                    )}
                    <Box display="flex" sx={{ flexDirection: { xs: 'column', md: 'row' }, px: { xs: 0.5, md: 1 } }}>
                        <FormTextField name={`${formikFieldName}.firstName`} inputLabel={formattedMessages.patient_first_name_label} />
                        <FormTextField name={`${formikFieldName}.lastName`} inputLabel={formattedMessages.patient_last_name_label} />
                    </Box>
                </Box>
            )}
        </Field>
    );
};

export default PatientDetails;

PatientDetails.defaultProps = {
    primaryHeader: '',
    secondaryHeader: '',
    hasHeader: true,
};
