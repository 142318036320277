import { ClaimFormValues } from 'interfaces/claim';
import { listInputProps } from 'interfaces/profile';
import { europeanDateFormatRegex } from 'validate/enrollment';
import * as yup from 'yup';

export const claimsCreationInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'date',
        style: { minWidth: '22vw', width: '100%', marginBottom: '1rem' },
        placeholder: 'incident_occurence_date_label',
        type: 'date',
        useState: 'dateOfIncident',
        variant: 'outlined',
        disableFuture: true,
        inputContainerStyle: { margin: 0 },
    },
    {
        id: 1,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%', marginBottom: '1rem' },
        placeholder: 'incident_description_question_label',
        type: 'text',
        useState: 'incidentDescription',
        variant: 'outlined',
        multiline: true,
        minRows: 10,
    },
];

export const claimFormInitValues: ClaimFormValues = {
    theftOrDamaged: 'Damaged',
    dateOfIncident: '',
    incidentDescription: '',
    practitioner: '',
    location: '',
    files: [],
};

export const claimCreationFormvalidationSchema = yup.object().shape({
    theftOrDamaged: yup.string().required(),
    dateOfIncident: yup.string().required('required_field').matches(europeanDateFormatRegex, 'european_date').nullable(),
    incidentDescription: yup.string().required('incident_details_required'),
    // practitioner: yup.string().required('required_field'),
    // location: yup.string().required('required_field'),
    files: yup
        .array()
        .when(['theftOrDamaged'], {
            is: (theftOrDamaged: 'Theft' | 'Damaged' | 'Lost') => theftOrDamaged === 'Theft' || theftOrDamaged === 'Lost',
            then: yup.array().min(2, 'police_report_invoice_required'),
        })
        .of(
            yup.object().shape({
                errors: yup.array().length(0),
            })
        )
        .min(1, 'minimum_one_file_required'),
});
