import { Card, Divider, Grid, Typography } from '@mui/material';
import PolicyDetailListing from 'components/PolicyDetailListing';
import { AuthenticationStore } from 'context/Authentication';
import { useLanguage } from 'context/LanguageContext';
import usePolicy from 'hook/usePolicy';
import messages from 'i18n/messages';
import translate from 'i18n/messages/translate';
import { providerProps } from 'interfaces/profile';
import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const PersonalDetails = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { activePolicy } = usePolicy();
    const { policyId } = useParams();
    const { authenticationState }: providerProps = useContext(AuthenticationStore);

    const specificPolicy = activePolicy?.find((policy) => policy.policySelfId === policyId);
    const personalDetails = useMemo(() => {
        if (specificPolicy && authenticationState) {
            const { policyNumber, coveragePeriod, policyHolderName } = specificPolicy;
            const { emailAddress, address } = authenticationState;
            return { policyNumber, coveragePeriod, policyHolderName, emailAddress, address };
        }
        return {
            policyNumber: '-',
            coveragePeriod: '-',
            policyHolderName: '-',
            emailAddress: '-',
            address: '-',
        };
    }, [specificPolicy]);

    const { policyNumber, coveragePeriod, policyHolderName, address, emailAddress } = personalDetails;
    return (
        <Card sx={{ p: '20px' }} elevation={0}>
            <Grid container>
                <Grid item xs={12} lg={12}>
                    <Typography variant="h6" gutterBottom sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {translate('personal_details_label')}
                    </Typography>
                    <Divider sx={{ marginBottom: '1rem' }} />
                </Grid>
                <PolicyDetailListing title={`${formattedMessages.full_name_label}: `} value={policyHolderName} />
                <PolicyDetailListing title={`${formattedMessages.policy_number_label}: `} value={policyNumber} />
                <PolicyDetailListing title={`${formattedMessages.coverage_period_label}: `} value={coveragePeriod} />
                <PolicyDetailListing title={`${formattedMessages.email_address_label}: `} value={emailAddress ?? ''} />
                <PolicyDetailListing title={`${formattedMessages.address_label}: `} value={address ?? ''} />
            </Grid>
        </Card>
    );
};

export default PersonalDetails;
