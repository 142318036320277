import { useCallback, useMemo } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

/**
 * Custom hook to manage query parameters in a React application using React Router.
 * @returns {[TInputs, (values: TInputs) => void]} A tuple containing the current query parameters and a function to set new query parameters.
 * @template TInputs - The type of the query parameters object.
 */
// eslint-disable-next-line
const useQueryParams = <TInputs extends {}>(): [TInputs, (values: TInputs) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { pathname } = useLocation();
    const query = useMemo(() => Object.fromEntries(searchParams as any) as TInputs, [searchParams]);

    const setQueryAction = useCallback(
        (values: TInputs) => {
            const updatedSearchParams = new URLSearchParams(values);
            setSearchParams(updatedSearchParams);
        },
        [setSearchParams, searchParams, pathname]
    );

    return [query, setQueryAction];
};

export default useQueryParams;
