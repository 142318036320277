import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import React, { forwardRef } from 'react';

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export interface popupProps {
    severity: AlertColor;
    status: boolean;
    message: string;
}

export interface PopperProps {
    popup: popupProps;
    handlePopup: () => void;
}

const Popper = ({ popup, handlePopup }: PopperProps) => (
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={popup.status}
        autoHideDuration={3000}
        onClose={handlePopup}
    >
        <Alert onClose={handlePopup} severity={popup.severity} sx={{ width: '100%' }}>
            {popup.message}
        </Alert>
    </Snackbar>
);

export default Popper;
