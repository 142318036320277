import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid } from '@mui/material';
import PageLayout from 'components/PageLayout';
import Header from 'components/Header';
import NavigateButton from 'components/NavigateButton';
import { useNavigate, useParams } from 'react-router-dom';
import { createClaim } from 'services/httpService';
import usePolicy from 'hook/usePolicy';
import { CreateClaim } from 'interfaces/claim';
import { useClaim, useClaimUpdate } from 'context/ClaimContext';
import { httpClient } from 'services/httpClient';
import { Claim } from 'interfaces/general';
import LikewizeAlert from 'components/customAlert/LikewizeAlert';
import { claimFormInitValues } from 'constant/claims';
import { toggleDateFormat } from 'utils/enrollment';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import ClaimDetails from './claimDetails';
import PolicyOwnerDetails from './PolicyOwnerDetails';
import AttachmentUploadDialog from './claimDetails/AttachmentUploadDialog';

const ClaimReview = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const navigate = useNavigate();
    const { policyId } = useParams();
    const { activePolicy } = usePolicy();
    const { files, dateOfIncident, incidentDescription, theftOrDamaged } = useClaim();
    const updateClaimDetails = useClaimUpdate();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    const [isClaimCreated, setIsClaimCreated] = useState<{ isCreated: boolean; claimStatus: string }>(
        {} as { isCreated: boolean; claimStatus: string }
    );
    const [errorExists, setErrorExists] = useState<boolean>(false);
    const [isCreatingClaim, setIsCreatingClaim] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);

    const specificPolicy = activePolicy?.find((policy) => policy.policySelfId === policyId);

    const handleAttachmentSubmissions = async (claimCreated: Claim) => {
        const totalFilesBytes = files.map((file) => file.file.size).reduce((previous, current) => previous + current, 0);
        let accumulatedBytes = 0;
        files.forEach((file) => {
            const formData = new FormData();
            formData.append('title', file.file.name);
            formData.append('attachedTo', claimCreated.self.href);
            formData.append('privateAttachment', file.file);

            httpClient
                .post('/activity-service/file-attachments/', formData, {
                    headers: { 'Content-Type': 'application/json' },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.lengthComputable) {
                            setUploadProgress(Math.round(((accumulatedBytes + progressEvent.loaded) * 100) / totalFilesBytes));
                        }
                    },
                })
                .then(() => {
                    accumulatedBytes += file.file.size;
                    if (accumulatedBytes === totalFilesBytes) {
                        setIsClaimCreated({ isCreated: true, claimStatus: claimCreated.status });
                        setIsCreatingClaim(false);
                    }
                });
        });
    };

    const handleClaimCreation = () => {
        const claimCreationPayload: CreateClaim = {
            checkBusinessRules: true,
            sendEmail: true,
            policyVersion: specificPolicy?.policyVersionHyperlink ?? '',
            typeOfNotification: 'Claim',
            notificationDate: format(new Date(), 'yyyy-MM-dd'),
            incidentDate: format(new Date(toggleDateFormat(dateOfIncident)), 'yyyy-MM-dd'),
            extraData: {
                incidentDescription,
                claimReason: theftOrDamaged,
            },
        };
        createClaim(claimCreationPayload)
            .then((res) => {
                // when attachments are included in claims creation
                if (files?.length > 0) {
                    handleAttachmentSubmissions(res);
                } else {
                    setIsClaimCreated({ isCreated: true, claimStatus: res.status });
                    setIsCreatingClaim(false);
                }
                updateClaimDetails(claimFormInitValues);
            })
            .catch(() => {
                setErrorExists(true);
            });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PageLayout>
                <Header
                    subTitle="review_submit_header_label"
                    subTitleStyle={{ color: 'text.primary', fontSize: '24px', fontWeight: 500, paddingLeft: '0.5rem', paddingTop: '10px' }}
                    hasBackButton
                    backButton={<NavigateButton buttonTitle="back_to_claim_form_button_label" targetedURL={`/claim-creation/${policyId}`} />}
                />
                <Grid container>
                    {!isBigScreen && (
                        <Grid item xs={12} lg={3}>
                            <PolicyOwnerDetails />
                        </Grid>
                    )}
                    <Grid item xs={12} lg={8}>
                        <ClaimDetails />
                    </Grid>
                    <Divider orientation="vertical" />
                    {isBigScreen && (
                        <Grid item xs={12} lg={3}>
                            <PolicyOwnerDetails />
                        </Grid>
                    )}
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={8} sx={{ textAlign: { xs: 'center', md: 'right' }, p: 2 }}>
                        <Button onClick={handleClaimCreation} fullWidth={!isBigScreen} variant="contained">
                            {formattedMessages.confirm}
                        </Button>
                    </Grid>
                </Grid>
            </PageLayout>
            <AttachmentUploadDialog
                isOpen={isCreatingClaim}
                uploadProgress={uploadProgress}
                cardTitle={formattedMessages.attachment_upload_inprogress_header}
                cardSubheading={formattedMessages.attachment_upload_inprogress_description}
            />
            <LikewizeAlert
                cardImage={isClaimCreated.claimStatus === 'Claim - Approved' ? 'assets/Success.png' : 'assets/ClaimSubmitted.png'}
                open={isClaimCreated.isCreated}
                secondaryButtonStyle={{ backgroundColor: '#3F51B5' }}
                cardTitle={
                    isClaimCreated.claimStatus === 'Claim - Approved' ? (
                        <div
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: `${formattedMessages.claim_approved_header_line_one} <br />
                        ${formattedMessages.claim_approved_header_line_two}`,
                            }}
                        />
                    ) : (
                        formattedMessages.success_claim_creation_header_label
                    )
                }
                mainButtonTitle={formattedMessages.view_status_button_label}
                cardSubheading={formattedMessages.success_claim_creation_description}
                secondaryButtonTitle={formattedMessages.new_claim_button_label}
                handleMainButtonOnClick={() => {
                    navigate(`/claim-history`);
                }}
                handleSecondaryButtonOnClick={() => {
                    navigate(`/dashboard`);
                }}
            />
            <LikewizeAlert
                open={errorExists}
                cardImage="assets/Warning.svg"
                cardTitle={
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `${formattedMessages.failed_claim_creation_header} <br />
                    ${formattedMessages.something_wrong}`,
                        }}
                    />
                }
                cardSubheading={
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `${formattedMessages.failed_claim_creation_description}<a href=mailto:claims@company.com>claims@company.com</a>`,
                        }}
                    />
                }
                mainButtonTitle={formattedMessages.try_again_button_label}
                hasSecondButton={false}
                handleMainButtonOnClick={() => {
                    navigate(`/dashboard`);
                }}
            />
        </>
    );
};

export default ClaimReview;
