import React, { Suspense, lazy } from 'react';
import Stack from '@mui/material/Stack';
import Chip, { ChipPropsVariantOverrides } from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';
import { listFilterProps } from 'interfaces/profile';

const Divider = lazy(() => import('@mui/material/Divider'));

interface ListHeaderProps {
    handleSelectedChip?: (value: string) => void;
    headerTitle?: string;
    listFilter?: listFilterProps[];
    selectedChip?: string[];
    withDivider?: boolean;
    withHeader?: boolean;
}

const ListHeader = ({ handleSelectedChip, headerTitle, listFilter, selectedChip, withDivider, withHeader }: ListHeaderProps) => {
    const onHandleSelectedChip = (value: string) => () => {
        if (handleSelectedChip) handleSelectedChip(value);
    };

    return (
        <Stack direction="column">
            {withHeader ? (
                <Stack style={{ position: 'relative', display: 'flex', marginBottom: 20, padding: '0px 16px 0px 16px' }}>
                    <Typography component="p" variant="subtitle2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {headerTitle}
                    </Typography>
                </Stack>
            ) : null}
            <Stack direction="column">
                <Stack direction="row" spacing={1} style={{ marginBottom: withDivider ? 12 : 0, padding: '0px 16px 0px 16px' }}>
                    {listFilter?.map((item) => {
                        let selectedVariant: OverridableStringUnion<'filled' | 'outlined', ChipPropsVariantOverrides> = 'outlined';
                        if (selectedChip?.includes(item.value)) selectedVariant = 'filled';

                        return (
                            <Chip
                                key={item.label}
                                color="primary"
                                label={item.label}
                                onClick={onHandleSelectedChip(item.value)}
                                style={{ minWidth: 50, cursor: selectedVariant === 'filled' ? 'default' : 'pointer' }}
                                variant={selectedVariant}
                            />
                        );
                    })}
                </Stack>
                <Suspense fallback={<span />}>
                    {withDivider ? (
                        <Stack>
                            <Divider />
                        </Stack>
                    ) : null}
                </Suspense>
            </Stack>
        </Stack>
    );
};

ListHeader.defaultProps = {
    handleSelectedChip: undefined,
    headerTitle: 'Cession type',
    selectedChip: undefined,
    withDivider: true,
    withHeader: true,
    listFilter: [],
};

export default ListHeader;
