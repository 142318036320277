import Stack from '@mui/material/Stack';
import CustomInput from 'components/customInput';
import FormHeader from 'components/FormHeader';
import { Formik } from 'formik';
import React, { CSSProperties, useContext } from 'react';
import profileValidator from 'validate/profile';
import {
    profileInputListAddress,
    profileInputListCity,
    profileInputListCountry,
    profileInputListDetail,
    profileInputListEmail,
    profileInputListProfileName,
    profileInputListProfileNameMobile,
    profileInputListDetailMobile,
    profileInputListEmailMobile,
    profileInputListAddressMobile,
    profileInputListCityMobile,
    profileInputListCountryMobile,
} from 'constant/profile';
import { AuthenticationStateProps, providerProps } from 'interfaces/profile';
import { AuthenticationStore } from 'context/Authentication';
import { useMediaQuery } from 'react-responsive';

interface ProfileProps {
    textInputRootStyle?: CSSProperties;
}

const Profile = ({ textInputRootStyle }: ProfileProps) => {
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    const onHandleSubmit = (values: AuthenticationStateProps) => {
        throw values;
    };

    return (
        <Stack>
            <Formik validationSchema={profileValidator} initialValues={authenticationState} onSubmit={onHandleSubmit}>
                {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <Stack>
                            <Stack>
                                <FormHeader rootStyle={isBigScreen ? {} : { m: 2 }} />
                                <CustomInput
                                    rootStyle={{ width: '100%', marginTop: isBigScreen ? '15px' : 0 }}
                                    boxFlexDirection={isBigScreen ? 'row' : 'column'}
                                    formValue={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    listInput={isBigScreen ? profileInputListProfileName : profileInputListProfileNameMobile}
                                    textInputRootStyle={textInputRootStyle}
                                />
                                <CustomInput
                                    rootStyle={{ width: '100%', marginTop: isBigScreen ? '15px' : 0 }}
                                    boxFlexDirection={isBigScreen ? 'row' : 'column'}
                                    formValue={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    listInput={isBigScreen ? profileInputListDetail : profileInputListDetailMobile}
                                    textInputRootStyle={textInputRootStyle}
                                />
                                <CustomInput
                                    rootStyle={{ width: '100%', marginTop: isBigScreen ? '15px' : 0 }}
                                    formValue={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    listInput={isBigScreen ? profileInputListEmail : profileInputListEmailMobile}
                                    textInputRootStyle={textInputRootStyle}
                                />
                            </Stack>
                            {isBigScreen && <div style={{ margin: 20 }} />}
                            <Stack>
                                <FormHeader title="address_label" rootStyle={isBigScreen ? {} : { m: 2 }} />
                                <CustomInput
                                    rootStyle={{ width: '100%', marginTop: isBigScreen ? '15px' : 0 }}
                                    formValue={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    listInput={isBigScreen ? profileInputListAddress : profileInputListAddressMobile}
                                    textInputRootStyle={textInputRootStyle}
                                />
                                <CustomInput
                                    rootStyle={{ width: '100%', marginTop: isBigScreen ? '15px' : 0 }}
                                    boxFlexDirection={isBigScreen ? 'row' : 'column'}
                                    formValue={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    listInput={isBigScreen ? profileInputListCity : profileInputListCityMobile}
                                    textInputRootStyle={textInputRootStyle}
                                />
                                <CustomInput
                                    rootStyle={{ width: '100%', marginTop: isBigScreen ? '15px' : 0 }}
                                    formValue={values}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    listInput={isBigScreen ? profileInputListCountry : profileInputListCountryMobile}
                                    textInputRootStyle={textInputRootStyle}
                                />
                            </Stack>
                        </Stack>
                    </form>
                )}
            </Formik>
        </Stack>
    );
};

Profile.defaultProps = {
    textInputRootStyle: { position: 'relative', display: 'flex', flexDirection: 'row', margin: '8px 0px 8px 0px', width: '100%' },
};

export default Profile;
