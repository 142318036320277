import { environment } from '../../environments/environment';

interface Endpoint {
    href: string;
    id?: string;
    headers?: { [key: string]: string };
}
export type Endpoints = { [key: string]: Endpoint };

const PRODUCT_UUID = environment.REACT_APP_PRODUCT_UUID;
const PRODUCT_PLAN_ID = environment.REACT_APP_PRODUCT_PLAN_ID;
const BUSINESS_PARTNER_CUSTOMER_ROLE = environment.REACT_APP_CUSTOMER_ROLE;
const GUARDIAN_RELATIONSHIP_ID = environment.REACT_APP_GUARDIAN_RELATIONSHIP_ID;
const PATIENT_RELATIONSHIP_ID = environment.REACT_APP_PATIENT_RELATIONSHIP_ID;

export const END_POINTS: Endpoints = {
    claim: {
        href: '/policy-service/claims/',
    },
    policies: {
        href: '/policy-service/policies/',
    },
    policiesVersion: {
        href: '/policy-service/versions/',
    },
    policyVersionItem: {
        href: '/policy-service/items/',
    },
    policyContract: {
        href: '/policy-service/contracts/',
    },
    policyHolder: {
        href: '/business-partner-service/business-partners/',
    },
    businessPartnerAddress: {
        href: '/business-partner-service/addresses/',
    },
    productPlan: {
        href: `/product-service/plans/${PRODUCT_PLAN_ID}/`,
        id: PRODUCT_PLAN_ID,
    },
    product: {
        href: `/product-service/products/${PRODUCT_UUID}/`,
        id: PRODUCT_UUID,
    },
    requestOtp: {
        href: '/auth-service/otp/email/',
    },
    verifyOtp: {
        href: '/auth-service/otp/token/',
    },
    policyIncompleteOrder: {
        href: '/policy-service/incomplete-orders/',
    },
    fileAttachment: {
        href: '/activity-service/file-attachments/',
    },
    manualRefund: {
        href: '/payment-service/refunds/',
    },
    policyDocument: {
        href: '/document-service/documents/',
    },
    ratingService: {
        href: '/rating-service/pricing/',
    },
    businessPartnerRole: {
        href: `/business-partner-service/roles/${BUSINESS_PARTNER_CUSTOMER_ROLE}/`,
    },
    businessPartnerRelationType: {
        href: '/business-partner-service/relation-types/',
    },
    policyRelation: {
        href: '/business-partner-service/policy-relations/',
    },
    guardianRelationType: {
        href: `/business-partner-service/policy-relation-types/${GUARDIAN_RELATIONSHIP_ID}/`,
    },
    patientRelationType: {
        href: `/business-partner-service/policy-relation-types/${PATIENT_RELATIONSHIP_ID}/`,
    },
    businessPartnerRelations: {
        href: '/business-partner-service/relations/',
    },
    rateIncompleteOrder: {
        href: '/rating-service/quotes/',
    },
    paymentIntent: {
        href: '/payment-service/stripe/group-payment-intent/',
    },
    // offline payment to activate policy LOCALLY
    offlinePayment: {
        href: '/payment-service/offline-payments/',
    },
    choices: {
        href: '/i2go-choice-service/choices/',
    },
};

export const getSpecificClaimURL: (claimId: string) => string = (claimId) => `${END_POINTS.claim.href}${claimId}`;
