import Stack from '@mui/material/Stack';
import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import translate from 'i18n/messages/translate';

interface dataProps {
    [key: string]: string | number | undefined;
}

interface ContentProps {
    data?: dataProps;
}

const Content = ({ data }: ContentProps) => (
    <Stack>
        <List>
            {data && Object.keys(data)?.length > 0
                ? Object.keys(data).map((childItem, childKey) => (
                      <ListItem
                          divider={childKey !== Object.keys(data).length - 1}
                          key={childItem}
                          disablePadding
                          style={{ paddingLeft: 16, minHeight: 40 }}
                          secondaryAction={<Typography variant="body2">{data[childItem] || '-'}</Typography>}
                      >
                          <ListItemText
                              primary={
                                  <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)', maxWidth: { xs: '120px', md: '200px' } }}>
                                      {childItem.includes('_') ? translate(childItem) : childItem}
                                  </Typography>
                              }
                          />
                      </ListItem>
                  ))
                : null}
        </List>
    </Stack>
);

Content.defaultProps = {
    data: undefined,
};

export default Content;
