import { FileError } from 'react-dropzone';
import { FormValues } from './general';

export type ClaimFormValues = Pick<FormValues, 'dateOfIncident' | 'incidentDescription' | 'files'> & {
    practitioner: string;
    location: string;
    theftOrDamaged: 'Theft' | 'Damaged';
};

export const claimCreationFormInitValues: ClaimFormValues = {
    theftOrDamaged: 'Damaged',
    dateOfIncident: '',
    incidentDescription: '',
    practitioner: '',
    location: '',
    files: [],
};

export interface UploadedFile {
    file: File;
    errors: FileError[];
}
export interface CreateClaim {
    checkBusinessRules: boolean;
    sendEmail: boolean;
    policyVersion: string;
    typeOfNotification: 'Claim';
    incidentDate: string;
    notificationDate: string;
    extraData: Record<string, string>;
}
