import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import React from 'react';

interface HeaderProps {
    titleStyle?: SxProps;
    title?: string;
    subTitleStyle?: SxProps;
    subTitle?: string;
    hasBackButton?: boolean;
    backButton?: JSX.Element;
    rootStyle?: SxProps;
}

const Header = ({ titleStyle, title, subTitleStyle, subTitle, hasBackButton, backButton, rootStyle }: HeaderProps) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <Stack sx={{ ...rootStyle }}>
            {hasBackButton ? (
                backButton
            ) : (
                <Typography variant="overline" sx={{ ...titleStyle }}>
                    {title?.includes('_') ? formattedMessages[title as keyof typeof formattedMessages] : title}
                </Typography>
            )}
            <Typography variant="h4" sx={{ ...subTitleStyle }}>
                {subTitle?.includes('_') ? formattedMessages[subTitle as keyof typeof formattedMessages] : subTitle}
            </Typography>
        </Stack>
    );
};

Header.defaultProps = {
    titleStyle: { color: 'rgba(0, 0, 0, 0.6)' },
    title: 'account_header_label',
    subTitleStyle: { color: 'rgba(0, 0, 0, 0.87)' },
    subTitle: 'account_settings_header_label',
    hasBackButton: false,
    backButton: null,
    rootStyle: {},
};

export default Header;
