import { deviceStateProps } from 'interfaces/devices';
import { environment } from '../../environments/environment';

export const productStorageKey: string = environment.REACT_APP_SESSION_KEY || '';

export const deviceState: deviceStateProps[] = [
    {
        id: 0,
        deviceImage: 'assets/cochlear_1.png',
        deviceStatus: 'In use',
        deviceName: 'The CochlearTM Nucleus® Implant System',
        devicePolicyNumber: 'ABC1234567890',
        deviceBrand: 'Cochlear',
        deviceModel: 'The Cochlear Nucleus Implant System',
        deviceYear: '2021',
        deviceSerialNumber: '1234567890ABC-XX',
        deviceColor: 'Rose Gold',
        devicePatient: 'John Doe',
    },
    {
        id: 1,
        deviceImage: 'assets/cochlear_2.png',
        deviceStatus: 'In use',
        deviceName: 'The CochlearTM Baha® System',
        devicePolicyNumber: 'CBA0987654321',
        deviceBrand: 'Cochlear',
        deviceModel: 'The Cochlear Nucleus Implant System',
        deviceYear: '2021',
        deviceSerialNumber: '1234567890ABC-XX',
        deviceColor: 'Midnight Gray',
        devicePatient: 'John Doe',
    },
];
