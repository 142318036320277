import React, { FC } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocationSelect } from 'interfaces/enrollment';
import { Field, FieldProps, useField } from 'formik';
import { FormHelperText, SxProps } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface DeviceLocationSelectProps {
    isDisabled?: boolean;
    itemsArr: LocationSelect[];
    name: string;
    selectLabel: string;
    valueSelected: string;
    handleChange: (valueSelected: string) => void;
    rootStyle?: SxProps;
}
const DeviceLocationSelect: FC<DeviceLocationSelectProps> = ({ isDisabled, itemsArr, name, selectLabel, valueSelected, handleChange, rootStyle }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const [, , helpers] = useField(name);
    const { setValue } = helpers;
    const handleSelectChange = (e: SelectChangeEvent<string>) => {
        handleChange(e.target.value);
        setValue(e.target.value);
    };
    return (
        <Field name={name}>
            {({ meta, field }: FieldProps) => {
                const { touched, error } = meta;
                return (
                    <FormControl
                        disabled={isDisabled}
                        error={!isDisabled && touched && !!error}
                        fullWidth
                        sx={{
                            flex: 1,
                            ml: 0.2,
                            mr: 3,
                            mt: 2,
                            '& .Mui-disabled': {
                                cursor: 'not-allowed !important',
                            },
                            ...rootStyle,
                        }}
                    >
                        <InputLabel>{selectLabel}</InputLabel>
                        <Select label={selectLabel} error={touched && !!error} {...field} onChange={handleSelectChange} value={valueSelected}>
                            {itemsArr.map((item) => (
                                <MenuItem key={item.value} value={`${item.value}:${item.displayValue}`}>
                                    {item.displayValue}
                                </MenuItem>
                            ))}
                        </Select>
                        {touched && !!error && <FormHelperText>{formattedMessages[error as keyof typeof formattedMessages]}</FormHelperText>}
                    </FormControl>
                );
            }}
        </Field>
    );
};

export default DeviceLocationSelect;

DeviceLocationSelect.defaultProps = {
    isDisabled: false,
    rootStyle: {},
};
