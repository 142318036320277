/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Card, Grid } from '@mui/material';
import React, { FC } from 'react';
// import CustomisedCardBody from 'views/enrollment/cardComponent/CustomisedCardBody';
import CustomisedCardHeader from 'views/enrollment/cardComponent/CustomisedCardHeader';
import PricingDetails from 'views/enrollment/cardComponent/PricingDetails';
import CustomisedCardBody from 'views/enrollment/cardComponent/CustomisedCardBody';
import { productStorage } from 'utils/storageHandler';
import extractDeviceDetails from 'utils/extractDeviceDetails';
import { listOfQuoteProps } from 'interfaces/quote';

interface DeviceCardProps {
    isDeviceRemovable?: boolean;
}
const DeviceCard: FC<DeviceCardProps> = ({ isDeviceRemovable }) => {
    const storedDeviceArr = extractDeviceDetails(productStorage()?.listQuote as listOfQuoteProps);

    return (
        <Grid sx={{ mt: { xs: 2, md: '40px' }, mb: { xs: 0, md: 5 } }} item xs={12} lg={5}>
            <Card sx={{ minWidth: 275, mx: { xs: 0, md: 2 }, border: '1px solid rgba(0, 0, 0, 0.12)' }} elevation={0}>
                <CustomisedCardHeader />
                <CustomisedCardBody deviceArr={storedDeviceArr} isDeviceRemovable={isDeviceRemovable!} />
                <PricingDetails deviceArr={storedDeviceArr} />
            </Card>
        </Grid>
    );
};

export default DeviceCard;

DeviceCard.defaultProps = {
    isDeviceRemovable: true,
};
