import React, { ChangeEvent, FocusEvent } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextInput, { formValueProps } from 'components/customInput/TextInput';
import { listInputProps } from 'interfaces/profile';
import { SxProps } from '@mui/system';

interface TextfieldButtonProps {
    formValue?: formValueProps;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    handleClick?: (value: string) => void;
    helperText?: string;
    item?: listInputProps;
    textInputRootStyle?: SxProps;
    textfieldButtonStatus?: boolean;
    withMinNumberSetting?: boolean;
}

const TextfieldButton = ({
    formValue,
    handleBlur,
    handleChange,
    handleClick,
    helperText,
    item,
    textInputRootStyle,
    textfieldButtonStatus,
    withMinNumberSetting,
}: TextfieldButtonProps) => {
    const buttonPosition = item?.buttonConfig?.position;
    const StartIcon = item?.buttonConfig?.startIcon;
    const EndIcon = item?.buttonConfig?.endIcon;

    const onHandleClick = () => {
        if (handleClick && formValue && item) handleClick(formValue[item?.useState]);
    };

    const customButton = (
        <Button
            disabled={textfieldButtonStatus}
            endIcon={EndIcon ? <EndIcon /> : null}
            onClick={onHandleClick}
            startIcon={StartIcon ? <StartIcon /> : null}
            style={{ ...item?.buttonConfig?.style }}
            type={item?.buttonType}
            variant={item?.buttonConfig?.variant || 'text'}
        >
            {item?.buttonConfig?.title}
        </Button>
    );

    return (
        <Stack direction="row">
            {buttonPosition === 'start' ? customButton : null}
            <TextInput
                formValue={formValue}
                handleBlur={handleBlur}
                handleChange={handleChange}
                helperText={helperText}
                item={item}
                rootStyle={textInputRootStyle}
                withMinNumberSetting={withMinNumberSetting}
            />
            {buttonPosition === 'end' ? customButton : null}
        </Stack>
    );
};

TextfieldButton.defaultProps = {
    formValue: undefined,
    handleBlur: undefined,
    handleChange: undefined,
    handleClick: undefined,
    helperText: undefined,
    item: undefined,
    textInputRootStyle: undefined,
    textfieldButtonStatus: false,
    withMinNumberSetting: false,
};

export default TextfieldButton;
