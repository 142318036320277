import { AuthenticationCallback } from 'context/Authentication';
import {
    AddressDetails,
    BusinessPartner,
    BusinessPartnerCreation,
    CreateBusinessPartnerAddress,
    CreateIncompleteOrder,
    CreatePolicyItem,
    CreatePolicyRelation,
    DeviceInfo,
    EnrollmentFormValues,
    LocationSelect,
    PatientCreation,
} from 'interfaces/enrollment';
import { IncompleteOrder, LocationChoicesResponse, PolicyVersionItem } from 'interfaces/general';
import { authenticationDispatchProps, AuthenticationStateProps } from 'interfaces/profile';
import { END_POINTS } from 'services/endpoints';
import {
    createAddress,
    createBussinessPartner,
    createIncompleteOrder,
    createPolicyRelation,
    createPolicyVersionItem,
    getTargetedPolicyHolder,
    rateIncompleOrder,
} from 'services/httpService';
import { environment } from '../../environments/environment';

// constants
const BASE_URL = environment.REACT_APP_API_BASE_URL;
const AGENT_ID = environment.REACT_APP_AGENT_ID;
const GUARDIAN_RELATION_HREF = `${BASE_URL}${END_POINTS.guardianRelationType.href}`;
const PATIENT_RELATION_HREF = `${BASE_URL}${END_POINTS.patientRelationType.href}`;

// toggle between european date format dd/MM/yyyy and american date format MM/dd/yyyy
export const toggleDateFormat = (inputDate: string) => {
    if (inputDate !== '') {
        const d = inputDate.split('/');
        return new Date(`${d[1]}/${d[0]}/${d[2]}`);
    }
    return inputDate;
};

const createSequentialAddress: (businessPartnerInput: BusinessPartner, formValuesInput: EnrollmentFormValues) => Promise<AddressDetails> = async (
    businessPartnerInput,
    formValuesInput
) => {
    try {
        const addressPayload: CreateBusinessPartnerAddress = {
            isDefault: true,
            type: 'DEFAULT',
            details: {
                line1: formValuesInput.personalOrGuardianAddress,
                city: formValuesInput.personalOrGuardianCity,
                country: formValuesInput.personalOrGuardianCountry,
                postalCode: formValuesInput.personalOrGuardianZip,
                state: formValuesInput.personalOrGuardianState,
            },
            businessPartner: businessPartnerInput.self.href,
        };
        return await createAddress(addressPayload);
    } catch (error) {
        throw new Error(`Address for ${businessPartnerInput.fullName} failed to be created`);
    }
};

const createSequentialPolicyVersionItem: (formValues: EnrollmentFormValues, deviceAddedInput: DeviceInfo) => Promise<PolicyVersionItem> = async (
    formValues,
    deviceAddedInput
) => {
    const serialNumber = Object.keys(deviceAddedInput)[0];
    const deviceDetails = deviceAddedInput[serialNumber];
    const policyVersionItemPayload: CreatePolicyItem = {
        name: deviceDetails.name,
        slug: serialNumber,
        amount: 0,
        attributes: [
            {
                key: 'serial_number',
                keyDisplay: 'Serial Number',
                value: serialNumber,
                valueDisplay: serialNumber,
            },
            {
                key: 'model',
                keyDisplay: 'Model',
                value: deviceDetails.model,
                valueDisplay: deviceDetails.model,
            },
        ],
    };

    try {
        return await createPolicyVersionItem(policyVersionItemPayload);
    } catch (error) {
        throw new Error(`Policy version item for device ${serialNumber} failed to be created`);
    }
};

const createSequentialIncompleteOrder: (
    formValues: EnrollmentFormValues,
    policyVersionItemHref: string,
    businessPartnerCreated: BusinessPartner,
    incompleteOrderHrefArr: string[]
) => Promise<IncompleteOrder> = async (formValues, policyVersionItemHref, businessPartnerCreated, incompleteOrderHrefArr: string[]) => {
    const { practitioner, location } = formValues;
    const [practitionerSlug, practitionerDisplayValue] = practitioner.split(':');
    const [locationSlug, locationDisplayValue] = location.split(':');
    const incompletOrderPayload: CreateIncompleteOrder = {
        agent: `${BASE_URL}${END_POINTS.policyHolder.href}${AGENT_ID}/`,
        holder: `${BASE_URL}${END_POINTS.policyHolder.href}${businessPartnerCreated.self.id}/`,
        product: `${BASE_URL}${END_POINTS.product.href}`,
        productPlan: `${BASE_URL}${END_POINTS.productPlan.href}`,
        progress: 60,
        policyTerm: 'Yearly',
        paymentScheme: 'Yearly',
        recurrenceScheme: 'Yearly',
        classification: 'Warm',
        items: [policyVersionItemHref],
        attributes: [
            {
                key: 'practitioner',
                keyDisplay: 'Practitioner',
                value: practitionerSlug,
                valueDisplay: practitionerDisplayValue,
            },
            {
                key: 'location',
                keyDisplay: 'Location',
                value: locationSlug,
                valueDisplay: locationDisplayValue,
            },
        ],
    };
    try {
        const incompleteOrderCreated = await createIncompleteOrder(incompletOrderPayload);
        incompleteOrderHrefArr.push(incompleteOrderCreated.self.href);
        return incompleteOrderCreated;
    } catch (error) {
        throw new Error('post_incomplete_order');
    }
};
// create business partner relationships
const createRelationship = async (mainIndividual: string, relatedTo: string, relationship: string, policyVersion: string) => {
    const payload: CreatePolicyRelation = {
        businessPartner: mainIndividual,
        linkedBusinessPartner: relatedTo,
        relationshipType: relationship,
        policyVersion,
    };
    await createPolicyRelation(payload);
};

const createSequentialPatient: (formValuesInput: EnrollmentFormValues) => Promise<BusinessPartner> = (formValuesInput) => {
    const patientPayload: PatientCreation = {
        firstName: formValuesInput.patient.firstName,
        lastName: formValuesInput.patient.lastName,
        category: 'Person',
        idType: 'Other',
        idNumber: formValuesInput.patient.id,
    };
    try {
        return createBussinessPartner(patientPayload);
    } catch (error) {
        throw new Error(`post_business_partner`);
    }
};

const singlePatientCreation: (
    formValues: EnrollmentFormValues,
    businessPartnerCreated: BusinessPartner,
    incompleteOrderCreated: IncompleteOrder
) => Promise<void> = async (formValues, businessPartnerCreated, incompleteOrderCreated) => {
    try {
        const patientCreated = await createSequentialPatient(formValues);
        // create guardian relationship
        await createRelationship(
            businessPartnerCreated.self.href,
            businessPartnerCreated.self.href,
            GUARDIAN_RELATION_HREF,
            incompleteOrderCreated.policyVersion.href
        );

        // create the patient relationship
        await createRelationship(
            patientCreated.self.href,
            businessPartnerCreated.self.href,
            PATIENT_RELATION_HREF,
            incompleteOrderCreated.policyVersion.href
        );
    } catch (error) {
        throw new Error(error as string);
    }
};

const multiplePatientsCreations: (
    formValues: EnrollmentFormValues,
    businessPartnerCreated: BusinessPartner,
    incompleteOrderCreated: IncompleteOrder,
    deviceAdded?: DeviceInfo
) => Promise<void> = async (formValues, businessPartnerCreated, incompleteOrderCreated, deviceAdded) => {
    await Promise.all(
        formValues.patients.map(async (patient) => {
            const patientPayload: PatientCreation = {
                firstName: patient.firstName,
                lastName: patient.lastName,
                category: 'Person',
                idType: 'Other',
                idNumber: patient.id,
            };
            if (deviceAdded) {
                const deviceAddedSerialNumber = Object.keys(deviceAdded)[0];
                const patientOwnedSerialNumber = Object.keys(patient.device)[0];
                // create MULTIPLE relationships between the guardian and patients
                // create the guardian relationship
                if (deviceAddedSerialNumber === patientOwnedSerialNumber) {
                    const patientCreated = await createBussinessPartner(patientPayload as PatientCreation);
                    await createRelationship(
                        businessPartnerCreated.self.href,
                        patientCreated.self.href,
                        GUARDIAN_RELATION_HREF,
                        incompleteOrderCreated.policyVersion.href
                    );

                    // create the patient relationship
                    await createRelationship(
                        patientCreated.self.href,
                        businessPartnerCreated.self.href,
                        PATIENT_RELATION_HREF,
                        incompleteOrderCreated.policyVersion.href
                    );
                }
            }
        })
    );
};

const usersPoliciesPurchases: (
    formValues: EnrollmentFormValues,
    deviceAddedInput: DeviceInfo,
    businessPartnerCreated: BusinessPartner,
    incompleteOrderHrefArr: string[]
) => Promise<void> = async (formValues, deviceAddedInput, businessPartnerCreated, incompleteOrderHrefArr) => {
    try {
        const policyVersionItemCreated = await createSequentialPolicyVersionItem(formValues, deviceAddedInput);
        const incompleteOrderCreated = await createSequentialIncompleteOrder(
            formValues,
            policyVersionItemCreated.self.href,
            businessPartnerCreated,
            incompleteOrderHrefArr
        );

        await rateIncompleOrder(incompleteOrderCreated.self.id);

        // create relationship when buying for 'myself'
        if (formValues.personalOrOthers === 'personal') {
            await createRelationship(
                businessPartnerCreated.self.href,
                businessPartnerCreated.self.href,
                GUARDIAN_RELATION_HREF,
                incompleteOrderCreated.policyVersion.href
            );
        }

        // create relationship when buying for ONE patient
        if (formValues.personalOrOthers === 'others' && formValues.sameOwner) {
            await singlePatientCreation(formValues, businessPartnerCreated, incompleteOrderCreated);
        }

        // create relationships when buying for multiple patients
        if (formValues.personalOrOthers === 'others' && !formValues.sameOwner) {
            // adjustment needed relationship for the patient shall only be created for its
            // corresponding policy
            await multiplePatientsCreations(formValues, businessPartnerCreated, incompleteOrderCreated, deviceAddedInput);
        }
    } catch (error) {
        throw new Error(error as string);
    }
};

const handleNewUserPurchase = async (
    businessPartnerPayload: BusinessPartnerCreation,
    values: EnrollmentFormValues,
    authenticationDispatch: React.Dispatch<authenticationDispatchProps>,
    setBPUUID: (key: any, value: any) => void
) => {
    const incompleteOrderHrefArr: string[] = [];
    const businessPartnerCreated = await createBussinessPartner(businessPartnerPayload);
    setBPUUID('businessPartnerUUID', businessPartnerCreated.self.id);
    await createSequentialAddress(businessPartnerCreated, values);

    // regardless buying for personal or other, we can get the list of items from the values.patients
    for (let index = 0; index < values.patients.length; index += 1) {
        const deviceTargeted = values.patients[index].device;
        try {
            // eslint-disable-next-line no-await-in-loop
            await usersPoliciesPurchases(values, deviceTargeted, businessPartnerCreated, incompleteOrderHrefArr);
        } catch (error) {
            throw new Error(error as string);
        }
    }

    const latestFormValues: AuthenticationStateProps = {
        personalOrOthers: values.personalOrOthers,
        sameOwner: values.sameOwner,
        firstName: values.personalOrGuardianFirstName,
        lastName: values.personalOrGuardianLastName,
        idNo: values.personalOrGuardianId,
        dateOfBirth: values.personalOrGuardianDob,
        emailAddress: values.personalOrGuardianEmailAddress,
        address: values.personalOrGuardianAddress,
        zipCode: values.personalOrGuardianZip,
        city: values.personalOrGuardianCity,
        state: values.personalOrGuardianState,
        country: values.personalOrGuardianCountry,
        patients: values.patients,
        patient: values.patient,
        devices: undefined,
        incompleteOrderHrefArr,
        businessPartnerHref: businessPartnerCreated.self.href,
        practitioner: values.practitioner,
        location: values.location,
    };
    authenticationDispatch({ type: AuthenticationCallback.handle_authentication, value: latestFormValues });
    return latestFormValues;
};

const handleExistingUserPurchase = async (
    values: EnrollmentFormValues,
    authenticationDispatch: React.Dispatch<authenticationDispatchProps>,
    authenticationState: AuthenticationStateProps
) => {
    const incompleteOrderHrefArr: string[] = [];
    const policyHolder = await getTargetedPolicyHolder();
    for (let index = 0; index < values.patients.length; index += 1) {
        const deviceTargeted = values.patients[index].device;
        try {
            // eslint-disable-next-line no-await-in-loop
            await usersPoliciesPurchases(values, deviceTargeted, policyHolder, incompleteOrderHrefArr);
        } catch (error) {
            throw new Error(error as string);
        }
    }
    const latestFormValues: AuthenticationStateProps = {
        ...authenticationState,
        personalOrOthers: values.personalOrOthers,
        sameOwner: values.sameOwner,
        patients: values.patients,
        patient: values.patient,
        incompleteOrderHrefArr,
        businessPartnerHref: policyHolder.self.href,
        dateOfBirth: values.personalOrGuardianDob,
    };
    authenticationDispatch({ type: AuthenticationCallback.handle_authentication, value: latestFormValues });
    return latestFormValues;
};

const extractChoicesInfo: (choicesArr: LocationChoicesResponse[]) => LocationSelect[] = (choicesArr) =>
    choicesArr.map((item) => ({ displayValue: item.value, value: item.key }));

export { handleNewUserPurchase, handleExistingUserPurchase, extractChoicesInfo };
