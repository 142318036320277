import { useQuery } from '@tanstack/react-query';
import { Product, ProductPlan } from '..';
import { getProductPlan } from '../services/app.service';

export type ExpandedProductPlan = Omit<ProductPlan, 'product'> & { product: Product };

export const useProductDetails = (productPlanSlug: string) => {
    const { data, error, ...restResult } = useQuery({
        queryKey: ['product-details', productPlanSlug],
        queryFn: () =>
            getProductPlan<ExpandedProductPlan>({
                slug: productPlanSlug,
                expand: 'product',
            }),
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });

    return {
        data,
        error,
        ...restResult,
    };
};
