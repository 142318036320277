const LOCALES = {
    ENGLISH: 'en-us',
    ITALY: 'it-it',
    DEUSTSCH: 'de-de',
};

export const localesCode = {
    [LOCALES.ENGLISH]: 'en',
    [LOCALES.ITALY]: 'it',
    [LOCALES.DEUSTSCH]: 'de',
};

export default LOCALES;
