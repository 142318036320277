import React, { ChangeEvent, FocusEvent, Suspense, lazy, CSSProperties } from 'react';
import { listInputProps } from 'interfaces/profile';
import Box from '@mui/material/Box';
import { SxProps, SystemCssProperties } from '@mui/system';

const TextfieldWithSelect = lazy(() => import('components/customInput/TextfieldWithSelect'));
const SelectInput = lazy(() => import('components/customInput/SelectInput'));
const DateInput = lazy(() => import('components/customInput/DateInput'));
const TextInput = lazy(() => import('components/customInput/TextInput'));
const SelectCheckbox = lazy(() => import('components/customInput/SelectCheckbox'));
const SelectAutocomplete = lazy(() => import('components/customInput/selectAutocomplete'));
const TextfieldButton = lazy(() => import('components/customInput/TextfieldButton'));

interface formValueProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface CustomInputProps {
    boxFlexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    boxStyle?: SystemCssProperties;
    formValue?: formValueProps;
    globalInputDisable?: boolean;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
    listInput?: listInputProps[];
    rootStyle?: SxProps;
    selectAutocompleteRootStyle?: CSSProperties;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectForm?: any;
    textInputRootStyle?: SxProps;
    textfieldButtonClick?: (value: string) => void;
    textfieldButtonStatus?: boolean;
    withMinNumberSetting?: boolean;
}

const CustomInput = ({
    boxFlexDirection,
    boxStyle,
    formValue,
    globalInputDisable,
    handleBlur,
    handleChange,
    helperText,
    listInput,
    rootStyle,
    selectAutocompleteRootStyle,
    selectForm,
    textInputRootStyle,
    textfieldButtonClick,
    textfieldButtonStatus,
    withMinNumberSetting,
}: CustomInputProps) => (
    <Box
        component="div"
        // style={{ ...rootStyle }}
        sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: boxFlexDirection,
            '& > :not(style)': {
                ...boxStyle,
            },
            ...rootStyle,
        }}
    >
        <Suspense fallback={<span />}>
            {listInput?.map((item: listInputProps) => {
                if (item.inputType === 'select' && selectForm && item?.selectLabel && selectForm[item.selectLabel.key]) {
                    return (
                        <SelectInput
                            key={item?.id}
                            formValue={formValue}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            item={item}
                            selectForm={selectForm}
                        />
                    );
                }

                if (item.inputType === 'date') {
                    return (
                        <DateInput
                            key={item?.id}
                            formValue={formValue}
                            disableFuture={item.disableFuture}
                            globalInputDisable={globalInputDisable}
                            item={item}
                        />
                    );
                }

                if (item.inputType === 'text-select') {
                    return (
                        <TextfieldWithSelect
                            key={item?.id}
                            formValue={formValue}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            item={item}
                            selectForm={selectForm}
                        />
                    );
                }

                if (item.inputType === 'select-checkbox') {
                    return <SelectCheckbox key={item?.id} formValue={formValue} item={item} handleChange={handleChange} />;
                }

                if (item.inputType === 'select-autocomplete') {
                    return (
                        <SelectAutocomplete
                            key={item?.id}
                            formValue={formValue}
                            handleChange={handleChange}
                            item={item}
                            rootStyle={selectAutocompleteRootStyle}
                            selectForm={selectForm}
                        />
                    );
                }

                if (item.inputType === 'textfield-button') {
                    return (
                        <TextfieldButton
                            key={item?.id}
                            formValue={formValue}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            handleClick={textfieldButtonClick}
                            helperText={helperText}
                            item={item}
                            textInputRootStyle={textInputRootStyle}
                            textfieldButtonStatus={textfieldButtonStatus}
                            withMinNumberSetting={withMinNumberSetting}
                        />
                    );
                }

                return (
                    <TextInput
                        key={item?.id}
                        formValue={formValue}
                        globalInputDisable={globalInputDisable}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        helperText={helperText}
                        item={item}
                        rootStyle={textInputRootStyle}
                        withMinNumberSetting={withMinNumberSetting}
                        multiline={item.multiline}
                        minRows={item.minRows}
                    />
                );
            })}
        </Suspense>
    </Box>
);

CustomInput.defaultProps = {
    boxFlexDirection: 'column',
    boxStyle: { width: '100%', m: 1 },
    formValue: undefined,
    globalInputDisable: undefined,
    handleBlur: undefined,
    handleChange: undefined,
    helperText: undefined,
    listInput: undefined,
    rootStyle: { width: '100%', minHeight: 500 },
    selectAutocompleteRootStyle: { width: '100%' },
    selectForm: undefined,
    textInputRootStyle: { position: 'relative', display: 'flex', flexDirection: 'row' },
    textfieldButtonClick: undefined,
    textfieldButtonStatus: false,
    withMinNumberSetting: true,
};

export default CustomInput;
