import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import ItemMenu from 'views/dashboard/customCard/ItemMenu';
import { activePolicyProps } from 'views/dashboard';

interface CustomCardProps {
    item: activePolicyProps;
    productImage?: string;
}

const Header = ({ item, productImage }: CustomCardProps) => {
    const statusColor = item.status === 'Active' ? '#4CAF50' : '#9E9E9E';

    return (
        <CardHeader
            avatar={
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        '& .MuiBadge-badge': {
                            display: item.status === 'Active' ? 'inherit' : 'none',
                            top: 27,
                            left: 27,
                            width: 10,
                            height: 10,
                            borderRadius: '100%',
                            border: '2px solid white',
                            backgroundColor: statusColor,
                        },
                    }}
                    badgeContent=" "
                    variant="dot"
                >
                    <img alt="company" src={productImage} width={40} height={40} />
                </Badge>
            }
            sx={{
                padding: 2.5,
                '& 	.MuiCardHeader-action': {
                    marginTop: 0,
                },
                '& 	.MuiCardHeader-content': {
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
            action={<ItemMenu item={item} />}
            title={
                <Typography component="span" variant="body1">
                    {item.productName || item.insuredItem}
                </Typography>
            }
            subheader={
                <Typography component="span" variant="body2" style={{ color: statusColor }}>
                    {item.status}
                </Typography>
            }
        />
    );
};

Header.defaultProps = {
    productImage: 'assets/company.png',
};

export default Header;
