import { Box } from '@mui/material';
import React, { FC } from 'react';
import { activePolicyProps } from 'views/dashboard';
import SingleMobileCard from './SingleMobileCard';

interface MobileDeviceCardProps {
    data: activePolicyProps[];
}
const MobileDeviceCard: FC<MobileDeviceCardProps> = ({ data }) => (
    <Box display="flex" sx={{ flexWrap: 'wrap' }}>
        {data.map((singleDeviceData) => (
            <SingleMobileCard key={singleDeviceData.serialNumber} singleDeviceData={singleDeviceData} />
        ))}
    </Box>
);

export default MobileDeviceCard;
