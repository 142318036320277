import { from, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

export enum ACTION_STATE {
    NONE = 'none',
    PROCESSING = 'processing',
    COMPLETED = 'completed',
    ERROR = 'error',
}

export type AsyncState<TResult> = {
    loading: boolean;
    actionState?: ACTION_STATE;
    error?: any;
    data?: TResult;
};

/**
 * Method to cosntruct response with async state flag (loading and error)
 * Method
 */
const mapPromiseToAsyncState = (promise: Promise<any>): Promise<AsyncState<any>> =>
    promise
        .then((data) => ({ loading: false, data, actionState: ACTION_STATE.COMPLETED }))
        .catch((error) => ({ loading: false, error, actionState: ACTION_STATE.ERROR }));

/**
 * Observable to convert async state value to observable
 * @param promise
 * @returns
 */
export const mapPromiseToAsyncStateObservable = (promise: Promise<any>): Observable<AsyncState<any>> => {
    const mappedPromise = mapPromiseToAsyncState(promise);
    const initialState = { loading: true, actionState: ACTION_STATE.PROCESSING };

    return from(mappedPromise).pipe(startWith(initialState));
};
