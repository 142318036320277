/* eslint-disable no-shadow */
import { AxiosError } from 'axios';
import { Reducer, useCallback, useReducer } from 'react';
import { BaseBusinessPartner, DuplicateCheck, DuplicateCheckData } from '../interfaces/api';
import { showDuplicateCheckDialog } from '../components/DuplicateCheck';
import { httpClient } from '../lib/httpClient';
import { checkDuplicateEmail, getBusinessPartnersFromEmail } from '../services/app.service';
import { DuplicateFailureCode, DuplicateSuccessCode } from '../components/DuplicateCheck/DuplicateCheck';
import { DuplicateCheckClient } from '../components/DuplicateCheck/config';

/**
 * Status of the duplicate check.
 *
 * This is used to determine what to show to the user.
 * - `INCOMPLETE` guarantees that the `isLoading` flag is set to `true` or `false`.
 * - `COMPLETE` guarantees that the `data` is available.
 * - `FAILED` guarantees that the `error` is available.
 */
export enum DuplicateCheckStatus {
    /** The check is not yet complete (might be loading data, or not — meaning the user is in the dialog) */
    INCOMPLETE = 'INCOMPLETE',
    /** The check is complete, and the `data` is available */
    COMPLETE = 'COMPLETE',
    /** A runtime `error` is available. Could be an {@linkcode Error} or {@linkcode PayloadError} (e.g. missing/invalid email) */
    FAILED = 'FAILED',
}

/**
 * Resulting `state` of a **successful** duplicate check (`data.state === DuplicateCheckStatus.COMPLETE`).
 *
 * We consider a duplicate check to be _successful_ if it completes, regardless of the result (blocked / cancelled).
 * The only thing that matters is that _we can operate on the result_.
 *
 * An error (`DuplicateCheckStatus.FAILED`) is a failure that is outside of the duplicate check's control (e.g. network error).
 */
export enum DuplicateCheckState {
    /** The email is blocked from use; email should be cleared */
    BLOCKED = 'BLOCKED',
    /** The user selected a partner from the dialog; holder should be set and fields should be populated */
    SELECT = 'SELECT',
    /** The user cancelled the dialog; email should be cleared */
    CANCELLED = 'CANCELLED',
    /** The user selected to create a new policyholder; email should be retained */
    NEW_POLICYHOLDER = 'NEW_POLICYHOLDER',
    /** The email is not a duplicate; email should be retained */
    NO_DUPLICATE = 'NO_DUPLICATE',
    /** In CSP mode and Login modal should be prompted */
    PROMPT_LOGIN = 'PROMPT_LOGIN',
}

export class PayloadError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'PayloadError';
    }
}

type IncompleteState = {
    status: DuplicateCheckStatus.INCOMPLETE;
    isLoading: boolean;
    error: null;
    data: undefined;
};

type CompleteState<T extends BaseBusinessPartner> = {
    status: DuplicateCheckStatus.COMPLETE;
    isLoading: false;
    error: null;
    data:
        | { state: DuplicateCheckState.BLOCKED; partner: null }
        | { state: DuplicateCheckState.CANCELLED; partner: null }
        | { state: DuplicateCheckState.NEW_POLICYHOLDER; partner: null }
        | { state: DuplicateCheckState.NO_DUPLICATE; partner: null }
        | { state: DuplicateCheckState.PROMPT_LOGIN; partner: null }
        | { state: DuplicateCheckState.SELECT; partner: T };
};

type FailedState = {
    status: DuplicateCheckStatus.FAILED;
    isLoading: false;
    error: Error | PayloadError;
    data: undefined;
};

type CheckEmailState<T extends BaseBusinessPartner> = IncompleteState | CompleteState<T> | FailedState;

enum ActionTypes {
    SET_LOADING = 'SET_LOADING',
    SET_COMPLETE = 'SET_COMPLETE',
    SET_FAILED = 'SET_FAILED',
}

type Action<T extends BaseBusinessPartner> =
    | { type: ActionTypes.SET_LOADING; payload: IncompleteState['isLoading'] }
    | { type: ActionTypes.SET_COMPLETE; payload: CompleteState<T>['data'] }
    | { type: ActionTypes.SET_FAILED; payload: FailedState['error'] };

function reducer<T extends BaseBusinessPartner>(state: CheckEmailState<T>, action: Action<T>): CheckEmailState<T> {
    switch (action.type) {
        case ActionTypes.SET_LOADING:
            return {
                ...state,
                status: DuplicateCheckStatus.INCOMPLETE,
                error: null,
                data: undefined,
                isLoading: action.payload,
            };
        case ActionTypes.SET_COMPLETE:
            return {
                ...state,
                status: DuplicateCheckStatus.COMPLETE,
                error: null,
                data: action.payload,
                isLoading: false,
            };
        case ActionTypes.SET_FAILED:
            return {
                ...state,
                status: DuplicateCheckStatus.FAILED,
                error: action.payload,
                data: undefined,
                isLoading: false,
            };
        default:
            return state;
    }
}

const initialState: IncompleteState = {
    status: DuplicateCheckStatus.INCOMPLETE,
    isLoading: false,
    error: null,
    data: undefined,
};

type Options = {
    /** E.g. http://localhost:8000/ */
    baseOrigin: string;
    /** Optional client for dialog customizations */
    client?: DuplicateCheckClient;
    /** Whether the app is run in CSP mode */
    isCSP?: boolean;
    isCreateNewPolicyholderDisabled?: boolean;
};

/**
 * Hook to check if a duplicate email exists. If it does, a dialog is shown and the selected BP is retrieved.
 *
 * Returns a tuple `[checkEmailState, checkEmail]` where:
 * - `checkEmailState` represents the {@linkcode CheckEmailState}
 * - `checkEmail` is a void promise that takes an email and performs the check and finishes on complete/error state.
 *
 * @param baseOrigin Base URL for the API. E.g. http://localhost:8000/ (needed as this is client-agnostic)
 *
 * @note Requires a `<ModalContainer />` from `react-modal-promise` to be rendered in the app.
 *
 * @example
 * ```ts
 * const [checkEmailState, checkEmail] = useDuplicateCheck<BusinessPartner>({
 *     baseOrigin: `${environment.REACT_APP_BASE_URL}`,
 * });
 *
 * useEffect(() => {
 *     dispatch(checkEmailState.isLoading ? showLoading() : hideLoading());
 * }, [checkEmailState.isLoading]);
 *
 * useEffect(() => {
 *     if (checkEmailState.status === DuplicateCheckStatus.FAILED) {
 *         console.error(checkEmailState.error);
 *         return;
 *     }
 *     if (checkEmailState.status === DuplicateCheckStatus.COMPLETE) {
 *         const { partner, state } = checkEmailState.data;
 *         if (state === DuplicateCheckState.BLOCKED || state === DuplicateCheckState.CANCELLED) {
 *             formik.setFieldValue('emailPrimary', ''); // reset the field
 *         } else if (state === DuplicateCheckState.SELECT) {
 *             dispatch(setHolder(partner)); // type is narrowed to BusinessPartner
 *                                           // (in TypeScript 5 only, not 4.5.5 sadly — which we use)
 *             // const businessPartner = partner as BusinessPartner; // for TypeScript 4.5.5
 *             dispatch(showLoading());
 *             formik.setFieldValue('emailPrimary', partner.emailPrimary ?? '');
 *             // and so on ...
 *             dispatch(hideLoading());
 *         }
 *     }
 * }, [checkEmailState.status]);
 *
 * // onBlur handler
 * const handleDuplicateCheck = async (email: string) => {
 *     if (email) {
 *         await checkEmail(email);
 *     }
 * };
 * ```
 */
export function useDuplicateCheck<T extends BaseBusinessPartner>({ baseOrigin, client, isCSP, isCreateNewPolicyholderDisabled }: Options) {
    const [state, dispatch] = useReducer<Reducer<CheckEmailState<T>, Action<T>>>(reducer, initialState);

    httpClient.setBaseOrigin(baseOrigin);

    const handlePromise = useCallback(
        async (email: string) => {
            try {
                if (!email) {
                    // Ignore empty email
                    return;
                }
                await checkDuplicateEmail({ email });
                dispatch({
                    type: ActionTypes.SET_COMPLETE,
                    payload: {
                        state: DuplicateCheckState.NO_DUPLICATE,
                        partner: null,
                    },
                });
            } catch (responseError: unknown) {
                const response = (responseError as AxiosError<DuplicateCheck>)?.response?.data;
                if (response && response.data && !('action' in response.data)) {
                    // e.g. { email: ['This field is required.'] } => "email: This field is required."
                    const formattedErrorMessage = Object.entries(response.data)
                        .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
                        .join('\n');
                    dispatch({
                        type: ActionTypes.SET_FAILED,
                        payload: new PayloadError(formattedErrorMessage),
                    });
                    return;
                }
                const userStatus = (response?.data as DuplicateCheckData).action;
                const isBlocked = userStatus === 'BLOCKED';
                if (isCSP && userStatus === 'PROMPT_LOGIN') {
                    dispatch({
                        type: ActionTypes.SET_COMPLETE,
                        payload: {
                            state: DuplicateCheckState.PROMPT_LOGIN,
                            partner: null,
                        },
                    });
                    return;
                }
                try {
                    let partners: Awaited<ReturnType<typeof getBusinessPartnersFromEmail>> = [];
                    if (!isBlocked) {
                        dispatch({ type: ActionTypes.SET_LOADING, payload: true });
                        partners = await getBusinessPartnersFromEmail(email, { expand: ['addresses'] });
                        dispatch({ type: ActionTypes.SET_LOADING, payload: false });
                    }
                    const response = await showDuplicateCheckDialog({ partners, email, isBlocked, client, isCreateNewPolicyholderDisabled });
                    let state = DuplicateCheckState.SELECT;
                    let partner = response;
                    if (response === DuplicateSuccessCode.NEW_POLICYHOLDER) {
                        // flag to create a new policyholder
                        state = DuplicateCheckState.NEW_POLICYHOLDER;
                        partner = null;
                    } else if (isBlocked) {
                        state = DuplicateCheckState.BLOCKED;
                        partner = null;
                    }
                    dispatch({
                        type: ActionTypes.SET_COMPLETE,
                        payload: {
                            state, // SELECT, NEW_POLICYHOLDER, BLOCKED
                            partner,
                        },
                    });
                } catch (error: unknown) {
                    if ((error as DuplicateFailureCode) === DuplicateFailureCode.NO_BP_SELECTED) {
                        dispatch({
                            type: ActionTypes.SET_COMPLETE,
                            payload: {
                                state: DuplicateCheckState.CANCELLED,
                                partner: null,
                            },
                        });
                        return;
                    }
                    dispatch({ type: ActionTypes.SET_FAILED, payload: error as Error });
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );
    return [state, handlePromise] as const;
}
