import React, { FC } from 'react';
import { ListItem, ListItemText } from '@mui/material';

interface DocumentsListItemProps {
    content: string;
    fileUrl: string;
}
const DocumentsListItem: FC<DocumentsListItemProps> = ({ content, fileUrl }) => (
    <a href={fileUrl} target="_blank" rel="noreferrer">
        <ListItem sx={{ display: 'list-item', listStyleType: 'disc' }} disableGutters disablePadding>
            <ListItemText sx={{ fontSize: '14px' }}>{content}</ListItemText>
        </ListItem>
    </a>
);

export default DocumentsListItem;
