import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import React, { CSSProperties } from 'react';
import { activePolicyProps } from 'views/dashboard';
import getDeviceImage from 'utils/getDeviceImage';
// import Chip from '@mui/material/Chip';

interface ContentProps {
    // chipKey?: string;
    // chipStyle?: CSSProperties;
    childHeader?: CSSProperties;
    contentStyle?: CSSProperties;
    data?: activePolicyProps;
    headerStyle?: CSSProperties;
    imageLabel?: string;
    imgKey?: string;
    imgStyle?: CSSProperties;
    titleKey?: string;
}
// getDeviceImage
const Header = ({ childHeader, contentStyle, data, headerStyle, imageLabel, imgKey, imgStyle, titleKey }: ContentProps) => (
    <div style={{ ...childHeader }}>
        {data ? (
            <>
                {imgKey ? (
                    <Stack style={{ ...headerStyle }}>
                        <img
                            style={{ ...imgStyle }}
                            alt={data[imgKey]?.toString()}
                            src={imageLabel ? getDeviceImage(imageLabel) : 'assets/cochlear_1.png'}
                        />
                    </Stack>
                ) : null}
                {titleKey ? (
                    <Stack style={{ ...contentStyle }}>
                        <Typography variant="body1">{data[titleKey] as string}</Typography>
                        {/* {chipKey ? <Chip style={{ ...chipStyle }} label={data[chipKey]} /> : null} */}
                    </Stack>
                ) : null}
            </>
        ) : null}
    </div>
);

Header.defaultProps = {
    // chipKey: 'deviceStatus',
    // chipStyle: { width: 60, marginTop: 5, backgroundColor: 'green', color: 'white' },
    childHeader: { maxHeight: 120, display: 'flex', position: 'relative', padding: 20 },
    contentStyle: { width: '100%' },
    data: undefined,
    headerStyle: { height: '100%', marginRight: 24 },
    imageLabel: undefined,
    imgKey: 'deviceImage',
    imgStyle: { width: 80, height: 80 },
    titleKey: 'insuredItem',
};

export default Header;
