import { ActivityStatusType, Choice } from './api';

type ExpansionMap = {
    subcategoryChoices: Choice;
    statuses: ActivityStatusType[];
};

type Expandable<T> = {
    [K in keyof T]: T[K];
};

export type Nullable = true;
export type NonNullable = false;

/**
 * Expands the specified fields of the input type based on the {@linkcode ExpansionMap}.
 * @example
 * ```ts
 * type ExpandedNote = Expand<Note, ['subcategoryChoices']>;
 * type ExpandedNote = Expand<Note, ['subcategoryChoices'], Nullable>;
 * ```
 */
export type Expand<T, K extends (keyof T & keyof ExpansionMap)[], N extends boolean = true> = Omit<T, K[number]> &
    Expandable<{ [P in K[number]]: N extends true ? ExpansionMap[P] | null : ExpansionMap[P] }>;

// use for pretty printing of types
// type Prettify<T> = {
//     [K in keyof T]: T[K];
//     // eslint-disable-next-line @typescript-eslint/ban-types
// } & {};
// type R = Prettify< >;
