import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import translate from 'i18n/messages/translate';

const Footer = () => (
    <FooterContainer>
        <FooterContent>
            <TopFooter>
                <LeftTopFooter>
                    <Box component="img" src="assets/tigercare.svg" />
                    <Description>{translate('about_tigerlab')}</Description>
                </LeftTopFooter>
                <RightTopFooter>
                    <LinkHeader>{translate('company')}</LinkHeader>
                    <Stack gap="4px">
                        <TermLink href="https://tigercare.io/privacy-policy/">{translate('privacy_policy')}</TermLink>
                        <TermLink href="https://tigercare.io/terms-of-use/">{translate('term_of_use')}</TermLink>
                        <TermLink href="https://tigercare.io/legal-notice/">{translate('legal_notice')}</TermLink>
                    </Stack>
                </RightTopFooter>
            </TopFooter>
            <Divider />
            <BottomFooter>
                <CopyRightDescription>Copyright 2024 tigerlab</CopyRightDescription>
                <CopyRightDescription>
                    tigerlab GmbH is a Registered Intermediary, an insurance agent with authorisation according to § 34 d para. 1 GewO [German Trade
                    Regulation].
                </CopyRightDescription>
            </BottomFooter>
        </FooterContent>
    </FooterContainer>
);

const TopFooter = styled(Box)(() => ({
    padding: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

const Description = styled(Typography)(() => ({
    fontSize: '12px',
    fontWeight: 400,
    color: '#000000DE',
}));

const LinkHeader = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: '14px',
    color: '#000000DE',
    marginBottom: '10px',
}));

const TermLink = styled(Link)(() => ({
    fontWeight: 400,
    fontSize: '14px',
    color: '#000000DE',
    textDecoration: 'none',
}));

const CopyRightDescription = styled(Typography)(() => ({
    fontWeight: 400,
    fontSize: '12px',
    color: '#00000099',
}));

const LeftTopFooter = styled(Box)(() => ({
    width: '100%',
    maxWidth: '840px',
    minWidth: '300px',
}));

const RightTopFooter = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        marginTop: '30px',
    },
}));

const BottomFooter = styled(Box)(() => ({
    padding: '16px 10px',
}));

const FooterContent = styled(Box)(() => ({
    width: '100%',
    margin: 'auto',
    maxWidth: '1440px',
}));

const FooterContainer = styled(Box)(({ theme }) => ({
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '16px 0',
    minHeight: '252px',
    position: 'relative',
    background: theme.palette.grey[50],
}));

export default Footer;
