import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import { Field, FieldProps } from 'formik';
import translate from 'i18n/messages/translate';
import React from 'react';

const EnrollmentDisclaimer = () => {
    const locale = useLanguage();
    return (
        <Field name="enrollmentDisclaimer">
            {({ field }: FieldProps) => (
                <FormControlLabel
                    sx={{ alignItems: { xs: 'flex-start', md: 'center' } }}
                    control={<Checkbox checked={field.value} {...field} sx={{ ml: { xs: 1, md: 0 } }} />}
                    label={
                        <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>
                            {translate('read_docs_acknowledgement', {
                                document_link: (
                                    <a href="assets/Condizione-di-Assicurazione.pdf" target="_blank">
                                        {translate('policy_document')}
                                    </a>
                                ),
                            })}
                        </Typography>
                    }
                />
            )}
        </Field>
    );
};

export default EnrollmentDisclaimer;
