import { useState, useCallback } from 'react';

export enum StatusState {
    Loading = 'loading',
    Complete = 'complete',
    Failed = 'failed',
}

export type AsyncState<T> = {
    status: null | StatusState;
    value: null | T;
    error: null | string;
};

const initialState = {
    status: null,
    value: null,
    error: null,
};

/**
 *
 * Hook to provide async status of an API call
 * @returns a tuple [state, function]
 * state - async state of Promise
 * function - which accepts a Promise and can be invoked on event handling etc...
 * callback function - which sets state to null
 *
 */
const useAsyncPost = <TResult, TArgs extends Readonly<any[]> = any[]>(
    operation: (...args: TArgs) => Promise<TResult>
): [AsyncState<TResult>, (...args: TArgs) => Promise<AsyncState<TResult>>] => {
    const [state, setState] = useState<AsyncState<TResult>>(initialState);
    const execute = useCallback(
        async (...args: TArgs) => {
            setState({
                status: StatusState.Loading,
                value: null,
                error: null,
            });
            try {
                const res = await operation(...args);
                setState({
                    status: StatusState.Complete,
                    value: res,
                    error: null,
                });
                return state;
            } catch (err: any) {
                setState({
                    status: StatusState.Failed,
                    value: null,
                    error: err.message,
                });
                return state;
            }
        },
        [state, setState, operation]
    );

    return [state, execute];
};

export default useAsyncPost;
