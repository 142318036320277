import React, { createContext, ReactNode, FC, useState, useContext, useMemo } from 'react';
import { DeviceInfo } from 'interfaces/enrollment';

interface DeviceProviderProps {
    children?: ReactNode;
}

const DevicesContext = createContext([] as DeviceInfo[]);
const DeviceUpdateContext = createContext<{ addDevice: (deviceAdded: DeviceInfo) => void; removeDevice: (deviceRemoved: string) => void }>(
    {} as { addDevice: (deviceAdded: DeviceInfo) => void; removeDevice: (deviceRemoved: string) => void }
);

export const useDevices = () => useContext(DevicesContext);
export const useDevicesUpdate = () => useContext(DeviceUpdateContext);

export const DeviceProvider: FC<DeviceProviderProps> = ({ children }) => {
    const [deviceArr, setDeviceArr] = useState<DeviceInfo[]>([] as DeviceInfo[]);
    const addDevice = (deviceAdded: DeviceInfo) => {
        const existingSerialNumberArr = deviceArr.map((item) => Object.keys(item)).flat();
        const deviceExist = existingSerialNumberArr.find((item) => item === Object.keys(deviceAdded)[0]);
        if (deviceExist === undefined) {
            setDeviceArr((prev) => [...prev, deviceAdded]);
        }
    };
    const removeDevice = (deviceRemoved: string) => {
        setDeviceArr((prev) => prev.filter((item) => Object.keys(item)[0] !== deviceRemoved));
    };

    const updateDeviceObject = useMemo(() => ({ addDevice, removeDevice }), [deviceArr]);

    return (
        <DevicesContext.Provider value={deviceArr}>
            <DeviceUpdateContext.Provider value={updateDeviceObject}>{children}</DeviceUpdateContext.Provider>
        </DevicesContext.Provider>
    );
};

DeviceProvider.defaultProps = {
    children: null,
};
