import { Box, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';

interface DetailsListingProps {
    title: string;
    value: string;
}

const PolicyDetailListing: FC<DetailsListingProps> = ({ title, value }) => (
    <Grid item xs={12} xl={12}>
        <Box display="flex" flexDirection="column" sx={{ my: { xs: 0, md: 1 } }}>
            <Typography color="text.secondary" variant="caption">
                {title}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '15px' }}>
                {value || '-'}
            </Typography>
        </Box>
    </Grid>
);

export default PolicyDetailListing;
