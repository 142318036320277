/* eslint-disable prettier/prettier */
import LOCALES from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        // navbar
        menu: 'Menu',
        accounts: 'Accounts',
        policies: 'Policies',
        profile: 'Profile',
        devices: 'Devices',
        languages: 'Languages',
        english: 'English',
        italian: 'Italian',
        deutsch: 'Deutsch',
        signout: 'Sign out',
        login: 'Login',
        logout: 'Log out',
        manage_account: 'Manage account',

        // footer
        about_tigerlab:
            'tigerlab is the leading provider of insurance software solutions and embedded insurance. Our modular software empowers customers to rapidly deploy and easily extend and change applications to meet strategic business needs. For over 10 years, we focused on what makes businesses more valuable and worked with our many clients to help them achieve breakthrough business results.',
        copyright_message:
            'tigerlab GmbH is a Registered Intermediary, an insurance agent with authorisation according to § 34 d para. 1 GewO [German Trade Regulation].',
        privacy_policy: 'Privacy Policy',
        term_of_use: 'Term of Use',
        legal_notice: 'Legal Notice',
        company: 'Company',

        // get quote page
        get_a_quote: 'Get a Quote',
        get_quote_description_v2: 'Add you device and get yearly coverage. You can add multiple devices.',
        get_quote_description: 'Get a quote for annual coverage. To add an additional device, click the "ADD" button below.',
        serial_number: 'Serial Number',
        add: 'Add',
        cochlear_device_insurance: 'Cochlear Device Insurance',
        enroll_now: 'Enroll Now',
        your_quote: 'Your Quote',
        remove: 'Remove',
        total_premium_label: 'Total Premium',
        total_premium_description: 'Total premium is exclusive of tax',
        protection_plan: 'Protection Plan',
        total: 'Total',
        image_header: 'We believe in simplifying insurance.',
        image_description: 'Simple and affordable insurance tailored to your needs.',
        captcha_verification: 'CAPTCHA verification failed!',
        error_similar_device: "Can't add device that have similar serial number",

        // enter email page
        back: 'Back',
        enter_email: 'Enter your email address',
        enter_email_description: 'Please enter your email address below to continue the enrollment process.',
        email_address_label: 'Email Address',
        continue: 'Continue',
        login_title: 'Continue with email',
        login_description: 'Please enter your email address to receive a one-time password.',
        adjust_quote: 'Adjust Quote',
        email: 'Email',
        login_disclaimer:
            'By continuing, you agree that we create an account for you (unless already created), and accept our {terms_and_condition} and {privacy_policy}',
        term_and_condition: 'Terms and Conditions',

        // otp page
        enter_otp: 'Enter one-time password',
        enter_otp_description: 'A one-time password (OTP) has been sent to your email',
        otp_verification_header: 'OTP Verification',
        otp_verification_description: 'Please enter the one-time password from the email you received.',
        edit_email: 'Edit email',

        // enrollment page
        enrollment_label: 'Enrollment',
        enrollment_alert_header: 'Account Creation',
        enrollment_alert_description: 'Your account will be created with the email address provided in the personal details section',
        personal_details_label: 'Personal Details',
        purchase_entity_label: `I'm purchasing on behalf of...`,
        myself_label: 'Myself',
        someone_else_label: 'Someone else',
        same_owner_checkbox_label: 'All devices are of same owner',
        first_name_label: 'First Name',
        last_name_label: 'Last Name',
        id_no_label: 'ID No',
        dob_label: 'Date of birth',
        confirm_email_label: 'Confirm Email Address',
        address_label: 'Address',
        zip_label: 'Zip Code',
        city_label: 'City',
        state_label: 'State',
        country_label: 'Country',
        enrollment_practitioner_center_label: 'Practitioner Center/ Store',
        location_label: 'Location',

        guardian_details_label: 'Guardian Details',
        guardian_first_name_label: 'Guardian First Name',
        guardian_last_name_label: 'Guardian Last Name',
        device_owner_details_label: 'Device owner details',
        patient_first_name_label: 'Patient first name',
        patient_last_name_label: 'Patient last name',
        patient_id_label: 'Patient ID number',
        device_purchase_place_label: 'Device purchase place',

        your_protection_policy_label: 'Your Protection Policy',
        coverage_period_label: 'Coverage Period',
        tax_label: 'Tax',
        transaction_fee_label: 'Transaction Fee',
        add_device_label: 'Add Device(s)',
        cancel: 'Cancel',
        confirm: 'Confirm',

        where_hear_insurance_label: 'How did you hear about the Insurance?',
        device_purchase_acknowledgement: 'I acknowledge that all the devices insured are bought within the last 60 days.',
        read_docs_acknowledgement: `I have read carefully the  {document_link} before I proceed to the purchase of the insurance policy. I'm aware that the hard copy of the information set will always be available to me upon request.`,
        continue_to_payment_label: 'Continue to payment',

        // checkout page
        checkout_label: 'Checkout',
        payment_details_label: 'Payment Details',
        card_number_label: 'Card Number',
        expiry_date_label: 'Expiry Date',
        cvv_cvc_label: 'CVV/CVC',
        name_on_card_label: 'Name on the card',
        same_billing_address_label: 'Billing address same as in personal information',

        billing_address_details_label: 'Billing address details',
        owner_label: 'Owner',
        place_order_label: 'Place order',
        checkout_acknowledgement_first_line: ' I have read, understood, accepted and downloaded on my computer the entire information set:',
        ipid_label: 'IPID',
        additional_ipid_label: 'Additional IPID',
        policy_terms_conditions_label: 'Policy Terms and Conditions',
        privacy_notice_label: 'Privacy Notice',
        annexes_label: 'Annexes 3, 4 and 4 ter',
        checkout_acknowledgement_last_line: `I hereby accept the policy terms and conditions of the insurance coverage issued by AIG Europe S.A. - Rappresentanza Generale per I'Italia and I accept to purchase the Cochlear Device Insurance`,

        // dashboard page
        active_tab_label: 'Active',
        inactive_tab_label: 'Inactive',
        claim_now_button_label: 'Claim now',
        policy_number_label: 'Policy number',
        insured_item_label: 'Insured Item',
        client_name_label: 'Client Name',
        view_docs_button_label: 'View documents',
        claim_history_button_label: 'Claim history',
        add_new_device_button_label: 'Add a new device',
        terms_conditions_label: 'Terms and Conditions',
        policy_document: 'policy document',

        // claim creation page
        hearing_aid_header_label: 'Hearing Aid',
        claim_reason_label: 'What is the reason of claim?',
        damaged_label: 'Damaged',
        theft_label: 'Theft',
        lost_label: 'Lost',

        incident_occurence_date_question_label: 'When does the incident happen?',
        incident_description_question_label: 'Describe more about incident...',
        incident_occurence_date_label: 'Date of Incident',
        attachment_question_label: 'Do you have any photos, videos or documents relevant to your claims',
        attachment_alert_header_label: 'To file a claim related to a stolen item please ensure the following documents are uploaded:',
        attachment_alert_police_report_label: '(i) Police report of the stolen item',
        attachment_alert_purchase_invoice_label: '(ii) Invoice of purchase',
        attachment_alert_self_declaration: `(iii) descriptive self-declaration of what happened (in case of
            accidental damage)`,
        attachment_alert_images: `(iv) photos, images of the damaged device
        highlighting the damage (in case of accidental damage)`,
        drag_drop_instruction_header: 'Drag your photos, videos or documents here to start uploading',
        drag_drop_instruction_details_line_one: `You can upload JPG, PNG images, MP4 Videos or PDF files up to 200MB.`,
        drag_drop_instruction_details_line_two: 'Please ensure each of your video attachment is below 10MB.',
        browse_file_button_label: 'Browse files',
        repair_center_question_label: 'Where would you like to fix your device?',
        claim_repair_center_label: 'Practitioner Center/ Store ',
        video_size_too_large_header: 'File size is too large',
        video_size_too_large_message: 'File size for video shall be less than 10 MB',
        or_label: 'OR',

        policy_details_card_header_label: 'Policy details',
        full_name_label: 'Full name',

        // claim review page
        back_to_claim_form_button_label: 'Back to claim form',
        review_submit_header_label: 'Review and Submit',
        claim_info_header_label: 'Claim Information',
        claim_date_application_header_label: 'Date of claim application:',
        claim_review_accident_date: `Date of Incident`,
        damaged_stolen_item_name_header_label: 'Damaged/ Stolen item name:',
        item_serial_number_header_label: 'Item serial number:',
        claim_reason_header_label: 'Claim reason:',
        incident_description_header_label: 'Incident description:',
        practitioner_store_selected_header_label: 'Practitioner center/ store selected:',
        edit_button_label: 'Edit',
        attachments_header_label: 'Attachments',
        delete_button_label: 'Delete',
        next_button_label: 'Next',

        // claim history page
        back_to_policies_button_label: 'Back to policies',
        claim_history_header_label: 'Here’s your claims history',
        claim_date_column_header: 'Claim date',
        incident_date_column_header: 'Incident date',
        insurance_product_name_column_header: 'Insurance product name',
        claim_number_column_header: 'Claim number',
        item_column_header: 'Item',
        status_column_header: 'Status',
        type_of_notification_column_header: 'Type Of Notification',

        // account setting page
        account_header_label: 'ACCOUNT',
        account_settings_header_label: 'Account settings',
        brand_label: 'Brand',
        model_label: 'Model',
        colour_label: 'Colour',
        patient_label: 'Patient',

        // mobile extra details
        learn_more_button_label: 'Learn more',

        // HTTP service error message
        ineligible_device: 'This device is not eligible for coverage',
        get_all_policies: 'Failed to retrieve all policies',
        get_targeted_policy: 'Failed to retrieve requested policy',
        delete_targeted_policy: 'Failed to cancel targeted policy',
        get_targeted_policy_version: 'Failed to retrieve policy version',
        get_targeted_incomplete_order: 'Failed to retrieve targeted incomplete order',
        get_targeted_policy_version_item: 'Failed to retrieve policy version item',
        get_targeted_business_partner: 'Failed to retrieve business partner',
        post_business_partner: 'Failed to create business partner',
        get_business_partner_address: 'Failed to retrieve business partner address',
        post_business_partner_address: 'Failed to create business partner address',
        post_policy_version_item: 'Failed to create policy version item',
        post_incomplete_order: 'Failed to create incomplete order',
        post_business_partner_relation_type: 'Business partner relation type failed to be created',
        post_business_partner_relation: 'Business partner relation failed to be created',
        rate_quotation: 'Failed to rate quotation',
        get_all_claims: 'Failed to retrieve all claims',
        create_claim: 'Failed to create claim',
        get_otp: 'Failed to request OTP',
        verify_otp: 'Failed to verify OTP',
        create_file_attachment: 'File(s) failed to be attached',
        create_manual_refund: 'Failed to create manual refund',
        get_policy_docs: 'Failed to get policy documents',
        get_device_quote: 'No device details is available',
        create_payment_intent: 'Failed to create payment intent',
        confirm_payment_intent: 'Payment intent failed to be confirmed',
        create_offline_payment: 'Failed to create offline payment',
        get_practitioner_locations: 'Failed to retrieve locations',
        something_wrong: 'Something went wrong',

        // pop-up messages in snack bar
        no_uuid: 'No UUID found',
        duplicated_card_device: 'Duplicated device added in card',
        duplicated_modal_device: 'Duplicated device added in modal',
        unregistered_email: `You're email is not registered`,
        successful_otp_verification: 'Token verified successfully',

        // yup validation error message
        valid_email_required: 'Must be a valid email',
        required_field: 'This field is required',
        required_checked_field: 'Field must be checked',
        mismatch_email: 'Email address enter does not match',
        european_date: 'European date format dd/mm/yyyy is expected',
        required_card_holder_name: 'Please provide your card holder name',
        incident_details_required: 'Please provide more information on the incident',
        police_report_invoice_required: 'Police report and invoice are required when claim is made on stolen or lost device',
        minimum_one_file_required: 'At least one file is needed',
        minimum_age: 'Age should be 18 or older',
        fiscale_id: '16 characters required LLLLLLNNLNNLNNNL where L represents for “letter” and N for “number”.',

        // pop up messages in modal
        successful_payment_label: 'Payment Successful',
        successful_payment_description: `Your payment was successful! We're generating your policy now, please wait as this may take a few minutes...`,
        okay_label: 'OKAY',

        failed_payment_label: 'Payment failed',
        failed_payment_description: 'Please try again by adding the new payment card.',
        close_try_label: 'Close and try again',

        cancel_policy_label: 'Are you sure to cancel this policy?',
        cancel_policy_description: 'If policy is cancelled within 14 days since purchase, full refund will be made',
        close_label: 'Close',
        cancel_policy_button_label: 'Cancel policy',

        download_documents_header_label: 'Download documents',
        download_all_documents_button_label: 'Download All',
        download_documents_description: 'The following are the documents associated with the policy',
        no_data_label: 'No data yet',
        no_data_description: 'Seems we have no data to display here.',
        file_name_label: 'File Name',
        certificate_of_insurance_label: 'Certificate of Insurance',
        invoice_label: 'Invoice',
        download_button_label: 'Download',

        no_attachment_label: 'No attachment yet',
        no_attachment_description: 'Seems you do not upload any attachments',

        success_claim_creation_header_label: 'Your claim has been submitted successfully',
        success_claim_creation_description: 'You can view the status of your payment now or apply for a new claim',
        view_status_button_label: 'View status',
        new_claim_button_label: 'New claim',

        claim_approved_header_line_one: 'Congratulations!',
        claim_approved_header_line_two: 'Your claim has been approved!',

        attachment_upload_inprogress_header: 'Almost there...',
        attachment_upload_inprogress_description: 'Please wait while we are processing your request.',

        failed_claim_creation_header: 'Oh no!',
        failed_claim_creation_description: 'Your claims has not been submitted. Please contact our claims team:',
        try_again_button_label: 'Try again',
    },
};
