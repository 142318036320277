import { BehaviorSubject } from 'rxjs';
import { render } from 'react-dom';
import { SxProps } from '@mui/system';
import LoaderComponent from './LoaderComponent';

/**
 * @typedef {Object} LoadingState
 * @property {boolean} isLoading - Indicates whether the loader is visible or not.
 * @property {SxProps} [loaderStyle] - Optional style for the loader component.
 * @property {string} [message] - Optional message to display with the loader.
 * @property {SxProps} [messageStyle] - Optional style for the loader message.
 * @property {number} [delay] - Optional delay (in milliseconds) before showing or hiding the loader.
 */

export type LoadingState = { isLoading: boolean; loaderStyle?: SxProps; message?: string; messageStyle?: SxProps; delay?: number };

/**
 * Global window interface extension to manage loader state and behavior.
 * @global
 */
declare global {
    interface Window {
        Loader: {
            LoaderSubject: BehaviorSubject<LoadingState> | undefined;
            isLoaderRendered: boolean;
            loaderTarget: HTMLElement | null;
            showLoader: (isLoading: boolean) => void;
        };
    }
}

function initializedGlobalLoader() {
    window.Loader = {
        LoaderSubject: undefined,
        isLoaderRendered: false,
        loaderTarget: null,
        showLoader: () => undefined,
    };
}

if (!window.Loader) {
    initializedGlobalLoader();
}

const OVERLAY_LOADER_ID = 'overlay-target-i2go-loader';

/**
 * Builds the target container for the loader if it does not already exist.
 * @returns {HTMLElement} The target container for the loader.
 */
function buildLoaderTarget() {
    const foundExistingTarget = document.getElementById(OVERLAY_LOADER_ID);

    if (foundExistingTarget) return foundExistingTarget;

    const builtTarget = document.createElement('div');
    builtTarget.id = OVERLAY_LOADER_ID;

    (document.getElementById('content') || document.body).appendChild(builtTarget);
    return builtTarget;
}

/**
 * Creates the loader container and renders the loader component if not already rendered.
 * @returns {undefined}
 */
function createContainer() {
    if (window.Loader.isLoaderRendered) return undefined;
    if (!window.Loader.loaderTarget || !document.body.contains(window.Loader.loaderTarget)) {
        window.Loader.loaderTarget = buildLoaderTarget();
    }

    window.Loader.isLoaderRendered = true;
    render(<LoaderComponent />, window.Loader.loaderTarget);

    return undefined;
}

/**
 * Retrieves the BehaviorSubject that tracks the loading state.
 * Initializes it if it doesn't already exist.
 * @returns {BehaviorSubject<LoadingState>} The subject that manages the loading state.
 */
function getLoaderSubject() {
    if (window.Loader.LoaderSubject) return window.Loader.LoaderSubject;
    window.Loader.LoaderSubject = new BehaviorSubject<LoadingState>({ isLoading: false });
    return window.Loader.LoaderSubject;
}

export const LoaderSubject = getLoaderSubject();

/**
 * Sets the current loading state, including showing or hiding the loader.
 * @param {LoadingState} state - The new loading state.
 * @returns {undefined}
 */
function setLoadingState(state: LoadingState) {
    createContainer();
    LoaderSubject.next(state);
}

/**
 * Displays the loader with optional configurations (e.g., message, style).
 * @param {Omit<LoadingState, 'isLoading'>} [props] - Optional configurations for the loader (message, style, etc.).
 * @returns {undefined}
 */

export function showLoading(props?: Omit<LoadingState, 'isLoading'>) {
    if (props) {
        const { messageStyle, message, delay, loaderStyle } = props;
        setLoadingState({ isLoading: true, message, messageStyle, delay, loaderStyle });
    } else {
        setLoadingState({ isLoading: true });
    }
}

/**
 * Hides the loader by setting its state to 'not loading'.
 * @returns {undefined}
 */
export function hideLoading() {
    setLoadingState({ isLoading: false });
}
