// base origin is assumed to look like 'http://localhost:8000/',
export default {
    // bp
    businessPartner: `api/v3/business-partner-service/business-partners/`,
    user: `api/v3/business-partner-service/users/`,
    address: `api/v3/business-partner-service/addresses/`,
    bank: `api/v3/business-partner-service/bank-accounts/`,
    role: `api/v3/business-partner-service/roles/`,
    relation: `api/v3/business-partner-service/policy-relations/`,
    duplicateCheck: `api/v1/partners/duplicate-check/`,
    // policy
    incompleteOrder: `api/v3/policy-service/incomplete-orders/`,
    policyVersion: `api/v3/policy-service/versions/`,
    policyMTA: `api/v3/policy-service/policies/`,
    policyVersionItem: `api/v3/policy-service/items/`,
    item: `api/v3/policy-service/items/`,
    renewalDraft: `api/v3/policy-service/renewals/`,
    policyV2: `api/v2/policies/`,
    // activities
    notes: `api/v3/activity-service/notes/`,
    activities: `api/v3/activity-service/activities/`,
    statusTypes: `api/v3/activity-service/status-types/`,
    priorities: `api/v3/activity-service/priorities/`,
    attributes: `api/v3/activity-service/attributes/`,
    categories: `api/v3/activity-service/categories/`,
    fileAttachments: `api/v3/activity-service/file-attachments/`,
    // choices
    choices: `api/v3/i2go-choice-service/choices/`,
    choiceTypes: `api/v3/i2go-choice-service/choice-types/`,
    // rating
    rating: `api/v3/rating-service/quotes/`,
    ratingVersion: `api/v3/rating-service/versions/`,
    // misc
    offlinePayment: `api/v3/payment-service/offline-payments/`,
    batch: `api/v3/batch-service/batch/`,
    documentVersioning: `api/v3/product-service/versions/`,
    DurationDate: `api/v1/date-duration/`,
    sendEmail: `api/v3/emailing-service/renewal-emails/`,
    // auth
    login: 'api/v2/users/auth/',
    registration: 'api/v2/users/use-registration-token/',
    passwordRecovery: 'api/v2/users/password-recovery/',
    passwordReset: 'api/v2/users/password-reset/',
    passwordUpdate: 'api/v2/users/update-password/',
    verifyToken: 'api/auth/verify-token/',
    refreshToken: 'api/auth/refresh-token/',
    personalBusinessPartner: 'api/v2/partners/me/',
    personalPolicies: 'api/v2/policies/',
    personalQuotations: 'api/v2/quotations/',
    productPlans: 'api/v3/product-service/plans/',
    products: 'api/v3/product-service/products/',
};
