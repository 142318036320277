/**
 * Executes a given function after a specified delay.
 *
 * @param {Function} execute - The function to be executed after the delay. It should not return a value.
 * @param {number} timeout - The delay in milliseconds before executing the function.
 * @returns {Promise<string>} A promise that resolves to the string 'passed' if the function executes successfully, or rejects with an error message if an exception occurs.
 *
 * @example
 * delay(() => console.log('Hello, world!'), 1000)
 *   .then(result => console.log(result)) // 'passed'
 *   .catch(error => console.error('Error:', error));
 */
export async function delay(execute: () => void, timeout: number) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            try {
                execute();
                res('passed');
            } catch (error: any) {
                rej(error.message);
            }
        }, timeout);
    });
}

/**
 * Wraps a promise with a timeout. If the promise does not settle before the timeout, it rejects with a timeout error.
 *
 * @param {Promise<T>} promise - The promise to which the timeout will be applied.
 * @param {number} timeout - The timeout duration in milliseconds.
 * @returns {Promise<T>} A promise that resolves or rejects based on which promise settles first: the original promise or the timeout promise.
 *
 * @template T - The type of the value that the promise resolves with.
 *
 * @throws {Error} Throws an error with the message 'Promise timed out' if the timeout is reached before the promise settles.
 */
export function withTimeout<T>(promise: Promise<T>, timeout: number): Promise<T> {
    // Create a timeout promise that rejects after the specified timeout
    const timeoutPromise = new Promise<never>((_, reject) => {
        setTimeout(() => reject(new Error('Promise timed out')), timeout);
    });

    // Use Promise.race to race the original promise against the timeout promise
    return Promise.race([promise, timeoutPromise]);
}
