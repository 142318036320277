import { listInputProps } from 'interfaces/profile';

export const personalDetailsInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'text',
        style: { width: '100%', minWidth: '22vw' },
        placeholder: 'first_name_label',
        type: 'text',
        useState: 'personalOrGuardianFirstName',
        variant: 'outlined',
    },
    {
        id: 1,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'last_name_label',
        type: 'text',
        useState: 'personalOrGuardianLastName',
        variant: 'outlined',
    },
    {
        id: 2,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'id_no_label',
        type: 'text',
        useState: 'personalOrGuardianId',
        variant: 'outlined',
    },
    {
        id: 3,
        inputType: 'date',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'dob_label',
        type: 'date',
        useState: 'personalOrGuardianDob',
        variant: 'outlined',
        disableFuture: true,
        inputContainerStyle: { flex: '1', margin: '0 1.5rem 2rem 0' },
    },
    {
        id: 4,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'email_address_label',
        type: 'text',
        useState: 'personalOrGuardianEmailAddress',
        variant: 'outlined',
        disabledInput: true,
    },
    {
        id: 6,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'personalOrGuardianAddress',
        variant: 'outlined',
    },
    {
        id: 7,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'personalOrGuardianZip',
        variant: 'outlined',
    },
    {
        id: 8,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'personalOrGuardianCity',
        variant: 'outlined',
    },
    {
        id: 9,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'personalOrGuardianState',
        variant: 'outlined',
    },
    {
        id: 10,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'personalOrGuardianCountry',
        variant: 'outlined',
    },
];
export const mobilePersonalDetailsInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'text',
        style: { width: '100%', minWidth: '22vw' },
        placeholder: 'first_name_label',
        type: 'text',
        useState: 'personalOrGuardianFirstName',
        variant: 'outlined',
    },
    {
        id: 1,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'last_name_label',
        type: 'text',
        useState: 'personalOrGuardianLastName',
        variant: 'outlined',
    },
    {
        id: 2,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'id_no_label',
        type: 'text',
        useState: 'personalOrGuardianId',
        variant: 'outlined',
    },
    {
        id: 3,
        inputType: 'date',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'dob_label',
        type: 'date',
        useState: 'personalOrGuardianDob',
        variant: 'outlined',
        disableFuture: true,
        inputContainerStyle: { flex: '1', margin: '0 0rem 2rem 0' },
    },
    {
        id: 4,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'email_address_label',
        type: 'text',
        useState: 'personalOrGuardianEmailAddress',
        variant: 'outlined',
        disabledInput: true,
    },
    {
        id: 6,
        inputType: 'text',
        style: { minWidth: '45.6vw', width: '100%' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'personalOrGuardianAddress',
        variant: 'outlined',
    },
    {
        id: 7,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'personalOrGuardianZip',
        variant: 'outlined',
    },
    {
        id: 8,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'personalOrGuardianCity',
        variant: 'outlined',
    },
    {
        id: 9,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'personalOrGuardianState',
        variant: 'outlined',
    },
    {
        id: 10,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'personalOrGuardianCountry',
        variant: 'outlined',
    },
];

export const guardianDetailsInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'guardian_first_name_label',
        type: 'text',
        useState: 'personalOrGuardianFirstName',
        variant: 'outlined',
    },
    {
        id: 1,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'guardian_last_name_label',
        type: 'text',
        useState: 'personalOrGuardianLastName',
        variant: 'outlined',
    },
    {
        id: 2,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'id_no_label',
        type: 'text',
        useState: 'personalOrGuardianId',
        variant: 'outlined',
    },
    {
        id: 3,
        inputType: 'date',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'dob_label',
        type: 'date',
        useState: 'personalOrGuardianDob',
        variant: 'outlined',
        disableFuture: true,
        inputContainerStyle: { flex: '1', margin: '0 1.5rem 2rem 0' },
    },
    {
        id: 4,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'email_address_label',
        type: 'text',
        useState: 'personalOrGuardianEmailAddress',
        variant: 'outlined',
    },
    {
        id: 6,
        inputType: 'text',
        style: { minWidth: '45.6vw', width: '100%' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'personalOrGuardianAddress',
        variant: 'outlined',
    },
    {
        id: 7,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'personalOrGuardianZip',
        variant: 'outlined',
    },
    {
        id: 8,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'personalOrGuardianCity',
        variant: 'outlined',
    },
    {
        id: 9,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'personalOrGuardianState',
        variant: 'outlined',
    },
    {
        id: 10,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'personalOrGuardianCountry',
        variant: 'outlined',
    },
];
export const guardianDetailsMobileInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'Guardian First Name',
        type: 'text',
        useState: 'personalOrGuardianFirstName',
        variant: 'outlined',
    },
    {
        id: 1,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'Guardian Last Name',
        type: 'text',
        useState: 'personalOrGuardianLastName',
        variant: 'outlined',
    },
    {
        id: 2,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'id_no_label',
        type: 'text',
        useState: 'personalOrGuardianId',
        variant: 'outlined',
    },
    {
        id: 3,
        inputType: 'date',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'dob_label',
        type: 'date',
        useState: 'personalOrGuardianDob',
        variant: 'outlined',
        disableFuture: true,
        inputContainerStyle: { flex: '1', margin: '0 0rem 2rem 0' },
    },
    {
        id: 4,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'email_address_label',
        type: 'text',
        useState: 'personalOrGuardianEmailAddress',
        variant: 'outlined',
    },
    {
        id: 5,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'confirm_email_label',
        type: 'text',
        useState: 'personalOrGuardianConfirmEmail',
        variant: 'outlined',
    },
    {
        id: 6,
        inputType: 'text',
        style: { minWidth: '45.6vw', width: '100%' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'personalOrGuardianAddress',
        variant: 'outlined',
    },
    {
        id: 7,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'personalOrGuardianZip',
        variant: 'outlined',
    },
    {
        id: 8,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'personalOrGuardianCity',
        variant: 'outlined',
    },
    {
        id: 9,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'personalOrGuardianState',
        variant: 'outlined',
    },
    {
        id: 10,
        inputType: 'text',
        style: { minWidth: '22vw', width: '100%' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'personalOrGuardianCountry',
        variant: 'outlined',
    },
];
