import Add from '@mui/icons-material/Add';
import translate from 'i18n/messages/translate';
import { listInputProps } from 'interfaces/profile';
import { listDeviceImageProps, listOfQuoteProps, quoteModelObjectProps } from 'interfaces/quote';
import { quotaDataStructureProps } from 'views/quote';

export const productOverdue = 'Your device purchase date has surpassed 60 days';

export const quoteInputList: listInputProps[] = [
    {
        id: 0,
        inputType: 'textfield-button',
        style: { width: '100%', marginRight: 15 },
        muiOutlinedInputRootStyle: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
        placeholder: translate('serial_number'),
        type: 'text',
        buttonType: 'submit',
        useState: 'serialNumber',
        variant: 'outlined',
        buttonConfig: {
            title: translate('add'),
            variant: 'contained',
            position: 'end',
            startIcon: Add,
            style: { height: 56, borderBottomLeftRadius: 0, borderTopLeftRadius: 0 },
        },
    },
];

export const handleQuote = (productStorageListQuote: listOfQuoteProps | undefined) => {
    if (productStorageListQuote) {
        const cloneProductStorageListQuote = {
            ...productStorageListQuote,
        } as Partial<listOfQuoteProps>;
        if (cloneProductStorageListQuote?.step) delete cloneProductStorageListQuote.step;
        if (cloneProductStorageListQuote?.totalAmounts) delete cloneProductStorageListQuote.totalAmounts;

        let quoteDataStructure: quotaDataStructureProps[] = [];
        Object.keys(cloneProductStorageListQuote).forEach((key: string) => {
            const quoteModelObject = cloneProductStorageListQuote[key] as quoteModelObjectProps;
            quoteDataStructure = [
                ...quoteDataStructure,
                {
                    id: key,
                    name: quoteModelObject?.name,
                    serialNumber: key,
                    deviceType: quoteModelObject?.model,
                    devicePrice: quoteModelObject?.pricing?.premiumExcludingIpt,
                },
            ];
        });

        return quoteDataStructure;
    }

    return [];
};

export const listDeviceImage: listDeviceImageProps = {
    nucleus: 'assets/cochlear_1.png',
};

export const submissionApi = '';
