import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Grid from '@mui/material/Grid';
import ArrowForward from '@mui/icons-material/ArrowForward';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';

interface ReviewButtonProps {
    buttonTitle?: string;
    buttonType?: 'button' | 'submit' | 'reset' | undefined;
    isValid?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ReviewButton = ({ buttonTitle, buttonType, isValid, onClick }: ReviewButtonProps) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <Box
            display="flex"
            sx={{
                marginBottom: 2,
                width: { xs: '100%' },
                mt: { xs: 1, md: 2 },
                justifyContent: { xs: 'center', md: 'flex-end' },
            }}
        >
            <Button type={buttonType} disabled={!isValid} fullWidth={!isBigScreen} variant="contained" onClick={onClick} endIcon={<ArrowForward />}>
                {buttonTitle?.includes('_') ? formattedMessages[buttonTitle as keyof typeof formattedMessages] : buttonTitle}
            </Button>
        </Box>
    );
};

ReviewButton.defaultProps = {
    buttonTitle: 'Review',
    buttonType: 'submit',
    isValid: false,
    onClick: undefined,
};

export default ReviewButton;
