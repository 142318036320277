import * as Yup from 'yup';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import { requestOtp } from 'services/httpService';
import translate from 'i18n/messages/translate';
import { showToast } from '@shared/core';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import { localesCode } from 'i18n/locales';
import ErrorTypography from './ErrorTypography';
import VerificationWrapper from './VerificationWrapper';

const validationSchema = Yup.object().shape({
    emailAddress: Yup.string().email('valid_email_required').max(255).required('Email is required'),
});

interface EmailVerificationProps {
    nextHandler: () => void;
    previousHandler: () => void;
}

const EmailVerification = ({ nextHandler, previousHandler }: EmailVerificationProps) => {
    const quoteStore = useQuotationStorage();
    const locale = useLanguage();
    const formattedMessages = messages[locale];

    const formik = useFormik({
        initialValues: {
            emailAddress: quoteStore.items.submissionEmailAddress,
        },
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async (values) => {
            try {
                if (values.emailAddress) {
                    const response = await requestOtp(values.emailAddress, localesCode[locale]);
                    quoteStore.addItem('hasBp', response.hasBp);
                    quoteStore.addItem('submissionEmailAddress', values.emailAddress);
                    nextHandler();
                }
            } catch (error) {
                showToast({
                    message: formattedMessages.get_otp,
                    enqueueSnackbarConfig: { variant: 'error' },
                    extraConfig: { enableCloseAction: true },
                });
            }
        },
    });
    const adjustQuoteHandler = () => {
        previousHandler();
    };

    const continueHandler = () => {
        formik.submitForm();
    };

    return (
        <VerificationWrapper
            disabled={formik.isSubmitting}
            textH5={translate('login_title')}
            goBackButtonLabel={translate('adjust_quote')}
            continueHandler={continueHandler}
            goBackHandler={adjustQuoteHandler}
            processing={formik.isSubmitting}
            disabledContinue={Boolean(!formik.values.emailAddress?.length)}
            disabledPrevious={Boolean(!quoteStore.items.quotedItems.length)}
            textBody1={translate('login_description')}
        >
            <Box>
                <TextField
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onFocus={() => formik.setFieldError('emailAddress', undefined)}
                    autoFocus
                    name="emailAddress"
                    fullWidth
                    label="Email"
                    id="emailAddress"
                    error={Boolean(formik.errors.emailAddress)}
                />
                {formik.errors.emailAddress && <ErrorTypography>{translate(formik.errors.emailAddress)}</ErrorTypography>}
            </Box>
        </VerificationWrapper>
    );
};

export default EmailVerification;
