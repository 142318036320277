import { Avatar, Box, Grid, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { InsertDriveFile, Movie, Image } from '@mui/icons-material';
import { FileError } from 'react-dropzone';
import FileHeader from './FileHeader';

interface SingleFileUploadWithProgressProps {
    file: File;
    errors: FileError[];
    onDelete: (file: File) => void;
}

const SingleFileUploadWithProgress: FC<SingleFileUploadWithProgressProps> = ({ file, onDelete, errors }) => {
    const icon = useMemo(() => {
        if (file.type.includes('image')) {
            return <Image />;
        }
        if (file.type.includes('video')) {
            return <Movie />;
        }
        return <InsertDriveFile />;
    }, [file.type]);

    return (
        <Grid item xs={10} lg={12}>
            <Box display="flex" alignItems="center" sx={{ px: { xs: 0, md: '20px' } }}>
                <Avatar sx={{ width: 40, height: 40, bgcolor: 'rgba(61, 81, 181, 0.08)', color: '#7986CB', ml: { xs: 1, md: 0 } }} alt="file images">
                    {icon}
                </Avatar>
                <Box sx={{ padding: '20px', minWidth: { xs: '95%', md: '440px' } }}>
                    <FileHeader file={file} onDelete={onDelete} />
                    {errors.length > 0 && (
                        <Typography variant="subtitle2" sx={{ color: '#d32f2f' }}>
                            {errors[0].message}
                        </Typography>
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default SingleFileUploadWithProgress;
