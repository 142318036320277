import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import useVerifiedUser from 'v2/hooks/useVerifiedUser';
import translate from 'i18n/messages/translate';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from './Sidebar';
import LanguageMenu from './LanguageMenu';
import AccountMenu from './AccountMenu';

const Navbar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const verifiedUser = useVerifiedUser();
    const [drawer, openDrawer] = useState(false);

    return (
        <AppBar position="static">
            <Toolbar>
                <LogoBox onClick={() => navigate('/')}>
                    <Logo component="img" src="assets/tigercare.svg" />
                </LogoBox>
                <Stack direction="row" alignItems="center" gap={2}>
                    <LanguageMenu />
                    {verifiedUser.hasBp ? (
                        <AccountMenu />
                    ) : (
                        <Button disabled={pathname === '/login'} onClick={() => navigate('/login')} variant="contained">
                            {translate('login')}
                        </Button>
                    )}
                    {!drawer ? (
                        <MobileIcon onClick={() => openDrawer(true)}>
                            <IconMenu />
                        </MobileIcon>
                    ) : (
                        <MobileIcon onClick={() => openDrawer(false)}>
                            <CloseIcon />
                        </MobileIcon>
                    )}
                </Stack>
            </Toolbar>
            <MobileDrawer anchor="right" open={drawer}>
                <Sidebar closeDrawer={() => openDrawer(false)} />
            </MobileDrawer>
        </AppBar>
    );
};

const MobileDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        top: theme.mixins.toolbar.minHeight,
        width: '220px',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

const MobileIcon = styled(IconButton)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },
}));

const IconMenu = styled(MenuIcon)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

const LogoBox = styled(Box)(() => ({
    flexGrow: 1,
    cursor: 'pointer',
    position: 'relative',
}));

const Logo = styled(Box)(() => ({
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
})) as typeof Box;

export default Navbar;
