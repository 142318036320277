/**
 * Interface for managing a Web Worker instance.
 * @interface
 */
export interface I2goWorkerInstance {
    /**
     * Gets the Web Worker instance.
     * @returns {Worker} The Web Worker instance.
     */
    getWorker(): Worker | undefined;

    /**
     * Sends a message to the worker.
     * @param {any} message - The message to send to the worker.
     */
    postMessage(message: any): void;

    /**
     * Adds a message event listener to the worker.
     * @param {(event: MessageEvent) => void} callback - The callback function to handle messages from the worker.
     */
    subscribeWorker(callback: (event: MessageEvent) => void): void;
}
