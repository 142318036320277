import React, { FC, useMemo } from 'react';
import { DeviceInfo } from 'interfaces/enrollment';
import { Alert, Backdrop, Box } from '@mui/material';
import DeviceListing from 'views/enrollment/cardComponent/DeviceListing';
import getDeviceImage from 'utils/getDeviceImage';
import PageLoader from 'components/loader/PageLoader';
import { useMediaQuery } from 'react-responsive';
import CollapsibleMobileDeviceListing from './CollapsibleMobileDeviceListing';

interface InteractiveCustomisedCardBodyProps {
    isDeviceRemovable: boolean;
    removingDevice: boolean;
    deviceArr: DeviceInfo[];
    handleRemoveDevice: (input: string) => Promise<void>;
}

const InteractiveCustomisedCardBody: FC<InteractiveCustomisedCardBodyProps> = ({
    isDeviceRemovable,
    removingDevice,
    deviceArr,
    handleRemoveDevice,
}) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const minimumOneDevice = useMemo(() => {
        if (!deviceArr) {
            return false;
        }
        return deviceArr.length === 1;
    }, [deviceArr]);

    return (
        <>
            <Box display="flex" sx={{ flexDirection: 'column' }}>
                {deviceArr.length > 0 ? (
                    deviceArr.map((device) => {
                        const serialNumber = Object.keys(device)[0];

                        return isBigScreen ? (
                            <DeviceListing
                                key={serialNumber}
                                imageUrl={getDeviceImage(serialNumber)}
                                deviceName={device[serialNumber].name}
                                serialNumber={serialNumber}
                                minimumOneDevice={minimumOneDevice}
                                handleRemoveDevice={handleRemoveDevice}
                                isDeviceRemovable={isDeviceRemovable}
                            />
                        ) : (
                            <CollapsibleMobileDeviceListing
                                key={serialNumber}
                                imageUrl={getDeviceImage(serialNumber)}
                                deviceName={device[serialNumber].name}
                                serialNumber={serialNumber}
                                minimumOneDevice={minimumOneDevice}
                                handleRemoveDevice={handleRemoveDevice}
                                isDeviceRemovable={isDeviceRemovable}
                            />
                        );
                    })
                ) : (
                    <Alert severity="error">No device is added</Alert>
                )}
            </Box>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={removingDevice}>
                <PageLoader rootStyle={{ zIndex: '99' }} />
            </Backdrop>
        </>
    );
};

export default InteractiveCustomisedCardBody;
