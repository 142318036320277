import messages from 'i18n/messages';
import LOCALES from 'i18n/locales';

type Messages = typeof messages;
type LocaleKey = keyof typeof LOCALES;
export type I18nMessageKey = keyof Messages[LocaleKey];

/**
 * Translate payment error message to i18n message key
 *
 * e.g. 'Your card has insufficient funds.' => `create_payment_intent`
 */
export function getPaymentErrorDetail(errorMessage: string): I18nMessageKey {
    let errorDetail: I18nMessageKey;
    switch (errorMessage) {
        default:
            errorDetail = 'create_payment_intent';
            break;
    }
    return errorDetail;
}
