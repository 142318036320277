import FormHeader from 'components/FormHeader';
import { FieldArray, useField, useFormikContext } from 'formik';
import React, { Fragment, useContext, useMemo } from 'react';
import { EnrollmentFormValues, PatientDetailsInterface } from 'interfaces/enrollment';
import CustomInput from 'components/customInput';
import { guardianDetailsInputList, guardianDetailsMobileInputList } from 'constant/enrollment';
import { formValueProps } from 'components/customInput/TextInput';
import { providerProps } from 'interfaces/profile';
import { AuthenticationStore } from 'context/Authentication';
import { useMediaQuery } from 'react-responsive';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import PatientAndLocationDetails from './PatientAndLocationDetails';
import InsuranceAcknowledgeLocation from '../insuranceAcknowledgeLocation/InsuranceAcknowledgeLocation';

const GuardianPatientForm = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const sameOwner = useField('sameOwner');
    const { values, handleBlur, handleChange } = useFormikContext<EnrollmentFormValues>();

    const { value: isSameOwner } = sameOwner[0];
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { emailAddress } = authenticationState;
    const isExistingPartner = useMemo(() => {
        if (emailAddress && emailAddress !== undefined) {
            return true;
        }
        return false;
    }, [emailAddress]);

    return (
        <>
            <FormHeader titleVariant="h6" title={formattedMessages.guardian_details_label} rootStyle={{ marginBottom: '2rem', mx: { xs: 1 } }} />
            <CustomInput
                boxFlexDirection={isBigScreen ? 'row' : 'column'}
                rootStyle={{ flexWrap: 'wrap', display: 'flex', width: { lg: '100%' }, px: { xs: 1 } }}
                // overwrite the default styling
                boxStyle={{}}
                textInputRootStyle={{ marginRight: isBigScreen ? '1.5rem' : '0', marginBottom: '2rem', flex: 1 }}
                formValue={values as formValueProps}
                handleBlur={handleBlur}
                handleChange={handleChange}
                listInput={isBigScreen ? guardianDetailsInputList : guardianDetailsMobileInputList}
                globalInputDisable={isExistingPartner}
            />

            <FieldArray name="patients">
                {(fieldArrayProps) => {
                    const { form } = fieldArrayProps;
                    const { values: patientValues } = form;
                    const { patients }: { patients: PatientDetailsInterface[] } = patientValues;

                    return (patients as PatientDetailsInterface[]).map((patient, index) => {
                        const serialNumber = Object.keys(patient.device)[0];
                        return (
                            <Fragment key={Object.keys(patient.device)[0]}>
                                {!isSameOwner && (
                                    <PatientAndLocationDetails
                                        formikFieldName={`patients[${index}]`}
                                        deviceModel={patient.device[serialNumber].name}
                                        deviceSerialNumber={serialNumber}
                                    />
                                )}
                            </Fragment>
                        );
                    });
                }}
            </FieldArray>
            {!isSameOwner && <InsuranceAcknowledgeLocation />}
        </>
    );
};

export default GuardianPatientForm;
