import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import React, { CSSProperties } from 'react';
import Header from 'components/deviceCard/Header';
import Content from 'components/deviceCard/Content';
import { activePolicyProps } from 'views/dashboard';

export interface cloneItemProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface DevicesCardProps {
    contentStyle?: CSSProperties;
    deviceData?: activePolicyProps[];
    keyToShowInShow?: cloneItemProps[];
    rootStyle?: CSSProperties;
}

const DevicesCard = ({ contentStyle, deviceData, keyToShowInShow, rootStyle }: DevicesCardProps) => (
    <Stack style={{ ...rootStyle }}>
        {deviceData?.map((item, key: number) => {
            let cloneItem: cloneItemProps = {};
            if (keyToShowInShow && keyToShowInShow?.length > 0) {
                keyToShowInShow?.forEach((ktr) => {
                    cloneItem = {
                        ...cloneItem,
                        [ktr?.key || '']: item[ktr?.value || ''],
                    };
                });
            }

            return (
                // eslint-disable-next-line react/no-array-index-key
                <Stack key={key.toString()} style={{ ...contentStyle }}>
                    <Header data={item} imageLabel={item.serialNumber} />
                    <Divider />
                    <Content data={cloneItem} />
                </Stack>
            );
        })}
    </Stack>
);

DevicesCard.defaultProps = {
    contentStyle: { border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: 6, flex: '0 1 48%', marginBottom: 30 },
    deviceData: undefined,
    keyToShowInShow: [
        { key: 'policy_number_label', value: 'policyNumber' },
        { key: 'brand_label', value: 'productBrand' },
        { key: 'model_label', value: 'insuredItem' },
        { key: 'coverage_period_label', value: 'coveragePeriod' },
        { key: 'serial_number', value: 'serialNumber' },
        { key: 'colour_label', value: '' },
        { key: 'patient_label', value: 'policyHolderName' },
        // { key: 'Year', value: 'deviceYear' },
        // { key: 'Color', value: 'deviceColor' },
        // { key: 'Patient', value: 'devicePatient' },
    ],
    rootStyle: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', flex: 1 },
};

export default DevicesCard;
