import React, { CSSProperties, MouseEventHandler } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { PaperProps } from '@mui/material/Paper';

interface LikewizeAlertProps {
    cardImage?: string;
    cardSubheading?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    cardTitle?: string | React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
    handleClose?: MouseEventHandler<HTMLElement>;
    handleMainButtonOnClick?: () => void;
    handleSecondaryButtonOnClick?: () => void;
    hasSecondButton?: boolean;
    loading?: boolean;
    mainButtonStyle?: CSSProperties;
    mainButtonTitle?: string;
    open?: boolean;
    paperProps?: Partial<PaperProps<'div'>>;
    secondaryButtonStyle?: CSSProperties;
    secondaryButtonTitle?: string;
}

const LikewizeAlert = ({
    cardImage,
    cardSubheading,
    cardTitle,
    handleClose,
    handleMainButtonOnClick,
    handleSecondaryButtonOnClick,
    hasSecondButton,
    loading,
    mainButtonStyle,
    mainButtonTitle,
    open,
    paperProps,
    secondaryButtonStyle,
    secondaryButtonTitle,
}: LikewizeAlertProps) => (
    <Dialog
        open={open || false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ ...paperProps }}
    >
        <CardMedia component="img" height="240" image={cardImage} alt="policy" />
        <CardContent>
            <>
                {typeof cardTitle === 'string' ? (
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ lineBreak: 'loose', fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}
                    >
                        {cardTitle}
                    </Typography>
                ) : (
                    cardTitle
                )}
                {typeof cardSubheading === 'string' ? (
                    <Typography variant="subtitle1" color="text.secondary" style={{ textAlign: 'center' }}>
                        {cardSubheading}
                    </Typography>
                ) : (
                    cardSubheading
                )}
            </>
        </CardContent>
        <CardActions style={{ padding: 24 }}>
            <Button variant="outlined" fullWidth onClick={handleMainButtonOnClick} style={{ ...mainButtonStyle }}>
                {mainButtonTitle}
            </Button>
            {hasSecondButton ? (
                <Button disabled={loading} variant="contained" style={{ ...secondaryButtonStyle }} fullWidth onClick={handleSecondaryButtonOnClick}>
                    {secondaryButtonTitle}
                </Button>
            ) : null}
        </CardActions>
    </Dialog>
);

LikewizeAlert.defaultProps = {
    cardImage: 'assets/Danger.png',
    cardSubheading: `You'll be refunded any unused premiums.`,
    cardTitle: 'Are you sure you want to cancel your policy?',
    handleClose: undefined,
    handleMainButtonOnClick: undefined,
    handleSecondaryButtonOnClick: undefined,
    hasSecondButton: true,
    loading: false,
    mainButtonStyle: {},
    mainButtonTitle: 'Back',
    open: false,
    paperProps: { sx: { width: 452 } },
    secondaryButtonStyle: { backgroundColor: '#F44336', marginLeft: 24 },
    secondaryButtonTitle: 'Cancel Policy',
};

export default LikewizeAlert;
