/* eslint-disable import/no-duplicates */
import { Box, Typography } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import { addYears, format, subDays } from 'date-fns';
import { it, enUS } from 'date-fns/locale';
import messages from 'i18n/messages';
import translate from 'i18n/messages/translate';
import React from 'react';

const CustomisedCardHeader = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const currentDate = format(new Date(), 'dd MMMM yyyy');
    // duration of policy is 1 year MINUS 1 day
    const afterOneYear = format(subDays(addYears(new Date(), 1), 1), 'dd MMMM yyyy', { locale: locale.includes('it') ? it : enUS });

    return (
        <Box display="flex" justifyContent="space-between">
            <Box display="flex" sx={{ flexDirection: 'column', py: 3, px: 2.5 }}>
                <Typography variant="h6">{translate('your_protection_policy_label')}</Typography>
                <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
                    {`${formattedMessages.coverage_period_label}:`}
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography variant="body2">{`${currentDate} - ${afterOneYear}`}</Typography>
                </div>
            </Box>
        </Box>
    );
};

export default CustomisedCardHeader;
