import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';
import { MuiColor } from 'interfaces/general';
import React from 'react';

interface PageLoaderProps {
    rootStyle?: SxProps;
    loaderColor?: MuiColor;
    loaderSize?: number | string;
    loaderThickness?: number;
}

const PageLoader = ({ rootStyle, loaderColor, loaderSize, loaderThickness }: PageLoaderProps) => (
    <Stack sx={{ ...rootStyle }}>
        <CircularProgress color={loaderColor} size={loaderSize} thickness={loaderThickness} />
    </Stack>
);

PageLoader.defaultProps = {
    rootStyle: { position: 'absolute', inset: 0, justifyContent: 'center', alignItems: 'center' },
    loaderColor: 'primary',
    loaderSize: '40px',
    loaderThickness: 3.6,
};

export default PageLoader;
