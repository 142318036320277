import { I2goWorkerInstance } from '../interfaces/I2goWorker';

declare global {
    interface Window {
        [key: string]: any;
    }
}

/**
 * Repeatedly checks for the existence of a Web Worker instance and subscribes to it once available.
 *
 * This function uses `setInterval` to periodically check if the Web Worker instance
 * with the specified name exists on the `window` object. Once the instance is found,
 * it subscribes to the worker using the provided callback function and clears the interval.
 *
 * @param {string} workerInstanceName - The name of the property on the `window` object
 * that holds the Web Worker instance. This should match the key under which the worker
 * instance is assigned on the `window` object.
 * @param {() => void} callback - A function to be called when the Web Worker instance is found
 * and the subscription is made.
 *
 * @example
 * // Example usage
 * subscribeI2goWebwWorker('i2goModalWorkerInstance', () => {
 *     console.log('Worker instance is available and subscribed.');
 * });
 */
export function subscribeI2goWebwWorker<T = any>(workerInstanceName: string, callback: (props: T) => void) {
    const intervalId = setInterval(() => {
        const workerInstance = window[workerInstanceName] as I2goWorkerInstance;
        if (workerInstance) {
            workerInstance.subscribeWorker((event: MessageEvent) => callback(JSON.parse(event.data)));
            clearInterval(intervalId);
        }
    }, 500);
}
