import React, { ChangeEvent, CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import { listInputProps } from 'interfaces/profile';
import { formValueProps } from 'components/customInput/TextInput';
import CustomList from 'components/customInput/selectAutocomplete/CustomList';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface objectProps {
    [key: string]: string;
}

interface SelectAutocompleteProps {
    ALL_KEY_SELECTED?: string;
    formValue?: formValueProps;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    item?: listInputProps;
    limitTags?: number;
    listCheckbox?: objectProps[];
    rootStyle?: CSSProperties;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectForm?: any;
    totalType?: number;
}

const SelectAutocomplete = ({
    ALL_KEY_SELECTED,
    formValue,
    handleChange,
    item,
    limitTags,
    listCheckbox,
    rootStyle,
    selectForm,
    totalType,
}: SelectAutocompleteProps) => {
    const [selectStatus, setSelectStatus] = useState<boolean>(false);
    const [selectedCession, setSelectedCession] = useState<string[]>(['all']);
    const [listData, setListData] = useState<objectProps[] | undefined>(undefined);
    const [query, setQuery] = useState<string>('');
    const domRef = useRef<number>();

    const formValueState = formValue && item?.useState ? formValue[item.useState] : [];
    const renderOption = selectForm && item?.selectLabel?.key ? selectForm[item.selectLabel.key] : listCheckbox || [];

    useEffect(() => {
        const selectedForm = (selectForm && item?.selectLabel?.key ? selectForm[item.selectLabel.key] : []) as objectProps[];
        const handleData = () => {
            const queryFilter =
                query?.length > 0
                    ? selectedForm?.filter((model) =>
                          (item?.selectLabel?.label ? ((model[item.selectLabel.label] as string)?.toLowerCase() as string) : '')?.includes(
                              query?.toLowerCase()
                          )
                      )
                    : selectedForm;

            const cessionFilter =
                selectedCession?.length > 0 && ALL_KEY_SELECTED && selectedCession?.includes(ALL_KEY_SELECTED) === false
                    ? queryFilter?.filter((model) =>
                          model && item?.selectLabel?.headerFilterBy
                              ? selectedCession.includes(model[item.selectLabel?.headerFilterBy] as string)
                              : queryFilter
                      )
                    : queryFilter;

            setListData(cessionFilter);
        };

        if (selectedForm && selectedForm?.length > 0) handleData();
    }, [ALL_KEY_SELECTED, item?.selectLabel, query, selectForm, selectedCession]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onHandleChange = (event: any, values: objectProps[] | string | objectProps, type?: 'delete' | 'remove-all') => {
        let submitValue = [...formValueState];
        if (type === 'delete' && submitValue.some((previous) => (item?.selectLabel?.label ? previous[item?.selectLabel?.label] : values === ''))) {
            submitValue = submitValue.filter((previous) => (item?.selectLabel?.label ? previous[item?.selectLabel?.label] : '') !== values);
        } else if (type === 'remove-all') {
            submitValue = [];
        } else {
            submitValue.push(values as string);
        }

        if (handleChange) {
            if (query?.length > 0) setQuery('');
            handleChange({
                ...event,
                target: {
                    ...event.target,
                    id: item?.useState,
                    name: item?.useState,
                    value: submitValue,
                },
            });
        }

        const selectAutocompleteList = document.getElementById('SelectAutocompleteList');
        if (selectAutocompleteList) domRef.current = selectAutocompleteList.scrollTop;
    };

    const handleStatus = (status: boolean) => () => {
        domRef.current = 0;
        setSelectStatus(status);
    };

    const handleInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            event.preventDefault();
            if (!selectStatus) setSelectStatus(!selectStatus);
            setQuery(event.target.value);
        },
        [selectStatus]
    );

    const handleSelectedChip = useCallback(
        (value: string) => {
            let newValue: string[] = [];
            if (!selectedCession.includes(value)) {
                newValue = [...selectedCession, value];
            }

            if (selectedCession.includes(value)) {
                newValue = selectedCession?.filter((selectedCessionItem: string) => selectedCessionItem !== value);
            } else if (value !== ALL_KEY_SELECTED && selectedCession.includes(ALL_KEY_SELECTED || '')) {
                newValue = [value];
            } else if (selectedCession.length === totalType) {
                newValue = [ALL_KEY_SELECTED || ''];
            } else if (value === ALL_KEY_SELECTED) {
                newValue = [ALL_KEY_SELECTED];
            }

            if (newValue.length === 0) {
                newValue = [value];
            }

            setSelectedCession(newValue);
        },
        [ALL_KEY_SELECTED, selectedCession, totalType]
    );

    return (
        <Autocomplete
            multiple
            onOpen={handleStatus(true)}
            open={selectStatus}
            renderTags={() =>
                formValueState?.map((value: objectProps) => (
                    <Chip
                        style={{ marginRight: 5, marginBottom: 5 }}
                        key={value && item?.selectLabel?.label ? value[item.selectLabel.label]?.toString() : ''}
                        label={value && item?.selectLabel?.label ? value[item.selectLabel.label]?.toString() : ''}
                        onDelete={(event) => {
                            if (value && item?.selectLabel?.label) onHandleChange(event, value[item.selectLabel.label] as string, 'delete');
                        }}
                    />
                ))
            }
            limitTags={limitTags}
            value={formValueState}
            id="autocomplete-checkboxes-tags-reinsurer"
            options={renderOption as unknown[]}
            getOptionLabel={(option) => (item?.selectLabel?.label ? option[item?.selectLabel?.label] : '')}
            isOptionEqualToValue={(option, value) =>
                item?.selectLabel?.label ? option[item?.selectLabel?.label] === value[item?.selectLabel?.label] : false
            }
            disableCloseOnSelect
            // eslint-disable-next-line react/no-unstable-nested-components
            PaperComponent={() => (
                <CustomList
                    customPageScroll={domRef.current}
                    formValueState={formValueState}
                    handleBlur={handleStatus}
                    handleSelectedChip={handleSelectedChip}
                    item={item}
                    listData={listData}
                    onHandleChange={onHandleChange}
                    selectedCession={selectedCession}
                />
            )}
            style={{ ...rootStyle }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Stack direction="row" style={{ position: 'absolute', right: 10 }}>
                                {formValueState && formValueState?.length > 0 ? (
                                    <IconButton size="small" onClick={(event) => onHandleChange(event, '', 'remove-all')}>
                                        <ClearIcon fontSize="small" style={{ color: 'gray' }} />
                                    </IconButton>
                                ) : null}
                                <IconButton size="small" onClick={handleStatus(!selectStatus)}>
                                    {selectStatus ? (
                                        <ArrowDropUpIcon fontSize="small" style={{ color: 'gray' }} />
                                    ) : (
                                        <ArrowDropDownIcon fontSize="small" style={{ color: 'gray' }} />
                                    )}
                                </IconButton>
                            </Stack>
                        ),
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ ...params.inputProps, value: query }}
                    onChange={handleInputChange}
                    label={item?.placeholder}
                />
            )}
        />
    );
};

SelectAutocomplete.defaultProps = {
    ALL_KEY_SELECTED: 'all',
    formValue: undefined,
    handleChange: undefined,
    item: undefined,
    limitTags: 2,
    rootStyle: { width: '100%' },
    selectForm: undefined,
    totalType: 2,
    listCheckbox: [
        { label: 'All', value: 'all' },
        { label: 'Quota Share', value: 'QS' },
        { label: 'Surplus', value: 'SP' },
        { label: 'Facultative', value: 'FB' },
    ],
};

export default SelectAutocomplete;
