import React from 'react';
import { FormControl, RadioGroup, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import CustomisedOutlinedRadioButton from 'components/CustomisedOutlinedRadioButton';
import translate from 'i18n/messages/translate';

const RadioButtonField = () => (
    <Field name="personalOrOthers">
        {({ field }: FieldProps) => (
            <FormControl fullWidth sx={{ mt: { xs: 1, md: 4 }, mx: { xs: 1 } }}>
                <Typography sx={{ mb: { xs: 1, md: 2 } }} variant="subtitle1">
                    {translate('purchase_entity_label')}
                </Typography>

                <RadioGroup sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }} row {...field}>
                    <CustomisedOutlinedRadioButton radioGroupValue={field.value} />
                    <CustomisedOutlinedRadioButton label="someone_else_label" value="others" radioGroupValue={field.value} />
                </RadioGroup>
            </FormControl>
        )}
    </Field>
);

export default RadioButtonField;
