import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import useUser from 'hook/useUser';
import { productStorage } from 'utils/storageHandler';

const PageLoader = lazy(() => import('components/loader/PageLoader'));

interface ProtectedRouteProps {
    children: JSX.Element;
    redirectPath?: string;
}

interface SecureAppProps {
    children: JSX.Element;
    secondaryChildren?: JSX.Element;
}

export const SecureApp = ({ children, secondaryChildren }: SecureAppProps) => {
    const { hasBp } = productStorage();
    const isExistingBp = JSON.parse(hasBp ?? 'false');
    return isExistingBp ? children : secondaryChildren || null;
};

SecureApp.defaultProps = {
    secondaryChildren: undefined,
};

const ProtectedRoute = ({ children, redirectPath }: ProtectedRouteProps) => {
    const { hasBp } = productStorage();
    const isExistingBp = JSON.parse(hasBp ?? 'false');
    const { loading } = useUser({ withClearStorage: false });

    if (loading && !isExistingBp && Cookies.get('token')) {
        return (
            <Suspense fallback={<span />}>
                <PageLoader />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<span />}>
            <SecureApp secondaryChildren={<Navigate to={redirectPath || ''} replace />}>{children}</SecureApp>
        </Suspense>
    );
};

ProtectedRoute.defaultProps = {
    redirectPath: '/quote',
};

export default ProtectedRoute;
