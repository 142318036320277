import { Box, Divider, Typography } from '@mui/material';
import translate from 'i18n/messages/translate';
import { DeviceInfo } from 'interfaces/enrollment';
import React, { useMemo, FC } from 'react';

interface PricingDetailsProps {
    deviceArr: DeviceInfo[];
}

const PricingDetails: FC<PricingDetailsProps> = ({ deviceArr }) => {
    // const subtotalSum = useMemo(() => {
    //     return deviceArr.reduce((acc, device) => {
    //         const serialNumber = Object.keys(device)[0];
    //         return device[serialNumber].pricing.premiumExcludingIpt + acc;
    //     }, 0);
    // }, [deviceArr]);

    const taxSum = useMemo(
        () =>
            deviceArr.reduce((acc, device) => {
                const serialNumber = Object.keys(device)[0];
                return device[serialNumber].pricing.ipt + acc;
            }, 0),
        [deviceArr]
    );

    const transactionSum = useMemo(
        () =>
            deviceArr.reduce((acc, device) => {
                const serialNumber = Object.keys(device)[0];
                return device[serialNumber].pricing.transactionFee + acc;
            }, 0),
        [deviceArr]
    );

    const totalPremium = useMemo(
        () =>
            deviceArr.reduce((acc, device) => {
                const serialNumber = Object.keys(device)[0];
                return device[serialNumber].pricing.totalPremium + acc;
            }, 0),
        [deviceArr]
    );

    return (
        <Box display="flex" sx={{ flexDirection: 'column' }}>
            <Box display="flex" justifyContent="space-between" sx={{ m: 2 }} style={{ display: 'flex', flexDirection: 'column' }}>
                {deviceArr?.map((item: DeviceInfo, key: number) => (
                    <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${item.name}${key}`}
                        style={{ display: 'flex', flexDirection: 'column', minHeight: 44 }}
                    >
                        {Object.keys(item).map((itemDetail: string) => (
                            <div key={itemDetail} style={{ display: 'flex', flexDirection: 'row', minHeight: 44, marginTop: 16, marginBottom: 16 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <Typography variant="body1">{translate('cochlear_device_insurance')}</Typography>
                                    <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                        {item[itemDetail].name}
                                    </Typography>
                                </div>
                                <Typography variant="body1" style={{ width: '100%', textAlign: 'end' }}>
                                    € {item[itemDetail].pricing.premiumExcludingIpt.toFixed(2)}
                                </Typography>
                            </div>
                        ))}
                        <Divider sx={{ borderStyle: 'dashed' }} />
                    </div>
                ))}
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ mr: 2, ml: 2, mb: 2 }}>
                <Typography variant="body1">{translate('tax_label')}</Typography>
                <Typography variant="body1">€ {taxSum.toFixed(2)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ mr: 2, ml: 2, mb: 2 }}>
                <Typography variant="body1">{translate('transaction_fee_label')}</Typography>
                <Typography variant="body1">€ {transactionSum.toFixed(2)}</Typography>
            </Box>
            <Divider sx={{ mx: 2 }} />
            <Box display="flex" justifyContent="space-between" sx={{ m: 2 }}>
                <Typography variant="h6">{translate('total_premium_label')}</Typography>
                <Typography variant="h6">€ {totalPremium.toFixed(2)}</Typography>
            </Box>
        </Box>
    );
};

export default PricingDetails;
