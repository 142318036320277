import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { MouseEventHandler } from 'react';
import { listMenuProps } from 'views/dashboard/customCard/ItemMenu';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';

interface CardMenuProps {
    anchorEl: HTMLElement | null;
    handleClose: MouseEventHandler<HTMLDivElement>;
    handleSelect?: (label: string) => void;
    listMenu?: listMenuProps[] | null;
    open: boolean;
}

const CardMenu = ({ anchorEl, handleClose, handleSelect, listMenu, open }: CardMenuProps) => {
    const onHandleSelect = (label: string) => () => {
        if (handleSelect) handleSelect(label);
    };
    const locale = useLanguage();
    const formattedMessages = messages[locale];

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {listMenu?.map((item: listMenuProps) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const ItemIcon: any = item?.icon;

                return (
                    <MenuItem key={item.id} onClick={onHandleSelect(item.label)}>
                        {item.icon ? (
                            <ListItemIcon>
                                <ItemIcon style={{ color: item.color }} fontSize="small" />
                            </ListItemIcon>
                        ) : null}
                        <Typography variant="body1" style={{ color: item.color }}>
                            {formattedMessages[item.label as keyof typeof formattedMessages]}
                        </Typography>
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

CardMenu.defaultProps = {
    handleSelect: undefined,
    listMenu: null,
};

export default CardMenu;
