import React, { ChangeEvent, FocusEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { listInputProps } from 'interfaces/profile';
import { InputProps as InputPropsTypes } from '@mui/material/Input';

interface formValueProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface SelectInputProps {
    InputProps?: Partial<InputPropsTypes>;
    formValue?: formValueProps;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    item?: listInputProps;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectForm?: any;
}

const SelectInput = ({ InputProps, formValue, handleBlur, handleChange, item, selectForm }: SelectInputProps) => (
    <TextField
        InputProps={InputProps}
        id={item?.useState}
        label={item?.placeholder}
        name={item?.useState}
        onBlur={handleBlur}
        onChange={handleChange}
        select={!!(selectForm && item?.selectLabel)}
        style={item?.style}
        type={item?.type}
        value={formValue && item?.useState ? formValue[item.useState] : ''}
        variant={item?.variant}
    >
        {selectForm && item?.selectLabel
            ? selectForm[item.selectLabel.key]?.map((option: { [x: string]: string }, optionKey: React.Key | null | undefined) => {
                  const selectedLabelValue: string = item.selectLabel?.value || '';
                  let menuItemValue = option[selectedLabelValue];

                  if (selectedLabelValue?.includes('.')) {
                      menuItemValue = '';
                      if (option[selectedLabelValue.split('.')[0]]) {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          menuItemValue = option[selectedLabelValue.split('.')[0]][selectedLabelValue.split('.').pop()];
                      }
                  }

                  return (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={optionKey} disabled={optionKey === 0} value={menuItemValue as string}>
                          {option[item.selectLabel?.label as string] as string}
                      </MenuItem>
                  );
              })
            : null}
    </TextField>
);

SelectInput.defaultProps = {
    InputProps: undefined,
    formValue: undefined,
    handleBlur: undefined,
    handleChange: undefined,
    item: undefined,
    selectForm: undefined,
};

export default SelectInput;
