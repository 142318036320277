import * as Yup from 'yup';
import differenceInYears from 'date-fns/differenceInYears';

export const europeanDateFormatRegex = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;
export const italianFiscalFormatRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
export const enrollmentValidate = {
    personalOrOthers: Yup.string().required(),
    enrollmentDisclaimer: Yup.boolean().oneOf([true], 'required_checked_field'),
    within60DaysDisclaimer: Yup.boolean().oneOf([true], 'required_checked_field'),
    sameOwner: Yup.boolean().when('personalOrOthers', {
        is: 'others',
        then: Yup.boolean().required(),
    }),

    practitioner: Yup.string().required('required_field'),
    location: Yup.string().required('required_field'),
    personalOrGuardianFirstName: Yup.string().required('required_field'),
    personalOrGuardianLastName: Yup.string().required('required_field'),
    personalOrGuardianId: Yup.string()
        .required('required_field')
        .matches(italianFiscalFormatRegex, 'fiscale_id')
        .test('len', 'Must be exactly 16 characters', (val) => val?.toString().length === 16),

    personalOrGuardianDob: Yup.string()
        .required('required_field')
        .matches(europeanDateFormatRegex, 'european_date')
        .test('personalOrGuardianDob', 'minimum_age', (value) => {
            const [date, month, year] = value?.split('/') ?? [];
            return differenceInYears(new Date(), new Date(`${month}/${date}/${year}` ?? '')) >= 18;
        })
        .nullable(),
    personalOrGuardianEmailAddress: Yup.string().email('valid_email_required').required('required_field'),
    personalOrGuardianAddress: Yup.string().required('required_field'),
    personalOrGuardianZip: Yup.string().required('required_field'),
    personalOrGuardianCity: Yup.string().required('required_field'),
    personalOrGuardianState: Yup.string().required('required_field'),
    personalOrGuardianCountry: Yup.string().required('required_field'),
    patient: Yup.object().when(['personalOrOthers', 'sameOwner'], {
        is: (personalOrOthers: 'personal' | 'others', sameOwner: boolean) => personalOrOthers === 'others' && sameOwner,
        then: Yup.object().shape({
            firstName: Yup.string().required('required_field'),
            lastName: Yup.string().required('required_field'),
            // id: Yup.string().required('required_field'),
        }),
    }),

    patients: Yup.array().when(['personalOrOthers', 'sameOwner'], {
        is: (personalOrOthers: 'personal' | 'others', sameOwner: boolean) => personalOrOthers === 'others' && !sameOwner,
        then: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required('required_field'),
                lastName: Yup.string().required('required_field'),
                // id: Yup.string().required('required_field'),
            })
        ),
    }),
};

export const enrollmentValidator = Yup.object({ ...enrollmentValidate });
