import useVerifiedUser from 'v2/hooks/useVerifiedUser';
import { Navigate } from 'react-router';

function withEmailVerification(Component: React.FC) {
    return function EmailVerificationRoute(props: { [key: string]: any }) {
        const verifiedUser = useVerifiedUser();
        const fallbackPath = verifiedUser.hasBp ? '/dashboard' : '/quote';

        if (!verifiedUser.hasBp) return <Component {...props} />;
        return <Navigate replace to={fallbackPath} />;
    };
}

export default withEmailVerification;
