import React, { MouseEventHandler } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import NoData from 'components/NoData';
import { policyDocumentProps } from 'views/dashboard';
import translate from 'i18n/messages/translate';

interface DocumentAlertProps {
    actionTitle?: string;
    dialogSubtitle?: string;
    dialogTitle?: string;
    policyDocument?: policyDocumentProps;
    handleClose?: MouseEventHandler<HTMLElement>;
    handleHeaderAction?: () => void;
    hasDialogAction?: boolean;
    listItemActionTitle?: string;
    listItemTitle?: string;
    open?: boolean;
}

const DocumentAlert = ({
    actionTitle,
    dialogSubtitle,
    dialogTitle,
    handleClose,
    handleHeaderAction,
    hasDialogAction,
    listItemActionTitle,
    listItemTitle,
    open,
    policyDocument,
}: DocumentAlertProps) => {
    const onHandleHeaderAction = () => {
        if (handleHeaderAction) handleHeaderAction();
    };

    const handleDownload = (item: string) => () => {
        const link = document?.createElement('a');
        link.target = '_blank';
        link.href = item;
        // link.download = item;
        link.dispatchEvent(new MouseEvent('click'));
    };

    const handleDownloadAll = () => {
        if (policyDocument)
            Object.keys(policyDocument)?.forEach((item: string) => {
                handleDownload(policyDocument[item])();
            });
    };

    return (
        <Dialog
            PaperProps={{ sx: { width: 540 } }}
            open={open || false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <ListItem>
                <ListItemText
                    primary={<Typography variant="h6">{translate(dialogTitle ?? 'download_documents_header_label')}</Typography>}
                    secondary={
                        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                            {translate(dialogSubtitle ?? 'download_documents_description')}
                        </Typography>
                    }
                />
                <IconButton onClick={onHandleHeaderAction}>
                    <CloseIcon />
                </IconButton>
            </ListItem>
            <Divider />
            <DialogContent>
                {policyDocument && Object.keys(policyDocument).length > 0 ? (
                    <List
                        style={{ padding: 0 }}
                        sx={{
                            '& 	.MuiListItem-secondaryAction': {
                                right: '0px !important',
                            },
                        }}
                    >
                        {Object.keys(policyDocument)?.map((item: string) => (
                            <ListItem divider={Object.keys(policyDocument).length > 1} disablePadding key={item}>
                                <ListItemText
                                    primary={
                                        <Typography variant="caption" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                                            {listItemTitle}
                                        </Typography>
                                    }
                                    secondary={<Typography variant="body1">{item}</Typography>}
                                />
                                <ListItemSecondaryAction style={{ right: 0, bottom: 0 }}>
                                    <Button size="small" variant="text" color="primary" onClick={handleDownload(policyDocument[item])}>
                                        <DownloadIcon style={{ marginRight: 10 }} />
                                        {listItemActionTitle}
                                    </Button>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <NoData endTitle="" />
                )}
            </DialogContent>
            {hasDialogAction && policyDocument && Object.keys(policyDocument).length > 1 ? (
                <DialogActions>
                    <Button fullWidth variant="outlined" onClick={handleDownloadAll}>
                        {actionTitle}
                    </Button>
                </DialogActions>
            ) : null}
        </Dialog>
    );
};

DocumentAlert.defaultProps = {
    actionTitle: '',
    dialogSubtitle: 'download_documents_description',
    dialogTitle: 'download_documents_header_label',
    policyDocument: undefined,
    handleClose: undefined,
    handleHeaderAction: undefined,
    hasDialogAction: false,
    listItemActionTitle: translate('download_button_label'),
    listItemTitle: translate('file_name_label'),
    open: false,
};

export default DocumentAlert;
