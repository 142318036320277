/* eslint-disable no-shadow */
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { create, InstanceProps } from 'react-modal-promise';
import { useState } from 'react';
import { BaseBusinessPartner } from '../../interfaces/api';
import useConfig, { DuplicateCheckClient } from './config';

const BP_DASHBOARD_PATH = '/dashboard/partners';

export enum DuplicateFailureCode {
    NO_BP_SELECTED = 'No BP selected',
}

export enum DuplicateSuccessCode {
    NEW_POLICYHOLDER = 'New policyholder',
}

interface DuplicateCheckProps<T extends BaseBusinessPartner> extends InstanceProps<any, any> {
    open: boolean;
    email: string;
    partners: T[];
    isBlocked: boolean;
    client?: DuplicateCheckClient;
    isCreateNewPolicyholderDisabled?: boolean;
}

const DuplicateCheck = <T extends BaseBusinessPartner>({
    open,
    email,
    partners,
    isBlocked = false,
    client = 'default',
    onResolve,
    onReject,
    isCreateNewPolicyholderDisabled = false,
}: DuplicateCheckProps<T>) => {
    const [selectedBP, setSelectedBP] = useState<string>();
    const { colors, styles, messages } = useConfig(client);

    const clear = () => {
        setSelectedBP(undefined);
        onReject(DuplicateFailureCode.NO_BP_SELECTED);
    };

    const onSubmit = () => {
        if (!selectedBP) {
            clear();
        }
        onResolve(partners.find((e) => e.self?.id === selectedBP));
    };

    return (
        <Dialog {...styles.Dialog} open={open} onClose={clear}>
            <DialogTitle {...styles.DialogTitle}>{messages.dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!isBlocked ? (
                        <>
                            <p style={{ color: 'black' }}>{messages.emailExists(email, colors.highlight)}</p>
                            <Box>
                                <FormControl>
                                    <RadioGroup name="dupe-check" onChange={(e) => setSelectedBP(e.target.value)}>
                                        {partners.map((e) => (
                                            <FormControlLabel
                                                key={e.self?.id}
                                                control={<Radio />}
                                                value={e.self?.id}
                                                label={
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <a href={`${BP_DASHBOARD_PATH}/${e.self?.id}`} target="_blank" rel="noopener noreferrer">
                                                            <span style={{ marginRight: '8px', color: colors.highlight }}>{e.number}</span>
                                                        </a>
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{e.fullName}</span>
                                                    </Box>
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            {isCreateNewPolicyholderDisabled ? null : (
                                <Box sx={{ borderTop: '1px solid #E0E0E0', width: '100%' }}>
                                    <Button
                                        onClick={() => onResolve(DuplicateSuccessCode.NEW_POLICYHOLDER)}
                                        variant="text"
                                        sx={{ color: colors.highlight, marginTop: '10px' }}
                                    >
                                        {messages.createNewPolicyholder}
                                    </Button>
                                </Box>
                            )}
                        </>
                    ) : (
                        <p>{messages.userBlocked}</p>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions {...styles.DialogActions}>
                {!isBlocked ? (
                    <>
                        <Button sx={{ color: colors.highlight }} variant="text" onClick={() => clear()}>
                            Cancel
                        </Button>
                        <Button sx={{ background: colors.highlight }} variant="contained" onClick={onSubmit}>
                            Proceed
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" onClick={() => clear()}>
                        Back
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
DuplicateCheck.defaultProps = {
    client: 'default' as DuplicateCheckClient,
    isCreateNewPolicyholderDisabled: false,
};

export default create(DuplicateCheck);
