import { Alert, AlertTitle } from '@mui/material';
import translate from 'i18n/messages/translate';
import React from 'react';

const AccountCreationAlert = () => (
    <Alert sx={{ width: { xs: '90%', md: '95%' }, mx: { xs: 'auto' }, mb: { xs: 2, md: 4 } }} severity="info">
        <AlertTitle>{translate('enrollment_alert_header')}</AlertTitle>
        {translate('enrollment_alert_description')}
    </Alert>
);

export default AccountCreationAlert;
