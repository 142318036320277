/* eslint-disable prettier/prettier */
import LOCALES from '../locales';

export default {
    [LOCALES.ITALY]: {
        // navbar
        menu: 'Menù',
        accounts: 'Profilo',
        policies: 'Polizze',
        profile: 'Profili',
        devices: 'Dispositivi',
        languages: 'Le lingue',
        english: 'English',
        italian: 'Italiano',
        deutsch: 'Deutsch',
        signout: 'Esci',
        login: 'Login',
        logout: 'Esci',
        manage_account: 'Gestisci conto',

        // footer
        about_tigerlab:
            'tigerlab è il fornitore leader di soluzioni software assicurative e assicurazioni integrate. Il nostro software modulare consente ai clienti di implementare rapidamente, estendere e modificare facilmente le applicazioni per soddisfare le esigenze aziendali strategiche. Per oltre 10 anni ci siamo concentrati su ciò che rende le aziende più preziose e abbiamo collaborato con i nostri numerosi clienti per aiutarli a raggiungere risultati aziendali rivoluzionari.',
        copyright_message:
            'tigerlab GmbH è un intermediario registrato, un agente assicurativo con autorizzazione ai sensi del § 34 d par. 1 GewO [Normativa commerciale tedesca].',
        privacy_policy: 'Informativa sulla Privacy',
        term_of_use: 'Termini di utilizzo',
        legal_notice: 'Avviso legale',
        company: 'Azienda',

        // get quote page
        get_a_quote: 'Ottieni preventivo',
        get_quote_description_v2: 'Aggiungi il tuo dispositivo e ottieni la copertura annuale. Puoi aggiungere più dispositivi.',
        get_quote_description:
            'Richiedi un preventivo per la copertura annuale inserendo il numero di serie del tuo processore e premendo "INSERISCI".  Puoi aggiungere altri dispositivi seguendo la stessa modalità. Se vuoi procedere alla sottoscrizione di una polizza, effettua la registrazione. ',
        serial_number: 'Numero di serie',
        add: 'Inserisci',
        cochlear_device_insurance: 'Protezione del dispositivo Cochlear',
        enroll_now: 'Iscriviti ora',
        your_quote: 'la tua quotazione',
        remove: 'Rimuovere',
        total_premium_label: 'Premio totale',
        total_premium_description: 'Il premio totale è al netto delle tasse',
        protection_plan: 'Piano di protezione',
        total: 'Totale',
        image_header: 'Crediamo nella semplificazione assicurativa.',
        image_description: "Un'assicurazione semplice e conveniente su misura per le tue esigenze.",
        captcha_verification: 'Verifica CAPTCHA fallita!',
        error_similar_device: 'Impossibile aggiungere un dispositivo con un numero di serie simile',

        // enter email page
        back: 'Indietro',
        enter_email: 'Inserisci il tuo indirizzo email',
        enter_email_description: 'Inserisci il tuo indirizzo email qui sotto per continuare il processo di registrazione.',
        email_address_label: 'Indirizzo e-mail',
        continue: 'Continua',
        login_title: "Continua con l'e-mail",
        login_description: 'Inserisci il tuo indirizzo email per ricevere una password monouso.',
        adjust_quote: 'Modifica preventivo',
        email: 'Email',
        login_disclaimer:
            'Continuando, accetti che creiamo un account per te (se non è già stato creato) e accetti i nostri Termini e {terms_and_condition} {privacy_policy}',
        term_and_condition: 'Termini e Condizioni',

        // otp page
        enter_otp: 'Immettere password monouso',
        enter_otp_description: 'Una password monouso (OTP) è stata inviata alla tua e-mail',
        otp_verification_header: 'Verifica OTP',
        otp_verification_description: "Inserisci la password monouso dall'e-mail che hai ricevuto.",
        edit_email: 'Modifica e-mail',

        // enrollment page
        enrollment_label: 'Iscrizione',
        enrollment_alert_header: 'Creazione account',
        enrollment_alert_description: `Il tuo account verrà creato con l'indirizzo email fornito nella sezione dei dati personali`,
        personal_details_label: 'Dati personali',
        purchase_entity_label: 'Sto acquistando per conto di...',
        myself_label: 'Me stesso',
        someone_else_label: 'Qualcun altro',
        same_owner_checkbox_label: 'Tutti i dispositivi appartengono allo stesso proprietario',
        first_name_label: 'Nome di battesimo',
        last_name_label: 'Cognome',
        id_no_label: 'Codice fiscale del contraente',
        dob_label: 'Data di nascita',
        confirm_email_label: `Conferma l'indirizzo e-mail`,
        address_label: 'Indirizzo',
        zip_label: 'Cap',
        city_label: 'Città',
        state_label: 'Provincia',
        country_label: 'Paese',
        enrollment_practitioner_center_label: `Saputo da`,
        location_label: 'Posizione',

        guardian_details_label: 'Dettagli Tutore/Parente',
        guardian_first_name_label: 'Nome del tutore/parente',
        guardian_last_name_label: 'Cognome Tutore/Parente',
        device_owner_details_label: 'Dettagli del proprietario del dispositivo',
        patient_first_name_label: 'Nome paziente',
        patient_last_name_label: 'Cognome paziente',
        patient_id_label: 'Numero ID paziente',
        device_purchase_place_label: 'Come sei venuto a conoscenza dell’Assicurazione',

        your_protection_policy_label: 'La tua polizza di protezione assicurativa',
        coverage_period_label: 'Periodo di copertura',
        tax_label: 'Imposta',
        transaction_fee_label: 'Costo della transazione',
        add_device_label: 'Aggiungi dispositivo',
        cancel: 'Annulla',
        confirm: 'Confermare',

        where_hear_insurance_label: 'Come sei venuto a conoscenza dell’Assicurazione',
        device_purchase_acknowledgement: `Dichiaro che tutti i dispositivi assicurati sono
        stati acquistati o sono stati applicati dalla clinica
         negli ultimi 60 giorni e che in caso contrario la
        polizza assicurativa non coprirà danni, furti o
        smarrimenti`,
        read_docs_acknowledgement: `Ho letto attentamente i {document_link} prima di procedere all'acquisto della polizza assicurativa. Sono consapevole che la copia cartacea del documento informativo sarà
        sempre a mia disposizione su richiesta.`,
        continue_to_payment_label: 'Continua con il pagamento',

        // checkout page
        checkout_label: `Procedi all'ordine`,
        payment_details_label: 'Dettagli di pagamento',
        card_number_label: 'Numero di carta',
        expiry_date_label: 'Data di scadenza',
        cvv_cvc_label: 'Codice verifica della carta',
        name_on_card_label: 'Intestatario della carta',
        same_billing_address_label: 'Indirizzo di fatturazione uguale a quello delle informazioni personali',
        billing_address_details_label: `Dettagli dell'indirizzo di fatturazione`,
        owner_label: 'Proprietario',
        place_order_label: 'Invia ordine',
        checkout_acknowledgement_first_line: `Ho letto, compreso, accettato e scaricato sul
        mio computer i documenti informativi:`,
        ipid_label: 'IDIP',
        additional_ipid_label: 'IDIP Aggiuntivo',
        policy_terms_conditions_label: 'Termini e condizioni della pratica assicurativa',
        privacy_notice_label: 'Informativa sulla Privacy',
        annexes_label: 'Allegati 3, 4 e 4 ter',
        checkout_acknowledgement_last_line: `Accetto i termini e le condizioni di polizza della
        copertura assicurativa emessa da AIG Europe
        S.A. - Rappresentanza Generale per I'Italia e
        accetto di acquistare la Processore Cochlear
        Polizza assicurativa`,

        // dashboard page
        active_tab_label: 'Attivo',
        inactive_tab_label: 'Non attivo',
        claim_now_button_label: 'Apri Reclamo',
        policy_number_label: 'Numero di polizza',
        insured_item_label: 'Articolo assicurato',
        client_name_label: 'Nome del cliente',
        view_docs_button_label: 'Visualizza documenti',
        claim_history_button_label: 'Cronologia dei rimborsi effettuati',
        add_new_device_button_label: `Aggiungi un nuovo dispositivo`,
        terms_conditions_label: 'Condizione di Assicurazione',
        policy_document: 'documento di politica',

        // claim creation page
        hearing_aid_header_label: 'Apparecchio acustico',
        claim_reason_label: `Qual è il motivo della richiesta di indennizzo?`,
        damaged_label: 'Danneggiamento',
        theft_label: 'Furto',
        lost_label: 'Persa',
        incident_occurence_date_question_label: `Quando si verifica l'incidente?`,
        incident_description_question_label: `Descrivi l'incidente...`,
        incident_occurence_date_label: `Data dell'incidente`,
        attachment_question_label:
            'Inserisci i documenti necessari riportati qui sotto per effettuare la tua richiesta. In mancanza di tali documenti la pratica potrà essere rigettata. La Compagnia di assicurazioni si riserva di chiedere maggiori informazioni in caso di necessità',
        attachment_alert_header_label: `Per presentare un reclamo  assicurati di aver caricato i seguenti
        documenti:`,
        attachment_alert_police_report_label: `(i) Denuncia alla polizia dell'oggetto rubato o smarrito (obbligatoria in caso
            di furto o smarrimento)`,
        attachment_alert_purchase_invoice_label: `(ii) Fattura di acquisto del dispositivo o prova di registrazione reperibile da
        Cochlear italia 051/6015330 - cochlearitalia@cochlear.com (a comprova
        dell'avvenuta stipula della polizza entro 60 gg dalla acquisizione o
        attivazione del dispositivo)`,
        attachment_alert_self_declaration: `(iii) autodichiarazione descrittiva dell'accaduto (in caso di
            danno accidentale)`,
        attachment_alert_images: `(iv) foto, immagini del dispositivo danneggiato
            evidenziando il danno (in caso di danno accidentale)`,
        drag_drop_instruction_header: 'Trascina qui le tue foto, video o documenti per caricare',
        drag_drop_instruction_details_line_one: `Puoi caricare immagini JPG, PNG, video MP4 o file PDF fino a 200 MB.`,
        drag_drop_instruction_details_line_two: 'Assicurati che ogni tuo allegato video sia inferiore a 10 MB.',
        browse_file_button_label: 'Sfoglia i file',
        repair_center_question_label: 'Seleziona il Centro ',
        claim_repair_center_label: 'Centro assistenza',
        video_size_too_large_header: 'La dimensione del file è troppo grande',
        video_size_too_large_message: 'La dimensione del file per il video deve essere inferiore a 10 MB',
        or_label: 'O',

        policy_details_card_header_label: 'Dettagli della Polizza',
        full_name_label: 'Nome e cognome',

        // claim review page
        back_to_claim_form_button_label: 'Torna alla pagina Indennizzo',
        review_submit_header_label: 'Rivedi i dati e invia',
        claim_info_header_label: 'Informazioni sulla pratica di indennizzo',
        claim_date_application_header_label: `Data dell'invio richiesta di indennizzo`,
        claim_review_accident_date: `Data dell'avvenuto incidente`,
        damaged_stolen_item_name_header_label: 'Nome del prodotto danneggiato/rubato:',
        item_serial_number_header_label: 'Numero seriale del prodotto:',
        claim_reason_header_label: `Motivo dell'indennizzo:`,
        incident_description_header_label: `Descrizione dell'accaduto:`,
        practitioner_store_selected_header_label: 'Centro assistenza:',
        edit_button_label: 'Scrivi',
        attachments_header_label: 'Allegati',
        delete_button_label: 'Elimina',

        // claim history page
        back_to_policies_button_label: 'Torna alle polizze',
        claim_history_header_label: 'Ecco la cronologia dei sinistri',
        claim_date_column_header: 'Data del reclamo',
        incident_date_column_header: 'Data incidente',
        insurance_product_name_column_header: 'Nome del prodotto assicurativo',
        claim_number_column_header: 'Numero di reclamo',
        item_column_header: 'Articolo',
        status_column_header: 'Provincia',
        type_of_notification_column_header: 'Tipo di notifica',
        next_button_label: 'Prossima',

        // account setting page
        account_header_label: 'ACCOUNT',
        account_settings_header_label: `Le mie impostazioni account`,
        brand_label: 'Marca',
        model_label: 'Modella',
        colour_label: 'Colore',
        patient_label: 'Paziente',

        // mobile extra details
        learn_more_button_label: 'Scopri di più',

        // HTTP service error message
        ineligible_device: 'Questo dispositivo non è idoneo per la copertura',
        get_all_policies: 'Recupero di tutte le polizze non riuscito',
        get_targeted_policy: 'Impossibile recuperare la politica richiesta',
        delete_targeted_policy: 'Annullamento della norma mirata non riuscito',
        get_targeted_policy_version: 'Impossibile recuperare la versione del criterio',
        get_targeted_incomplete_order: `Impossibile recuperare l'ordine incompleto mirato`,
        get_targeted_policy_version_item: `Impossibile recuperare l'elemento della versione del criterio`,
        get_targeted_business_partner: 'Impossibile recuperare il partner commerciale',
        get_business_partner_address: `Recupero dell'indirizzo del business partner non riuscito`,
        post_business_partner: 'Impossibile creare un business partner',
        post_business_partner_address: `Impossibile creare l'indirizzo del partner commerciale`,
        post_policy_version_item: `Impossibile creare l'elemento della versione dei criteri`,
        post_incomplete_order: `Impossibile creare l'ordine incompleto`,
        post_business_partner_relation_type: 'Impossibile creare il tipo di relazione business partner',
        post_business_partner_relation: `Impossibile creare la relazione con il partner commerciale`,
        rate_quotation: 'Impossibile valutare la quotazione',
        get_all_claims: 'Impossibile recuperare tutte le attestazioni',
        create_claim: 'Impossibile creare la rivendicazione',
        get_otp: `Impossibile richiedere l'OTP`,
        verify_otp: `Impossibile verificare l'OTP`,
        create_file_attachment: 'Impossibile allegare i file',
        create_manual_refund: 'Impossibile creare il rimborso manuale',
        get_policy_docs: `Impossibile ottenere i documenti della politica`,
        get_device_quote: 'Nessun dettaglio del dispositivo è disponibile',
        create_payment_intent: `Impossibile creare l'intento di pagamento`,
        confirm_payment_intent: `L'intenzione di pagamento non è stata confermata`,
        create_offline_payment: 'Impossibile creare il pagamento offline',
        get_practitioner_locations: 'Impossibile recuperare le posizioni',

        something_wrong: 'Qualcosa è andato storto',

        // pop-up messages from snack bar
        no_uuid: 'Nessun UUID trovato',
        duplicated_card_device: 'Dispositivo duplicato aggiunto nella scheda',
        duplicated_modal_device: 'Dispositivo duplicato aggiunto in modale',
        unregistered_email: 'La tua email non è registrata',
        successful_otp_verification: 'Token verificato correttamente',

        // yup validation error message
        valid_email_required: `Deve essere un'e-mail valida`,
        required_field: 'Questo campo è obbligatorio',
        required_checked_field: 'Il campo deve essere controllato',
        mismatch_email: `L'indirizzo email inserito non corrisponde`,
        european_date: 'È previsto il formato della data europea gg/mm/aaaa',
        required_card_holder_name: 'Si prega di fornire il Codice fiscale del contraente',
        incident_details_required: `Si prega di fornire maggiori informazioni sull'incidente`,
        police_report_invoice_required:
            'Il rapporto di polizia e la fattura sono richiesti quando la denuncia viene presentata su un dispositivo rubato',
        minimum_one_file_required: 'È necessario almeno un file',
        minimum_age: "L'età dovrebbe essere di 18 anni o più",
        fiscale_id: '16 caratteri richiesti LLLLLLNNLNNLNNNL dove L sta per "lettera" e N per "numero"',

        // pop up messages in modal
        successful_payment_label: 'Pagamento riuscito',
        successful_payment_description: `Il tuo pagamento è andato a buon fine! Stiamo generando la tua polizza, si prega di attendere poiché l'operazione potrebbe richiedere alcuni minuti...`,
        okay_label: 'BENE',

        failed_payment_label: 'Pagamento fallito',
        failed_payment_description: 'Riprova aggiungendo la nuova carta di pagamento.',
        close_try_label: 'Chiudi e riprova',

        cancel_policy_label: 'Sei sicuro di cancellare questa polizza?',
        cancel_policy_description: `Se la polizza viene annullata entro 14 giorni dall'acquisto, verrà effettuato il rimborso completo`,
        close_label: 'Indietro',
        cancel_policy_button_label: 'Annulla la pratica',

        download_documents_header_label: 'Scarica documenti',
        download_all_documents_button_label: 'scarica tutto',
        download_documents_description: 'Di seguito i documenti associati alla polizza',
        no_data_label: 'Nessun dato ancora',
        no_data_description: 'Sembra che non abbiamo dati da visualizzare qui.',
        file_name_label: 'Nome File',
        certificate_of_insurance_label: 'Certificato Assicurativo',
        invoice_label: 'Fattura',
        download_button_label: 'Scarica',

        no_attachment_label: 'Nessun allegato ancora',
        no_attachment_description: 'Sembra che tu non carichi alcun allegato',

        success_claim_creation_header_label: 'La tua richiesta è stata inoltrata con successo',
        success_claim_creation_description: 'Puoi visualizzare lo stato della tua pratica ora o richiedere un nuovo reclamo',
        view_status_button_label: 'Visualizza lo stato',
        new_claim_button_label: 'Nouva Richiesta',

        claim_approved_header_line_one: 'Congratulazioni!',
        claim_approved_header_line_two: 'La tua richiesta è stata approvata!',

        attachment_upload_inprogress_header: 'Quasi lì...',
        attachment_upload_inprogress_description: 'Attendi mentre stiamo elaborando la tua richiesta.',

        failed_claim_creation_header: 'Oh no!',
        failed_claim_creation_description: 'Le tue richieste non sono state presentate. Si prega di contattare il nostro team reclami:',
        try_again_button_label: 'Riprova',
    },
};
