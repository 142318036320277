import { Box, FormHelperText, SxProps } from '@mui/material';
import React, { FC, ReactNode, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

interface StripeComponentWrapperProps {
    label: string;
    isFocused?: boolean;
    isEmpty?: boolean;
    errorMessage?: string;
    customisedContainerStyle?: SxProps;
    children: ReactNode;
}
const StripeComponentWrapper: FC<StripeComponentWrapperProps> = ({ children, isFocused, isEmpty, label, customisedContainerStyle, errorMessage }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });

    const wrapperStyle = useMemo(() => {
        if (isFocused && errorMessage) {
            return {
                border: 'solid 2px #e53935',
            };
        }
        if (isFocused) {
            return {
                border: 'solid 2px #3f51b5',
            };
        }
        if (errorMessage) {
            return {
                border: 'solid 1px #e53935',
            };
        }
        return {};
    }, [isFocused, errorMessage]);
    const legendStyle = useMemo(() => {
        if (isFocused) {
            return { color: '#3f51b5' };
        }
        if (errorMessage) {
            return { color: '#e53935' };
        }
        return { color: 'rgba(0, 0, 0, 0.6)' };
    }, [isFocused, errorMessage]);
    return (
        <Box display="flex" sx={{ flexDirection: 'column', my: 1, ...customisedContainerStyle }}>
            <fieldset
                style={{
                    maxWidth: isBigScreen ? '26vw' : '100vw',
                    marginBottom: '1rem',
                    border: 'solid 1px #bdbdbd',
                    borderRadius: '4px',
                    padding: '15px 10px',
                    margin: '0 20px 0 0',
                    ...wrapperStyle,
                }}
            >
                {(isFocused || !isEmpty) && (
                    <legend
                        style={{
                            fontSize: '12px',
                            marginBottom: '-5px',
                            ...legendStyle,
                        }}
                    >
                        {label}
                    </legend>
                )}
                {children}
            </fieldset>
            {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Box>
    );
};

export default StripeComponentWrapper;
StripeComponentWrapper.defaultProps = {
    isFocused: false,
    isEmpty: false,
    errorMessage: '',
    customisedContainerStyle: undefined,
};
