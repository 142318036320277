import React, { ChangeEvent, FocusEvent } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SelectInput from 'components/customInput/SelectInput';
import { listInputProps } from 'interfaces/profile';

interface formValueProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface TextfieldWithSelectProps {
    item?: listInputProps;
    formValue?: formValueProps;
    handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectForm?: any;
}

const TextfieldWithSelect = ({ item, formValue, handleBlur, handleChange, selectForm }: TextfieldWithSelectProps) => {
    let endAdornmentValue = item?.endAdornment?.label;
    if (item?.endAdornment?.type === 'refer-value') {
        endAdornmentValue = formValue && item.endAdornment?.useState ? formValue[item.endAdornment.useState] : '';
    }

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'row' }}>
            <TextField
                inputProps={{
                    autoComplete: 'off',
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                    endAdornment: <InputAdornment position="end">{endAdornmentValue}</InputAdornment>,
                    inputProps: { min: item?.minNumber || 0 },
                    style: { borderRadius: '4px 0px 0px 4px' },
                }}
                id={item?.useState}
                label={item?.placeholder}
                name={item?.useState}
                onBlur={handleBlur}
                onChange={handleChange}
                style={item?.style}
                type={item?.type}
                value={formValue && item?.useState ? formValue[item.useState] : ''}
                variant={item?.variant}
            />
            <SelectInput
                InputProps={{
                    style: { borderRadius: '0px 4px 4px 0px' },
                }}
                formValue={formValue}
                handleBlur={handleBlur}
                handleChange={handleChange}
                item={item?.select}
                selectForm={selectForm}
            />
        </div>
    );
};

TextfieldWithSelect.defaultProps = {
    item: undefined,
    formValue: undefined,
    handleBlur: undefined,
    handleChange: undefined,
    selectForm: undefined,
};

export default TextfieldWithSelect;
