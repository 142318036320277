import { claimFormInitValues } from 'constant/claims';
import { ClaimFormValues } from 'interfaces/claim';
import React, { createContext, useState, useCallback, useContext } from 'react';
import withClaimContext from 'utils/withClaimContext';

const claimContext = createContext(claimFormInitValues);
const updateClaimContext = createContext<(newClaimDetails: ClaimFormValues) => void>({} as (newClaimDetails: ClaimFormValues) => void);

export const useClaim = () => useContext(claimContext);
export const useClaimUpdate = () => useContext(updateClaimContext);

interface useClaimContextProps {
    children: React.ReactNode;
}

export const useClaimContext = ({ children }: useClaimContextProps) => {
    const [claimDetails, setClaimDetails] = useState<ClaimFormValues>(claimFormInitValues);

    const updateClaimDetails = useCallback((newClaimDetails: ClaimFormValues) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setClaimDetails((prev: any) => ({ ...prev, ...newClaimDetails }));
    }, []);

    return (
        <claimContext.Provider value={claimDetails}>
            <updateClaimContext.Provider value={updateClaimDetails}>{children}</updateClaimContext.Provider>
        </claimContext.Provider>
    );
};

export const ClaimContext = withClaimContext(useClaimContext);
