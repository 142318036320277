import React, { ComponentType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useUser from 'hook/useUser';
import useVerifiedUser from 'v2/hooks/useVerifiedUser';

const enrollmentValidation = <P extends object>(WrappedComponent: ComponentType<P>) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function withEnrollmentValidation(componentProps: P | any) {
        const verifiedUser = useVerifiedUser();
        const { state } = useLocation();
        useUser({ withClearStorage: false });
        // const nonValidatePath = submissionEmailAddress ? '/enrollment' : '/quote';

        return verifiedUser.emailAddress || state?.token ? (
            <WrappedComponent {...componentProps}>{componentProps.children}</WrappedComponent>
        ) : (
            <Navigate replace to="/quote" />
        );
    };

export default enrollmentValidation;
