import { Checkbox, Box, Divider } from '@mui/material';
import { Field, FieldProps } from 'formik';
import React from 'react';
import DisclaimerContent from './DisclaimerContent';

const DisclaimerComponent = () => (
    <Field name="checkoutDisclaimer">
        {({ field }: FieldProps) => (
            <>
                <Divider sx={{ width: '100%', pt: 1, pb: 2, mb: 3 }} />
                <Box display="flex">
                    <Box sx={{ py: 1.5 }}>
                        <Checkbox checked={field.value} {...field} sx={{ alignItems: 'flex-start' }} />
                    </Box>
                    <DisclaimerContent />
                </Box>
            </>
        )}
    </Field>
);

export default DisclaimerComponent;
