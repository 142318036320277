/* eslint-disable import/no-duplicates */
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useLanguage } from 'context/LanguageContext';
import { format } from 'date-fns';
import it from 'date-fns/esm/locale/it/index';
import { enUS } from 'date-fns/locale';
import React from 'react';
import { dataProps, statusColorProps } from 'views/ClaimHistory';

interface TableContentProps {
    data?: dataProps[];
    statusLabel?: string;
    statusColor?: statusColorProps;
    defaultStatusColor?: string;
}

const TableContent = ({ data, statusLabel, statusColor, defaultStatusColor }: TableContentProps) => {
    const locale = useLanguage();
    return (
        <TableBody>
            {data && data?.length > 0
                ? data?.map((item: dataProps) => (
                      <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          {Object.keys(item).map((param: string, key: number) => {
                              const textAlign = key === Object.keys(item).length - 1 ? 'right' : 'left';
                              const chipColor = statusColor ? statusColor[item.status] : defaultStatusColor || 'gainsboro';

                              let renderComponent = <span>{item[param] || '-'}</span>;
                              if (param === 'id') {
                                  return null;
                              }
                              if (param === 'status' && item[param]) {
                                  renderComponent = (
                                      <Chip
                                          label={statusLabel || item[param]}
                                          variant="outlined"
                                          style={{
                                              borderColor: chipColor,
                                              color: chipColor,
                                          }}
                                      />
                                  );
                              }
                              if (param === 'formatIncidentDate' || param === 'date') {
                                  renderComponent = (
                                      <span>{format(new Date(item[param]), 'eee MMM dd yyyy', { locale: locale === 'it-it' ? it : enUS })}</span>
                                  );
                              }
                              return (
                                  <TableCell align={textAlign} key={param} component="th" scope="row" style={{ width: '200px', padding: '16px 4px' }}>
                                      {renderComponent}
                                  </TableCell>
                              );
                          })}
                      </TableRow>
                  ))
                : null}
        </TableBody>
    );
};

TableContent.defaultProps = {
    data: [],
    statusLabel: undefined,
    defaultStatusColor: 'gainsboro',
    statusColor: undefined,
};

export default TableContent;
