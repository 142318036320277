import { Box, Button, Card, Grid, SxProps } from '@mui/material';
import SearchDeviceModal from 'components/SearchDeviceModal';
import React, { FC, useState } from 'react';
import CustomisedCardHeader from 'views/enrollment/cardComponent/CustomisedCardHeader';
import PricingDetails from 'views/enrollment/cardComponent/PricingDetails';
import InteractiveCustomisedCardBody from 'views/enrollment/cardComponent/InteractiveCustomisedCardBody';
import storageHandler, { productStorage } from 'utils/storageHandler';
import extractDeviceDetails from 'utils/extractDeviceDetails';
import { listOfQuoteProps } from 'interfaces/quote';
import { DeviceInfo, PatientDetailsInterface } from 'interfaces/enrollment';
import { useField } from 'formik';
import { getDevicesQuote, getServiceRatingPricing } from 'services/httpService';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import translate from 'i18n/messages/translate';

interface InteractiveDeviceCardProps {
    isDeviceRemovable?: boolean;
    isDeviceAddable?: boolean;
    rootStyle?: SxProps;
    cardStyle?: SxProps;
}
const InteractiveDeviceCard: FC<InteractiveDeviceCardProps> = ({ isDeviceAddable, isDeviceRemovable, rootStyle, cardStyle }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { value: patientValues } = useField<PatientDetailsInterface[]>('patients')[0];
    const { setValue: setPatientsValue } = useField('patients')[2];
    const [addedDevicesArr, setAddedDevicesArr] = useState<DeviceInfo[]>([]);
    const [listSerialNumber, setListSerialNumber] = useState(productStorage()?.listSerialNumber);
    const [storedDeviceArr, setStoredDeviceArr] = useState(extractDeviceDetails(productStorage()?.listQuote as listOfQuoteProps));
    const [addingDevice, setAddingDevice] = useState<boolean>(false);
    const [removingDevice, setRemovingDevice] = useState<boolean>(false);
    const [confirmingModalUpdate, setConfirmingModalUpdate] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    const handleModalOpen = () => {
        setIsModalOpen(true);
    };
    const handleAddButtonClick = async (serialNumberInput: string) => {
        try {
            setAddingDevice(true);
            const quoteResult = await getDevicesQuote([serialNumberInput]);
            const existingDeviceSerialArrInModal = addedDevicesArr.map((device) => Object.keys(device)).flat();
            const existingDeviceSerialArrInEnrolment = storedDeviceArr.map((device) => Object.keys(device)).flat();
            if (existingDeviceSerialArrInModal.includes(serialNumberInput)) {
                setErrorMessage(formattedMessages.duplicated_modal_device);
                setAddingDevice(false);
                return;
            }
            if (existingDeviceSerialArrInEnrolment.includes(serialNumberInput)) {
                setErrorMessage(formattedMessages.duplicated_card_device);
                setAddingDevice(false);
                return;
            }
            if (quoteResult) {
                setAddedDevicesArr((prev) => [...prev, ...extractDeviceDetails(quoteResult)]);
                setAddingDevice(false);
                setErrorMessage('');
            }
        } catch {
            setAddingDevice(false);
            setErrorMessage(formattedMessages.get_device_quote);
        }
    };

    const handleConfirmButton = async () => {
        setConfirmingModalUpdate(true);
        const latestlistSerialNumber = [...(listSerialNumber ?? []), ...addedDevicesArr.map((item) => Object.keys(item)[0])];
        storageHandler('listSerialNumber', latestlistSerialNumber);
        setListSerialNumber(latestlistSerialNumber);
        const serviceRatingPricing = await getServiceRatingPricing(latestlistSerialNumber);
        if (serviceRatingPricing) {
            storageHandler('listQuote', serviceRatingPricing);
            setStoredDeviceArr(extractDeviceDetails(serviceRatingPricing));
        }

        // ensure same device is not added twice
        const existingDeviceSerialArr = storedDeviceArr.map((device) => Object.keys(device)).flat();
        const uniqueDeviceArr = addedDevicesArr.filter((device) => !existingDeviceSerialArr.includes(Object.keys(device)[0]));
        const newAddedPatientsArr = uniqueDeviceArr.map((device) => ({ firstName: '', lastName: '', id: '', device }));
        setPatientsValue([...patientValues, ...newAddedPatientsArr]);
        setAddedDevicesArr([]);
        setIsModalOpen(false);
        setConfirmingModalUpdate(false);
    };

    const handleRemoveDeviceInModal = (input: string) => {
        setAddedDevicesArr((prev) =>
            prev.filter((item) => {
                const existingSerialNumber = Object.keys(item)[0];
                return existingSerialNumber !== input;
            })
        );
    };

    const handleRemoveDeviceInCard = async (input: string) => {
        setRemovingDevice(true);
        const newPatientsArr = patientValues.filter((patient) => Object.keys(patient.device)[0] !== input);
        setPatientsValue(newPatientsArr);
        const latestSerialNumberArr = listSerialNumber?.filter((serial) => serial !== input) ?? [];
        const serviceRatingPricing = await getServiceRatingPricing(latestSerialNumberArr);
        storageHandler('listSerialNumber', latestSerialNumberArr);
        storageHandler('listQuote', serviceRatingPricing);
        setStoredDeviceArr(extractDeviceDetails(serviceRatingPricing));
        setListSerialNumber(latestSerialNumberArr);
        setRemovingDevice(false);
    };
    return (
        <Grid sx={{ mt: { xs: 2, md: '40px' }, mb: 5, ...rootStyle }} item xs={12} lg={5}>
            <Card sx={{ minWidth: 275, mx: { xs: 0, md: 2 }, border: '1px solid rgba(0, 0, 0, 0.12)', ...cardStyle }} elevation={0}>
                <CustomisedCardHeader />

                {isDeviceAddable && (
                    <SearchDeviceModal
                        isOpen={isModalOpen}
                        addingDevice={addingDevice}
                        confirmingModalUpdate={confirmingModalUpdate}
                        errorMessage={errorMessage}
                        deviceArr={addedDevicesArr}
                        handleClose={handleModalClose}
                        handleAddButtonClick={handleAddButtonClick}
                        handleConfirmButton={handleConfirmButton}
                        handleRemoveDevice={handleRemoveDeviceInModal}
                    />
                )}
                {isDeviceAddable && isDeviceRemovable && (
                    <InteractiveCustomisedCardBody
                        deviceArr={storedDeviceArr}
                        removingDevice={removingDevice}
                        isDeviceRemovable={isDeviceRemovable}
                        handleRemoveDevice={handleRemoveDeviceInCard}
                    />
                )}
                <Box display="flex" justifyContent="flex-end" sx={{ px: 2 }} style={{ marginBottom: 10 }}>
                    <Button
                        variant="text"
                        sx={{
                            alignSelf: 'flex-end',
                            textTransform: 'none',
                            textDecoration: 'underline',
                            display: isDeviceAddable ? '' : 'none',
                            '&:hover': {
                                textDecoration: 'underline',
                            },
                        }}
                        onClick={handleModalOpen}
                    >
                        {translate('add_device_label')}
                    </Button>
                </Box>

                <PricingDetails deviceArr={storedDeviceArr} />
            </Card>
        </Grid>
    );
};

export default InteractiveDeviceCard;

InteractiveDeviceCard.defaultProps = {
    isDeviceAddable: true,
    isDeviceRemovable: true,
    rootStyle: {},
    cardStyle: {},
};
