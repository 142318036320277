import { listInputProps } from 'interfaces/profile';

export const profileInputListProfileName: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%' },
        placeholder: 'first_name_label',
        type: 'text',
        useState: 'firstName',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        placeholder: 'last_name_label',
        style: { width: '100%', marginLeft: '15px' },
        type: 'text',
        useState: 'lastName',
        variant: 'outlined',
    },
];

export const profileInputListProfileNameMobile: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'first_name_label',
        type: 'text',
        useState: 'firstName',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        placeholder: 'last_name_label',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        type: 'text',
        useState: 'lastName',
        variant: 'outlined',
    },
];
export const profileInputListDetail: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%' },
        placeholder: 'id_no_label',
        type: 'text',
        useState: 'idNo',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', marginLeft: '15px' },
        placeholder: 'dob_label',
        type: 'text',
        useState: 'dateOfBirth',
        variant: 'outlined',
    },
];

export const profileInputListDetailMobile: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'id_no_label',
        type: 'text',
        useState: 'idNo',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'dob_label',
        type: 'text',
        useState: 'dateOfBirth',
        variant: 'outlined',
    },
];
export const profileInputListEmail: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '48.5%' },
        placeholder: 'email_address_label',
        type: 'text',
        useState: 'emailAddress',
        variant: 'outlined',
    },
];
export const profileInputListEmailMobile: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'email_address_label',
        type: 'text',
        useState: 'emailAddress',
        variant: 'outlined',
    },
];

export const profileInputListAddress: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '0 15px 15px 0' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'address',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        style: { width: '48.5%' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'zipCode',
        variant: 'outlined',
    },
];
export const profileInputListAddressMobile: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'address_label',
        type: 'text',
        useState: 'address',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'zip_label',
        type: 'text',
        useState: 'zipCode',
        variant: 'outlined',
    },
];

export const profileInputListCity: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'city',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', marginLeft: '15px' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'state',
        variant: 'outlined',
    },
];
export const profileInputListCityMobile: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'city_label',
        type: 'text',
        useState: 'city',
        variant: 'outlined',
    },
    {
        id: 1,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 3px 5px' },
        placeholder: 'state_label',
        type: 'text',
        useState: 'state',
        variant: 'outlined',
    },
];

export const profileInputListCountry: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '48.5%' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'country',
        variant: 'outlined',
    },
];
export const profileInputListCountryMobile: listInputProps[] = [
    {
        id: 0,
        disabledInput: true,
        inputType: 'text',
        style: { width: '100%', margin: '3px 20px 30px 5px' },
        placeholder: 'country_label',
        type: 'text',
        useState: 'country',
        variant: 'outlined',
    },
];
