export interface AddressDetails {
    id: string;
    line1: string;
    line2: string;
    line3: string;
    postalCode: string;
    unitNo?: string;
    building: string;
    district: string;
    city: string;
    state: string;
    country: string;
    fullAddress: string;
    extraData: Record<any, any>;
    isMta: boolean;
}

interface Createdby {
    uuid: string;
    name: string;
}

interface ExtraData {
    key: string;
    value: string;
    key_display: string;
    value_display: string;
}

export interface V2BusinessPartner {
    uuid: string;
    number: string;
    first_name: string;
    middle_name?: any;
    nickname: string;
    last_name: string;
    name: string;
    title: number;
    email_primary: string;
    email_correspondence?: any;
    profile_picture?: any;
    company?: any;
    phone_work: string;
    phone_fax: string;
    phone_mobile: string;
    data_privacy_policy: boolean;
    preferred_communication?: any;
    gender: number;
    dob: string;
    occupation: string;
    registration_name: string;
    registration_code: string;
    category: number;
    addresses: AddressDetails[];
    policies: string[];
    quotations: any[];
    renewal_drafts: any[];
    active_relations: any[];
    pending_relations: any[];
    absolute_url: string;
    created_at: string;
    created_by: Createdby;
    updated_at: string;
    updated_by: Createdby;
    gdpr_status?: any;
    paper_document_enabled: boolean;
    extra_data: ExtraData[];
}

interface Agent {
    uuid: string;
    name: string;
    email: string;
    phone_mobile: string;
}

interface Holder {
    uuid: string;
    name: string;
    email: string;
}

interface Plan {
    uuid: string;
    name: string;
}

interface Product {
    uuid: string;
    name: string;
    currency: string;
}

interface Rootversion {
    uuid: string;
    start_date: string;
    end_date: string;
    published_at: string;
    paid_date: string;
}

interface Policyversion {
    uuid: string;
}

export interface V2IncompleteOrderInterface {
    uuid: string;
    agent: Agent;
    holder: Agent;
    product: Product;
    plan: Agent;
    number: string;
    status: string;
    reason_status_lost?: any;
    reason_status_lost_others?: any;
    lead_type: string;
    payment_scheme: string;
    payment_day?: any;
    progress: number;
    pricing_breakdown: any;
    voucher_attributes?: any;
    campaign_attributes?: any;
    extra_data?: any;
    net_amount: string;
    loading_amount: string;
    net_load_amount: string;
    gross_amount: string;
    ipt_amount: string;
    final_amount: string;
    total_payable_amount: string;
    prorated_amount: string;
    admin_fee: string;
    transaction_type: string;
    policy_version: Policyversion;
    latest_quotation_version?: any;
    current_active_version: any;
    policy: Policyversion;
    relations: any[];
    start_date: string;
    end_date?: any;
    policy_term: string;
    recurrence_scheme: string;
    headers: Headers;
    attributes: any;
    declarations: any;
    rating_payload: any;
    items_insured: any[];
    created_at: string;
    created_by: Agent;
    updated_at: string;
    updated_by: Agent;
    pdf_file?: any;
    endorsement_note: boolean;
    root_version: Rootversion;
    client_response?: any;
    follow_up_date?: any;
    reseller: Agent;
    agency: Agent;
    lead_type_key: string;
    lead_type_reason_key?: any;
    status_key: string;
    classification_key?: any;
    reason_status_lost_key?: any;
    lead_classification_value: string;
    contract: Policyversion;
}

interface Currentversion {
    uuid: string;
    parent?: any;
    position: number;
    title: string;
    relations: any[];
    start_date: string;
    end_date: string;
    policy_term: string;
    recurrence_scheme: string;
    headers: Headers;
    attributes: any;
    declarations: any;
    rating_payload: any;
    pdf_file: string;
    items_insured: any[];
    quotation: V2IncompleteOrderInterface;
    created_at: string;
    created_by: Plan;
    updated_at: string;
    updated_by: Plan;
    endorsement_note: boolean;
}

export interface V2PolicyInterface {
    uuid: string;
    agent: Agent;
    holder: Holder;
    contract: string;
    number: string;
    product: Product;
    plan: Plan;
    status: string;
    current_version: Currentversion;
    pdf_file: string;
    latest_draft?: any;
    root_version: Rootversion;
    extra_data?: any;
}

export interface PoliciesV2Response {
    count: number;
    data: V2PolicyInterface[];
    previous?: null;
    next?: null;
}
export interface QuotationV2Response {
    count: number;
    data: V2IncompleteOrderInterface[];
    previous?: null;
    next?: null;
}

export interface PersonalPartnerV2Response {
    status: number;
    data: V2BusinessPartner;
    message: string;
}
