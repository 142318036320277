import React, { useMemo, useReducer, createContext } from 'react';
import { AuthenticationCallbackProps, AuthenticationStateProps } from 'interfaces/profile';

const initialState: AuthenticationStateProps = {
    firstName: undefined,
    lastName: undefined,
    idNo: undefined,
    dateOfBirth: undefined,
    emailAddress: undefined,
    address: undefined,
    zipCode: undefined,
    city: undefined,
    state: undefined,
    country: undefined,
    devices: undefined,
    practitioner: undefined,
    location: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthenticationStore = createContext(initialState) as any;

export const AuthenticationCallback: AuthenticationCallbackProps = {
    handle_authentication: 'handle_authentication',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducer = (state: AuthenticationStateProps, action: any) => {
    switch (action.type) {
        case AuthenticationCallback.handle_authentication:
            return {
                ...state,
                ...action.value,
            };

        default:
            return state;
    }
};

export const AuthenticationProvider = ({ children }: { children: React.ReactNode }) => {
    const [authenticationState, authenticationDispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => ({ authenticationState, authenticationDispatch }), [authenticationState, authenticationDispatch]);

    return <AuthenticationStore.Provider value={value}>{children}</AuthenticationStore.Provider>;
};
