import { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import QuoteItems from 'v2/components/QuoteItems';
import useQuoteMutation from 'v2/hooks/useQuoteMutation';
import MainPageWrapper from 'v2/components/MainPageWrapper';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorTypography from 'v2/components/ErrorTypography';
import useVerifiedUser from 'v2/hooks/useVerifiedUser';
import translate from 'i18n/messages/translate';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useAsyncPost, showToast } from '@shared/core';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import { withTimeout } from 'v2/utils/cookies';
import { environment } from '../../../environments/environment';

interface QuoteProps {
    googleReCaptchaProps?: { executeRecaptcha: (token: string) => Promise<string> };
}

const Quote = ({ googleReCaptchaProps }: QuoteProps) => {
    const locale = useLanguage();
    const navigate = useNavigate();
    const quote = useQuoteMutation();
    const quoteStore = useQuotationStorage();
    const formattedMessages = messages[locale];
    const verifiedUser = useVerifiedUser();
    const { executeRecaptcha } = googleReCaptchaProps ?? {};
    const [inputValue, setInputValue] = useState<string>('');
    const disabledAddButton = useMemo(() => quote.status.isLoading || quote.status.isRemoving || Boolean(!inputValue), [quote.status, inputValue]);
    const disabledContinueButton = useMemo(
        () => Boolean(!quote.quotedItems?.length) || quote.status.isLoading || quote.status.isRemoving,
        [quote.quotedItems, quote.status]
    );

    const [captchaState, handleRecaptcha] = useAsyncPost(async () => {
        if (!executeRecaptcha) {
            console.error('Missing executeRecaptcha to validate reCaptcha!');
            showToast({
                message: formattedMessages.captcha_verification,
                enqueueSnackbarConfig: { variant: 'error' },
                extraConfig: { enableCloseAction: true },
            });
            return;
        }

        try {
            const token = await withTimeout(executeRecaptcha('homepage'), 2000);
            quoteStore.addItem('captcha', token);

            if (verifiedUser.emailAddress) {
                navigate('/enrollment');
            } else {
                navigate('/login');
            }
        } catch (error: any) {
            console.error(`Failed to execute recaptcha! ${error.message}`);

            if (error.message === 'Promise timed out' && environment.REACT_APP_SKIPPED_GOOGLE_CAPTCHA_TIMEOUT) {
                if (verifiedUser.emailAddress) {
                    navigate('/enrollment');
                } else {
                    navigate('/login');
                }
            } else {
                showToast({
                    message: formattedMessages.captcha_verification,
                    enqueueSnackbarConfig: { variant: 'error' },
                    extraConfig: { enableCloseAction: true },
                });
            }
        }
    });

    const addHandler = () => {
        quote.fetchPricing(inputValue);
    };

    const continueHandler = () => {
        handleRecaptcha();
    };

    useEffect(() => {
        setInputValue('');
    }, [quote.quotedItems]);

    return (
        <MainPageWrapper>
            <Container gap={2}>
                <TextHeader>{translate('get_a_quote')}</TextHeader>
                <TextDescription>{translate('get_quote_description_v2')}</TextDescription>
                <FieldBox gap={1}>
                    <Box width="100%">
                        <TextField
                            autoFocus
                            fullWidth
                            id="serialNumber"
                            value={inputValue}
                            name="serialNumber"
                            label={translate('serial_number')}
                            onFocus={quote.resetFieldError}
                            error={Boolean(quote.fieldError)}
                            onChange={(event) => setInputValue(event.target.value)}
                            InputProps={{
                                endAdornment: quote.status.isLoading ? <CircularProgress size={24} /> : null,
                            }}
                        />
                        {quote.fieldError && <ErrorTypography>{quote.fieldError}</ErrorTypography>}
                    </Box>
                    <AddButton disabled={disabledAddButton} onClick={addHandler} startIcon={<AddIcon />}>
                        {translate('add')}
                    </AddButton>
                </FieldBox>
                {quote.quotedItems.length > 0 && (
                    <QuoteItems
                        removedItem={quote.removedItem}
                        removeItem={quote.removeQuoteItem}
                        quotedItems={quote.quotedItems}
                        totalAmount={String(quote.totalPrice) ?? ''}
                    />
                )}
                <ContinueButton
                    key="button-continue-quote"
                    disabled={disabledContinueButton}
                    loading={captchaState.status === 'loading'}
                    variant="contained"
                    onClick={continueHandler}
                >
                    <span>{translate('continue')}</span>
                </ContinueButton>
            </Container>
        </MainPageWrapper>
    );
};

Quote.defaultProps = {
    googleReCaptchaProps: undefined,
};

const AddButton = styled(Button)(() => ({
    justifyContent: 'left',
}));

const Container = styled(Stack)(({ theme }) => ({
    width: '100%',
    maxWidth: '488px',

    [theme.breakpoints.up('sm')]: {
        maxHeight: '90vh',
    },
}));

const ContinueButton = styled(LoadingButton)(() => ({
    width: '121px',
}));

const TextHeader = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: '400',
    color: '#000000DE',
}));

const FieldBox = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const TextDescription = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000DE',
}));

export default withGoogleReCaptcha(Quote);
