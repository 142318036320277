import { Box, Skeleton } from '@mui/material';
import React from 'react';

const PolicyDetailsCardLoader = () => (
    <Box display="flex" sx={{ flexDirection: 'column', pt: 1, alignItems: 'center' }}>
        <Skeleton variant="text" width={90} height={10} sx={{ mb: 0.5, ml: 0.5, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={120} height={20} sx={{ ml: 0.5, mb: 2, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={100} height={10} sx={{ mb: 0.5, ml: 0.5, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={160} height={20} sx={{ ml: 0.5, mb: 2, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={90} height={10} sx={{ mb: 0.5, ml: 0.5, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={130} height={20} sx={{ ml: 0.5, mb: 2, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={90} height={10} sx={{ mb: 0.5, ml: 0.5, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={130} height={20} sx={{ ml: 0.5, mb: 2, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={90} height={10} sx={{ mb: 0.5, ml: 0.5, alignSelf: 'flex-start' }} />
        <Skeleton variant="text" width={170} height={20} sx={{ ml: 0.5, mb: 2, alignSelf: 'flex-start' }} />
    </Box>
);

export default PolicyDetailsCardLoader;
