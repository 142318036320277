import { createTheme } from '@mui/material';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#2196F3',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    boxShadow: 'none',
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    zIndex: 1201,
                    background: 'transparent',
                    [theme.breakpoints.down('sm')]: {
                        background: 'white',
                    },
                }),
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    maxWidth: '1600px !important',
                    minWidth: '300px',
                    width: '100%',
                    minHeight: 'calc(100vh - 280px)',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    position: 'relative',
                    margin: 'auto',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    maxWidth: '1200px',
                    minWidth: '300px',
                    width: '100%',
                    margin: 'auto',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    '&:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 100px white inset',
                        WebkitTextFillColor: 'black',
                    },
                },
            },
        },
    },
});
export default customTheme;
