import { createBrowserRouter, RouteObject, Navigate } from 'react-router-dom';
import Layout from 'v2/elements/Layout';
import Quote from 'v2/elements/Quote';
import Login from 'v2/elements/Login';
import Enrollment from 'views/enrollment';
import ProtectedRoute from 'routes/ProtectedRoute';
import ClaimCreation from 'views/claimCreation';
import ClaimReview from 'views/claimReview';
import ClaimHistory from 'views/ClaimHistory';
import Dashboard from 'views/dashboard';
import Payment from 'views/payment';
import AccountSetting from 'views/accountSetting';
import Profile from 'views/accountSetting/Profile';
import Devices from 'views/accountSetting/Devices';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ErrorFallback from 'v2/components/ErrorFallback';
import { Link } from '@mui/material';
import { environment } from '../../../environments/environment';

const pages: RouteObject[] = [
    {
        path: '/',
        element: <Navigate to="/quote" />,
    },
    {
        path: 'quote',
        element: (
            <GoogleReCaptchaProvider reCaptchaKey={environment.REACT_APP_GOOGLE_CAPTCHA || ''}>
                <Quote />
            </GoogleReCaptchaProvider>
        ),
    },
    {
        path: 'login',
        element: <Login />,
    },
    {
        path: 'enrollment',
        element: <Enrollment />,
    },
    {
        path: 'payment',
        element: (
            <ProtectedRoute>
                <Payment />
            </ProtectedRoute>
        ),
    },
    {
        path: 'dashboard',
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
    },
    {
        path: 'claim-history',
        element: (
            <ProtectedRoute>
                <ClaimHistory />
            </ProtectedRoute>
        ),
    },
    {
        path: '/claim-creation/:policyId',
        element: (
            <ProtectedRoute>
                <ClaimCreation />
            </ProtectedRoute>
        ),
    },
    {
        path: '/claim-review/:policyId',
        element: (
            <ProtectedRoute>
                <ClaimReview />
            </ProtectedRoute>
        ),
    },
    {
        path: 'account-setting',
        element: (
            <ProtectedRoute>
                <AccountSetting />
            </ProtectedRoute>
        ),
        children: [
            {
                path: 'profile',
                element: <Profile />,
            },
            {
                path: 'devices',
                element: <Devices />,
            },
        ],
    },
];

function useCreateRouter() {
    return createBrowserRouter([
        {
            errorElement: (
                <ErrorFallback
                    title="Page Not Found"
                    subtitle={
                        <>
                            The page you are looking for could not be found or has been removed.
                            <br />
                            Please check your address and try again or contact our support team at
                            <br /> <Link href="mailto:clientsupport@tigerlab.com">clientsupport@tigerlab.com</Link> if you continue to experience
                            difficulties.
                        </>
                    }
                    callToAction="redirect"
                    redirectUrl="/dashboard"
                    redirectLabel="Go back to dashboard"
                    img="assets/Page-Not-Found.svg"
                />
            ),
            element: <Layout />,
            children: pages,
        },
    ]);
}

export default useCreateRouter;
