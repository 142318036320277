import { AxiosInstance } from 'axios';
import { ThemeOptions } from '@mui/material';
import { IncompleteOrder } from './api';

/**
 * Represents a saved credit card information.
 */
export interface SavedCard {
    /**
     * Primary key of the saved card.
     */
    pk: string;
    /**
     * Masked card number for display purposes.
     */
    cardNumberMask: string;
    /**
     * Indicates whether the card is expired or not.
     */
    isExpired: boolean;
    /**
     * Type of the credit card (e.g., Visa, Mastercard).
     */
    cardType: string;
    /**
     * Payment method (eg. stripes)
     */
    paymentMethod: string;
}

/**
 * Represents the state of the checkout process.
 */
export interface CheckoutState {
    /**
     * Indicates whether the validation and data query is currently in progress.
     */
    isProcessing: boolean;
    /**
     * Incomplete order object
     */
    quotationData: IncompleteOrder | undefined;
    /**
     * Array of saved payment card data associated with the user.
     */
    savedCardData: SavedCard[];
    /**
     * Function to remove a saved payment card from the user's account.
     * @param {string} cardNumber - The card number of the card to remove.
     */
    removeSavedCard: (cardNumber: string) => void;
}

export interface Languages {
    en: string;
    it: string;
}

export type Language = keyof Languages;

export interface Locales {
    languages: Languages;
    howAreYou: string;
    leaveTo: string;
    orderSummary: string;
    totalAmount: string;
    policyHolder: string;
    policyNumber: string;
    quotationNumber: string;
    coveragePeriod: string;
    startDate: string;
    emailAddress: string;
    email: string;
    cardInformation: string;
    paymentInfo: string;
    savedCardHeader: string;
    insertNewCardHeader: string;
    remove: string;
    billingAddress: string;
    savedBillingAddressHeader: string;
    paymentNote: string;
    processing: string;
    pay: string;
    cardNumberPlaceholder: string;
    nameOnCardPlaceholder: string;
    expiryDateCardPlaceholder: string;
    addressLinePlaceholder: string;
    postalCodePlaceholder: string;
    statePlaceholder: string;
    cityPlaceholder: string;
    countryPlaceholder: string;
    paymentSuccessful: string;
    paymentSuccessfulMessage: string;
    transactionDetails: string;
    transactionDetailsIdLabel: string;
    transactionDetailsDateLabel: string;
    transactionDetailsTotalPaymentLabel: string;
    transactionDetailsPaidToLabel: string;
    paymentLinkExpired: string;
    paymentLinkExpiredMessage: string;
    invalidLinkPayment: string;
    invalidLinkPaymentMessage: string;
    paymentReceived: string;
    paymentRecievedMessage: string;
    backTo: string;
    expired: string;
}

/**
 * Interface defining forwarded properties is used in components or contexts in client configuration.
 */
export interface ForwardedProps {
    /**
     * Axios client instance for making HTTP requests.
     */
    client: AxiosInstance;
    /**
     * Localization settings and data.
     */
    locales: Locales;
}

/**
 * Interface defining parameters for advancing to the next step or route.
 */
export interface NextHandlerParams {
    /**
     * The path to navigate to in the next step or route.
     */
    toPath: string;
    /**
     * Optional query parameters to include in the navigation.
     */
    queryParams?: { [key: string]: string };
}

/**
 * Represents the base props forwarded to a route component.
 */
export interface BaseForwardedRouteProps extends ForwardedProps {
    /**
     * Function to advance to the next step or route.
     * @param {NextHandlerParams} params - Parameters for the next handler function.
     */
    next: (params: NextHandlerParams) => void;
    /**
     * Environment variables or configuration specific to the application.
     */
    environment: { [key: string]: any };
}

/**
 * Interface extending `ForwardedProps` with additional route-specific properties.
 * @extends ForwardedProps
 */
export interface ForwardedRouteProps extends BaseForwardedRouteProps {
    /**
     * Represents the state of the checkout process.
     */
    checkoutState: CheckoutState;
}

/**
 * Interface defining configuration for a route in a routing system.
 * @template T The type of props expected by the component associated with the route.
 */
export interface RouteConfig<T = any> {
    /**
     * Indicates whether the route is enabled or not.
     */
    enabled: boolean;
    /**
     * Unique key identifier for the route configuration.
     */
    key: string;
    /**
     * URL path for the route.
     */
    path: string;
    /**
     * Component to render for the route, accepting props of type T.
     */
    component: (props: T) => JSX.Element;
}

/**
 * Props interface for CheckoutSummary component
 */
export interface CheckoutSummaryProps extends ForwardedProps {
    /**
     * Incomplete order object
     */
    quotationData: IncompleteOrder;
}

/**
 * Configuration interface for I2go payment checkout customization.
 */
export interface I2goPaymentCheckoutConfig {
    /**
     * Localization settings for the checkout process.
     */
    locales?: {
        /**
         * Default language used in the checkout.
         */
        language: Language;
        /**
         * Supported languages for the checkout.
         */
        supportedLanguage: Language[];
    };
    /**
     * Specifies whether saved card functionality is enabled.
     */
    enabledSavedCard?: boolean;
    /**
     * Enable configuration options for enabling localization features.
     */
    enabledLocalization?: boolean;
    /**
     * Enable saved card info component.
     */
    enabledSavedCardInfoAlertComponent?: boolean;
    /**
     * Disabled button for user to redirect to client site.
     */
    disabledSiteRedirectButton?: boolean;
    /**
     * Optional routes configuration for navigation during checkout.
     */
    routes?: {
        /**
         * Configuration for the pre-checkout route.
         */
        preCheckout?: RouteConfig<ForwardedRouteProps>;
        /**
         * Configuration for the post-checkout route.
         */
        postCheckout?: RouteConfig<ForwardedRouteProps>;
    };
    /**
     * Override Logo in checkout interface
     */
    logo?: JSX.Element;
    /**
     * URL path to favicon image file
     */
    favicon?: string;
    /**
     * Override label in core checkout page
     */
    customTextLabel?: {
        /**
         * Override "paid to" value in successfull page
         */
        paidTo?: string;
    };
    /**
     * Custom theme options to style the checkout interface.
     */
    theme?: ThemeOptions;
    /**
     * Optional components to customize various parts of the checkout interface.
     */
    components?: {
        /**
         * Override checkout disclaimer component
         */
        CheckoutDisclaimer?: (props: CheckoutSummaryProps) => JSX.Element;
        /**
         * Custom component for displaying checkout summary details.
         */
        CheckoutSummaryDetail?: (props: CheckoutSummaryProps) => JSX.Element;
        /**
         * Custom component for displaying total amount.
         */
        TotalAmount?: (props: CheckoutSummaryProps) => JSX.Element;
        /**
         * Custom component for the header of the checkout interface.
         */
        Header?: (props: ForwardedProps) => JSX.Element;
        /**
         * Custom component for the footer of the checkout interface.
         */
        Footer?: (props: ForwardedProps) => JSX.Element;
    };
}
