import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import translate from 'i18n/messages/translate';

const MainPageWrapper = ({ children }: { children: ReactNode }) => (
    <FillContainer>
        <Container>
            <ImageContainer>
                <Image component="img" src="assets/Cochlear_Baby.png" />
                <TextInImage>
                    <Stack padding="24px" gap={2}>
                        <ImageTextTop>{translate('image_header')}</ImageTextTop>
                        <ImageTextBottom>{translate('image_description')}</ImageTextBottom>
                    </Stack>
                </TextInImage>
            </ImageContainer>
            <MainContainer>
                <CenteredContainer>{children}</CenteredContainer>
            </MainContainer>
        </Container>
    </FillContainer>
);

const FillContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexFlow: 'column',
        height: '90vh',
    },
}));

const Image = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
})) as typeof Box;

const MainContainer = styled(Box)(() => ({
    width: '100%',
    position: 'relative',
}));

const TextInImage = styled(Stack)(() => ({
    top: '16px',
    left: '16px',
    right: '16px',
    padding: '8px',
    position: 'absolute',
}));

const Container = styled(Box)(({ theme }) => ({
    margin: '10px',
    display: 'flex',
    height: '100%',

    [theme.breakpoints.down('sm')]: {
        margin: 0,
        padding: '16px',
        display: 'block',
    },
    [theme.breakpoints.between('sm', 'md')]: {
        minWidth: '950px',
    },
}));

const ImageTextTop = styled(Typography)(() => ({
    color: '#FFFFFF',
    fontSize: '34px',
    fontWeight: '400',
    paddingTop: '48px',
    lineHeight: '41.99px',
}));

const ImageTextBottom = styled(Typography)(() => ({
    color: '#FFFFFF',
    fontSize: '16px',
}));

const ImageContainer = styled(Box)(({ theme }) => ({
    padding: '16px',
    minWidth: '488px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
        padding: 0,
        minWidth: 0,
        width: '100%',
    },
}));

const CenteredContainer = styled(Stack)(({ theme }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
        display: 'block',
        padding: '32px 0',
    },
}));

export default MainPageWrapper;
