import { Button } from '@mui/material';
import React, { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import translate from 'i18n/messages/translate';

interface NavigateButtonProps {
    targetedURL?: string;
    buttonTitle?: string;
    startIcon?: React.ReactNode;
}

const NavigateButton: FC<NavigateButtonProps> = ({ targetedURL, buttonTitle, startIcon }) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (targetedURL) navigate(targetedURL);
    };

    return (
        <Button startIcon={startIcon} sx={{ alignSelf: 'flex-start' }} onClick={handleOnClick}>
            {translate(buttonTitle ?? 'back')}
        </Button>
    );
};

NavigateButton.defaultProps = {
    targetedURL: '',
    buttonTitle: 'back',
    startIcon: <ArrowBackIcon />,
};

export default NavigateButton;
