import { useReducer, useEffect } from 'react';
import * as R from 'ramda';
import { listOfQuoteProps } from 'interfaces/quote';
import { productStorageKey } from 'constant/devices';
import { QuotedItems } from './useQuoteMutation';

enum ACTION_TYPES {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    UPDATE = 'UPDATE',
    RESET = 'RESET',
    OVERRIDE = 'OVERRIDE',
}

interface AddAction {
    type: ACTION_TYPES.ADD;
    payload: { key: keyof State; value: string };
}

interface RemoveAction {
    type: ACTION_TYPES.REMOVE;
    payload: keyof State;
}

interface OverrideAction {
    type: ACTION_TYPES.OVERRIDE;
    payload: State;
}

interface ResetAction {
    type: ACTION_TYPES.RESET;
    payload: typeof initialState;
}

interface ResetAction {
    type: ACTION_TYPES.RESET;
    payload: typeof initialState;
}

type Action = AddAction | RemoveAction | ResetAction | OverrideAction;

interface State {
    businessPartnerUUID: string | undefined;
    hasBp: boolean;
    captcha: string | undefined;
    submissionEmailAddress: string | undefined;
    listQuote: listOfQuoteProps | undefined;
    listSerialNumber: string[];
    quotedItems: QuotedItems[];
}

const initialState: State = {
    businessPartnerUUID: undefined,
    hasBp: false,
    captcha: undefined,
    submissionEmailAddress: undefined,
    listQuote: undefined,
    listSerialNumber: [],
    quotedItems: [],
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ACTION_TYPES.ADD:
            return { ...state, [action.payload.key]: action.payload.value };
        case ACTION_TYPES.REMOVE:
            return { ...state, [action.payload]: initialState[action.payload] };
        case ACTION_TYPES.RESET:
            return { ...action.payload };
        case ACTION_TYPES.OVERRIDE:
            return { ...action.payload };
        default:
            return state;
    }
};

function useQuotationStorage() {
    const key = productStorageKey;
    const [state, dispatch] = useReducer(reducer, initialState, () => {
        const savedState = sessionStorage.getItem(key);

        if (!savedState) return initialState;
        if (R.isEmpty(JSON.parse(savedState))) return initialState;

        return JSON.parse(savedState);
    });

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(state));
    }, [key, state]);

    useEffect(() => {
        const updatedState = sessionStorage.getItem(key);

        if (updatedState) {
            dispatch({ type: ACTION_TYPES.OVERRIDE, payload: { ...initialState, ...JSON.parse(updatedState) } });
        }
    }, [sessionStorage.getItem(key)]);

    const addItem = (itemKey: keyof State, value: any) => dispatch({ type: ACTION_TYPES.ADD, payload: { key: itemKey, value } });
    const removeItem = (itemKey: keyof State) => dispatch({ type: ACTION_TYPES.REMOVE, payload: itemKey });
    const resetItem = () => dispatch({ type: ACTION_TYPES.RESET, payload: initialState });

    return {
        items: state,
        addItem,
        removeItem,
        resetItem,
    };
}

export default useQuotationStorage;
