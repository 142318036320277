import { Box, Button, Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';
import { providerProps } from 'interfaces/profile';
import { AuthenticationStore } from 'context/Authentication';
import messages from 'i18n/messages';
import { useLanguage } from 'context/LanguageContext';
import DynamicOwnerNaming from './DynamicOwnerNaming';
import StaticOwnerNaming from './StaticOwnerNaming';

interface CollapsibleMobileDeviceListingProps {
    deviceName: string;
    imageUrl: string;
    serialNumber: string;
    handleRemoveDevice: (deviceSerialNumber: string) => void;
    minimumOneDevice?: boolean;
    isDeviceRemovable?: boolean;
}
const CollapsibleMobileDeviceListing: FC<CollapsibleMobileDeviceListingProps> = ({
    deviceName,
    imageUrl,
    serialNumber,
    handleRemoveDevice,
    minimumOneDevice,
    isDeviceRemovable,
}) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const { pathname } = useLocation();
    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { firstName, lastName } = authenticationState;

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const handleCollapseClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <Box display="flex" sx={{ flexDirection: 'column' }}>
            <Box key={serialNumber} sx={{ mx: 2 }}>
                <ListItemButton sx={{ '&.MuiListItemButton-root': { backgroundColor: '#eeeeee !important' } }} onClick={handleCollapseClick}>
                    <ListItemText primary={deviceName} sx={{ fontWeight: 600 }} />
                    {!isCollapsed ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={!isCollapsed} timeout="auto">
                    <List
                        component="div"
                        disablePadding
                        sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}
                    >
                        <ListItemIcon>
                            <Box component="img" height="60px" src={imageUrl} />
                        </ListItemIcon>
                        <Box display="flex" sx={{ flexDirection: 'column', pt: 3, alignItems: 'flex-start' }}>
                            <Typography variant="body2" sx={{ pl: 1 }} style={{ display: 'flex', flexDirection: 'row', color: 'rgba(0, 0, 0, 0.6)' }}>
                                <span style={{ minWidth: 110 }}>{formattedMessages.serial_number}</span> {serialNumber}
                            </Typography>
                            {firstName && lastName && pathname === '/enrollment' ? <DynamicOwnerNaming serialNumber={serialNumber} /> : null}
                            {firstName && lastName && pathname !== '/enrollment' ? <StaticOwnerNaming serialNumber={serialNumber} /> : null}
                            {isDeviceRemovable && (
                                <Button
                                    variant="text"
                                    startIcon={<RemoveCircleOutlineIcon />}
                                    color="secondary"
                                    onClick={() => handleRemoveDevice(serialNumber)}
                                    disabled={minimumOneDevice}
                                >
                                    {formattedMessages.remove}
                                </Button>
                            )}
                        </Box>
                    </List>
                </Collapse>
            </Box>
        </Box>
    );
};
export default CollapsibleMobileDeviceListing;

CollapsibleMobileDeviceListing.defaultProps = {
    minimumOneDevice: false,
    isDeviceRemovable: true,
};
