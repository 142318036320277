import { Box } from '@mui/material';
import React, { ReactNode, FC, CSSProperties } from 'react';

interface PageLayoutProps {
    rootStyle?: CSSProperties;
    children: ReactNode;
}
const PageLayout: FC<PageLayoutProps> = ({ rootStyle, children }) => (
    <Box
        sx={{
            padding: { xs: '10px 2px', md: '47px 120px 0px' },
            height: '100%',
            overflow: 'auto',
            ...rootStyle,
        }}
    >
        {children}
    </Box>
);

export default PageLayout;

PageLayout.defaultProps = {
    rootStyle: {},
};
