import { useState } from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MainPageWrapper from 'v2/components/MainPageWrapper';
import OTPVerification from 'v2/components/OTPVerification';
import EmailVerification from 'v2/components/EmailVarification';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import withEmailVerification from 'v2/hoc/withEmailVerification';

const Login = () => {
    const [otpVerification, setOtpVerification] = useState(false);
    const [transitionDirection, setTransitionDirection] = useState('left');
    const navigate = useNavigate();

    const emailPreviousHandler = () => {
        navigate('/quote');
    };

    const emailNextHandler = () => {
        setOtpVerification(true);
        setTransitionDirection('right');
    };

    const otpPreviousHandler = () => {
        setOtpVerification(false);
        setTransitionDirection('left');
    };

    return (
        <MainPageWrapper>
            <SwitchTransition>
                <CSSTransition
                    key={otpVerification ? 'otp' : 'email'}
                    timeout={300}
                    classNames={transitionDirection === 'right' ? 'slide-right' : 'slide-left'}
                    unmountOnExit
                >
                    <Slide>
                        {otpVerification ? (
                            <OTPVerification previousHandler={otpPreviousHandler} />
                        ) : (
                            <EmailVerification nextHandler={emailNextHandler} previousHandler={emailPreviousHandler} />
                        )}
                    </Slide>
                </CSSTransition>
            </SwitchTransition>
        </MainPageWrapper>
    );
};

const Slide = styled(Box)(() => ({
    '&.slide-right-enter': {
        transform: 'translateX(100%)',
        opacity: 0,
    },
    '&.slide-right-enter-active': {
        transform: 'translateX(0)',
        opacity: 1,
        transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
    },
    '&.slide-right-exit': {
        opacity: 1,
    },
    '&.slide-right-exit-active': {
        opacity: 0,
        transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
    },

    '&.slide-left-enter': {
        transform: 'translateX(-100%)',
        opacity: 0,
    },
    '&.slide-left-enter-active': {
        transform: 'translateX(0)',
        opacity: 1,
        transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
    },
    '&.slide-left-exit': {
        opacity: 1,
    },
    '&.slide-left-exit-active': {
        opacity: 0,
        transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
    },
}));

export default withEmailVerification(Login);
