import Box from '@mui/material/Box';
import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { QuotedItems } from 'v2/hooks/useQuoteMutation';
import getDeviceImage from 'utils/getDeviceImage';
import { CURRENCY_SYMBOL } from 'v2/constant';
import CircularProgress from '@mui/material/CircularProgress';
import translate from 'i18n/messages/translate';

interface QuoteItemsProps {
    quotedItems: QuotedItems[];
    totalAmount: string;
    removedItem?: QuotedItems;
    removeItem: (serialNumber: string) => void;
}

const QuoteItems = ({ quotedItems, totalAmount, removeItem, removedItem }: QuoteItemsProps) => (
    <Stack>
        <QuoteItemHeader>
            {translate('your_quote')} ({quotedItems.length})
        </QuoteItemHeader>
        <Container>
            <ItemInfoContainer>
                {quotedItems.map((value) => (
                    <ItemInfo key={value.serialNumber} direction="row">
                        <ItemImage component="img" src={getDeviceImage(value.serialNumber)} />
                        <ItemDetail>
                            <ItemName>{value.name}</ItemName>
                            <LabelContainer>
                                <ItemLabel>{translate('serial_number')}:</ItemLabel>
                                <ItemValue>{value.serialNumber}</ItemValue>
                            </LabelContainer>
                        </ItemDetail>
                        {removedItem?.serialNumber === value.serialNumber ? (
                            <RemovingIcon size={20} />
                        ) : (
                            <CancelButton onClick={() => removeItem(value.serialNumber)}>
                                <CloseIcon />
                            </CancelButton>
                        )}
                    </ItemInfo>
                ))}
            </ItemInfoContainer>
            <ItemPriceContainer>
                {quotedItems.map((value) => (
                    <PriceInfo key={value.serialNumber} direction="row">
                        <PriceInfoLeft>
                            <PriceHead>{translate('protection_plan')}</PriceHead>
                            <PriceName>{value.name}</PriceName>
                        </PriceInfoLeft>
                        <PriceValue>
                            {CURRENCY_SYMBOL} {Number(value.pricing.premiumExcludingIpt).toFixed(2)}
                        </PriceValue>
                    </PriceInfo>
                ))}
            </ItemPriceContainer>
        </Container>
        <GapDivider />
        <TotalPriceContainer direction="row">
            <TotalLeft>
                <TotalLabel>{translate('total')}</TotalLabel>
                <TotalInfo>{translate('total_premium_description')}</TotalInfo>
            </TotalLeft>
            <TotalLabel>
                {CURRENCY_SYMBOL} {Number(totalAmount).toFixed(2)}
            </TotalLabel>
        </TotalPriceContainer>
    </Stack>
);

QuoteItems.defaultProps = {
    removedItem: undefined,
};

const Container = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        maxHeight: '300px',
        overflow: 'scroll',
    },
}));

const RemovingIcon = styled(CircularProgress)(() => ({
    color: '#00000099',
    margin: '5px',
}));

const TotalLeft = styled(Box)(() => ({
    flexGrow: 1,
}));

const TotalLabel = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 700,
    color: '#000000DE',
}));

const TotalInfo = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    color: '#00000099',
}));

const PriceInfoLeft = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    maxWidth: '418px',

    [theme.breakpoints.down('sm')]: {
        maxWidth: '288px',
    },
}));

const PriceHead = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000DE',
}));

const PriceName = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    color: '#00000099',
    overflow: 'hidden',
    overflowWrap: 'break-word',
}));

const PriceValue = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: 400,
    color: '#000000DE',
}));

const ItemValue = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: 400,
    color: '#000000DE',
    overflow: 'hidden',
    overflowWrap: 'break-word',
}));

const GapDivider = styled(Divider)(() => ({
    margin: '10px 0',
}));

const PriceInfo = styled(Stack)(() => ({
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '10px',
}));

const ItemLabel = styled(Typography)(() => ({
    fontSize: '14px',
    fontWeight: '400',
    color: '#00000099',
    minWidth: '110px',
}));

const CancelButton = styled(IconButton)(() => ({
    padding: '2px',
}));

const LabelContainer = styled(Box)(({ theme }) => ({
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
        display: 'block',
    },
}));

const ItemName = styled(Typography)(() => ({
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000DE',
    overflow: 'hidden',
    overflowWrap: 'break-word',
}));

const ItemDetail = styled(Stack)(({ theme }) => ({
    flexGrow: 1,
    gap: '4px',
    width: '352px',
    [theme.breakpoints.down('sm')]: {
        width: '225px',
    },
}));

const ItemImage = styled(Box)(() => () => ({
    width: '64px',
    height: '64px',
})) as typeof Box;

const ItemInfoContainer = styled(Stack)(() => ({
    gap: '8px',
}));

const ItemPriceContainer = styled(Stack)(() => ({
    marginTop: '20px',
    gap: '8px',
}));

const ItemInfo = styled(Stack)(({ theme }) => ({
    gap: '10px',
    width: '100%',
    padding: '8px',
    borderRadius: '6px',
    alignItems: 'flex-start',
    minWidth: '300px',
    background: theme.palette.grey[100],
}));

const TotalPriceContainer = styled(Stack)(() => ({
    alignItems: 'flex-start',
    gap: '10px',
}));

const QuoteItemHeader = styled(Typography)(() => ({
    fontSize: '20px',
    fontWeight: 500,
    padding: '16px 0',
    color: '#000000DE',
}));

export default QuoteItems;
