import { Checkbox, FormControlLabel, Box, Divider, Typography } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import { Field, FieldProps } from 'formik';
import messages from 'i18n/messages';
import React, { FC } from 'react';

interface AddressCheckboxProps {
    policyHolderName: string;
    originalDefaultAddress: string;
}

const onlySpaces = (str: string) => /^\s*$/.test(str);

const AddressCheckbox: FC<AddressCheckboxProps> = ({ policyHolderName, originalDefaultAddress }) => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    let reformatHolderName = policyHolderName?.replaceAll('undefined', '')?.replaceAll('-', '');
    let reformatHolderAddress = originalDefaultAddress?.replaceAll('undefined,', '')?.replaceAll('undefined', '')?.replaceAll('-', '');
    if (onlySpaces(reformatHolderName)) {
        reformatHolderName = '';
    }
    if (onlySpaces(reformatHolderAddress)) {
        reformatHolderAddress = '';
    }

    return (
        <Box display="flex" sx={{ mx: { xs: 0.5, md: 2 }, mt: { xs: 2, md: 0 } }}>
            <Box display="flex" sx={{ flexDirection: 'column' }}>
                <Field name="isSameAddress">
                    {({ field }: FieldProps) => (
                        <FormControlLabel
                            control={<Checkbox {...field} checked={field.value} />}
                            label={formattedMessages.same_billing_address_label}
                            labelPlacement="end"
                        />
                    )}
                </Field>
                <Divider sx={{ mx: { xs: 0.5, md: 3.5 } }} />
                <Typography variant="subtitle1" sx={{ mx: 3.5 }} color="text.secondary">
                    {reformatHolderName || '-'}
                </Typography>
                <Typography variant="subtitle1" sx={{ mx: 3.5 }} color="text.secondary">
                    {reformatHolderAddress || '-'}
                </Typography>
            </Box>
        </Box>
    );
};

export default AddressCheckbox;
