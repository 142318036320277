import React, { FC, useContext, useEffect, useState } from 'react';
import { BusinessPartnerCreation, EnrollmentFormValues, PatientDetailsInterface } from 'interfaces/enrollment';
import { format } from 'date-fns';
import { AuthenticationStore } from 'context/Authentication';
import { providerProps } from 'interfaces/profile';
import { useNavigate } from 'react-router-dom';
import storageHandler, { productStorage } from 'utils/storageHandler';
import { listOfQuoteProps } from 'interfaces/quote';
import extractDeviceDetails from 'utils/extractDeviceDetails';
import { handleExistingUserPurchase, handleNewUserPurchase, toggleDateFormat } from 'utils/enrollment';
import Popper, { popupProps } from 'components/Popper';
import { END_POINTS } from 'services/endpoints';
import PageLoader from 'components/loader/PageLoader';
import enrollmentValidation from 'context/enrollmentValidation';
import { useMediaQuery } from 'react-responsive';
import PageLayout from 'components/PageLayout';
import Header from 'components/Header';
import { Backdrop, Grid, Box, Button, Divider } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { enrollmentValidator } from 'validate/enrollment';
import FormHeader from 'components/FormHeader';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import translate from 'i18n/messages/translate';
import { localesCode } from 'i18n/locales';
import useQuotationStorage from 'v2/hooks/useQuotationStorage';
import AccountCreationAlert from './AccountCreationAlert';
import EnrollmentDisclaimer from './EnrollmentDisclaimer';
import InteractiveDeviceCard from './cardComponent/InteractiveDeviceCard';
import SameOwnerCheckbox from './SameOwnerCheckbox';
import PersonalDetailsForm from './personalDetails/PersonalDetailsForm';
import GuardianPatientForm from './guardianPatientDetails/GuardianPatientForm';
import RadioButtonField from './RadioButtonField';
import OtherAndSameOwnerDetails from './guardianPatientDetails/OtherAndSameOwnerDetails';
import Within60DaysDisclaimer from './Within60DaysDisclaimer';
import InsuranceAcknowledgeLocation from './insuranceAcknowledgeLocation/InsuranceAcknowledgeLocation';
import { environment } from '../../../environments/environment';

const BASE_URL = environment.REACT_APP_API_BASE_URL;

const Enrollment: FC = () => {
    const quoteStore = useQuotationStorage();
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const { authenticationState, authenticationDispatch }: providerProps = useContext(AuthenticationStore);
    const { submissionEmailAddress, listQuote } = productStorage();
    const storedDeviceArr = extractDeviceDetails(listQuote as listOfQuoteProps);
    const [isFormsubmitting, setIsFormsubmitting] = useState<boolean>(false);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<popupProps>({
        message: '',
        severity: 'error',
        status: false,
    });
    const userValidate = authenticationState.emailAddress;
    const navigate = useNavigate();
    const { hasBp } = productStorage();
    const isExistingPartner = JSON.parse(hasBp ?? 'false');
    const initialPatientsArr: PatientDetailsInterface[] = storedDeviceArr.map((item) => ({
        firstName: '',
        lastName: '',
        id: '',
        device: item,
    }));

    const [formInitialValues, setFormInitialValues] = useState<EnrollmentFormValues>({
        // helper state
        personalOrOthers: authenticationState?.personalOrOthers || 'personal',
        sameOwner: authenticationState?.sameOwner || true,
        enrollmentDisclaimer: false,
        within60DaysDisclaimer: false,
        practitioner: authenticationState.practitioner || '',
        location: authenticationState.location || '',

        personalOrGuardianFirstName: authenticationState?.firstName || '',
        personalOrGuardianLastName: authenticationState?.lastName || '',
        personalOrGuardianId: authenticationState?.idNo || '',
        personalOrGuardianDob: authenticationState?.dateOfBirth?.split('-').reverse().join('/') || '',
        personalOrGuardianEmailAddress: authenticationState?.emailAddress || submissionEmailAddress || '',
        personalOrGuardianConfirmEmail: '',
        personalOrGuardianAddress: authenticationState?.address || '',
        personalOrGuardianZip: authenticationState?.zipCode || '',
        personalOrGuardianCity: authenticationState?.zipCode || '',
        personalOrGuardianState: authenticationState?.state || '',
        personalOrGuardianCountry: authenticationState?.country || '',
        patients: initialPatientsArr,
        patient: {
            firstName: '',
            lastName: '',
            id: '',
        },
    } as EnrollmentFormValues);
    const onPaymentPageRedirect = () => {
        navigate('/payment');
        // update the storage when successfully create Bp
        storageHandler('hasBp', 'true');
        setIsFormsubmitting(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onErrorHandling = (error: any) => {
        const modifiedErrorMessage = error?.message.replace('Error: ', '') ?? '';
        setPopup((prev) => ({
            ...prev,
            status: true,
            message: formattedMessages[modifiedErrorMessage as keyof typeof formattedMessages],
        }));
        setIsFormsubmitting(false);
    };
    const handleOnSubmit = async (values: EnrollmentFormValues) => {
        setIsFormsubmitting(true);
        const businessPartnerPayload: BusinessPartnerCreation = {
            firstName: values.personalOrGuardianFirstName,
            lastName: values.personalOrGuardianLastName,
            roles: [`${BASE_URL}${END_POINTS.businessPartnerRole.href}`],
            // toggle date format to convert the string in dd/MM/yyyy to MM/dd/yyyy such that
            // new Date() does not convert the date wrong. Then, further convert the data into
            // yyyy-MM-dd which is needed by Tigerlab backend
            dob: format(new Date(toggleDateFormat(values.personalOrGuardianDob as string)), 'yyyy-MM-dd'),
            category: 'Person',
            idType: 'Other',
            idNumber: values.personalOrGuardianId,
            emailPrimary: values.personalOrGuardianEmailAddress,
            correspondenceLanguage: localesCode[locale] as 'en' | 'it',
        };

        // when new BP needs to be created
        if (!isExistingPartner) {
            handleNewUserPurchase(businessPartnerPayload, values, authenticationDispatch, quoteStore.addItem)
                .then(() => {
                    onPaymentPageRedirect();
                })
                .catch((error) => {
                    onErrorHandling(error);
                });
            // existing BP is found
        } else {
            handleExistingUserPurchase(values, authenticationDispatch, authenticationState)
                .then(() => {
                    onPaymentPageRedirect();
                })
                .catch((error) => {
                    onErrorHandling(error);
                });
        }
    };
    const handlePopperClose = () => {
        setPopup((prev) => ({
            ...prev,
            status: false,
            message: '',
        }));
    };

    useEffect(() => {
        setIsPageLoading(true);
        if (authenticationState?.emailAddress) {
            setFormInitialValues((prevValues) => ({
                ...prevValues,
                enrollmentDisclaimer: false,
                personalOrOthers: authenticationState?.personalOrOthers || 'personal',
                sameOwner: authenticationState?.sameOwner || true,
                personalOrGuardianFirstName: authenticationState?.firstName || '',
                personalOrGuardianLastName: authenticationState?.lastName || '',
                personalOrGuardianId: authenticationState?.idNo || '',
                personalOrGuardianDob: authenticationState?.dateOfBirth?.split('-').reverse().join('/') || '',
                personalOrGuardianEmailAddress: authenticationState?.emailAddress || '',
                personalOrGuardianConfirmEmail: authenticationState?.emailAddress || '',
                personalOrGuardianAddress: authenticationState?.address || '',
                personalOrGuardianZip: authenticationState?.zipCode || '',
                personalOrGuardianCity: authenticationState?.city || '',
                personalOrGuardianState: authenticationState?.state || '',
                personalOrGuardianCountry: authenticationState?.country || '',
                patients: initialPatientsArr,
                patient: authenticationState?.patient || {
                    firstName: '',
                    lastName: '',
                    id: '',
                    practitioner: '',
                    location: '',
                },
            }));
        }
        setIsPageLoading(false);
    }, [authenticationState.emailAddress]);

    if (isPageLoading) {
        return <PageLoader />;
    }

    return (
        <PageLayout>
            <Header title="" subTitle="enrollment_label" />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isFormsubmitting}>
                <PageLoader />
            </Backdrop>
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validationSchema={enrollmentValidator}
                validateOnMount
                onSubmit={handleOnSubmit}
            >
                {(formikProps: FormikProps<EnrollmentFormValues>) => {
                    const { values, isValid } = formikProps;

                    return (
                        <Form>
                            {!isBigScreen && <InteractiveDeviceCard />}
                            <Grid container spacing={2}>
                                <Grid sx={{ mt: { xs: '0px', md: '40px' } }} item lg={7} xs={12}>
                                    {!isExistingPartner ? <AccountCreationAlert /> : null}
                                    <FormHeader titleVariant="h6" rootStyle={{ mx: 1 }} />
                                    <RadioButtonField />
                                    {values.personalOrOthers === 'others' && <SameOwnerCheckbox />}
                                    {values.personalOrOthers === 'personal' && (
                                        <Divider
                                            sx={{
                                                mt: { xs: 0, md: 5 },
                                                mb: { xs: 3, md: 5 },
                                                width: { xs: '95%', md: '98%' },
                                                mx: { xs: 'auto' },
                                            }}
                                        />
                                    )}
                                    {userValidate ? <div style={{ margin: 10 }} /> : null}
                                    {values.personalOrOthers === 'personal' ? <PersonalDetailsForm /> : <GuardianPatientForm />}

                                    {values.personalOrOthers === 'personal' && <InsuranceAcknowledgeLocation />}
                                    {values.personalOrOthers === 'others' && values.sameOwner && <OtherAndSameOwnerDetails />}
                                    <Within60DaysDisclaimer />
                                    <EnrollmentDisclaimer />
                                    <Box display="flex" justifyContent="flex-end" sx={{ mb: 4, mt: 4 }}>
                                        <Button
                                            sx={{ ml: { xs: 1, md: 0.2 }, mr: { xs: 1, md: 3 }, mb: 2 }}
                                            variant="contained"
                                            type="submit"
                                            fullWidth={!isBigScreen}
                                            // values.patients will have a length according to the number of devices added
                                            disabled={!isValid || !(values.patients.length > 0)}
                                        >
                                            {translate('continue_to_payment_label')}
                                        </Button>
                                    </Box>
                                </Grid>
                                {isBigScreen && <InteractiveDeviceCard />}
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
            <Popper popup={popup} handlePopup={handlePopperClose} />
        </PageLayout>
    );
};
const EnrollmentValidate = enrollmentValidation(Enrollment);
export default EnrollmentValidate;
