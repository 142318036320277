import { Box } from '@mui/material';
import FormHeader from 'components/FormHeader';
import React, { FC } from 'react';
import PatientDetails from './PatientDetails';

interface PatientAndLocationDetailsProps {
    deviceModel: string;
    formikFieldName: string;
    deviceSerialNumber: string;
}
const PatientAndLocationDetails: FC<PatientAndLocationDetailsProps> = ({ deviceModel, formikFieldName, deviceSerialNumber }) => (
    <Box sx={{ mb: 3 }}>
        <FormHeader
            title={deviceModel}
            titleVariant="subtitle1"
            titleStyle={{ fontWeight: 500, fontSize: '1.1rem' }}
            subtitle={deviceModel && deviceSerialNumber ? `S/N: ${deviceSerialNumber}` : ''}
            subtitleStyle={{ fontWeight: 400, color: 'rgba(0, 0, 0, 0.6)' }}
            rootStyle={{ mx: { xs: 1, md: 0 }, mb: { xs: 0, md: 1 } }}
        />
        <PatientDetails formikFieldName={formikFieldName} deviceModel={deviceModel} deviceSerialNumber={deviceSerialNumber} />
    </Box>
);

export default PatientAndLocationDetails;
