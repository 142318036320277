/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import { Field, FieldProps } from 'formik';
import messages from 'i18n/messages';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const SameOwnerCheckbox = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <Field name="sameOwner">
            {({ field }: FieldProps) => (
                <FormGroup sx={{ my: 2, mx: { xs: 1 } }}>
                    <FormControlLabel control={<Checkbox {...field} checked={!!field.value} />} label={formattedMessages.same_owner_checkbox_label} />
                </FormGroup>
            )}
        </Field>
    );
};

export default SameOwnerCheckbox;
