import axios from 'axios';
import Cookies from 'js-cookie';
import { environment } from '../../environments/environment';

export const httpClient = axios.create({
    baseURL: environment.REACT_APP_API_BASE_URL,
    headers: {
        Authorization: Cookies.get('token') ? `i2go ${Cookies.get('token')}` : ``,

        // Authorization: Cookies.get('token') ? `i2go ${Cookies.get('token')}` : `i2go ${environment.REACT_APP_DEFAULT_USER_TOKEN}`,
        // Authorization: `i2go ${environment.REACT_APP_DEFAULT_USER_TOKEN}`,
    },
});

export function getCSRFToken() {
    return Cookies.get('csrftoken');
}
