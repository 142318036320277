import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { RouterProvider } from 'react-router-dom';
import useCreateRouter from './hooks/useCreateRouter';
import ErrorFallback from './components/ErrorFallback';

const App = () => {
    const queryClient = new QueryClient();
    const router = useCreateRouter();

    return (
        <ErrorBoundary fallback={<ErrorFallback callToAction="reload" />}>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export default App;
