import React, { useEffect } from 'react';
import { isEmpty } from 'ramda';
import styled from '@emotion/styled';
import { useObservableState } from 'observable-hooks';
import { useSnackbar, SnackbarProvider, MaterialDesignContent, OptionsObject, SnackbarKey } from 'notistack';
import { ToastSubject, ToastState } from '.';
import ActionComponent from './ActionComponent';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        fontSize: '15px',
    },
    '&.notistack-MuiContent-error': {
        fontSize: '15px',
    },
    '&.notistack-MuiContent-default': {
        fontSize: '15px',
    },
    '&.notistack-MuiContent-warning': {
        fontSize: '15px',
    },
    '&.notistack-MuiContent-info': {
        fontSize: '15px',
    },
}));

const ToastNotistack = ({ config, message, extraConfig }: ToastState) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        if (message && !isEmpty(config)) {
            const enqueueConfig: OptionsObject = { ...config };
            if (!isEmpty(extraConfig)) {
                enqueueConfig.action = (snackbarKey: SnackbarKey) => (
                    <ActionComponent
                        closeSnackbar={closeSnackbar}
                        snackbarKey={snackbarKey}
                        actionButton={extraConfig.actionButton}
                        closeEvent={extraConfig.closeEvent}
                        undoEvent={extraConfig.undoEvent}
                        enableCloseAction={extraConfig.enableCloseAction}
                    />
                );
            }

            enqueueSnackbar(message, { ...enqueueConfig });
        }
    }, [config, message, extraConfig]);

    return <div />;
};

const ToastComponent = () => {
    const state = useObservableState(ToastSubject);

    return (
        <SnackbarProvider
            maxSnack={5}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                default: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
            }}
        >
            <ToastNotistack extraConfig={state.extraConfig} config={state.config} message={state.message} />
        </SnackbarProvider>
    );
};

export default React.memo(ToastComponent);
