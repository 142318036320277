import { useEffect, useRef } from 'react';

type Interval = ReturnType<typeof setInterval>;

export class IntervalController {
    currentId: Interval | null = null;

    static create() {
        return new IntervalController();
    }

    start(cb: () => void, delay: number) {
        this.clear();
        this.currentId = setInterval(() => {
            this.currentId = null;
            cb();
        }, delay);
    }

    clear() {
        if (this.currentId !== null) {
            clearInterval(this.currentId);
            this.currentId = null;
        }
    }
}

export function useInterval() {
    const controller = useRef(IntervalController.create()).current;

    useEffect(() => {
        controller.clear();
    }, []);

    return controller;
}
