import messages from 'i18n/messages';
import React, { FC, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { useLanguage } from './LanguageContext';

interface CustomisedIntlProviderProps {
    children: ReactNode;
}
const CustomisedIntlProvider: FC<CustomisedIntlProviderProps> = ({ children }) => {
    const locales = useLanguage();
    return (
        <IntlProvider locale={locales} messages={messages[locales]}>
            {children}
        </IntlProvider>
    );
};

export default CustomisedIntlProvider;
