import { ComponentsProps, useTheme } from '@mui/material';
import { display } from '@mui/system';
import React from 'react';

interface ModalConfig {
    styles: {
        Dialog: ComponentsProps['MuiDialog'];
        DialogTitle: ComponentsProps['MuiDialogTitle'];
        DialogActions: ComponentsProps['MuiDialogActions'];
    };
    colors: {
        highlight: string;
    };
    messages: {
        userBlocked: string;
        createNewPolicyholder: string;
        dialogTitle: string;
        emailExists: (email: string, color?: string) => JSX.Element;
    };
}

type RenderedStringPart = {
    type: 'text';
    content: string;
};

type RenderedHighlightPart = {
    type: 'highlight';
    content: string;
    color: string;
};

type RenderedMessage = Array<RenderedStringPart | RenderedHighlightPart>;

const renderMessageToJsx = (message: RenderedMessage): JSX.Element => (
    <>
        {message.map((part) => {
            const { type, content } = part;
            switch (type) {
                case 'text':
                    return <React.Fragment key={content}>{content}</React.Fragment>;
                case 'highlight':
                    return (
                        <span key={content} style={{ color: part.color }}>
                            {content}
                        </span>
                    );
                default:
                    return null;
            }
        })}
    </>
);

export type DuplicateCheckClient = 'ukg' | 'vanbreda' | 'default';

export default function useConfig(client: DuplicateCheckClient): ModalConfig {
    const theme = useTheme();

    const defaultConfig: ModalConfig = {
        styles: {
            Dialog: {
                maxWidth: 'sm',
            },
            DialogTitle: {
                sx: {
                    mt: 4,
                },
            },
            DialogActions: {
                sx: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mb: 4,
                    mx: 4,
                },
            },
        },
        colors: {
            highlight: theme.palette.primary.main,
        },
        messages: {
            userBlocked: 'The user is already linked to a Back Office user, please try with another email address.',
            createNewPolicyholder: 'CREATE A NEW POLICYHOLDER',
            dialogTitle: 'Oops... This email is already in use',
            emailExists: (email: string, color = 'highlight') =>
                renderMessageToJsx([
                    { type: 'text', content: 'The email ' },
                    { type: 'highlight', content: email, color },
                    {
                        type: 'text',
                        content:
                            ' already exists in the system. Please select the policyholder to populate the details or create a new policyholder.',
                    },
                ]),
        },
    };

    switch (client) {
        case 'ukg':
            return {
                ...defaultConfig,
                colors: {
                    highlight: '#FD0066',
                },
            };
        case 'vanbreda':
            return {
                ...defaultConfig,
                colors: {
                    highlight: '#3F51B5',
                },
            };
        default:
            return defaultConfig;
    }
}
