import { Box, List, Typography } from '@mui/material';
import { useLanguage } from 'context/LanguageContext';
import messages from 'i18n/messages';
import React from 'react';
import DocumentsListItem from './DocumentsListItem';

const DisclaimerContent = () => {
    const locale = useLanguage();
    const formattedMessages = messages[locale];
    return (
        <Box display="flex" sx={{ flexDirection: 'column', my: 2 }}>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>{formattedMessages.checkout_acknowledgement_first_line}</Typography>
            <List sx={{ px: '2rem', my: 0, py: 0 }}>
                <DocumentsListItem content={formattedMessages.policy_terms_conditions_label} fileUrl="assets/Condizione-di-Assicurazione.pdf" />
            </List>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '14px' }}>{formattedMessages.checkout_acknowledgement_last_line}</Typography>
        </Box>
    );
};

export default DisclaimerContent;
