import React, { useContext, useMemo } from 'react';
import { useFormikContext } from 'formik';
import CustomInput from 'components/customInput';
import { personalDetailsInputList, mobilePersonalDetailsInputList } from 'constant/enrollment';
import { formValueProps } from 'components/customInput/TextInput';
import { providerProps } from 'interfaces/profile';
import { AuthenticationStore } from 'context/Authentication';
import { useMediaQuery } from 'react-responsive';

const PersonalDetailsForm = () => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
    const { values, handleBlur, handleChange } = useFormikContext();

    const { authenticationState }: providerProps = useContext(AuthenticationStore);
    const { emailAddress } = authenticationState;
    const isExistingPartner = useMemo(() => {
        if (emailAddress && emailAddress !== undefined) {
            return true;
        }
        return false;
    }, [emailAddress]);

    return (
        <CustomInput
            boxFlexDirection={isBigScreen ? 'row' : 'column'}
            globalInputDisable={isExistingPartner}
            rootStyle={{ flexWrap: 'wrap', display: 'flex', width: { lg: '100%' }, px: { xs: 1 } }}
            // overwrite the default styling
            boxStyle={{}}
            textInputRootStyle={{ marginRight: isBigScreen ? '1.5rem' : '0', marginBottom: '2rem', flex: 1 }}
            formValue={values as formValueProps}
            handleBlur={handleBlur}
            handleChange={handleChange}
            listInput={isBigScreen ? personalDetailsInputList : mobilePersonalDetailsInputList}
        />
    );
};

export default PersonalDetailsForm;
