import { useEffect } from 'react';
import { Subject, switchMap, of } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { useObservableState } from 'observable-hooks';
import { saveSearchClient, SaveSearchData } from '../services/SaveSearchService';
import { mapPromiseToAsyncStateObservable, AsyncState, ACTION_STATE } from '../observables';

export type InjectedFilter = { saveSearchState: AsyncState<{ data: SaveSearchData[] }> | undefined };

type SaveSubjectProps = { action: 'update'; searchData: SaveSearchData[] } | { action: 'refresh' };

declare global {
    interface Window {
        SaveSearch: {
            SaveSearchSubject: Subject<SaveSubjectProps> | null;
        };
    }
}

if (!window.SaveSearch) {
    window.SaveSearch = {
        SaveSearchSubject: null,
    };
}

function getSaveSearchSubject() {
    if (!window.SaveSearch.SaveSearchSubject) {
        window.SaveSearch.SaveSearchSubject = new Subject<SaveSubjectProps>();
    }
    return window.SaveSearch.SaveSearchSubject;
}

const SaveSearchSubject = getSaveSearchSubject();

const saveSearchObservable = SaveSearchSubject.pipe(
    switchMap((data) => {
        if (data.action === 'refresh') return mapPromiseToAsyncStateObservable(saveSearchClient.getPinnedSaveSearches());
        return of({ loading: false, actionState: ACTION_STATE.COMPLETED, data: { count: data.searchData.length, data: data.searchData } });
    }),
    startWith({ loading: true, actionState: ACTION_STATE.PROCESSING })
);

/**
 * Method to refetch save search and feed latest data to the HOC component
 */
export function saveSearchRefresh(props: SaveSubjectProps) {
    SaveSearchSubject.next(props);
}

/**
 * HOC component for Save search data.
 * @param Component
 * @returns
 */
export function withSaveSearch(Component: React.FunctionComponent<InjectedFilter>) {
    return function () {
        const saveSearchState = useObservableState(saveSearchObservable);

        useEffect(() => {
            SaveSearchSubject.next({ action: 'refresh' });
        }, []);

        return <Component saveSearchState={saveSearchState} />;
    };
}
